import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-vie-files-table',
  templateUrl: './vie-files-table.component.html',
  styleUrls: ['./vie-files-table.component.scss'],
})
export class VieFilesTableComponent implements OnInit {
  url: SafeResourceUrl | string = '';
  fileType: string = '';
  supportedFileTypes: string[] = ['application/pdf', 'image/jpeg', 'image/png'];
  fileSupported = false;
  constructor(public sanitizer: DomSanitizer) {}

  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  ngOnInit(): void {
    this.fileType = this.nzModalData.data.type;
    this.convertBase64toFile(this.nzModalData.data.file, this.fileType);
    if (!this.isSupportedFileType(this.fileType)) {
      this.fileSupported = false;
      this.downloadFile();
    } else {
      this.fileSupported = true;
    }
  }

  convertBase64toFile(base64Data: any, fileType: any): void {
    let url = `data:${fileType};base64,${base64Data}`;
    if (this.isSupportedFileType(fileType)) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.url = url;
    }
  }

  isSupportedFileType(fileType: string): boolean {
    return this.supportedFileTypes.includes(fileType);
  }

  async downloadFile(): Promise<void> {
    await this.saveFile(this.nzModalData.data, this.nzModalData.data.fileName);
  }

  async saveFile(res: any, name: any): Promise<void> {
    let link = document.createElement('a');
    link.href = `data:${res.type};base64,${res.file}`;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
