<div class="f-14 fw-500 lh-24">
        <div>{{nzModalData?.data?.text1}}</div>
        <div>{{nzModalData?.data?.text2}}</div>
    <nz-layout class="action-btn mt-30" style="padding-bottom: 10px">
      <nz-row class="justifyCenter">
        <div class="text-center cPointer">
          <button
            nzType="primary"
            class="f-10 fw-700"
            type="submit"
            (click)="confirm()"
          >
            YES, DELETE
          </button>
        </div>
        <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
          <button
            nz-button
            nzType="default"
            class="buttonDefault f-10 fw-700"
            style="height: 37px; border-radius: 5px; margin-left: 20px"
            (click)="close()"
          >
            NO, DON’T DELETE
          </button>
        </div>
      </nz-row>
    </nz-layout>
  </div>
