<div>
  <div
    class="editable-cell emphasis"
    [hidden]="editId === header.name + ',' + index + ',' + data.entity_id"
    (click)="startEdit(header.name, index)"
    nz-popover
    nzPopoverContent="{{ data[header.name] }}"
  >
    {{ data[header.name] }}
  </div>
  <div class="">
    <textarea
      rows="2"
      nz-input
      [nzAutosize]="{ minRows: 2, maxRows: 6 }"
      [(ngModel)]="data[header.name]"
      [hidden]="editId !== header.name + ',' + index + ',' + data.entity_id"
      (blur)="stopEdit()"
    ></textarea>
  </div>
</div>
