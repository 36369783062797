<nz-row [formGroup]="addStatuessForm" class="addNewFieldContainer inputIconContainer">
    <div formArrayName="statuesDetails" style="width: 100%">
      <nz-layout
        draggable="true"
        (dragstart)="dragStartColumn(i, $event)"
        (dragover)="allowDrop($event)"
        (drop)="dropColumn(i, $event)"
        *ngFor="let variable of statuesDetails().controls; let i = index"
        [formGroupName]="i"
      >
        <nz-row [nzGutter]="10" style="margin-bottom: 10px;">
          <div
            style="cursor: grab;margin: auto;"
            nz-col
            [nzXs]="{ span: 1 }"
            [nzLg]="{ span: 1 }"
            [nzXXl]="{span:1}"
          >
            <img src="../../../../assets/images/icons/arrowDragIcon.svg" />
          </div>
          <div nz-col [nzXs]="{ span: 20 }" [nzLg]="{ span: 20 }" [nzXXl]="{ span: 20 }">
            <div class="d-flex" style="width: 100%">
              <nz-form-item style="width: 100%">
                <nz-form-control nzErrorTip="Enter field name">
                  <nz-input-group>
                    <input
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
                      (input)="inputFieldName(i, $event)"
                      #defaultFieldValue
                      nz-input
                      formControlName="name"
                      placeholder="Option name"/>
                  </nz-input-group>
  
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <!-- <div nz-col [nzXs]="{ span: 11 }" [nzLg]="{ span: 11 }" [nzXXl]="11">
            <div class="d-flex" style="width: 100%">
              <nz-form-item style="width: 100%">
                <nz-form-control nzErrorTip="Enter value">
                  <nz-input-group>
                    <input
                      #defaultFieldValue
                      nz-input
                      formControlName="name"
                      placeholder="Option label"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div> -->
          <div
            style="cursor: grab"
            class="m-auto"
            nz-col
            [nzXs]="{ span: 1 }"
            [nzLg]="{ span: 1 }"
            [nzXXl]="{ span: 1 }"
          >
            <img
              *ngIf="statuesDetails().length > 1 && i > 0"
              class="cPointer"
              src="../../../../assets/images/icons/delete.svg"
             
              (click)="removeStatusOption(i);checkDuplicatename()"
            />
          </div>
          <nz-col [nzXs]="{ span: 20,offset:2}" [nzLg]="{ span: 20,offset:2}" [nzXXl]="{ span: 20 ,offset:2}">
            <div
            style="color: red"
            *ngIf="duplicateNameError && duplicateNameIndex === i"
          >
            Duplicate name entered
          </div>
          </nz-col>
        </nz-row>
      </nz-layout>
    </div>
  
    <nz-col [nzSpan]="24">
      <div class="addMore" style="cursor: pointer" (click)="addMore()">
        ++Add More
      </div>
    </nz-col>
  </nz-row>
  <nz-row style="justify-content: end">
    <div class="text-center cPointer">
      <button
        nzType="primary"
        class="f-10 fw-700 save"
        type="submit"
        style="padding: 8px 24px"
        (click)="confirm()"
      >
        SAVE
      </button>
    </div>
    <div class="text-center f-10 fw-700 lh-18 blueColor">
      <button
        nz-button
        nzType="default"
        class="buttonDefault cancel f-10 fw-700"
        style="border-radius: 5px; margin-left: 20px"
        (click)="close()"
      >
        CANCEL
      </button>
    </div>
  </nz-row>
  