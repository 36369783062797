import { NgModule } from '@angular/core';
import { ProcessoCustomTableComponent } from './processo-custom-table.component';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { CommonModule } from '@angular/common';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DateComponent } from './components/date/date.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { NumberComponent } from './components/number/number.component';
import { TextAreaWrappedComponent } from './components/text-area-wrapped/text-area-wrapped.component';
import { LabelsComponent } from './components/labels/labels.component';
import { PeopleComponent } from './components/people/people.component';
import { TagComponent } from './components/tag/tag.component';
import { ImageComponent } from './components/image/image.component';
import { WebsiteComponent } from './components/website/website.component';
import { PhoneComponent } from './components/phone/phone.component';
import { LocationComponent } from './components/location/location.component';
import { EmailComponent } from './components/email/email.component';
import { DropdownMultipleComponent } from './components/dropdown-multiple/dropdown-multiple.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { TextAreaUnwrappedComponent } from './components/text-area-unwrapped/text-area-unwrapped.component';
import { AddNewFieldComponent } from './add-new-field/add-new-field.component';
import { AddNewTabComponent } from './popup/add-new-tab/add-new-tab.component';
import { AddStatusesPopupComponent } from './popup/add-statuses-popup/add-statuses-popup.component';
import { AddSelectOptionPopupComponent } from './popup/add-select-option-popup/add-select-option-popup.component';
import { AddTagPopupComponent } from './popup/add-tag-popup/add-tag-popup.component';
import { FilterComponent } from './components/filter/FilterComponent';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { UiScrollComponent } from './components/ui-scroll/ui-scroll.component';
import { CustomHeaderTemplateComponent } from './components/custom-header-template/custom-header-template.component';
import { TableHeadComponent } from './components/table-head/table-head.component';
import { TableBodyComponent } from './components/table-body/table-body.component';
import { TableCollapseComponent } from './components/table-collapse/table-collapse.component';
import { CustomTableHeaderComponent } from './components/custom-table-header/custom-table-header.component';
import { CustomTableBodyComponent } from './components/custom-table-body/custom-table-body.component';
import { CustomTableRowComponent } from './components/custom-table-row/custom-table-row.component';
import { CdkVirtualScrollViewportPatchDirective } from './directives/lazyfor.directive';
import { ProgressComponent } from './components/progress/progress.component';
import { StatusComponent } from './components/status/status.component';
import { FluidWidthDirective } from './directives/fluidWidth.directive';
import { FluidRowWidthDirective } from './directives/fluidWIdthRow.directive';
import { AutofocusinputDirective } from './directives/autofocusinput.directive';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { CustomCellComponent } from './components/custom-cell/custom-cell.component';
import { TimeComponent } from './components/time/time.component';
import { PriorityComponent } from './components/priority/priority.component';
import { NgxEditorModule } from 'ngx-editor';
import { VieFilesTableComponent } from './popup/vie-files-table/vie-files-table.component';
@NgModule({
  declarations: [
    ProcessoCustomTableComponent,
    InputFieldComponent,
    DropdownComponent,
    DateComponent,
    RatingsComponent,
    CurrencyComponent,
    NumberComponent,
    TextAreaWrappedComponent,
    LabelsComponent,
    PeopleComponent,
    TagComponent,
    ImageComponent,
    WebsiteComponent,
    PhoneComponent,
    LocationComponent,
    EmailComponent,
    DropdownMultipleComponent,
    CheckboxComponent,
    AttachmentComponent,
    TextAreaUnwrappedComponent,
    AddNewFieldComponent,
    AddNewTabComponent,
    AddStatusesPopupComponent,
    AddSelectOptionPopupComponent,
    AddTagPopupComponent,
    FilterComponent,
    CdkVirtualScrollViewportPatchDirective,
    UiScrollComponent,
    CustomHeaderTemplateComponent,
    TableHeadComponent,
    TableBodyComponent,
    TableCollapseComponent,
    CustomTableHeaderComponent,
    CustomTableBodyComponent,
    CustomTableRowComponent,
    ProgressComponent,
    StatusComponent,
    FluidWidthDirective,
    FluidRowWidthDirective,
    AutofocusinputDirective,
    CustomCellComponent,
    TimeComponent,
    PriorityComponent,
    VieFilesTableComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    NgxEditorModule,
  ],
  exports: [
    ProcessoCustomTableComponent,
    FilterComponent,
    CustomCellComponent,
    VieFilesTableComponent,
  ],
})
export class ProcessoCustomTableModule {}
