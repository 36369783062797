import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  OnInit,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;

  @Input() innerTable: any;
  @Input() parent: any;
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  error = '';
  ngOnInit(): void {
    // if(this.data[this.header.name]){
    //   this.data[this.header.name] = (this.data[this.header.name]).toString()
    // }
    // if(this.header.default){
    //   this.header.default = (this.header.default).toString()
    // }
  }
  editing() {
    if (!this.header.can_edit) {
      return;
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    // this.error = this.numberValidation(this.header, e.target.value);
    this.error = this.numberFieldValidation(this.header, e.target.value);
    this.check_is_unique(e);
  }
  numberValidation(field: any, val: any) {
    return field.mandatory && !val ? `${field.label} is mandatory` : '';
  }
  check_is_unique(e: any) {
    let filterData: any = {};
    let body: any = {
      org_id: this.parent.pservice.appState().selectedOrganization.org_id,
      process_id: this.parent.pservice.appState().selectedProcess.process_id,
      process_name: this.parent.pservice
        .appState()
        .selectedProcess.process_name?.replace(/\s/g, '_'),
      entity_name: this.parent.pservice.appState()?.selectedEntity,
      entity_id: this.data?.entity_id,
    };
    if (this.header.is_unique && this.header.unq_sa) {
      filterData[this.header?.name] = this.data[this.header?.name];
    } else if (this.header.is_unique && !this.header.unq_sa) {
      let total_field = this.parent.pservice
        .appState()
        ?.metaData?.entities?.find(
          (_e: any) =>
            _e.name === this.parent.pservice.appState()?.selectedEntity
        )?.fields;
      total_field.forEach((_fields: any) => {
        if (_fields.is_unique && !_fields.unq_sa) {
          filterData[_fields?.name] = this.data[_fields?.name];
        }
      });
    }
    body['filter'] = filterData;
    if(this.header.is_unique){
      this.parent.pservice.fetchEntityDataUnique(body).subscribe({
        next: (res: any) => {
          if (res) {
            if (res.exists) {
              this.error = `${this.header.label} value already exists`;
            } else {
              this.error = '';
              setTimeout(() => {
                if (this.error?.length === 0 || this.error === '') {
                  this.formatNumber(this.header, e.target.value);
                }
              }, 100);
            }
            this.cdr.detectChanges();
          }
        },
        complete: () => {

        },
      });
    }else{
      setTimeout(() => {
        if (this.error?.length === 0 || this.error === '') {
          this.formatNumber(this.header, e.target.value);
        }
      }, 100);
    }

  }
  formatNumber(field: any, input: any) {
    const inputValue = input;
    if (field.seperator && inputValue) {
      const numericValue = Number(inputValue?.replace(/,/g, ''));
      if (numericValue) {
        let separator = '';
        if (field.seperator.toLowerCase() === 'millions') {
          separator = 'en-US';
        } else if (field.seperator.toLowerCase() === 'thousands') {
          separator = 'en-IN';
        } else {
          separator = '';
        }
        const formattedValue = this.formatNumberWithSeparators(
          numericValue,
          separator,
          field.decimal
        );
        this.data[this.header.name] = formattedValue.toString();

        if (formattedValue) {
          if (!this.error) {
            let tableEdit: any;
            if (this.innerTable) {
              tableEdit = 'innerTable';

              this.parent.updateDataOnInnerStopEdit(
                { target: { value: Number(formattedValue) } },
                this.header.name,
                this.data
              );
              this.editval.next(false);
            } else {
              this.parent.updateDataOnStopEdit(
                {
                  target: { value: Number(formattedValue?.replace(/,/g, '')) },
                },
                this.header.name,
                this.data,
                this.parent.erulistData
              );
              this.editval.next(false);
            }
            let data: any = {};
            this.parent.saveEntityDropdownData(
              this.header,
              tableEdit,
              this.data
            );
          }
        }
      }
    } else if (input === '') {
      if (!this.error) {
        let tableEdit: any;
        if (this.innerTable) {
          tableEdit = 'innerTable';

          this.parent.updateDataOnInnerStopEdit(
            { target: { value: null } },
            this.header.name,
            this.data
          );
          this.editval.next(false);
        } else {
          this.parent.updateDataOnStopEdit(
            { target: { value: null } },
            this.header.name,
            this.data,
            this.parent.erulistData
          );
          this.editval.next(false);
        }
        this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
      }
    }
  }
  formatNumberWithSeparators(
    value: number,
    format: string,
    decimal: number
  ): string {
    if (format) {
      const formattedValue = value.toLocaleString(format, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formattedValue;
    } else {
      return value.toFixed(decimal || 0);
    }
  }
  numberFieldValidation(field: any, event: any) {
    if (field.mandatory && !event) {
      return `${field.label} is mandatory`;
    }
    let inputText = event?.replace(/[-.]/g, '');
    let length = Number(field?.num_val);
    if (event?.length > 0) {
      if (field?.num_val_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.num_val}`;
        } else {
          return '';
        }
      } else if (field?.num_val_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.num_val}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
