<div style="display: flex; height: 100%;column-gap: 15px;">
  <!-- <div class="f-12 fw-400 my-auto" style="min-width: 137px">
    <span class="customTabStyle CPointer" (click)="openAddmore()"
      >Manage Entities</span
    >
  </div> -->
  <button
  nz-tooltip
  nzTooltipPlacement="bottom"
  [nzTooltipOverlayClassName]="'toolTipContainers'"
  [nzTooltipTitle]="'Manage Entities'"
  style="min-width: auto !important;margin: 0px!important;"
  nz-button
  nzType="text"
  (click)="openAddmore()"
  class="tool_button"
>
  <img
    style="padding-right: 0 !important"
    src="../../../../assets/images/icons/manage_entities.svg"
  />
</button>
  <ng-container>
    <button
    nz-tooltip
    nzTooltipPlacement="bottom"
    [nzTooltipOverlayClassName]="'toolTipContainers'"
    [nzTooltipTitle]="'My Approval'"
    style="min-width: auto !important;margin: 0px!important;"
    nz-button
    nzType="text"
    (click)="open_my_approval()"
    class="tool_button"
  >
    <img
      style="padding-right: 0 !important"
      src="../../../../assets/images/icons/my_approval_icon.svg"
    />
  </button>
  </ng-container>

  <div
    class="f-12"
    [hidden]="!app_service.is_mobile()"
    
  >
  <button
  nz-tooltip
  nzTooltipPlacement="bottom"
  [nzTooltipOverlayClassName]="'toolTipContainers'"
  [nzTooltipTitle]="'More Filter'"
  style="min-width: auto !important;margin: 0px!important;"
  nz-button
  nzType="text"
  (click)="show_more_action()"
  class="tool_button"
>
  <img
    style="padding-right: 0 !important;width: 20px;height: 20px;"
    src="../../../../assets/images/icons/filterIcon.svg"
  />
</button>

    <!-- <button nz-button nzType="default" class="save_btn_style">
      More Action &nbsp;
      <img src="../../../../assets/images/icons/downArrow.svg"/>
    </button> -->
  </div>
</div>
