<div class="f-14 fw-500 lh-24">
    <div>Any unsaved data will be lost.</div>
    <div> Are you sure you want to proceed ?</div>
    <nz-layout class="action-btn mt-30" style="padding-bottom: 10px">
        <nz-row class="justifyCenter">
          <div class="text-center cPointer"  style="border: none!important;">
            <button 
            style="border: none!important;"
              nzType="primary"
              class="f-10 fw-700"
              type="button"
              (click)="stay()"
            >
            STAY
             
            </button>
          </div>
          <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
            <button
              nz-button
              nzType="default"
              class="buttonDefault f-10 fw-700"
              style="height: 37px; border-radius: 5px; margin-left: 20px"
                (click)="leave()"
            >
            LEAVE
            </button>
          </div>
        </nz-row>
      </nz-layout>
</div>
