<div
  *ngIf="selectedMenu"
  class="form-fields inputIconContainer selectContainer"
>
  <div class="newColumn1 containers">
    <section>
      <nz-row [nzGutter]="16">
        <div
          [ngClass]="{ disableField: isEdit }"
          [style.pointerEvents]="isEdit ? 'none' : 'auto'"
          nz-col
          [nzXs]="{ span: 12 }"
          [nzLg]="{ span: 12 }"
          [nzXXl]="12"
        >
          <nz-form-label class="dgrey-text titletext" [nzNoColon]="true"
            >Data Type</nz-form-label
          >
          <nz-form-item>
            <nz-form-control>
              <ng-container>
                <nz-select
                  (click)="filterDataTYpe('')"
                  nzPlaceHolder="Select "
                  [(ngModel)]="formData.data_type.value"
                  (ngModelChange)="selectDataTYpe($event)"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateDataType"
                >
                  <ng-template #selectSearchTemplateDataType>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterDataTYpe(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of newColumnValue"
                    [nzValue]="item.datatype"
                    [nzLabel]="item.label | titlecase"
                  ></nz-option>
                </nz-select>
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-row>
    </section>
  </div>
  <nz-divider></nz-divider>
  <nz-row [nzGutter]="16" style="margin-bottom: 10px">
    <div
      [style.pointerEvents]="isEdit ? 'none' : 'auto'"
      nz-col
      [nzXs]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXXl]="12"
    >
      <nz-form-item>
        <nz-form-label
          [nzXs]="{ span: 24 }"
          class="dgrey-text titletext"
          [nzLabelAlign]="'left'"
          [nzNoColon]="true"
          >Field Name <span class="redColor">*</span></nz-form-label
        >
        <nz-form-control [nzXs]="{ span: 24 }">
          <input
            class="f-12"
            #defaultFieldValue
            nz-input
            [(ngModel)]="formData?.fieldName.value"
            oninput="this.value = this.value.replace(/[^a-z_]/g, '');"
            [placeholder]="formData?.fieldName?.placeholder"
            (input)="
              checkInputValidation(formData?.fieldName.value, 'fieldName')
            "
          />
        </nz-form-control>
      </nz-form-item>
      <span
        style="color: red; font-size: smaller"
        *ngIf="
          formData?.fieldName.validators?.required &&
          formData?.fieldName.validators?.error
        "
        >{{ formData?.fieldName?.validators?.error | titlecase }}</span
      >
    </div>
    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
      <nz-form-item>
        <nz-form-label
          [nzXs]="{ span: 24 }"
          [nzLabelAlign]="'left'"
          class="dgrey-text titletext"
          [nzNoColon]="true"
          >Field Label <span class="redColor">*</span></nz-form-label
        >
        <nz-form-control [nzXs]="{ span: 24 }">
          <input
            class="f-12"
            #defaultFieldValue
            nz-input
            [(ngModel)]="formData?.fieldLabel.value"
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
            [placeholder]="formData?.fieldLabel?.placeholder"
            (input)="
              checkInputValidation(formData?.fieldLabel.value, 'fieldLabel')
            "
          />
        </nz-form-control>
      </nz-form-item>
      <span
        style="color: red; font-size: smaller"
        *ngIf="
          formData?.fieldLabel.validators?.required &&
          formData?.fieldLabel.validators?.error
        "
      >
        {{ formData?.fieldLabel?.validators?.error | titlecase }}</span
      >
    </div>
    <ng-container
      *ngIf="
        [
          'status',
          'number',
          'currency',
          'date',
          'time',
          'currency',
          'progress',
          'rating'
        ].includes(formData.data_type.value)
      "
    >
      <ng-container *ngIf="entityData?.length > 0">
        <nz-row
          class="w-100 mb-10 entityContainer"
          [nzAlign]="'middle'"
          [nzGutter]="16"
        >
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="switchStyle"
          >
            <nz-form-item>
              <nz-form-control>
                <div class="" style="display: flex; align-items: center">
                  <nz-switch
                    [(ngModel)]="formData.dfc"
                    id="switch0"
                    (ngModelChange)="derivedFromChild($event)"
                  ></nz-switch>
                  <label for="switch0" class="f-12"
                    >&nbsp;&nbsp;Derived from Child Record</label
                  >
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="switchStyle"
          >
            <nz-form-item>
              <nz-form-control>
                <div class="" style="display: flex; align-items: center">
                  <nz-switch
                    [(ngModel)]="formData.editable"
                    id="switch8"
                  ></nz-switch>
                  <label for="switch8" class="f-12"
                    >&nbsp;&nbsp;Editable
                  </label>
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-container *ngIf="formData.dfc">
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-label class="dgrey-text titletext" [nzNoColon]="true"
                >Entity <span class="redColor">*</span></nz-form-label
              >
              <nz-form-item>
                <nz-form-control>
                  <ng-container>
                    <nz-select
                      (click)="tabFieldsEntity = entityData"
                      nzPlaceHolder="Select "
                      [(ngModel)]="formData.ce"
                      (ngModelChange)="
                        selectEntityType($event);
                        formData.ce ? (showdfcEntityError = false) : ''
                      "
                      nzAllowClear
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateEntity"
                    >
                      <!-- <nz-option class="f-12" [nzValue]="formData.ce" [nzLabel]="formData.ce"
                    nzHide></nz-option> -->
                      <ng-template #selectSearchTemplateEntity>
                        <input
                          #saearhx2
                          nz-input
                          placeholder="Search..."
                          (input)="filterEntity(saearhx2.value)"
                        />
                      </ng-template>
                      <nz-option [nzLabel]="formData.ce" [nzValue]="formData.ce" nzHide></nz-option>
                      <ng-container *ngFor="let item of tabFieldsEntity">
                        <ng-container *ngIf="item.name !== tabName">
                          <nz-option
                            [nzLabel]="item.label"
                            [nzValue]="item.name"
                          ></nz-option>
                        </ng-container>
                      </ng-container>
                    </nz-select>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !(entityData?.length > 0) &&
                      formData.data_type.value !== ''
                    "
                  >
                    <div *ngIf="!showdfcEntityError" class="errorStyle">
                      The selected data type has no Entity.
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!formData.ce && showdfcEntityError">
                    <div class="errorStyle">Entity is required</div>
                  </ng-container>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-label class="dgrey-text titletext" [nzNoColon]="true"
                >Fields <span class="redColor">*</span></nz-form-label
              >
              <nz-form-item>
                <nz-form-control>
                  <ng-container>
                    <nz-select
                      (click)="tabFields = savetabFields"
                      nzPlaceHolder="Select "
                      [nzDropdownRender]="selectSearchTemplateFields"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [(ngModel)]="formData.cef"
                      nzAllowClear
                      (ngModelChange)="
                        formData.cef ? (showdfcFieldError = false) : ''
                      "
                    >
                      <ng-template #selectSearchTemplateFields>
                        <input
                          #saearhFields
                          nz-input
                          placeholder="Search..."
                          (input)="filterFields(saearhFields.value)"
                        />
                      </ng-template>
                      <nz-option [nzLabel]="formData.cef" [nzValue]="formData.cef" nzHide></nz-option>
                      <ng-container *ngFor="let items of tabFields">
                        <nz-option
                          [nzLabel]="items.label"
                          [nzValue]="items.name"
                        ></nz-option>
                      </ng-container>
                    </nz-select>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !(tabFields?.length > 0) &&
                      formData.ce !== '' &&
                      !formData.cef
                    "
                  >
                    <div class="errorStyle" *ngIf="!showdfcFieldError">
                      The selected data type does not contain any field
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!formData.cef && showdfcFieldError">
                    <div class="errorStyle">Fields is required</div>
                  </ng-container>
                </nz-form-control>
              </nz-form-item>
            </div>
            <ng-container
              *ngIf="
                formData.data_type.value === 'date' ||
                formData.data_type.value === 'number' ||
                formData.data_type.value === 'time' ||
                formData.data_type.value === 'progress' ||
                formData.data_type.value === 'rating' ||
                formData.data_type.value === 'currency'
              "
            >
              <nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
                <nz-form-label class="dgrey-text titletext" [nzNoColon]="true"
                  >Aggregation <span class="redColor"></span
                ></nz-form-label>
                <div>
                  <nz-radio-group [(ngModel)]="formData.cefa">
                    <label nz-radio nzValue="min" class="f-12">Min</label>
                    <label nz-radio nzValue="max" class="f-12">Max</label>
                    <label
                      *ngIf="
                        formData.data_type.value === 'number' ||
                        formData.data_type.value === 'time' ||
                        formData.data_type.value === 'currency' ||
                        formData.data_type.value === 'progress'
                      "
                      nz-radio
                      nzValue="sum"
                      class="f-12"
                      >Sum</label
                    >
                  </nz-radio-group>
                </div>
              </nz-col>
            </ng-container>
          </ng-container>
        </nz-row>
      </ng-container>
    </ng-container>
  </nz-row>
  <ng-container *ngIf="Object.keys(formData.fieldForm).length > 0">
    <ng-container [ngSwitch]="formData.data_type.value">
      <ng-container *ngSwitchCase="'date'">
        <nz-row [nzGutter]="16" class="mt-10">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Date Format</nz-form-label
              >

              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  [(ngModel)]="formData?.fieldForm?.dateFormat.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    *ngFor="
                      let option of formData?.fieldForm?.dateFormat?.options
                    "
                    [nzValue]="option[0]"
                    [nzLabel]="option[1]"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.dfc"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Value</nz-form-label
              >
              <!-- <nz-form-control [nzXs]="{ span: 24 }">
              <nz-date-picker [nzAllowClear]="false" style="width: 100%; height: 37px" *ngIf="!formData.default_value"
               [(ngModel)]="formData.default_value"
                [nzPlaceHolder]="'Select Date'"
                [nzFormat]="formData?.fieldForm?.dateFormat.value"
                ></nz-date-picker>
              <nz-date-picker [nzAllowClear]="false" *ngIf="formData.default_value" style="width: 100%; height: 37px"
                [(ngModel)]="formData.default_value"
                [nzPlaceHolder]="'Select Date'"
                [nzFormat]="formData?.fieldForm?.dateFormat.value"
                ></nz-date-picker>
            </nz-form-control> -->
              <nz-select
                style="width: 100%"
                (ngModelChange)="formData.default_value = $event"
                [ngModel]="formData.default_value ? 'Current_Date' : 'Blank'"
                [nzDropdownClassName]="'dropDownContainer'"
                nzAllowClear
              >
                <nz-option
                  class="f-12"
                  [nzValue]="''"
                  [nzLabel]="''"
                ></nz-option>
                <nz-option
                  class="f-12"
                  [nzValue]="'Current_Date'"
                  [nzLabel]="'Current Date'"
                ></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </nz-row>
        <nz-row style="width: 100%" *ngIf="!formData.dfc">
          <nz-form-item style="width: 100%">
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Allow Days</nz-form-label
            >

            <div
              class=""
              *ngFor="let j of formData?.fieldForm?.AllowDays; let i = index"
            >
              <button
                (click)="j.checked = true"
                *ngIf="!j?.checked"
                [ngStyle]="{ 'margin-left': i != 0 ? '15px' : '' }"
                style="
                  color: black !important;
                  padding: 8px 10px;
                  min-width: 52px;
                  border: 1px solid #1d66ff !important;
                  background: white !important;
                "
              >
                {{ j?.value }}
              </button>
              <button
                (click)="j.checked = false"
                *ngIf="j?.checked"
                [ngStyle]="{ 'margin-left': i != 0 ? '15px' : '' }"
                style="
                  color: white !important;
                  padding: 8px 10px;
                  min-width: 52px;
                "
              >
                {{ j?.value }}
              </button>
            </div>
          </nz-form-item>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'textbox'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Data Length</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  class="f-12"
                  #defaultFieldValue
                  nz-input
                  type="text"
                  (input)="
                    numberInputValue(formData?.fieldForm?.dataLength.value)
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLength.value"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="disableSelect"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Data Length Check</nz-form-label
              >

              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  nzPlaceHolder="Select"
                  [disabled]="
                    !(formData?.fieldForm?.dataLength?.value?.length > 0)
                  "
                  (ngModelChange)="
                    textboxValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      $event
                    )
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    *ngFor="
                      let item of formData?.fieldForm?.dataLengthCheckOptions
                    "
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  ></nz-option>
                </nz-select>
                <div
                  class="errorText"
                  *ngIf="
                    formData?.fieldForm?.dataLength?.value?.toString()?.length >
                      0 &&
                    formData?.fieldForm?.dataLengthCheck.value?.length === 0
                  "
                >
                  required Data Length Check
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.is_unique"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  (input)="
                    textboxValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      formData?.fieldForm?.dataLengthCheck.value
                    )
                  "
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'Enter'"
                  class="f-12"
                />
                <div class="errorText" *ngIf="!(number_default_error === '')">
                  {{ number_default_error }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'priority'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Priority Options &nbsp;
              <img src="../../../assets/images/icons/blueInfoIcon.svg" alt=""
            /></nz-form-label>
            <div class="statusesContainer" style="padding: 10px 14px">
              <div
                *ngFor="let item of formData.fieldForm.options; let i = index"
                style="
                  height: 30px;
                  border-radius: 100px;
                  width: 100%;
                  padding: 6px 8px;
                  margin-top: 5px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <img
                      src="../../../assets/images/icons/greenbullet.svg"
                      alt=""
                    />
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-row>
        <nz-row [nzGutter]="16">
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="mt-20"
            *ngIf="!formData.is_unique"
          >
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Default Value</nz-form-label
            >
            <input
              nz-dropdown
              nzTrigger="click"
              readonly
              [nzDropdownMenu]="menu"
              nz-input
              [(ngModel)]="formData.default_value"
              [placeholder]="'Select'"
              class="f-12"
              (click)="
                priorityDefaultOption = formData.fieldForm.options;
                saearhx2.value = ''
              "
            />
            <nz-dropdown-menu #menu="nzDropdownMenu" nzPlacement="bottomRight">
              <input
                #saearhx2
                nz-input
                placeholder="Search..."
                (input)="filterpriorityDefault(saearhx2.value)"
              />
              <ul
                nz-menu
                (click)="$event.preventDefault(); $event.stopPropagation()"
                nzSelectable
                class="option-list"
              >
                <ng-container *ngFor="let item of priorityDefaultOption">
                  <li
                    #menuitem
                    nz-menu-item
                    (click)="formData.default_value = item.name"
                  >
                    <span
                      style="
                        padding: 2px;
                        border-radius: 50%;
                        pointer-events: auto;
                      "
                    >
                      <div class="avtarcheck">
                        <span
                          nz-icon
                          nzType="flag"
                          nzTheme="fill"
                          [style.color]="getColor(item.name)"
                        ></span>
                      </div>
                    </span>
                    <span style="margin-left: 5px; pointer-events: auto">{{
                      item.name
                    }}</span>
                  </li>
                </ng-container>
              </ul>
            </nz-dropdown-menu>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'textarea'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Data Length</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  type="number"
                  [(ngModel)]="formData?.fieldForm?.dataLength.value"
                  (input)="
                    numberInputValue(formData?.fieldForm?.dataLength.value)
                  "
                  [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="disableSelect"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Data Length Check</nz-form-label
              >

              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  nzPlaceHolder="Select "
                  [disabled]="
                    !(
                      formData?.fieldForm?.dataLength?.value?.toString()
                        ?.length > 0
                    )
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                  (ngModelChange)="
                    textboxValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      $event
                    )
                  "
                  [nzDropdownClassName]="'dropDownContainer'"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    *ngFor="
                      let item of formData?.fieldForm?.dataLengthCheckOptions
                    "
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  ></nz-option>
                </nz-select>
                <div
                  class="errorText"
                  *ngIf="
                    formData?.fieldForm?.dataLength?.value?.toString()?.length >
                      0 &&
                    formData?.fieldForm?.dataLengthCheck.value?.length === 0
                  "
                >
                  required Data Length Check
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.rich_text || !formData.is_unique"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  (input)="
                    textboxValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      formData?.fieldForm?.dataLengthCheck.value
                    )
                  "
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'Enter'"
                  class="f-12"
                />
                <div class="errorText" *ngIf="!(number_default_error === '')">
                  {{ number_default_error }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'location'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'ENTER'"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'checkbox'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <label nz-checkbox [ngModel]="formData.default_value" (ngModelChange)="formData.default_value=$event"></label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'currency'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Decimal</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  nz-input
                  [(ngModel)]="formData.fieldForm.Decimal.value"
                  [placeholder]="formData?.fieldForm?.Decimal?.placeholder"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Separator</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  [(ngModel)]="formData.fieldForm.Seperator.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    *ngFor="let item of formData?.fieldForm?.SeparatorOptions"
                    [nzValue]="item.value"
                    [nzLabel]="item.label | titlecase"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Number Value
              </nz-form-label>
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  class="f-12"
                  #defaultFieldValue
                  nz-input
                  type="text"
                  (input)="
                    numberInputValue(formData?.fieldForm?.dataLength.value)
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLength.value"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="disableSelect"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Number Value Check</nz-form-label
              >

              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  nzPlaceHolder="Select"
                  [disabled]="
                    formData?.fieldForm?.dataLength?.value?.length === 0
                  "
                  (ngModelChange)="
                    numberFieldValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      $event
                    )
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                >
                  <nz-option
                    class="f-12"
                    *ngFor="
                      let item of formData?.fieldForm?.dataLengthCheckOptions
                    "
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  ></nz-option>
                </nz-select>
                <div
                  class="errorText"
                  *ngIf="
                    !(formData?.fieldForm?.dataLength?.value?.length === 0) &&
                    formData?.fieldForm?.dataLengthCheck.value?.length === 0
                  "
                >
                  required Number Value Check
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Symbol</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="saveCurrencyLists = CurrencyLists; getCurrencyList()"
                  [(ngModel)]="formData.fieldForm.Symbol.value"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateCurrency"
                  nzAllowClear
                >
                  <ng-template #selectSearchTemplateCurrency>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterCurrency(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of CurrencyLists"
                    [nzValue]="item.CurrencySymbol"
                    [nzLabel]="item.CurrencySymbol"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData.default_value"
                  (input)="
                    numberFieldValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      formData?.fieldForm?.dataLengthCheck.value
                    )
                  "
                  oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
                  class="f-12"
                  [placeholder]="'ENTER'"
                />
              </nz-form-control>
              <div class="errorText" *ngIf="!(number_default_error === '')">
                {{ number_default_error }}
              </div>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'status'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Open Statuses &nbsp;
              <img src="../../../assets/images/icons/blueInfoIcon.svg" alt=""
            /></nz-form-label>
            <div
              class="statusesContainer"
              [ngClass]="
                getDropdown(formData.fieldForm.open_status).length === 0
                  ? 'd-flex'
                  : ''
              "
              style="padding: 10px 14px"
            >
              <div
                class="cPointer f-12"
                *ngIf="getDropdown(formData.fieldForm.open_status).length === 0"
                style="color: #1d66ff; margin: auto; text-align: center"
                (click)="openStatuses(formData)"
              >
                Add Open Statuses
              </div>
              <div
                *ngFor="
                  let item of getDropdown(formData.fieldForm.open_status);
                  let i = index
                "
                [style.background]="hexToRgba(item.color, 0.2)"
                [style.color]="item.color"
                style="
                  height: 30px;
                  border-radius: 100px;
                  width: 100%;
                  padding: 6px 8px;
                  margin-top: 5px;
                  font-weight: 500;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <div [style.color]="item.color">
                    <img
                      src="../../../assets/images/icons/greenbullet.svg"
                      alt=""
                    />
                    {{ item.name }}
                  </div>
                  <div>
                    <!-- <img class="ml-14 cPointer" src="../../../assets/images/icons/editIcon.svg" /> -->
                    <img
                      *ngIf="!formData.dfc"
                      class="ml-14 cPointer"
                      (click)="deleteStatus(i)"
                      src="../../../assets/images/icons/deleteIcon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="getDropdown(formData.fieldForm.open_status).length > 0"
              class="cPointer f-12"
              style="color: #1d66ff"
              (click)="openStatuses(formData)"
            >
              Add Open Statuses
              <span
                *ngIf="formData.dfc && false"
                style="font-size: 14px !important"
                >Color</span
              >
            </div>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Close Statuses &nbsp;
              <img src="../../../assets/images/icons/blueInfoIcon.svg" alt=""
            /></nz-form-label>
            <div
              class="statusesContainer"
              [ngClass]="
                getDropdown(formData.fieldForm.close_status).length === 0
                  ? 'd-flex'
                  : ''
              "
              style="padding: 10px 14px"
            >
              <div
                class="cPointer f-12"
                *ngIf="
                  getDropdown(formData.fieldForm.close_status).length === 0
                "
                style="color: #1d66ff; margin: auto; text-align: center"
                (click)="closeStatuses()"
              >
                Add Close Statuses
              </div>
              <div
                *ngFor="
                  let item of getDropdown(formData.fieldForm.close_status);
                  let i = index
                "
                [style.background]="hexToRgba(item.color, 0.2)"
                [style.color]="item.color"
                style="
                  height: 30px;
                  border-radius: 100px;
                  width: 100%;
                  padding: 6px 8px;
                  margin-top: 5px;
                  font-weight: 500;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <div [style.color]="item.color">
                    <img
                      src="../../../assets/images/icons/greenbullet.svg"
                      alt=""
                    />
                    {{ item.name }}
                  </div>
                  <div>
                    <!-- <img class="ml-14 cPointer" src="../../../assets/images/icons/editIcon.svg" /> -->
                    <img
                      *ngIf="!formData.dfc"
                      class="ml-14 cPointer"
                      (click)="delteCloseStatus(i)"
                      src="../../../assets/images/icons/deleteIcon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="getDropdown(formData.fieldForm.close_status).length > 0"
              class="cPointer f-12"
              style="color: #1d66ff"
              (click)="closeStatuses()"
            >
              Add Close Statuses
              <span *ngIf="formData.dfc && false">Color</span>
            </div>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.dfc"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Status
              </nz-form-label>
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="filterStatusDefault('')"
                  [(ngModel)]="formData.default_value"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateStatusDefault"
                  nzAllowClear
                  nzPlaceHolder="Select"
                >
                  <ng-template #selectSearchTemplateStatusDefault>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterStatusDefault(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of filteredStatusOptions"
                    [nzValue]="item.name"
                    [nzLabel]="item.name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'tag'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-label
              [nzXs]="{ span: 24 }"
              [nzLabelAlign]="'left'"
              class="dgrey-text titletext"
              [nzNoColon]="true"
              >Tag Options &nbsp;
              <img src="../../../assets/images/icons/blueInfoIcon.svg" alt=""
            /></nz-form-label>
            <div
              class="statusesContainer"
              [ngClass]="
                getDropdown(formData.fieldForm.options).length === 0
                  ? 'd-flex'
                  : ''
              "
              style="padding: 10px 14px"
            >
              <div
                class="cPointer"
                *ngIf="getDropdown(formData.fieldForm.options).length === 0"
                style="color: #1d66ff; margin: auto; text-align: center"
                (click)="addtag('addNew')"
              >
                Tag Options
              </div>
              <div
                *ngFor="
                  let item of getDropdown(formData.fieldForm.options);
                  let i = index
                "
                [style.background]="item.color"
                style="
                  height: 30px;
                  border-radius: 100px;
                  width: 100%;
                  padding: 6px 8px;
                  margin-top: 5px;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <img
                      src="../../../assets/images/icons/greenbullet.svg"
                      alt=""
                    />
                    {{ item.name }}
                  </div>
                  <div>
                    <!-- <img class="ml-14 cPointer" src="../../../assets/images/icons/editIcon.svg" /> -->
                    <img
                      class="ml-14 cPointer"
                      (click)="deletetag(i)"
                      src="../../../assets/images/icons/deleteIcon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="getDropdown(formData.fieldForm.options).length > 0"
              class="cPointer"
              style="color: #1d66ff"
              (click)="addtag('addNew')"
            >
              Add Tag
            </div>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
          ></div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <!-- <nz-form-control [nzXs]="{ span: 24 }">

                <nz-select [(ngModel)]="formData.default_value">
                  <nz-option *ngFor="let item of formData.fieldForm.optionType" [nzValue]="item.value"
                    [nzLabel]="item.name"></nz-option>
                </nz-select>

            </nz-form-control> -->
              <nz-form-control>
                <nz-select
                  (click)="saveTagDefault = formData.fieldForm.options"
                  [nzRemoveIcon]="null"
                  class="dropdownui"
                  [(ngModel)]="formData.default_value"
                  [nzMaxTagCount]="2"
                  nzMode="multiple"
                  [nzMaxTagPlaceholder]="tagPlaceHolder"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateTagDefault"
                  class="cPointer"
                  style="cursor: pointer !important"
                  nzPlaceHolder="Select"
                >
                  <ng-template #selectSearchTemplateTagDefault>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterTagDefault(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-layout *ngFor="let option of formData.fieldForm.options">
                    <nz-option
                      class="f-12"
                      nzCustomContent
                      [nzValue]="option?.name"
                      [nzLabel]="option.name"
                    >
                      <ng-container>
                        <div class="f-12">{{ option.name }}</div>
                      </ng-container>
                    </nz-option>
                  </nz-layout>
                </nz-select>

                <ng-template
                  #tagPlaceHolder
                  let-selectedList
                  style="background-color: none !important"
                >
                  +{{ selectedList.length }} more</ng-template
                >
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'rating'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Emoji Type</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  [(ngModel)]="formData.fieldForm.emojiValue"
                  [nzDropdownClassName]="'dropDownContainer'"
                  [nzCustomTemplate]="defaultTemplate"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    nzCustomContent
                    *ngFor="let item of formData?.fieldForm?.emojiType"
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  >
                    <img [src]="item.img1" alt="" /> &nbsp;
                    <img [src]="item.img2" alt="" />
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <ng-template #defaultTemplate let-selected>
              <div>
                <img [src]="getEmojiicon(selected.nzLabel, 'fill')" alt="" />
                &nbsp;
                <img
                  [src]="getEmojiicon(selected.nzLabel, 'outlined')"
                  alt=""
                />
              </div>
            </ng-template>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Number</nz-form-label
              >
              <nz-slider
                [nzMax]="5"
                [nzMin]="1"
                class="sliderContainer"
                [nzTipFormatter]="null"
                [nzStep]="1"
                [nzMarks]="marks"
                [(ngModel)]="formData.fieldForm.number.value"
                style="width: 100%"
              ></nz-slider>
            </nz-form-item>
          </div>
          <!-- <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
                    <nz-form-item>
                        <nz-form-label [nzXs]="{ span: 24}" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                            [nzNoColon]="true">Number</nz-form-label>
                        <nz-slider class="sliderContainer" [nzTipFormatter]="null" [nzMarks]="marks"
                            style="width: 100%;" [(ngModel)]="formData?.fieldForm?.default.value"></nz-slider>
                    </nz-form-item>
                </div> -->
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Decimal</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  nz-input
                  [(ngModel)]="formData.fieldForm.Decimal.value"
                  [placeholder]="formData?.fieldForm?.Decimal?.placeholder"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Separator</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  [(ngModel)]="formData.fieldForm.Seperator.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                  nzAllowClear
                >
                  <nz-option
                    class="f-12"
                    *ngFor="let item of formData?.fieldForm?.SeparatorOptions"
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Number Value
              </nz-form-label>
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  class="f-12"
                  #defaultFieldValue
                  nz-input
                  type="text"
                  (input)="
                    numberInputValue(formData?.fieldForm?.dataLength.value)
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLength.value"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            class="disableSelect"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Number Value Check</nz-form-label
              >

              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  nzPlaceHolder="Select"
                  [disabled]="
                    formData?.fieldForm?.dataLength?.value?.length === 0
                  "
                  (ngModelChange)="
                    numberFieldValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      $event
                    )
                  "
                  [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                  [nzDropdownClassName]="'dropDownContainer'"
                >
                  <nz-option
                    class="f-12"
                    *ngFor="
                      let item of formData?.fieldForm?.dataLengthCheckOptions
                    "
                    [nzValue]="item.value"
                    [nzLabel]="item.value"
                  ></nz-option>
                </nz-select>
                <div
                  class="errorText"
                  *ngIf="
                    !(formData?.fieldForm?.dataLength?.value?.length === 0) &&
                    formData?.fieldForm?.dataLengthCheck.value?.length === 0
                  "
                >
                  required Number Value Check
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.dfc || !formData.is_unique"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  (input)="
                    numberFieldValidation(
                      formData?.fieldForm?.dataLength.value,
                      formData.default_value,
                      formData?.fieldForm?.dataLengthCheck.value
                    )
                  "
                  [(ngModel)]="formData.default_value"
                  oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
                  class="f-12"
                  [placeholder]="'ENTER'"
                />
              </nz-form-control>
              <div class="errorText" *ngIf="!(number_default_error === '')">
                {{ number_default_error }}
              </div>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'website'">
        <nz-row [nzGutter]="16">
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="!formData.is_unique"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'Enter'"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'phone'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <!-- <nz-form-item>
            <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
              [nzNoColon]="true">Default Value</nz-form-label>
            <nz-form-control [nzXs]="{ span: 24 }">
              <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value"
                [placeholder]="'Enter'" />
            </nz-form-control>
          </nz-form-item> -->
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'progress'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Start Value <span class="redColor">*</span></nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData?.fieldForm?.startValue.value"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  [placeholder]="formData?.fieldForm?.startValue?.placeholder"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
            <div
              *ngIf="
                !(formData?.fieldForm?.startValue.value?.toString().length > 0)
              "
              style="color: red; font-size: smaller"
            >
              start value required
            </div>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >End Value <span class="redColor">*</span></nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData?.fieldForm?.endValue.value"
                  [placeholder]="formData?.fieldForm?.endValue?.placeholder"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
            <div
              *ngIf="
                !(formData?.fieldForm?.endValue.value?.toString().length > 0)
              "
              style="color: red; font-size: smaller"
            >
              end value required
            </div>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'Enter'"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>

      <ng-container *ngSwitchCase="'attachment'">
        <nz-row [nzGutter]="16"> </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'dropdown_single_select'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }" [nzXXl]="24">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Option Type</nz-form-label
              >
              <nz-radio-group
                [(ngModel)]="formData.fieldForm.optionType"
                (ngModelChange)="optionTypeChange($event)"
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <label nz-radio nzValue="STATIC" class="f-12">Static</label>
                <label nz-radio nzValue="ENTITY_DATA" class="f-12"
                  >Entity</label
                >
                <label nz-radio nzValue="API" class="f-12">API</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 24 }"
            [nzLg]="{ span: 24 }"
            [nzXXl]="24"
            style="display: flex"
            *ngIf="formData.fieldForm.optionType === 'ENTITY_DATA'"
          >
            <nz-row style="width: 100%" [nzGutter]="16">
              <div
                nz-col
                [nzXs]="{ span: 12 }"
                [nzLg]="{ span: 12 }"
                [nzXXl]="12"
              >
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    class="dgrey-text titletext"
                    [nzLabelAlign]="'left'"
                    [nzNoColon]="true"
                    >Entity</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      (ngModelChange)="getEntityFields()"
                      (click)="entities = savedEntities"
                      [(ngModel)]="formData.fieldForm.entity_name"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateEntity"
                      nzAllowClear
                    >
                      <ng-template #selectSearchTemplateEntity>
                        <input
                          #saearhx2
                          nz-input
                          placeholder="Search..."
                          (input)="filterEntities(saearhx2.value)"
                        />
                      </ng-template>
                      <nz-option [nzLabel]="formData.fieldForm.entity_name" [nzValue]="formData.fieldForm.entity_name" nzHide></nz-option>
                      <nz-option
                        class="f-12"
                        *ngFor="let item of entities"
                        [nzValue]="item.name"
                        [nzLabel]="item.display_name"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div
                nz-col
                [nzXs]="{ span: 12 }"
                [nzLg]="{ span: 12 }"
                [nzXXl]="12"
              >
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    class="dgrey-text titletext"
                    [nzLabelAlign]="'left'"
                    [nzNoColon]="true"
                    >Fields</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      (click)="getEntityFields()"
                      (ngModelChange)="
                        entityselectFields($event, formData, 'select');
                        selectedField()
                      "
                      [nzDisabled]="formData.fieldForm.entity_name == ''"
                      [(ngModel)]="formData.fieldForm.field_name"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateFields"
                      nzAllowClear
                    >
                      <ng-template #selectSearchTemplateFields>
                        <input
                          #saearhx2
                          nz-input
                          placeholder="Search..."
                          (input)="filterField(saearhx2.value)"
                        />
                      </ng-template>
                      <nz-option [nzLabel]="formData.fieldForm.field_name" [nzValue]="formData.fieldForm.field_name" nzHide></nz-option>
                      <nz-option
                        class="f-12"
                        *ngFor="let item of entityFields"
                        [nzValue]="item.name"
                        [nzLabel]="item.label"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <ng-container
                *ngIf="formData.fieldForm.optionType === 'ENTITY_DATA'"
              >
                <ng-container>
                  <nz-col [nzSpan]="24" style="margin-bottom: 5px">
                    <nz-switch
                      id="switch0"
                      [(ngModel)]="showDependentField"
                    ></nz-switch>
                    <label for="switch0" class="f-12"
                      >&nbsp;&nbsp;Dependent Field</label
                    >
                  </nz-col>
                </ng-container>

                <nz-col [nzSpan]="12" *ngIf="showDependentField">
                  <nz-form-item>
                    <nz-form-label
                      [nzXs]="{ span: 24 }"
                      class="dgrey-text titletext"
                      [nzLabelAlign]="'left'"
                      [nzNoColon]="true"
                      >Dependent Field</nz-form-label
                    >
                    <nz-form-control [nzXs]="{ span: 24 }">
                      <nz-select
                        (click)="getSelectedTabField()"
                        [nzOptionOverflowSize]="5"
                        [(ngModel)]="formData.fieldForm.def"
                        nzAllowClear
                        [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                        [nzDropdownRender]="selectSearchTemplateFieldData"
                        (ngModelChange)="Fieldentity($event)"
                      >
                        <ng-template #selectSearchTemplateFieldData>
                          <input
                            #saearhFieldData
                            nz-input
                            placeholder="Search..."
                            (input)="filterFieldData(saearhFieldData.value)"
                        /></ng-template>
                        <nz-option [nzLabel]="formData.fieldForm.def" [nzValue]="formData.fieldForm.def" nzHide></nz-option>
                        <ng-container *ngFor="let item of fieldData">
                          <ng-container
                            *ngIf="formData.fieldForm.field_name !== item.name"
                          >
                            <nz-option
                              class="f-12"
                              [nzValue]="item.name"
                              [nzLabel]="item.label"
                            >
                            </nz-option>
                          </ng-container>
                        </ng-container>
                      </nz-select>
                    </nz-form-control>
                    <div
                      *ngIf="fieldEntity && formData.fieldForm.def === ''"
                      style="color: red; font-size: smaller"
                    >
                      Dependent Field Is Required.
                    </div>
                  </nz-form-item>
                </nz-col>
                <nz-col [nzSpan]="12" *ngIf="showDependentField">
                  <nz-form-item>
                    <nz-form-label
                      [nzXs]="{ span: 24 }"
                      class="dgrey-text titletext"
                      [nzLabelAlign]="'left'"
                      [nzNoColon]="true"
                      >Parent Entity Field</nz-form-label
                    >
                    <nz-form-control [nzXs]="{ span: 24 }">
                      <nz-select
                        [nzOptionOverflowSize]="5"
                        (click)="showDefaultdependency()"
                        [(ngModel)]="formData.fieldForm.dpef"
                        [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                        [nzDropdownRender]="selectSearchTemplateDependencyField"
                        nzAllowClear
                        (ngModelChange)="parentEntityField($event)"
                      >
                        <ng-template #selectSearchTemplateDependencyField>
                          <input
                            #searchDependencyField
                            nz-input
                            placeholder="Search..."
                            (input)="
                              filterDependencyField(searchDependencyField.value)
                            "
                        /></ng-template>
                        <nz-option [nzLabel]="formData.fieldForm.dpef" [nzValue]="formData.fieldForm.dpef" nzHide></nz-option>
                        <nz-option
                          class="f-12"
                          *ngFor="let item of getEntityFields()"
                          [nzValue]="item.name"
                          [nzLabel]="item.label"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                    <div
                      style="color: red; font-size: smaller"
                      *ngIf="
                        parentEntityFields && formData.fieldForm.dpef === ''
                      "
                    >
                      Parent Entity Field Is Required.
                    </div>
                  </nz-form-item>
                </nz-col>
              </ng-container>

              <nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    [nzLabelAlign]="'left'"
                    class="dgrey-text titletext"
                    [nzNoColon]="true"
                    >Default Value</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      [(ngModel)]="formData.default_value"
                      [nzDropdownRender]="renderTemplate"
                      [nzDisabled]="formData.fieldForm.field_name == ''"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      nzAllowClear
                      placeholder="Select"
                    >
                      <ng-template #renderTemplate>
                        <div>
                          <input
                            placeholder="search"
                            nz-input
                            (input)="
                              entityselectFields($event, formData, 'input')
                            "
                            class="f-12"
                          />
                          <ng-template #suffixIcon>
                            <span nz-icon nzType="search"></span>
                          </ng-template>
                        </div>
                      </ng-template>
                      <ng-container
                        *ngFor="let option of getEntityptionList$ | async"
                      >
                        <nz-layout
                          *ngIf="getEntityptionList$ | async"
                          class="dropDownContainer"
                        >
                          <nz-option
                            class="f-12"
                            [nzLabel]="option.entity_data"
                            [nzValue]="option.entity_data"
                          ></nz-option>
                        </nz-layout>
                      </ng-container>
                      <!-- <nz-option *ngFor="let item of getEntityptionList$ | async"" [nzValue]="item.value"
                      [nzLabel]="item.name"></nz-option> -->
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </nz-row>
          </div>
          <nz-row
            [nzGutter]="16"
            *ngIf="formData.fieldForm.optionType === 'STATIC'"
            style="width: 100%"
          >
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Drop Down Options</nz-form-label
              >
              <div
                class="statusesContainer"
                [ngClass]="
                  getDropdown(formData.fieldForm.options).length === 0
                    ? 'd-flex'
                    : ''
                "
                style="padding: 10px 14px"
              >
                <div
                  class="cPointer"
                  *ngIf="getDropdown(formData.fieldForm.options).length === 0"
                  style="color: #1d66ff; margin: auto; text-align: center"
                  (click)="addDropDownoption('addNew')"
                >
                  Add Drop Down
                </div>
                <div
                  *ngFor="
                    let item of getDropdown(formData.fieldForm.options);
                    let i = index
                  "
                  style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                  "
                >
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img
                        src="../../../assets/images/icons/greenbullet.svg"
                        alt=""
                      />
                      {{ item.name }}
                    </div>
                    <div>
                      <img
                        class="ml-14 cPointer"
                        (click)="delteDropDown(i)"
                        src="../../../assets/images/icons/deleteIcon.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="getDropdown(formData.fieldForm.options).length > 0"
                class="cPointer"
                style="color: #1d66ff"
                (click)="addDropDownoption('addNew')"
              >
                Add Drop Down
              </div>
            </div>
            <nz-col [nzSpan]="24">
              <nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    [nzLabelAlign]="'left'"
                    class="dgrey-text titletext"
                    [nzNoColon]="true"
                    >Default Value</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      (click)="formData.fieldForm.options = saveDefaultValue"
                      [(ngModel)]="formData.default_value"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchdefault"
                      nzAllowClear
                      nzPlaceHolder="Select"
                    >
                      <ng-template #selectSearchdefault>
                        <input
                          #saearhx2
                          nz-input
                          placeholder="Search..."
                          (input)="filterdefault(saearhx2.value)"
                        />
                      </ng-template>
                      <nz-option
                        class="f-12"
                        *ngFor="let item of formData.fieldForm.options"
                        [nzValue]="item.name"
                        [nzLabel]="item.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </nz-col>
          </nz-row>

          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="formData.fieldForm.optionType === 'API'"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >Select API</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="save_api_options = select_api_options"
                  [(ngModel)]="formData.fieldForm.api_name"
                  (ngModelChange)="selectApiChange($event)"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateapi"
                  nzAllowClear
                >
                  <!-- <nz-option></nz-option> -->
                  <ng-template #selectSearchTemplateapi>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterapi(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option [nzValue]="formData.fieldForm.api_name" [nzLabel]="formData.fieldForm.api_name" nzHide></nz-option>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of select_api_options"
                    [nzValue]="item.api_name"
                    [nzLabel]="item.api_name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="formData.fieldForm.optionType === 'API'"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >API Field</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="Save_api_field_options = api_field_options"
                  [(ngModel)]="formData.fieldForm.api_field"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateFieldOption"
                  nzAllowClear
                >
                  <ng-template #selectSearchTemplateFieldOption>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterFieldOption(saearhx2.value)"
                    />
                  </ng-template>
                  <!-- <nz-option [nzHide]="true" [nzValue]="formData.fieldForm.api_field"
                [nzLabel]="formData.fieldForm.api_field"></nz-option> -->
                <nz-option [nzValue]="formData.fieldForm.api_field" [nzLabel]="formData.fieldForm.api_field" nzHide></nz-option>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of api_field_options"
                    [nzValue]="item"
                    [nzLabel]="item"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-container *ngIf="formData.fieldForm.optionType === 'API'">
            <ng-container>
              <nz-col [nzSpan]="24" style="margin-bottom: 5px">
                <nz-switch
                  id="switch0"
                  [(ngModel)]="showDependentField"
                ></nz-switch>
                <label for="switch0" class="f-12"
                  >&nbsp;&nbsp;Dependent Field</label
                >
              </nz-col>
            </ng-container>

            <nz-col [nzSpan]="12" *ngIf="showDependentField">
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  class="dgrey-text titletext"
                  [nzLabelAlign]="'left'"
                  [nzNoColon]="true"
                  >Dependent Field</nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    (click)="getSelectedTabField()"
                    [nzOptionOverflowSize]="5"
                    [(ngModel)]="formData.fieldForm.def"
                    nzAllowClear
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateFieldData"
                    (ngModelChange)="Fieldentity($event)"
                  >
                    <ng-template #selectSearchTemplateFieldData>
                      <input
                        #saearhFieldData
                        nz-input
                        placeholder="Search..."
                        (input)="filterFieldData(saearhFieldData.value)"
                    /></ng-template>
                    <nz-option [nzLabel]="formData.fieldForm.def" [nzValue]="formData.fieldForm.def" nzHide></nz-option>
                    <ng-container *ngFor="let item of fieldData">
                      <ng-container
                        *ngIf="formData.fieldForm.field_name !== item.name"
                      >
                        <nz-option
                          class="f-12"
                          [nzValue]="item.name"
                          [nzLabel]="item.label"
                        >
                        </nz-option>
                      </ng-container>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
                <!-- <div *ngIf="fieldEntity && formData.fieldForm.def ===''" style="color: red; font-size: smaller">Dependent Field Is Required.</div> -->
              </nz-form-item>
            </nz-col>
          </ng-container>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'dropdown_multi_select'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }" [nzXXl]="24">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Option Type</nz-form-label
              >
              <nz-radio-group
                [(ngModel)]="formData.fieldForm.optionType"
                style="
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                "
                (ngModelChange)="optionTypeChange($event)"
              >
                <label nz-radio nzValue="STATIC" class="f-12">Static</label>
                <label nz-radio nzValue="ENTITY_DATA" class="f-12"
                  >Entity</label
                >
                <label nz-radio nzValue="API" class="f-12">API</label>
              </nz-radio-group>
            </nz-form-item>
          </div>
          <div
            nz-row
            [nzGutter]="16"
            style="width: 100%"
            *ngIf="formData.fieldForm.optionType === 'ENTITY_DATA'"
          >
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-item>
                <nz-form-label
                  style="cursor: pointer"
                  [nzXs]="{ span: 24 }"
                  class="dgrey-text titletext"
                  [nzLabelAlign]="'left'"
                  [nzNoColon]="true"
                  >Entity</nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    (ngModelChange)="getEntityFields()"
                    (click)="saveentities = entities"
                    [(ngModel)]="formData.fieldForm.entity_name"
                    [nzDropdownClassName]="'dropDownContainer'"
                    nzAllowClear
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplatEntities"
                  >
                    <ng-template #selectSearchTemplatEntities>
                      <input
                        #saearhx2
                        nz-input
                        placeholder="Search..."
                        (input)="filterEntitie(saearhx2.value)"
                      />
                    </ng-template>
                    <nz-option [nzLabel]="formData.fieldForm.entity_name" [nzValue]="formData.fieldForm.entity_name" nzHide></nz-option>
                    <ng-container *ngFor="let item of entities">
                      <nz-option
                        class="f-12"
                        [nzValue]="item.name"
                        [nzLabel]="item.display_name"
                      >
                      </nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  class="dgrey-text titletext"
                  [nzLabelAlign]="'left'"
                  [nzNoColon]="true"
                  >Fields</nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    (click)="getEntityFields()"
                    [nzDisabled]="formData.fieldForm.entity_name == ''"
                    [(ngModel)]="formData.fieldForm.field_name"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateFields"
                    nzAllowClear
                    (ngModelChange)="selectedField()"
                  >
                    <ng-template #selectSearchTemplateFields>
                      <input
                        #saearhx2
                        nz-input
                        placeholder="Search..."
                        (input)="filterField(saearhx2.value)"
                      />
                    </ng-template>
                    <nz-option
                      class="f-12"
                      *ngFor="let item of entityFields"
                      [nzValue]="item.name"
                      [nzLabel]="item.label"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <ng-container
              *ngIf="formData.fieldForm.optionType === 'ENTITY_DATA'"
            >
              <ng-container>
                <nz-col [nzSpan]="24" style="margin-bottom: 5px">
                  <nz-switch
                    id="switch0"
                    [(ngModel)]="showDependentField"
                  ></nz-switch>
                  <label for="switch0" class="f-12"
                    >&nbsp;&nbsp;Dependent Field</label
                  >
                </nz-col>
              </ng-container>
              <nz-col [nzSpan]="12" *ngIf="showDependentField">
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    class="dgrey-text titletext"
                    [nzLabelAlign]="'left'"
                    [nzNoColon]="true"
                    >Dependent Field</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      (click)="getSelectedTabField()"
                      [nzOptionOverflowSize]="5"
                      [(ngModel)]="formData.fieldForm.def"
                      nzAllowClear
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateFieldData"
                      (ngModelChange)="Fieldentity($event)"
                    >
                      <ng-template #selectSearchTemplateFieldData>
                        <input
                          #saearhFieldData
                          nz-input
                          placeholder="Search..."
                          (input)="filterFieldData(saearhFieldData.value)"
                      /></ng-template>
                      <nz-option [nzLabel]="formData.fieldForm.def" [nzValue]="formData.fieldForm.def" nzHide></nz-option>
                      <ng-container *ngFor="let item of fieldData">
                        <ng-container
                          *ngIf="formData.fieldForm.field_name !== item.name"
                        >
                          <nz-option
                            class="f-12"
                            [nzValue]="item.name"
                            [nzLabel]="item.label"
                          >
                          </nz-option>
                        </ng-container>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                  <div
                    *ngIf="fieldEntity && formData.fieldForm.def === ''"
                    style="color: red; font-size: smaller"
                  >
                    Dependent Field Is Required.
                  </div>
                </nz-form-item>
              </nz-col>
              <nz-col [nzSpan]="12" *ngIf="showDependentField">
                <nz-form-item>
                  <nz-form-label
                    [nzXs]="{ span: 24 }"
                    class="dgrey-text titletext"
                    [nzLabelAlign]="'left'"
                    [nzNoColon]="true"
                    >Parent Entity Field</nz-form-label
                  >
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select
                      [nzOptionOverflowSize]="5"
                      (click)="showDefaultdependency()"
                      [(ngModel)]="formData.fieldForm.dpef"
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateDependencyField"
                      nzAllowClear
                      (ngModelChange)="parentEntityField($event)"
                    >
                      <ng-template #selectSearchTemplateDependencyField>
                        <input
                          #searchDependencyField
                          nz-input
                          placeholder="Search..."
                          (input)="
                            filterDependencyField(searchDependencyField.value)
                          "
                      /></ng-template>
                      <nz-option [nzLabel]="formData.fieldForm.dpef" [nzValue]="formData.fieldForm.dpef" nzHide></nz-option>
                      <nz-option
                        class="f-12"
                        *ngFor="let item of getEntityFields()"
                        [nzValue]="item.name"
                        [nzLabel]="item.label"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                  <div
                    style="color: red; font-size: smaller"
                    *ngIf="parentEntityFields && formData.fieldForm.dpef === ''"
                  >
                    Parent Entity Field Is Required.
                  </div>
                </nz-form-item>
              </nz-col>
            </ng-container>
          </div>
          <nz-row
            [nzGutter]="16"
            *ngIf="formData.fieldForm.optionType === 'STATIC'"
            style="width: 100%"
          >
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
            >
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Drop Down Options</nz-form-label
              >
              <div
                class="statusesContainer"
                [ngClass]="
                  getDropdown(formData.fieldForm.options).length === 0
                    ? 'd-flex'
                    : ''
                "
                style="padding: 10px 14px"
              >
                <div
                  class="cPointer"
                  *ngIf="getDropdown(formData.fieldForm.options).length === 0"
                  style="color: #1d66ff; margin: auto; text-align: center"
                  (click)="addDropDownoption('addNew')"
                >
                  Add Drop Down
                </div>
                <div
                  *ngFor="
                    let item of getDropdown(formData.fieldForm.options);
                    let i = index
                  "
                  style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                  "
                >
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img
                        src="../../../assets/images/icons/greenbullet.svg"
                        alt=""
                      />
                      {{ item.name }}
                    </div>
                    <div>
                      <img
                        class="ml-14 cPointer"
                        (click)="delteDropDown(i)"
                        src="../../../assets/images/icons/deleteIcon.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="getDropdown(formData.fieldForm.options).length > 0"
                class="cPointer"
                style="color: #1d66ff"
                (click)="addDropDownoption('addNew')"
              >
                Add Drop Down
              </div>
            </div>
          </nz-row>

          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="formData.fieldForm.optionType === 'API'"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >Select API</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="save_api_options = select_api_options"
                  [(ngModel)]="formData.fieldForm.api_name"
                  (click)="selectApiChange($event)"
                  (ngModelChange)="selectApiChange($event)"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateapi"
                  nzAllowClear
                >
                  <ng-template #selectSearchTemplateapi>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterapi(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option [nzValue]="formData.fieldForm.api_name" [nzLabel]="formData.fieldForm.api_name" nzHide></nz-option>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of select_api_options"
                    [nzValue]="item.api_name"
                    [nzLabel]="item.api_name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="formData.fieldForm.optionType === 'API'"
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >API Field</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  (click)="Save_api_field_options = api_field_options"
                  [(ngModel)]="formData.fieldForm.api_field"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateFieldOption"
                  nzAllowClear
                >
                  <ng-template #selectSearchTemplateFieldOption>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterFieldOption(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option [nzValue]="formData.fieldForm.api_field" [nzLabel]="formData.fieldForm.api_field" nzHide></nz-option>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of api_field_options"
                    [nzValue]="item"
                    [nzLabel]="item"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-container *ngIf="formData.fieldForm.optionType === 'API'">
            <ng-container>
              <nz-col [nzSpan]="24" style="margin-bottom: 5px">
                <nz-switch
                  id="switch0"
                  [(ngModel)]="showDependentField"
                ></nz-switch>
                <label for="switch0" class="f-12"
                  >&nbsp;&nbsp;Dependent Field</label
                >
              </nz-col>
            </ng-container>

            <nz-col [nzSpan]="12" *ngIf="showDependentField">
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  class="dgrey-text titletext"
                  [nzLabelAlign]="'left'"
                  [nzNoColon]="true"
                  >Dependent Field</nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    (click)="getSelectedTabField()"
                    [nzOptionOverflowSize]="5"
                    [(ngModel)]="formData.fieldForm.def"
                    nzAllowClear
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateFieldData"
                    (ngModelChange)="Fieldentity($event)"
                  >
                    <ng-template #selectSearchTemplateFieldData>
                      <input
                        #saearhFieldData
                        nz-input
                        placeholder="Search..."
                        (input)="filterFieldData(saearhFieldData.value)"
                    /></ng-template>
                    <nz-option [nzLabel]="formData.fieldForm.def" [nzValue]="formData.fieldForm.def" nzHide></nz-option>
                    <ng-container *ngFor="let item of fieldData">
                      <ng-container
                        *ngIf="formData.fieldForm.field_name !== item.name"
                      >
                        <nz-option
                          class="f-12"
                          [nzValue]="item.name"
                          [nzLabel]="item.label"
                        >
                        </nz-option>
                      </ng-container>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
                <!-- <div *ngIf="fieldEntity && formData.fieldForm.def ===''" style="color: red; font-size: smaller">Dependent Field Is Required.</div> -->
              </nz-form-item>
            </nz-col>
          </ng-container>
          <div
            nz-col
            [nzXs]="{ span: 12 }"
            [nzLg]="{ span: 12 }"
            [nzXXl]="12"
            *ngIf="
              formData.fieldForm.optionType !== 'ENTITY_DATA' &&
              formData.fieldForm.optionType !== 'API'
            "
          >
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                class="dgrey-text titletext"
                [nzLabelAlign]="'left'"
                [nzNoColon]="true"
                >Default Option</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <nz-select
                  nzPlaceHolder="Select"
                  (click)="saveoptions = formData.fieldForm.options"
                  [(ngModel)]="formData.default_value"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                  [nzDropdownRender]="selectSearchTemplateMultiDefault"
                  nzAllowClear
                >
                  <ng-template #selectSearchTemplateMultiDefault>
                    <input
                      #saearhx2
                      nz-input
                      placeholder="Search..."
                      (input)="filterMultiDefault(saearhx2.value)"
                    />
                  </ng-template>
                  <nz-option
                    class="f-12"
                    [nzValue]="'blank'"
                    [nzLabel]="''"
                  ></nz-option>
                  <nz-option
                    class="f-12"
                    [nzValue]="formData.default_value"
                    [nzLabel]="formData.default_value"
                    nzHide
                  ></nz-option>
                  <nz-option
                    class="f-12"
                    *ngFor="let item of formData.fieldForm.options"
                    [nzValue]="item.name"
                    [nzLabel]="item.name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'rm'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-form-control [nzXs]="{ span: 24 }">
                <input
                  #defaultFieldValue
                  nz-input
                  [(ngModel)]="formData.default_value"
                  [placeholder]="'Enter'"
                  class="f-12"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
      <ng-container *ngSwitchCase="'people'">
        <nz-row [nzGutter]="16">
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Field Name <span class="redColor">*</span></nz-form-label
              >
              <nz-select
                style="width: 100%"
                [(ngModel)]="formData.fieldForm.api_field"
                [nzDropdownClassName]="'dropDownContainer'"
                (nzOpenChange)="
                  onPeopleOpenChange($event);
                  formData.fieldForm.api_field
                    ? (formData.fieldForm.api_field_validators.error = '')
                    : ''
                "
                (ngModelChange)="selectPeopleChange($event)"
                nzAllowClear
              >
                <nz-option
                  class="f-12"
                  *ngIf="formData.fieldForm.api_field as p"
                  [nzValue]="p"
                  [nzLabel]="p"
                  nzHide=""
                ></nz-option>
                <nz-option
                  *ngFor="let p of people$() | async"
                  [nzValue]="p"
                  [nzLabel]="p"
                ></nz-option>
              </nz-select>
            </nz-form-item>
            <div
              *ngIf="
                formData.fieldForm.api_field_validators &&
                formData.fieldForm.api_field_validators.error
              "
              style="color: red; text-align: start; font-size: 11px"
            >
              {{ formData.fieldForm.api_field_validators.error | titlecase }}
            </div>
          </div>
          <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
            <nz-form-item>
              <nz-form-label
                [nzXs]="{ span: 24 }"
                [nzLabelAlign]="'left'"
                class="dgrey-text titletext"
                [nzNoColon]="true"
                >Default Value</nz-form-label
              >
              <nz-select
                [nzRemoveIcon]="null"
                class="dropdownui dropDownContainer"
                nzAllowClear
                [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                [nzDropdownRender]="selectSearchTemplatePeople"
                [(ngModel)]="formData.default_value"
                [nzMaxTagCount]="2"
                nzMode="multiple"
                [nzMaxTagPlaceholder]="selectPeople"
                nzPlaceHolder="Select"
                class="cPointer"
                style="cursor: pointer !important; width: 100%"
                (click)="
                  this.selectAPI.emit({ action: true, people: 'people' })
                "
              >
                <ng-template #selectSearchTemplatePeople>
                  <input
                    #saearhx2
                    nz-input
                    placeholder="Search..."
                    [(ngModel)]="peopleDefaultInput"
                  />
                </ng-template>
                <ng-container *ngIf="formData.default_value">
                  <nz-option
                    class="f-12"
                    *ngFor="let item of formData.default_value"
                    [nzLabel]="item"
                    [nzValue]="item"
                    nzHide
                  ></nz-option>
                </ng-container>
                <nz-option
                  class="f-12"
                  *ngFor="let option of getPeopleOption() | async"
                  [nzValue]="option"
                  nzLabel="{{ option }}"
                ></nz-option>
              </nz-select>

              <ng-template
                #selectPeople
                let-selectedList
                style="background-color: none !important"
              >
                +{{ selectedList.length }} more</ng-template
              >
            </nz-form-item>
          </div>
        </nz-row>
      </ng-container>
    </ng-container>
  </ng-container>
  <nz-divider style="margin: 5px 0 !important"></nz-divider>
  <nz-row [nzGutter]="16" class="switchStyle">
    <ng-container *ngIf="formData.can_edit">
      <div
      nz-col
      [nzXs]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXXl]="12"
      *ngIf="!formData.is_hidden"
    >
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              [(ngModel)]="formData.isMandatory"
              id="switch1"
            ></nz-switch>
            <label for="switch1" class="f-12">&nbsp;Is Mandatory?</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    </ng-container>

    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              [(ngModel)]="formData.is_hidden"
              id="switch6"
            ></nz-switch>
            <label for="switch6" class="f-12">&nbsp;is Hidden?</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <ng-container>
      <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
        <nz-form-item>
          <nz-form-control>
            <div class="" style="display: flex; align-items: center">
              <nz-switch
                id="switchUnique"
                [(ngModel)]="formData.is_unique"
              ></nz-switch>
              <label for="switchUnique" class="f-12">&nbsp;Is Unique?</label>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="formData.is_unique">
        <nz-form-item>
          <nz-form-control>
            <div class="" style="display: flex; align-items: center">
              <nz-switch
                id="switchUnique"
                [(ngModel)]="formData.unq_sa"
              ></nz-switch>
              <label for="switchUnique" class="f-12">&nbsp;Standalone Unique?</label>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <div
        nz-col
        [nzXs]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
        [nzXXl]="12"
        *ngIf="formData.is_unique"
        class="isUniqueField"
      >
        <nz-form-label
          [nzXs]="{ span: 24 }"
          [nzLabelAlign]="'left'"
          class="dgrey-text titletext"
          [nzNoColon]="true"
          style="font-size: 12px !important"
          >Unique Field</nz-form-label
        >
        <nz-form-item>
          <nz-select
            (click)="filteruniquEntityOption('')"
            [nzMode]="'multiple'"
            class="dropdownui w-100 f-12 text-start"
            [(ngModel)]="formData.unique_fn"
            nzPlaceHolder="Select"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            nzAllowClear
            [nzDropdownRender]="searchTemplateStatic"
          >
            <ng-template #searchTemplateStatic>
              <input
                #saearhValue
                nz-input
                placeholder="Search..."
                (input)="filteruniquEntityOption(saearhValue.value)"
              />
            </ng-template>
            <ng-container *ngFor="let item of optionTypeEntityData">
              <nz-option
                [nzLabel]="item.label"
                [nzValue]="item.name"
              ></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-item>
      </div> -->
    </ng-container>
    <!-- <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="formData.data_type.value !== 'date'">
    </div> -->
    <div
      nz-col
      [nzXs]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXXl]="12"
      *ngIf="formData.data_type.value !== 'textarea'"
    >
      <!-- <nz-form-label class="dgrey-text titletext" style="visibility: hidden"></nz-form-label> -->
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              id="switch2"
              [ngModel]="
                formData.showGrid === 'yes' || formData.showGrid === 'always'
                  ? true
                  : false
              "
              (ngModelChange)="showGridChange($event)"
            ></nz-switch>
            <label for="switch2" class="f-12"> &nbsp; Show Grid?</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              [(ngModel)]="formData.can_edit"
              id="an_edit"
            ></nz-switch>
            <label for="an_edit" class="f-12">&nbsp;Can Edit</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!-- <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" style="opacity: 0; pointer-events: none"
      *ngIf="formData.data_type.value !== 'date'">
      <nz-form-item>
        <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
          [nzNoColon]="true">Grid Index</nz-form-label>
        <nz-form-control [nzXs]="{ span: 24 }">
          <input nz-input placeholder="Enter" [(ngModel)]="formData.grid_index" />
        </nz-form-control>
      </nz-form-item>
    </div> -->
    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="formData.data_type.value !== 'textarea'">
      <!-- <nz-form-label class="dgrey-text titletext" style="visibility: hidden"></nz-form-label> -->
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              id="switch2"
              [(ngModel)]="formData.can_group"
              (ngModelChange)="onCanGroupChange($event)"
            ></nz-switch>
            <label for="switch2" class="f-12"> &nbsp;Can Group ?</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div
      nz-col
      [nzXs]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXXl]="12"
      *ngIf="formData.can_group"
    >
      <!-- <nz-form-label class="dgrey-text titletext" style="visibility: hidden"></nz-form-label> -->
      <nz-form-item>
        <nz-form-control style="position: relative">
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              id="switch2"
              [(ngModel)]="formData.default_group"
              (ngModelChange)="onDefaultGropChange($event)"
            ></nz-switch>
            <label for="switch2" class="f-12"> &nbsp;Default Group?</label>
          </div>
          <div
            style="position: absolute; color: red; font-size: 8px"
            class="fw-400"
            *ngIf="entityField.length > 0"
          >
            Default grouping has been set on -{{ entityField[0].label }}. This
            will override the default grouping setting
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div
      nz-col
      [nzXs]="{ span: 12 }"
      [nzLg]="{ span: 12 }"
      [nzXXl]="12"
      *ngIf="formData.data_type.value === 'textarea'"
    >
      <nz-form-item>
        <nz-form-control>
          <div class="" style="display: flex; align-items: center">
            <nz-switch
              id="switch2"
              (ngModelChange)="formData.default_value = ''"
              [(ngModel)]="formData.rich_text"
            ></nz-switch>
            <label for="switch2" class="f-12"> &nbsp;is Rich Text ?</label>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
      <nz-form-item>
        <nz-form-label
          [nzXs]="{ span: 24 }"
          [nzLabelAlign]="'left'"
          class="dgrey-text titletext"
          [nzNoColon]="true"
          >Field to appear in which tab
          <span class="redColor">*</span></nz-form-label
        >
        <nz-form-control [nzXs]="{ span: 24 }">
          <nz-select
            #selectTabToShow
            *ngIf="getTabNames() | async"
            [(ngModel)]="formData.tab_name.value"
            [nzDropdownRender]="renderTemplate"
            nzPlaceHolder="Select"
            [nzDropdownClassName]="'dropDownContainer'"
            nzAllowClear
            (ngModelChange)="formData?.tab_name?.validators.error = ''"
          >
            <ng-container *ngFor="let item of getTabNames() | async">
              <nz-option
                class="f-12"
                nzCustomContent
                [nzValue]="item.name"
                [nzLabel]="item.label"
              >
                <div
                  class="d-flex align-items-center"
                  style="justify-content: space-between"
                >
                  <div>{{ item.label }}</div>
                  <div *ngIf="item.field_type != 'SYSTEM'">
                    <!-- <div> -->
                    <img
                      src="../../../assets/images/Trash.svg"
                      alt=""
                      style="cursor: pointer"
                      (click)="deleteTab(item, selectTabToShow)"
                    />
                  </div>
                </div>
              </nz-option>
            </ng-container>
          </nz-select>
          <span
            style="color: red; font-size: smaller"
            *ngIf="
              formData?.tab_name.validators?.required &&
              formData?.tab_name.validators?.error
            "
            >{{ formData?.tab_name?.validators?.error | titlecase }}</span
          >
          <ng-template #renderTemplate>
            <nz-divider></nz-divider>
            <div
              class="m-auto text-center f-10 fw-700 lh-18 blueColor cPointer buttonStyles"
            >
              <button
                nz-button
                nzType="default"
                class="buttonDefault"
                style="width: 100%"
                (click)="addNewTab()"
              >
                ADD NEW
              </button>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </nz-row>
  <div nz-col [nzXs]="{ span: 24 }">
    <nz-form-label
      class="dgrey-text titletext"
      [nzLabelAlign]="'left'"
      [nzNoColon]="true"
      >Tool Tip</nz-form-label
    >
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          placeholder="Enter"
          type="text"
          [(ngModel)]="formData.tooltip.value"
          class="f-12"
        />
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }">
    <nz-form-label
      class="dgrey-text titletext"
      [nzLabelAlign]="'left'"
      [nzNoColon]="true"
      >Date Privacy & Security</nz-form-label
    >
    <div nz-col [nzXs]="{ span: 24 }" style="display: flex">
      <div nz-col nzSpan="8">
        <label nz-checkbox [(ngModel)]="formData.is_pii" class="f-12"
          >PII</label
        >
      </div>
      <div nz-col nzSpan="8">
        <label nz-checkbox [(ngModel)]="formData.to_encrypt" class="f-12"
          >Encrypt</label
        >
      </div>
      <div nz-col nzSpan="8">
        <label nz-checkbox [(ngModel)]="formData.is_ephi" class="f-12"
          >ePHI</label
        >
      </div>
    </div>
  </div>
</div>
<div class="form-footer">
  <nz-row [nzJustify]="'end'">
    <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
      <button
        nz-button
        nzType="primary"
        class="buttonDefault save f-12"
        style="height: 35px; width: 90px"
        (click)="onSave()"
      >
        SAVE
      </button>
    </div>
    <div
      class="text-center f-10 fw-700 lh-18 cPointer"
      style="margin-left: 10px; height: 35px; width: 90px"
    >
      <button
        nz-button
        nzType="default"
        style="height: 34px"
        class="cancel f-12"
        (click)="closeDrawer()"
      >
        CANCEL
      </button>
    </div>
  </nz-row>
</div>
