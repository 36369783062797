<nz-row [nzAlign]="'middle'" class="menuContainers">
  <div nz-col nzXl="20" nzLg="20" nzMd="22">
    <nz-row [nzAlign]="'middle'" [nzGutter]="32" class="positionchange">
      <div nz-col nzXl="8">
        <nz-input-group
          [nzPrefix]="prefixTemplateUser"
          class="searchInputContainer"
        >
          <input
            [nzBorderless]="false"
            #searchTable
            autofocus
            nz-input
            placeholder="Search"
            [(ngModel)]="tableInputSearch"
            type="text"
            (input)="tabelSearch(searchTable.value)"
          />
          <ng-template #prefixTemplateUser
            ><img src="../../assets/images/icons/searchIons.svg"
          /></ng-template>
        </nz-input-group>
      </div>
      <div class="filter addmobile">
        <div
          class="grayButtonContainer"
          style="padding: 6px 24px; font-size: 12px"
          (click)="filter()"
          nz-dropdown
          nzTrigger="click"
          nzPlacement="bottomCenter"
          [nzDropdownMenu]="filterMenu"
          [nzOverlayClassName]="'filter_container'"
        >
          <img src="../../assets/images/icons/filterIcon.svg" /> &nbsp;Filter
          &nbsp;
          <nz-switch
            nzSize="small"
            style="pointer-events: none"
            [(ngModel)]="is_filter_"
          ></nz-switch>
        </div>
      </div>
      <div nz-col class="addmobile">
        <div
          class="grayButtonContainer"
          style="font-size: 12px"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="menu"
        >
          <img src="../../assets/images/icons/groupIcon.svg" /> &nbsp; Group by
          : {{ getLabelNameForSort(selectedSortLabel) }} &nbsp;
          <nz-switch
            nzSize="small"
            [ngModel]="getLabelNameForSort(selectedSortLabel) !== 'NA'"
            style="pointer-events: none"
          ></nz-switch>
        </div>
      </div>
      <div
        *ngIf="showClosedStatus"
        class="switchStyle"
        style="
          display: flex;
          height: 30px;
          align-items: center;
          column-gap: 10px;
          margin: 0;
          background: #f2f2f2;
          padding: 0px 8px;
          border-radius: 8px;
          cursor: pointer;
          width: fit-content;
        "
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="showStatus"
        (click)="showClosed()"
      >
        <!-- (click)="showClosed()" -->
        <label for="switch0" class="f-12" style="cursor: pointer"
          >&nbsp;&nbsp;Show Closed</label
        >
        <nz-switch
          nzSize="small"
          id="switch0"
          [(ngModel)]="isStatusClose"
          style="pointer-events: none"
        ></nz-switch>
      </div>

      <nz-dropdown-menu #showStatus="nzDropdownMenu">
        <ul nz-menu class="statusMenuContainer">
          <!-- <li class="statusMenuStyle" >{{this.tabName}} <nz-switch [(ngModel)]="defaultStatus" (ngModelChange)="statusDefaultClosed($event)" nzSize="small" id="switch0"></nz-switch></li> -->
          <ng-container *ngFor="let i of noOfStatusFields; let index = index">
            <!-- <ng-container *ngIf="index !== 0"> -->
            <ng-container *ngIf="index == 1">
              <nz-divider
                style="font-size: 9px; border-top-color: rgba(0, 0, 0, 0.5)"
                nzDashed="false"
                nzText="Linked Entities"
              ></nz-divider>
            </ng-container>
            <li class="statusMenuStyle">
              {{ i.label }}
              <nz-switch
                nzSize="small"
                id="switch0"
                (ngModelChange)="statusDefaultClosed($event, index, i)"
                [(ngModel)]="i.showStatue"
              ></nz-switch>
            </li>
          </ng-container>
          <!-- </ng-container> -->
        </ul>
      </nz-dropdown-menu>
      <!-- {{tableMetaData?.has_am}} 5852858 -->
      <!-- <div class="moreOptionContainer" *ngIf="tableMetaData?.has_am">
        <ng-container *ngFor="let item of moreOptions">
          <div
            [ngClass]="
              isSelectedMore === item?.value
                ? isSelectedMore === 'error_records'
                  ? 'selectedMoreOptionError'
                  : 'selectedMoreOption'
                : 'defaultMoreOption'
            "
            class="f-12 cPointer"
            (click)="moreOption(item?.value)"
          >
            {{ item?.lable }}
          </div>
        </ng-container>
      </div> -->
    </nz-row>
  </div>
  <div
    nz-col
    nzXl="4"
    nzLg="4"
    nzMd="2"
    style="text-align: end; padding-right: 10px; padding-bottom: 5px"
  >
    <ng-content></ng-content>
  </div>
</nz-row>

<nz-dropdown-menu class="groupby" #menu="nzDropdownMenu">
  <div class="groupby">
    <nz-row class="f-12 fw-400" style="padding: 10px; height: 50px">
      Group By
    </nz-row>

    <nz-divider
      style="margin-top: -15px !important; margin-bottom: 0"
    ></nz-divider>
    <nz-row class="search addSpace" style="padding: 0 15px">
      <div style="width: 20px">
        <img src="../../../assets/images/search.png" />
      </div>
      <input
        #search2
        autofocus
        (input)="inputValue($event)"
        nz-input
        placeholder="Search"
        type="text"
        style="margin-left: 10px; width: 84% !important"
      />
    </nz-row>

    <nz-divider
      style="margin-top: -15px !important; margin-bottom: 0"
    ></nz-divider>
    <div class="custom-css">
      <li
        nz-menu-item
        style="padding-bottom: 20px; cursor: pointer; align-items: center"
        (click)="clearSort()"
        class="d-flex"
      >
        <img
          src="../../../../../assets/images/delete.svg"
          style="cursor: pointer; width: 18px; height: 18px; opacity: 0"
        />
        <div
          class="d-flex"
          style="
            flex-direction: column;
            height: 100%;
            align-items: center;
            width: 24px;
          "
          nz-tooltip
          [nzTooltipTitle]="'Clear Sort'"
        ></div>

        <div
          class="d-flex"
          style="align-items: center; width: 100%; padding: 0 12px"
        >
          <div
            style="
              font-size: 12px;
              font-weight: 400;
              line-height: 0;
              margin-left: -20px;
            "
          >
            {{ "None" }}
          </div>
          &nbsp;
        </div>
      </li>
      <ng-container *ngFor="let item of getSortFields(search2.value)">
        <ng-container *ngIf="show_error_field(item)">
          <li
          nz-menu-item
          style="padding-bottom: 20px; cursor: pointer; align-items: center"
          (click)="selectedSortRow(item)"
          class="d-flex"
        >
          <img
            *ngIf="item.name !== selectedSortLabel?.[0]"
            style="width: 18px; height: 18px"
            [src]="
              getIconForGroupBy(item.datatype) ||
              '../../../assets/images/addNewColumnIcons/messageBox.svg'
            "
          />
          <div
            class="d-flex"
            style="
              flex-direction: column;
              height: 100%;
              align-items: center;
              width: 24px;
            "
            nz-tooltip
            [nzTooltipTitle]="
              selectedSortLabel?.[1] === 'desc' ? 'descending' : 'ascending'
            "
            *ngIf="item.name === selectedSortLabel?.[0]"
          >
            <img
              src="../../assets/images/sort_asc.svg"
              alt=""
              *ngIf="selectedSortLabel[1] === 'asc'"
            />
            <img
              src="../../assets/images/sort_desc.svg"
              alt=""
              *ngIf="selectedSortLabel[1] === 'desc'"
            />
          </div>

          <div
            class="d-flex"
            style="align-items: center; width: 100%; padding: 0 12px"
          >
            <div
              style="
                font-size: 12px;
                font-weight: 400;
                line-height: 0;
                white-space: nowrap;
              "
            >
              {{ item.label }}
            </div>
            &nbsp;
            <div
              style="font-size: 8px; font-weight: 300"
              *ngIf="item.default_group === true"
            >
              (default)
            </div>
            <div
              *ngIf="item.name === selectedSortLabel?.[0]"
              style="margin-left: auto"
            >
              <img src="../../assets/images/icons/blueTickIcon.svg" alt="" />
            </div>
          </div>
        </li>
        </ng-container>

      </ng-container>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #filterMenu="nzDropdownMenu">
  <div class="filterContainer">
    <ng-container>
      <lib-filter
        [entitiesData]="entitiesData"
        (resetFilter)="resetFilters()"
        [state]="state"
        [filters]="filters"
        [erulistData]="erulistData"
        [tabName]="tabName"
        (onSaveFilter)="saveFilter($event)"
        (logFilterData)="logFilterData($event)"
        (statusFilters)="statusFilter($event)"
        [parent]="this"
        [isSelectedMore]="isSelectedMore"
        [tableMetaData]="tableMetaData ? tableMetaData : 'noData'"
        [setStatusFilterData]="setStatusFilterData"
        [environmentType]="environmentType"
        [statusValue]="status_Value"
        [statusFilter]="status_Filter"
      ></lib-filter>
    </ng-container>
  </div>
</nz-dropdown-menu>

<div class="record">
  {{
    pagination.currentRecords > pagination.totalRecords
      ? pagination.totalRecords
      : pagination.currentRecords
  }}
  of {{ pagination.totalRecords }} Records
</div>
<div fluidWidth class="processo-table mt-20" (scroll)="onScroll($event)">
  <nz-layout>
    <nz-drawer
      [nzWrapClassName]="visible ? 'addFieldContainers' : ''"
      [nzMaskStyle]="{ 'background-color': 'transparent' }"
      [nzClosable]="false"
      [nzVisible]="visible"
      nzPlacement="left"
      (nzOnClose)="close()"
      [nzWidth]="285"
      style="border-radius: 10px"
    >
      <ng-container *nzDrawerContent>
        <nz-tabset [nzAnimated]="false" class="tabContainers">
          <nz-tab nzTitle="Show/Hide">
            <nz-row class="search addSpace" style="padding: 0 15px">
              <div style="width: 20px">
                <img src="../../../assets/images/search.png" />
              </div>

              <input
                #search
                autofocus
                nz-input
                placeholder="Search"
                type="text"
                style="margin-left: 10px; width: 85% !important"
              />
            </nz-row>
            <nz-divider
              style="margin-top: -15px !important; margin-bottom: 0"
            ></nz-divider>
            <div class="nz-row newFieldHeight">
              <nz-row class="f-11 title-text addSpace" style="padding: 0 15px"
                >Visible Fields</nz-row
              >
              <ul class="addSpace" style="padding: 0 15px">
                <ng-container *ngFor="let item of getShowFields(search.value)">
                  <ng-container *ngIf="show_error_field(item)">
                    <li
                    (mouseenter)="editOptions.hidden = false"
                    (mouseleave)="editOptions.hidden = true"
                    style="
                      display: flex;
                      align-items: baseline;
                      justify-content: space-between;
                    "
                    class="field_hover"
                  >
                    <p
                      class="f-12"
                      (click)="removeFieldShow(item)"
                      style="margin: 10px 0 !important"
                    >
                      {{ item.label }}
                    </p>

                    <div
                      class="display:flex:align-items:center;justify-content:space-around;"
                    >
                      <span #editOptions [hidden]="true">
                        <img
                          *ngIf="item.datatype === 'people'"
                          (click)="openPeopleAssignee(item)"
                          style="cursor: pointer; margin-right: 3px"
                          src="../../assets/images/entityIcons/people.svg"
                        />
                        <img
                          style="cursor: pointer"
                          src="../../assets/images/Pencil.svg"
                          alt=""
                          (click)="editField(item)"
                        />

                        <img
                          style="cursor: pointer"
                          *ngIf="item.show_grid !== 'always'"
                          (click)="showVisibilityFromField(item)"
                          src="../../assets/images/eye.svg"
                          alt=""
                        />
                        <img
                          style="cursor: pointer"
                          *ngIf="
                            item.show_grid !== 'always' &&
                            item.field_type !== 'SYSTEM'
                          "
                          (click)="deleteItemFromTable(item)"
                          src="../../assets/images/Trash.svg"
                          alt=""
                        />
                      </span>
                      <img
                        *ngIf="item.show_grid !== 'always'"
                        src="../../assets/images/icons/blueTickIcon.svg"
                      />
                    </div>
                  </li>
                  </ng-container>

                </ng-container>
              </ul>
              <nz-divider
                style="margin-top: -0px !important; margin-bottom: 0"
              ></nz-divider>
              <nz-row class="f-11 title-text addSpace" style="padding: 0 15px"
                >Available Fields</nz-row
              >
              <ul class="addSpace" style="padding: 0 15px">
                <ng-container
                  *ngFor="let item of getFieldsInspace(search?.value)"
                >
                <ng-container *ngIf="show_error_field(item)">
                  <ng-container *ngIf="item.datatype !== 'entity'">
                    <li
                      (mouseenter)="editOptions.hidden = false"
                      (mouseleave)="editOptions.hidden = true"
                      style="
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                      "
                      class="field_hover"
                    >
                      <p
                        class="f-12"
                        (click)="addFieldShow(item)"
                        style="margin: 10px 0 !important"
                      >
                        {{ item.label }}
                      </p>
                      <div
                        class="display:flex:align-items:center;justify-content:space-around;"
                      >
                        <span #editOptions [hidden]="true">
                          <img
                            *ngIf="item.datatype === 'people'"
                            (click)="openPeopleAssignee(item)"
                            style="cursor: pointer; margin-right: 3px"
                            src="../../assets/images/entityIcons/people.svg"
                          />

                          <img
                            style="cursor: pointer"
                            src="../../assets/images/Pencil.svg"
                            alt=""
                            (click)="editField(item)"
                          />
                          <img
                            style="cursor: pointer"
                            *ngIf="item.show_grid !== 'always'"
                            (click)="showVisibilityFromField(item)"
                            src="../../assets/images/eye.svg"
                            alt=""
                          />
                          <img
                            style="cursor: pointer"
                            *ngIf="
                              item.show_grid !== 'always' &&
                              item.field_type !== 'SYSTEM'
                            "
                            (click)="deleteItemFromTable(item)"
                            src="../../assets/images/Trash.svg"
                            alt=""
                          />
                        </span>
                        <img src="../../assets/images/icons/greyTickIcon.svg" />
                      </div>
                    </li>
                  </ng-container>
                </ng-container>

                </ng-container>
              </ul>
            </div>
          </nz-tab>
          <nz-tab nzTitle="New Column">
            <nz-row class="search addSpace" style="padding: 0 15px">
              <div style="width: 20px">
                <img src="../../../assets/images/search.png" />
              </div>
              <input
                #search2
                autofocus
                nz-input
                placeholder="Search"
                type="text"
                style="margin-left: 10px; width: 84% !important"
              />
            </nz-row>
            <nz-divider
              style="margin-top: -15px !important; margin-bottom: 0"
            ></nz-divider>
            <div class="nz-row newFieldHeight">
              <nz-row class="f-11 title-text addSpace" style="padding: 0 15px"
                >CREATE NEW FIELD</nz-row
              >
              <ul class="addSpace" style="padding: 0 15px">
                <ng-container *ngFor="let item of getNewFields(search2.value)">
                  <li
                    [style.opacity]="disableStatus(item.datatype) ? '0.2' : '1'"
                    [style.PointerEvents]="
                      disableStatus(item.datatype) ? 'none' : 'auto'
                    "
                    (click)="openNewfield(item)"
                    style="
                      padding-top: 10px;
                      padding-bottom: 10px;
                      cursor: pointer;
                    "
                  >
                    <span
                      style="
                        font-size: 12px;
                        font-weight: 400;
                        line-height: normal;
                      "
                    >
                      <img
                        style="width: 24px; height: 24px; margin-right: 15px"
                        [src]="item.image"
                      />{{ item.label | titlecase }}
                    </span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </nz-tab>
        </nz-tabset>
        <ng-template #footerTpl>
          <div style="float: right">
            <button nz-button style="margin-right: 8px" class="cancel">
              Cancel
            </button>
            <button nz-button nzType="primary">Submit</button>
          </div>
        </ng-template>
      </ng-container>
    </nz-drawer>
  </nz-layout>

  <nz-layout *ngIf="isVisited">
    <nz-drawer
      [nzWidth]="640"
      [nzBodyStyle]="{ overflow: 'auto' }"
      [nzMaskClosable]="false"
      [nzVisible]="addNewColumn"
      nzTitle="Add New Field"
      (nzOnClose)="closeAddColumn()"
      [nzWidth]="559"
      [nzMask]="false"
    >
      <ng-container *nzDrawerContent>
        <lib-add-new-field
          *ngIf="tabName && erulistData && selectedTabName"
          [isVisited]="isVisited"
          [stateService]="pservice"
          [selectedMenuData]="selectedMenuData"
          [selectedMenu]="selectedMenu"
          [erulistData]="erulistData"
          [tabName]="tabName"
          [newColumnValue]="newColumnValue"
          [selectedTabName]="selectedTabName"
          (UpdateTabs)="UpdateTabsEvnt($event)"
          (updateFields)="updateFields($event)"
          (close)="closeAddColumn()"
          (addSaveColumns)="addSaveColumns($event)"
          (editSaveColumns)="editSaveColumns($event)"
          (selectAPI)="selectAPIEmitter($event)"
          [select_api_options]="select_api_options"
          [api_field_options]="api_fields"
          (delTab)="deleteTab($event)"
          [isEdit]="isEdit"
          (entitySingleSelect)="entityselectFields($event)"
          [getEntityptionList$]="getEntityptionList$"
          (getCurrencyLists)="getCurrencyLists($event)"
          [CurrencyLists]="CurrencyLists"
        ></lib-add-new-field>
      </ng-container>
    </nz-drawer>
  </nz-layout>

  <ng-container *ngIf="erulistData.dataLoaded">
    <ng-container *ngIf="Object.keys(groupData$).length > 0">
      <ng-container
        *ngFor="let groupedArray of Object.keys(groupData$); let last = last"
        style="height: 100dvh; overflow-y: auto"
      >
        <nz-collapse
          [nzBordered]="false"
          class="mt-10"
          
        >
          <nz-collapse-panel
            #colapsePanel
            [nzShowArrow]="false"
            [nzHeader]="customHeaderTemplate"
            [nzActive]="true"
            style="pointer-events: none"
            [nzDisabled]="false"
          >
            <ng-template
              [ngTemplateOutlet]="tableLayout"
              [ngTemplateOutletContext]="{ groupedArray: groupedArray }"
            ></ng-template>
          </nz-collapse-panel>
        </nz-collapse>
        <ng-template #customHeaderTemplate>
          <div (click)="selectedColapsePannel(last)">
            <div class="group-sec" style="pointer-events: none">
              <div 
                [ngClass]="{ isOpenClass: colapsePanel.nzActive }"
                style="pointer-events: auto"
              >
                <img src="../../../assets/images/down-arrow.png" />
              </div>
              <div style="pointer-events: auto; font-size: 12px">
                {{
                  groupedArray !== "undefined"
                    ? "Grouping" + " -" + groupedArray
                    : "No grouping"
                }}
                -
                {{ groupData$[groupedArray]?.length || 0 }}
                rows
              </div>
            </div>
          </div>

        </ng-template>

        <ng-template #tableLayout let-groupedArray="groupedArray">
          <div
            style="
              pointer-events: auto;
              /* width: auto;
            max-width: 100vw;
            overflow-x: auto; */
              padding-bottom: 20px;
              /* min-height: 300px; */
              /* max-height: 300px;
          overflow-y: auto; */
            "
            class="tableStyleContainer"
          >
            <nz-table
              #virtualTable
              #basicTable
              nzTableLayout="fixed"
              [nzShowPagination]="false"
              [nzSize]="'small'"
              class="containers tableStyles"
            >
              <thead>
                <tr class="table-header">
                  <th
                    style="
                      width: 60px !important;
                      text-align: center;
                      padding-left: 10px;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <input
                        type="checkbox"
                        (click)="
                          groupSelect(
                            groupData$[groupedArray],
                            getgroupChecked(
                              selectedChekBox,
                              groupData$[groupedArray]
                            )
                          )
                        "
                        [checked]="
                          getgroupChecked(
                            selectedChekBox,
                            groupData$[groupedArray]
                          )
                        "
                      />
                      <div
                        *ngIf="isAdmin()"
                        (click)="
                          $event.stopPropagation();
                          $event.preventDefault();
                          openAction()
                        "
                        style="margin-left: 7px"
                      >
                        <img
                          src="../../assets/images/add.png"
                          width="15px"
                          class="cPointer"
                        />
                      </div>
                    </div>
                  </th>
                  <ng-container>
                    <ng-container
                      *ngFor="let header of getTableHeaders(); index as i"
                    >
                    <ng-container *ngIf="show_error_field(header)">
                      <ng-container
                      *ngIf="
                        !header.is_hidden &&
                        !(header.datatype === 'textarea' && header.rich_text)
                      "
                    >
                      <th
                        nz-tooltip
                        nzTooltipPlacement="bottom"
                        [nzTooltipTitle]="header.tool_tip"
                        (click)="
                          $event.stopPropagation();
                          $event.preventDefault();
                          dynamic(header.datatype)
                        "
                        class="hoverdata"
                        [nzTooltipOverlayClassName]="'toolTipContainers'"
                        [nzWidth]="getDynamicWidth(header.name)"
                        (nzResizeEnd)="onResize($event, header.name)"
                        nz-resizable
                        nzBounds="window"
                        nzPreview
                      >
                        <!-- [nzWidth]="dynamicWidth[header.datatype]" -->
                        <div
                          (dragover)="allowDrop($event)"
                          (drop)="dropColumn(i, header)"
                          draggable="true"
                          (dragstart)="dragStartColumn(i, header)"
                        >
                          <div
                            style="
                              display: flex;
                              justify-content: center;
                              cursor: pointer;
                              align-items: center;
                            "
                          >
                            <!-- <img src="../../../assets/images/drag.svg" class="showHover" /> -->
                            <span style="font-size: 12px">{{
                              header.label
                            }}</span>
                            <div
                              class=""
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                              *ngIf="
                                getSortOrder(header.label) &&
                                getSortingOrderlength() > 1
                              "
                              nz-dropdown
                              nzTrigger="click"
                              [nzDropdownMenu]="sortMenu"
                              class="sort-bubble"
                            >
                              <div
                                class=""
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  height: 100%;
                                  align-items: center;
                                  width: 5px;
                                "
                              >
                                <img
                                  src="../../assets/images/sort_asc.svg"
                                  *ngIf="sortingOrder[header.label] === 'asc'"
                                />
                                <img
                                  src="../../assets/images/sort_desc.svg"
                                  *ngIf="
                                    sortingOrder[header.label] === 'desc'
                                  "
                                />
                              </div>
                              <div
                                class=""
                                *ngIf="
                                  getSortingOrderNumber(header.label) != ''
                                "
                              >
                                <span
                                  style="
                                    padding-left: 4px;
                                    padding-right: 0px;
                                  "
                                  >{{
                                    getSortingOrderNumber(header.label)
                                  }}</span
                                >
                              </div>
                              <!-- Sort Dropdown List(only when length>1) -->
                              <nz-dropdown-menu #sortMenu="nzDropdownMenu">
                                <div
                                  class="sorting-popup"
                                  style="overflow: scroll; max-height: 400px"
                                >
                                  <nz-row
                                    class="f-10 fw-400"
                                    style="opacity: 0.8"
                                  >
                                    SORTING ORDER
                                  </nz-row>
                                  <nz-row
                                    *ngFor="
                                      let item of getSortingOrderArray();
                                      let i = index
                                    "
                                    class="mt-10"
                                  >
                                    <div
                                      class=""
                                      style="
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                      "
                                    >
                                      <div
                                        class=""
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <img
                                          src="../../assets/images/sort_asc.svg"
                                          *ngIf="sortingOrder[item] === 'asc'"
                                          (click)="
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                            addToSortingOrder(item)
                                          "
                                        />
                                        <img
                                          src="../../assets/images/sort_desc.svg"
                                          *ngIf="
                                            sortingOrder[item] === 'desc'
                                          "
                                          (click)="
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                            addToSortingOrder(item)
                                          "
                                        />
                                        <span
                                          style="
                                            padding-left: 8px;
                                            word-break: break-all;
                                          "
                                        >
                                          {{ item }}
                                        </span>
                                      </div>
                                      <div
                                        class=""
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <img
                                          src="../../assets/images/Trash.svg"
                                          (click)="
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                            removeFromSort(item)
                                          "
                                          style="padding-left: 3px"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </nz-row>
                                  <nz-divider> </nz-divider>
                                  <nz-row class="mt-10">
                                    <span
                                      (click)="
                                        $event.stopPropagation();
                                        $event.preventDefault();
                                        removeFromSort(header.label)
                                      "
                                      >Clear sort</span
                                    >
                                  </nz-row>
                                </div>
                              </nz-dropdown-menu>
                            </div>
                            <div
                              class=""
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                              *ngIf="
                                getSortOrder(header.label) &&
                                getSortingOrderlength() == 1
                              "
                              (click)="
                                $event.stopPropagation();
                                $event.preventDefault();
                                addToSortingOrder(header.label)
                              "
                              class="sort-bubble"
                            >
                              <div
                                class=""
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  height: 100%;
                                  align-items: center;
                                  width: 5px;
                                "
                              >
                                <img
                                  src="../../assets/images/sort_asc.svg"
                                  *ngIf="sortingOrder[header.label] === 'asc'"
                                />
                                <img
                                  src="../../assets/images/sort_desc.svg"
                                  *ngIf="
                                    sortingOrder[header.label] === 'desc'
                                  "
                                />
                              </div>
                            </div>
                            <img
                              src="../../../assets/images/Sort.svg"
                              class="showHover"
                              *ngIf="!getSortOrder(header.label)"
                              (click)="
                                $event.stopPropagation();
                                $event.preventDefault();
                                addToSortingOrder(header.label)
                              "
                            />
                            <!-- <img *ngIf="
                      header.show_grid !== 'always' &&
                      header.field_type !== 'SYSTEM'
                    " src="../../../assets/images/icons/closeIcon.svg" class="showHover"
                      (click)="removeTabelHeader(header, i)" style="margin-top: -2px" /> -->
                          </div>
                        </div>

                        <nz-resize-handle nzDirection="right">
                          <div class="resize-trigger"></div>
                        </nz-resize-handle>
                      </th>
                    </ng-container>
                    </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isSelectedMore !== 'approved_records'">
                    <th style="width: 150px !important; text-align: center">
                      <div style="font-size: 12px">Approval Status</div>
                    </th>
                  </ng-container>

                  <ng-container *ngIf="get_user_access() !== 'VIEW'">
                    <th style="width: 100px !important; text-align: center">
                      <div style="font-size: 12px">Action</div>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody class="tableRow" style="background: white">
                <ng-container
                  *ngFor="let data of groupData$[groupedArray]; let i = index"
                >
                  <ng-container
                    *ngIf="
                      !data.is_hidden &&
                      !(data.datatype === 'textarea' && data.rich_text)
                    "
                  >
                    <tr clss="custom-row-hover">
                      <td >
                        <div style="display: flex; align-items: center">
                          <input
                            [checked]="
                              getChecked(
                                selectedChekBox?.includes(data.entity_id)
                              )
                            "
                            type="checkbox"
                            (click)="addIds(data.entity_id, i)"
                          />
                          <img
                            style="margin-left: 5px"
                            *ngIf="isInnerChild()"
                            (click)="
                              $event.stopPropagation();
                              $event.preventDefault();
                              dataInnerExpand(data)
                            "
                            [ngStyle]="{
                              transform: data['expand']
                                ? 'rotate(180deg)'
                                : 'none',
                              cursor: 'pointer'
                            }"
                            src="../../assets/images/icons/expanddowninner.svg"
                          />
                        </div>
                      </td>

                      <ng-container
                        *ngFor="let header of getTableHeaders(); let i = index"
                      >
                      <ng-container *ngIf="show_error_field(header)">
                        <ng-container
                        *ngIf="
                          !header.is_hidden &&
                          !(
                            header.datatype === 'textarea' && header.rich_text
                          )
                        "
                      >
                        <td
                          [style.pointerEvents]="
                            (isViewOnlyAccess &&
                            isSelectedMore !== 'for_my_approval') || data?.is_locked
                              ? 'none'
                              : 'all'
                          "
                        >
                          <lib-custom-cell
                            [style.pointerEvents]="
                              state?.tableDiable === 'ERRORS' || data?.is_locked ? 'none' : 'all'
                            "
                            [ngClass]="{
                              inlineFlex: header.datatype === 'status'
                            }"
                            [parent]="this"
                            [header]="header"
                            [data]="data"
                          ></lib-custom-cell>
                        </td>
                      </ng-container>
                      </ng-container>

                      </ng-container>
                      <ng-container
                        *ngIf="isSelectedMore !== 'approved_records'"
                      >
                        <td
                          style="background: white"
                          [style.pointerEvents]="
                            state?.tableDiable === 'ERRORS' ? 'none' : 'all'
                          "
                        >
                          <div class="f-12">
                            {{ data?.a_status }}
                          </div>
                        </td>
                      </ng-container>
                      <ng-container *ngIf="get_user_access() !== 'VIEW'">
                        <td style="background: white">
                          <div class="">
                            <img
                              nz-menu
                              nz-dropdown
                              nzTrigger="click"
                              [ngClass]="{
                                pointerNone:
                                  isSelectedMore == 'my_drafts'
                                    ? data?.a_status === 'REJECTED' ||
                                      data?.a_status === 'DRAFT'
                                      ? false
                                      : true
                                    : false
                              }"
                              [nzDropdownMenu]="menu"
                              class="cPointer actionPadding"
                              src="../../assets/images/dot.png"
                              alt=""
                            />
                          </div>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <ng-container
                                *ngIf="isSelectedMore == 'approved_records'"
                              >
                                <li
                                  *ngIf="!isViewOnlyAccess && !data?.is_locked"
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    editRow(data)
                                  "
                                >
                                  Edit
                                </li>
                                <li
                                *ngIf="!isViewOnlyAccess && data?.is_locked"
                                class="f-12"
                                nz-menu-item
                                (click)="
                                  $event.stopPropagation();
                                  $event.preventDefault();
                                  editRow(data)
                                "
                              >
                                View
                              </li>
                                <li
                                  *ngIf="!isViewOnlyAccess && !data?.is_locked"
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    deleteRow(data.entity_id)
                                  "
                                >
                                  Delete
                                </li>
                                <li
                                  *ngIf="state?.tableDiable !== 'ERRORS'"
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    openVisibilityOptions(data)
                                  "
                                >
                                  Visibility
                                </li>
                              </ng-container>
                              <ng-container
                                *ngIf="isSelectedMore == 'for_my_approval'"
                              >
                                <li
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    selectedMenuOption(
                                      'outer',
                                      data,
                                      i,
                                      'approve'
                                    )
                                  "
                                >
                                  Accept
                                </li>
                                <li
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    selectedMenuOption(
                                      'outer',
                                      data,
                                      i,
                                      'reject'
                                    )
                                  "
                                >
                                  Reject
                                </li>
                                <li
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    viewRow(data, 'outerView')
                                  "
                                >
                                  View
                                </li>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  isSelectedMore == 'my_drafts' &&
                                  state?.tableDiable !== 'ERRORS'
                                "
                              >
                                <li
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    editRow(data)
                                  "
                                  *ngIf="!isViewOnlyAccess"
                                >
                                  Edit
                                </li>
                                <li
                                  *ngIf="!isViewOnlyAccess"
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    deleteRow(data.entity_id)
                                  "
                                >
                                  Delete
                                </li>
                                <li
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    sendForApproval('outer', data, i)
                                  "
                                >
                                  Send For Approval
                                </li>
                              </ng-container>
                              <ng-container
                                *ngFor="let item of filterCustomAction(tabName)"
                              >
                                <li
                                  *ngIf="!isViewOnlyAccess"
                                  class="f-12"
                                  nz-menu-item
                                  (click)="
                                    $event.stopPropagation();
                                    $event.preventDefault();
                                    selectedCustomAction(
                                      data,
                                      item,
                                      tabName,
                                      'parrent'
                                    )
                                  "
                                >
                                  {{ item?.label }}
                                </li>
                              </ng-container>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                      </ng-container>
                    </tr>
                    <tr
                      #expandMore
                      [nzExpand]="data['expand']"
                      style="background-color: darkgray"
                      class="expanddiv"
                    >
                      <ng-container *ngIf="data['expand']">
                        <ng-container
                          *ngIf="isEntityDataType$ | async as entityData"
                        >
                          <!-- child -->
                          <div class="childTableContainer">
                            <nz-layout class="layout tabContainer">
                              <ul nz-menu nzMode="horizontal">
                                <ng-container *ngFor="let i of entityData">
                                  <li
                                    nz-menu-item
                                    (click)="
                                      $event.stopPropagation();
                                      $event.preventDefault();
                                      expand(i, data, entityData)
                                    "
                                    [nzSelected]="i.expand"
                                  >
                                    {{ getDisplayName(i.name) }}
                                  </li>
                                </ng-container>
                                <ng-container>
                                  <li
                                    nz-menu-item
                                    [nzDisabled]="true"
                                    class="cPointer"
                                    (click)="
                                      $event.stopPropagation();
                                      $event.preventDefault();
                                      addMoreChildTable(data)
                                    "
                                  >
                                    <span
                                      style="
                                        border: 1px dashed #a1a8b5;
                                        padding: 7px 21px 6px 15px;
                                        color: #1d66ff;
                                      "
                                    >
                                      + Add
                                    </span>
                                  </li>
                                </ng-container>
                              </ul>
                            </nz-layout>
                            <div style="margin-top: 10px; margin-bottom: 20px">
                              <nz-table
                                #innerTable
                                [nzData]="['']"
                                nzSize="small"
                                [nzShowPagination]="false"
                              >
                                <thead>
                                  <!-- <ng-container *ngFor="
                                let header of isEntityDataTypeColumns$
                                  | async;
                                index as i
                              "> -->
                                  <!-- <ng-container *ngIf="!(header.is_hidden)"> -->

                                  <tr>
                                    <ng-container
                                      *ngFor="
                                        let header of isEntityDataTypeColumns$
                                          | async;
                                        index as i
                                      "
                                    >
                                      <ng-container *ngIf="!header.is_hidden">
                                        <th
                                          nz-tooltip
                                          nzTooltipPlacement="bottom"
                                          [nzTooltipTitle]="header.tool_tip"
                                          (click)="
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                            dynamic(header.datatype)
                                          "
                                          class="hoverdata"
                                          [nzTooltipOverlayClassName]="
                                            'toolTipContainers'
                                          "
                                          [style.width]="'100px'"
                                        >
                                          <div
                                            draggable="true"
                                            (dragstart)="
                                              dragStartColumn(i, header)
                                            "
                                            (dragover)="allowDrop($event)"
                                            (drop)="dropColumn(i, header)"
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                justify-content: center;
                                                cursor: pointer;
                                                align-items: center;
                                              "
                                            >
                                              <span style="font-size: 12px">{{
                                                header.label
                                              }}</span>
                                            </div>
                                          </div>
                                        </th>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="
                                        isSelectedMore !== 'approved_records'
                                      "
                                    >
                                      <th
                                        style="
                                          width: 150px !important;
                                          text-align: center;
                                        "
                                      >
                                        <div style="font-size: 12px">
                                          Approval Status
                                        </div>
                                      </th>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="get_user_access() !== 'VIEW'"
                                    >
                                      <th
                                        style="
                                          width: 100px !important;
                                          text-align: center;
                                        "
                                      >
                                        <div style="font-size: 12px">
                                          Action
                                        </div>
                                      </th>
                                    </ng-container>
                                  </tr>
                                  <!-- </ng-container> -->
                                  <!-- </ng-container> -->
                                </thead>
                                <tbody style="background: white">
                                  <tr
                                    *ngFor="
                                      let data of isEntityDataTypeRow$ | async
                                    "
                                    style="background: white"
                                  >
                                    <!-- <td>
              <input type="checkbox" (click)="addIds(data.entity_id)" />
              <img *ngIf="false" style="padding-left: 5px;" (click)="dataInnerExpand(data)"
                [ngStyle]="{'transform': data['expand'] ? 'rotate(180deg)' : 'none', 'cursor': 'pointer'}"
                src="../../assets/images/icons/expanddowninner.svg">
            </td> -->

                                    <ng-container
                                      *ngFor="
                                        let header of isEntityDataTypeColumns$
                                          | async;
                                        let i = index
                                      "
                                    >
                                      <ng-container *ngIf="!header.is_hidden">
                                        <td
                                          [style.pointerEvents]="
                                            isViewOnlyAccess ? 'none' : 'all'
                                          "
                                        >
                                          <lib-custom-cell
                                            [style.pointerEvents]="
                                              state?.tableDiable === 'ERRORS'
                                                ? 'none'
                                                : 'all'
                                            "
                                            [innerTable]="true"
                                            [parent]="this"
                                            [header]="header"
                                            [data]="data"
                                          ></lib-custom-cell>
                                        </td>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="
                                        isSelectedMore !== 'approved_records'
                                      "
                                    >
                                      <td style="background: white">
                                        <div class="f-12">
                                          {{ data?.a_status }}
                                        </div>
                                      </td>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="get_user_access() !== 'VIEW'"
                                    >
                                      <td style="background: white">
                                        <div class="">
                                          <img
                                            nz-menu
                                            nz-dropdown
                                            nzTrigger="click"
                                            [nzDropdownMenu]="menu"
                                            class="cPointer actionPadding"
                                            src="../../assets/images/dot.png"
                                            alt=""
                                          />
                                        </div>
                                        <nz-dropdown-menu
                                          #menu="nzDropdownMenu"
                                        >
                                          <ul nz-menu>
                                            <ng-container
                                              *ngIf="
                                                isSelectedMore ==
                                                'approved_records'
                                              "
                                            >
                                              <li
                                                *ngIf="!isViewOnlyAccess"
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  editRow(data, 'innerView')
                                                "
                                              >
                                                Edit
                                              </li>
                                              <li
                                                *ngIf="!isViewOnlyAccess"
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  deleteRow(data, 'innerView')
                                                "
                                              >
                                                Delete
                                              </li>
                                              <li
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  openVisibilityOptions(data)
                                                "
                                              >
                                                Visibility
                                              </li>
                                            </ng-container>
                                            <ng-container
                                              *ngIf="
                                                isSelectedMore ==
                                                'for_my_approval'
                                              "
                                            >
                                              <li
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  selectedMenuOption(
                                                    'inner',
                                                    data,
                                                    i,
                                                    'approve'
                                                  )
                                                "
                                              >
                                                Approve
                                              </li>
                                              <li
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  selectedMenuOption(
                                                    'inner',
                                                    data,
                                                    i,
                                                    'reject'
                                                  )
                                                "
                                              >
                                                Reject
                                              </li>
                                              <li
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  viewRow(data, 'innerView')
                                                "
                                              >
                                                View
                                              </li>
                                            </ng-container>
                                            <ng-container
                                              *ngIf="
                                                isSelectedMore == 'my_drafts'
                                              "
                                            >
                                              <li
                                                *ngIf="!isViewOnlyAccess"
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  editRow(data, 'innerView')
                                                "
                                              >
                                                Edit
                                              </li>
                                              <li
                                                *ngIf="!isViewOnlyAccess"
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  deleteRow(data, 'innerView')
                                                "
                                              >
                                                Delete
                                              </li>
                                              <li
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  sendForApproval(
                                                    'inner',
                                                    data,
                                                    i
                                                  )
                                                "
                                              >
                                                Send For Approval
                                              </li>
                                            </ng-container>
                                            <ng-container
                                              *ngFor="
                                                let item of filterCustomAction(
                                                  child_entity_name
                                                )
                                              "
                                            >
                                              <li
                                                *ngIf="!isViewOnlyAccess"
                                                class="f-12"
                                                nz-menu-item
                                                (click)="
                                                  $event.stopPropagation();
                                                  $event.preventDefault();
                                                  selectedCustomAction(
                                                    data,
                                                    item,
                                                    child_entity_name,
                                                    'child'
                                                  )
                                                "
                                              >
                                                {{ item?.label }}
                                              </li>
                                            </ng-container>
                                          </ul>
                                        </nz-dropdown-menu>
                                      </td>
                                    </ng-container>
                                  </tr>
                                </tbody>
                              </nz-table>

                              <div
                                style="background: #f2f2f2"
                                *ngIf="(isEntityDataTypeRow$|async)?.[0]?.total_records >10"
                              >
                                <div class="text-center">
                                  <span
                                    class="viewMore"
                                    (click)="
                                      $event.stopPropagation();
                                      $event.preventDefault();
                                      viewMore(data, i)
                                    "
                                    >click here to view more</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- old inner table ref -->
                          <ng-container *ngIf="false">
                            <ng-container *ngFor="let i of entityData">
                              <div
                                style="
                                  width: fit-content;
                                  margin-top: 10px;
                                  margin-bottom: 20px;
                                "
                              >
                                <div
                                  (click)="expand(i, data, entityData)"
                                  style="
                                    cursor: pointer !important;
                                    background: #f2f2f2;
                                    height: 35px;
                                    width: initial;
                                    padding: 7px 7px;
                                    border-radius: 5px;
                                  "
                                >
                                  {{ i.name }}
                                  <img
                                    style="height: 10px; width: 10px"
                                    src="../../assets/images/down-arrow.png"
                                    alt=""
                                    srcset=""
                                  />
                                </div>
                                <nz-table
                                  *ngIf="i.expand"
                                  #innerTable
                                  [nzData]="['']"
                                  nzSize="small"
                                  [nzShowPagination]="false"
                                >
                                  <thead>
                                    <tr>
                                      <!-- <th style="width: 60px !important; text-align: center">
              <div>
                <img src="../../assets/images/add.png" width="15px" class="cPointer" />


              </div>
            </th> -->
                                      <th
                                        nz-tooltip
                                        nzTooltipPlacement="bottom"
                                        [nzTooltipTitle]="header.tool_tip"
                                        (click)="dynamic(header.datatype)"
                                        class="hoverdata"
                                        *ngFor="
                                          let header of isEntityDataTypeColumns$
                                            | async;
                                          index as i
                                        "
                                        draggable="true"
                                        (dragstart)="dragStartColumn(i, header)"
                                        [nzTooltipOverlayClassName]="
                                          'toolTipContainers'
                                        "
                                        (dragover)="allowDrop($event)"
                                        (drop)="dropColumn(i, header)"
                                        [style.width]="'100px'"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            justify-content: center;
                                            cursor: pointer;
                                            align-items: center;
                                          "
                                        >
                                          <!-- <img src="../../../assets/images/drag.svg" class="showHover" /> -->
                                          <span style="font-size: 12px">{{
                                            header.label
                                          }}</span>
                                          <!-- <div class="" style="
  display: flex;
  align-items: center;
  justify-content: center;
" *ngIf="
  getSortOrder(header.label) &&
  getSortingOrderlength() > 1
" nz-dropdown nzTrigger="click" [nzDropdownMenu]="sortMenu" class="sort-bubble">
                  <div class="" style="
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    width: 5px;
  ">
                    <img src="../../assets/images/sort_asc.svg"
                      *ngIf="sortingOrder[header.label] === 'asc'" />
                    <img src="../../assets/images/sort_desc.svg"
                      *ngIf="sortingOrder[header.label] === 'desc'" />
                  </div>
                  <div class="" *ngIf="getSortingOrderNumber(header.label) != ''">
                    <span style="padding-left: 4px; padding-right: 0px">{{
                      getSortingOrderNumber(header.label)
                      }}</span>
                  </div>

                  <nz-dropdown-menu #sortMenu="nzDropdownMenu">
                    <div class="sorting-popup" style="overflow: scroll; max-height: 400px">
                      <nz-row class="f-10 fw-400" style="opacity: 0.8">
                        SORTING ORDER
                      </nz-row>
                      <nz-row *ngFor="
        let item of getSortingOrderArray();
        let i = index
      " class="mt-10">
                        <div class="" style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
                          <div class="" style="display: flex; align-items: center">

                            <img src="../../assets/images/sort_asc.svg"
                              *ngIf="sortingOrder[item] === 'asc'"
                              (click)="addToSortingOrder(item)" />
                            <img src="../../assets/images/sort_desc.svg"
                              *ngIf="sortingOrder[item] === 'desc'"
                              (click)="addToSortingOrder(item)" />
                            <span style="
              padding-left: 8px;
              word-break: break-all;
            ">
                              {{ item }}
                            </span>
                          </div>
                          <div class="" style="display: flex; align-items: center">
                            <img src="../../assets/images/Trash.svg"
                              (click)="removeFromSort(item)" style="padding-left: 3px" alt="" />
                          </div>
                        </div>
                      </nz-row>
                      <nz-divider> </nz-divider>
                      <nz-row class="mt-10">
                        <span (click)="removeFromSort(header.label)">Clear sort</span>
                      </nz-row>
                    </div>
                  </nz-dropdown-menu>
                </div>
                <div class="" style="
  display: flex;
  align-items: center;
  justify-content: center;
" *ngIf="
  getSortOrder(header.label) &&
  getSortingOrderlength() == 1
" (click)="addToSortingOrder(header.label)" class="sort-bubble">
                  <div class="" style="
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    width: 5px;
  ">
                    <img src="../../assets/images/sort_asc.svg"
                      *ngIf="sortingOrder[header.label] === 'asc'" />
                    <img src="../../assets/images/sort_desc.svg"
                      *ngIf="sortingOrder[header.label] === 'desc'" />
                  </div>
                </div>
                <img src="../../../assets/images/Sort.svg" class="showHover"
                  *ngIf="!getSortOrder(header.label)" (click)="addToSortingOrder(header.label)" />
                <img *ngIf="
  header.show_grid !== 'always' &&
  header.field_type !== 'SYSTEM'
" src="../../../assets/images/icons/closeIcon.svg" class="showHover"
                  (click)="removeTabelHeader(header, i)" style="margin-top: -2px" /> -->
                                        </div>
                                      </th>
                                      <ng-container
                                        *ngIf="
                                          isSelectedMore !== 'approved_records'
                                        "
                                      >
                                        <th
                                          style="
                                            width: 150px !important;
                                            text-align: center;
                                          "
                                        >
                                          <div style="font-size: 12px">
                                            Approval Status
                                          </div>
                                        </th>
                                      </ng-container>

                                      <ng-container
                                        *ngIf="get_user_access() !== 'VIEW'"
                                      >
                                        <th
                                          style="
                                            width: 100px !important;
                                            text-align: center;
                                          "
                                        >
                                          <div style="font-size: 12px">
                                            Action
                                          </div>
                                        </th>
                                      </ng-container>
                                    </tr>
                                  </thead>
                                  <tbody style="background: white">
                                    <tr
                                      *ngFor="
                                        let data of isEntityDataTypeRow$ | async
                                      "
                                      style="background: white"
                                    >
                                      <td>
                                        <input
                                          type="checkbox"
                                          (click)="addIds(data.entity_id)"
                                        />
                                        <img
                                          *ngIf="false"
                                          style="margin-left: 5px"
                                          (click)="dataInnerExpand(data)"
                                          [ngStyle]="{
                                            transform: data['expand']
                                              ? 'rotate(180deg)'
                                              : 'none',
                                            cursor: 'pointer'
                                          }"
                                          src="../../assets/images/icons/expanddowninner.svg"
                                        />
                                      </td>

                                      <ng-container
                                        *ngFor="
                                          let header of isEntityDataTypeColumns$
                                            | async;
                                          let i = index
                                        "
                                      >
                                        <td>
                                          <lib-custom-cell
                                            [style.pointerEvents]="
                                              state?.tableDiable === 'ERRORS'
                                                ? 'none'
                                                : 'all'
                                            "
                                            [innerTable]="true"
                                            [parent]="this"
                                            [header]="header"
                                            [data]="data"
                                          ></lib-custom-cell>
                                        </td>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          isSelectedMore !== 'approved_records'
                                        "
                                      >
                                        <td style="background: white">
                                          <div class="f-12">
                                            {{ data?.a_status }}
                                          </div>
                                        </td>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="get_user_access() !== 'VIEW'"
                                      >
                                        <td style="background: white">
                                          <div class="">
                                            <img
                                              nz-menu
                                              nz-dropdown
                                              nzTrigger="click"
                                              [nzDropdownMenu]="menu"
                                              class="cPointer actionPadding"
                                              src="../../assets/images/dot.png"
                                              alt=""
                                            />
                                          </div>
                                          <nz-dropdown-menu
                                            #menu="nzDropdownMenu"
                                          >
                                            <ul nz-menu>
                                              <ng-container
                                                *ngIf="
                                                  isSelectedMore ==
                                                  'approved_records'
                                                "
                                              >
                                                <li
                                                  *ngIf="!isViewOnlyAccess"
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="editRow(data, i)"
                                                >
                                                  Edit
                                                </li>
                                                <li
                                                  *ngIf="!isViewOnlyAccess"
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    deleteRow(data.entity_id)
                                                  "
                                                >
                                                  Delete
                                                </li>
                                                <li
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    openVisibilityOptions(data)
                                                  "
                                                >
                                                  Visibility
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  isSelectedMore ==
                                                  'for_my_approval'
                                                "
                                              >
                                                <li
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    selectedMenuOption(
                                                      'inner',
                                                      data,
                                                      i,
                                                      'approve'
                                                    )
                                                  "
                                                >
                                                  Approve
                                                </li>
                                                <li
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    selectedMenuOption(
                                                      'inner',
                                                      data,
                                                      i,
                                                      'reject'
                                                    )
                                                  "
                                                >
                                                  Reject
                                                </li>
                                                <li
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    viewRow(data, 'innerView')
                                                  "
                                                >
                                                  View
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  isSelectedMore == 'my_drafts'
                                                "
                                              >
                                                <li
                                                  *ngIf="!isViewOnlyAccess"
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="editRow(data)"
                                                >
                                                  Edit
                                                </li>
                                                <li
                                                  *ngIf="!isViewOnlyAccess"
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    deleteRow(data.entity_id)
                                                  "
                                                >
                                                  Delete
                                                </li>
                                                <li
                                                  class="f-12"
                                                  nz-menu-item
                                                  (click)="
                                                    sendForApproval(
                                                      'inner',
                                                      data,
                                                      i
                                                    )
                                                  "
                                                >
                                                  Send For Approval
                                                </li>
                                              </ng-container>
                                            </ul>
                                          </nz-dropdown-menu>
                                        </td>
                                      </ng-container>
                                    </tr>
                                  </tbody>
                                </nz-table>

                                <div
                                  style="background: #f2f2f2"
                                  *ngIf="(isEntityDataTypeRow$|async)?.[0]?.total_records >10"
                                >
                                  <div class="text-center">
                                    <span
                                      class="viewMore"
                                      (click)="viewMore(data, i)"
                                      >click here to view more</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-container>
                  <ng-template
                    *ngIf="!!scrollTimeout && last"
                    [ngTemplateOutlet]="skeltonloader"
                    [ngTemplateOutletContext]="{
                      headerList: getTableHeaders()
                    }"
                  ></ng-template>
                </ng-container>
                <ng-container *ngIf="!computed && last">
                  <ng-template
                    [ngTemplateOutlet]="skeltonloader"
                    [ngTemplateOutletContext]="{
                      headerList: getTableHeaders()
                    }"
                  ></ng-template>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="Object.keys(groupData$).length == 0">
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel
          #colapsePanel
          [nzShowArrow]="false"
          [nzHeader]="customHeaderTemplate"
          [nzActive]="true"
          [nzDisabled]="false"
        >
          <ng-template [ngTemplateOutlet]="tableLayout"></ng-template>
        </nz-collapse-panel>
      </nz-collapse>
      <ng-template #customHeaderTemplate>
        <div class="group-sec">
          <div [ngClass]="{ isOpenClass: colapsePanel.nzActive }">
            <img src="../../../assets/images/down-arrow.png" />
          </div>
          <div>Grouping - {{ "NA" }} - {{ 0 }} rows</div>
        </div>
      </ng-template>

      <ng-template #tableLayout let-groupedArray="groupedArray">
        <div
          style="
            width: auto;
            max-width: 100vw;
            overflow-x: auto;
            padding-bottom: 20px;
          "
        >
          <nz-table
            #virtualTable
            #basicTable
            nzTableLayout="fixed"
            [nzShowPagination]="false"
            [nzSize]="'small'"
            class="containers"
          >
            <thead>
              <tr class="table-header">
                <th
                  *ngIf="isAdmin()"
                  style="width: 30px !important; text-align: center"
                  (click)="openAction()"
                >
                  <div>
                    <img
                      src="../../assets/images/add.png"
                      width="15px"
                      class="cPointer"
                    />
                  </div>
                </th>
                <ng-container
                  *ngFor="let header of getTableHeaders(); index as i"
                >
                <ng-container *ngIf="show_error_field(header)">
                  <ng-container
                  *ngIf="
                    !(header.datatype === 'textarea' && header.rich_text)
                  "
                >
                  <th
                    (click)="dynamic(header.datatype)"
                    class="hoverdata"
                    nz-tooltip
                    nzTooltipPlacement="bottom"
                    [nzTooltipTitle]="header.tool_tip"
                    [nzTooltipOverlayClassName]="'toolTipContainers'"
                    [nzWidth]="getDynamicWidth(header.name)"
                    (nzResizeEnd)="onResize($event, header.name)"
                    nz-resizable
                    nzBounds="window"
                    nzPreview
                  >
                    <!-- [nzWidth]="dynamicWidth[header.datatype]" -->
                    <div
                      draggable="true"
                      (dragstart)="dragStartColumn(i, header)"
                      (dragover)="allowDrop($event)"
                      (drop)="dropColumn(i, header)"
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          cursor: pointer;
                          align-items: center;
                        "
                      >
                        <!-- <img src="../../../assets/images/drag.svg" class="showHover" /> -->
                        <span style="font-size: 12px">{{
                          header.label
                        }}</span>
                        <div
                          class=""
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          *ngIf="
                            getSortOrder(header.label) &&
                            getSortingOrderlength() > 1
                          "
                          nz-dropdown
                          nzTrigger="click"
                          [nzDropdownMenu]="sortMenu"
                          class="sort-bubble"
                        >
                          <div
                            class=""
                            style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                            "
                          >
                            <img
                              src="../../assets/images/sort_asc.svg"
                              *ngIf="sortingOrder[header.label] === 'asc'"
                            />
                            <img
                              src="../../assets/images/sort_desc.svg"
                              *ngIf="sortingOrder[header.label] === 'desc'"
                            />
                          </div>
                          <div
                            class=""
                            *ngIf="getSortingOrderNumber(header.label) != ''"
                          >
                            <span
                              style="padding-left: 4px; padding-right: 0px"
                              >{{ getSortingOrderNumber(header.label) }}</span
                            >
                          </div>
                          <nz-dropdown-menu #sortMenu="nzDropdownMenu">
                            <div
                              class="sorting-popup"
                              style="overflow: scroll; max-height: 400px"
                            >
                              <nz-row
                                class="f-10 fw-400"
                                style="opacity: 0.8"
                              >
                                SORTING ORDER
                              </nz-row>
                              <nz-row
                                *ngFor="
                                  let item of getSortingOrderArray();
                                  let i = index
                                "
                                class="mt-10"
                              >
                                <div
                                  class=""
                                  style="
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                  "
                                >
                                  <div
                                    class=""
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      src="../../assets/images/sort_asc.svg"
                                      *ngIf="sortingOrder[item] === 'asc'"
                                      (click)="addToSortingOrder(item)"
                                    />
                                    <img
                                      src="../../assets/images/sort_desc.svg"
                                      *ngIf="sortingOrder[item] === 'desc'"
                                      (click)="addToSortingOrder(item)"
                                    />
                                    <span
                                      style="
                                        padding-left: 8px;
                                        word-break: break-all;
                                      "
                                    >
                                      {{ item }}
                                    </span>
                                  </div>
                                  <div
                                    class=""
                                    style="display: flex; align-items: center"
                                  >
                                    <img
                                      src="../../assets/images/Trash.svg"
                                      (click)="removeFromSort(item)"
                                      style="padding-left: 3px"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </nz-row>
                              <nz-divider> </nz-divider>
                              <nz-row class="mt-10">
                                <span (click)="removeFromSort(header.label)"
                                  >Clear sort</span
                                >
                              </nz-row>
                            </div>
                          </nz-dropdown-menu>
                        </div>
                        <div
                          class=""
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          *ngIf="
                            getSortOrder(header.label) &&
                            getSortingOrderlength() == 1
                          "
                          (click)="addToSortingOrder(header.label)"
                          class="sort-bubble"
                        >
                          <div
                            class=""
                            style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                            "
                          >
                            <img
                              src="../../assets/images/sort_asc.svg"
                              *ngIf="sortingOrder[header.label] === 'asc'"
                            />
                            <img
                              src="../../assets/images/sort_desc.svg"
                              *ngIf="sortingOrder[header.label] === 'desc'"
                            />
                          </div>
                        </div>
                        <img
                          src="../../../assets/images/Sort.svg"
                          class="showHover"
                          *ngIf="!getSortOrder(header.label)"
                          (click)="addToSortingOrder(header.label)"
                        />
                        <!-- <img *ngIf="
                        header.show_grid !== 'always' &&
                        header.field_type !== 'SYSTEM'
                      " src="../../../assets/images/icons/closeIcon.svg" class="showHover"
                    (click)="removeTabelHeader(header, i)" style="margin-top: -2px" /> -->
                      </div>
                    </div>
                    <nz-resize-handle nzDirection="right">
                      <div class="resize-trigger"></div>
                    </nz-resize-handle>
                  </th>
                </ng-container>
                </ng-container>

                </ng-container>
                <ng-container *ngIf="isSelectedMore !== 'approved_records'">
                  <th style="width: 150px !important; text-align: center">
                    <div style="font-size: 12px">Approval Status</div>
                  </th>
                </ng-container>
                <ng-container *ngIf="get_user_access() !== 'VIEW'">
                  <th style="width: 100px !important; text-align: center">
                    <div style="font-size: 12px">Action</div>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="tableRow">
              <ng-container *ngIf="!computed">
                <ng-template
                  [ngTemplateOutlet]="skeltonloader"
                  [ngTemplateOutletContext]="{ headerList: getTableHeaders() }"
                ></ng-template>
              </ng-container>
              <ng-container *ngIf="computed"></ng-container>
            </tbody>
          </nz-table>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #skeltonloader let-headerList="headerList">
  <tr *ngFor="let row of [].constructor(10)">
    <td *ngIf="isAdmin()" style="text-align: center">
      <div>
        <img src="../../assets/images/add.png" width="15px" class="cPointer" />
      </div>
    </td>
    <ng-container *ngFor="let header of headerList">
      <td style="text-align: center">
        <div class="mt-10" style="background: #f7f7fb; margin: auto">
          <div
            class="ghost"
            style="height: 20px; margin: auto; width: 70%"
          ></div>
        </div>
      </td>
    </ng-container>
    <td style="text-align: center">
      <div class="mt-10" style="background: #f7f7fb">
        <div class="ghost" style="height: 20px; margin: auto; width: 70%"></div>
      </div>
    </td>
  </tr>
</ng-template>
