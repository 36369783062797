import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit {
  @Input() header: any;
  @Input() innerTable: any;
  @Input() data: any;
  @Input() parent: any;
  @Output() stopedit: any = new EventEmitter<any>();
  @Output() startDropDownEditEmitter: any = new EventEmitter<any>();
  @ViewChild('tagStyle') tagStyle: any;
  @ViewChild('tagSelector', { static: true }) tagSelector: any;
  statuesColor: any = [
    {
      color: '#1DF1FF',
    },
    {
      color: '#56C0C6',
    },
    {
      color: '#FF1DE8',
    },
    {
      color: '#F70295',
    },
    {
      color: '#FFC01D',
    },
    {
      color: '#CF6BFF',
    },
    {
      color: '#E9FD00',
    },
    {
      color: '#FF8058',
    },
  ];
  selectedValue: any = [];
  Object: any;
  constructor(private elementRef: ElementRef) {}
  ngOnInit(): void {}
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  updateColor(temp: any, label: any, value: any, s: any) {
    let ele =
      temp?.elementRef?.nativeElement?.previousSibling.querySelectorAll(
        'nz-select-item'
      );
    let backgroundColor = this.header?.options?.find((option: any) => {
      return option.name === value;
    })?.color;
    if (ele.length > 0) {
      for (let e of ele) {
        let specificElement = e.title === label;
        if (specificElement) {
          e.style.background = backgroundColor;
        }
      }
    }

    return '';
  }
  stopEdit() {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(
        { target: { value: this.data[this.header.name] } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: this.data[this.header.name] } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    this.stopedit.emit();
  }
  editClicked = false;
  cdr=inject(ChangeDetectorRef);
  error = '';
 check_is_unique() {
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.stopEdit()
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.stopEdit()
       }
     }, 100);
   }

 }
  onEditClick(tag: any) {
    if(!this.header.can_edit || this.header.tab_name==='XX' || this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)){
      return
    }
    this.editClicked = true;
    this.startDropDownEditEmitter.emit();
  }
  handleDropdownClose(event: any) {
    if (!event) {
      this.editClicked = false;
    }
  }
  getBackgrounfColor(value: any) {
    let findColor = this.header.options.find(
      (e: any) => e.name === value
    )?.color;
    return findColor || '';
  }
  addTagOption: any;
  disableTagOptionButton: boolean = false;
  saveTagOption(field: any) {
    if (!(this.addTagOption?.length > 0)) {
      return;
    }
    this.disableTagOptionButton = true;
    let addOption = {
      name: `${this.addTagOption}`,
      color: '',
    };
    let fields = field.options.push(addOption);
    let payload = {
      org_id: this.parent.pservice?.appState().selectedOrganization.org_id,
      process_id: this.parent.pservice?.appState().selectedProcess.process_id,
      entity_name: this.parent.pservice?.appState()?.selectedEntity,
      field: field,
      parent_entity: field.entity_name || '',
      child_field: field.name || '',
      option_type: field.option_type || '',
      parent_field: field.field_name || '',
      f_name: field.name,
    };
    if (this.addTagOption.length > 0) {
      this.addTagOption = '';
      this.parent.pservice?.saveAddColumn(payload).subscribe((res: any) => {
        this.addTagOption = '';
        this.disableTagOptionButton = false;
        let copy = cloneDeep(this.parent.pservice?.appState());
        let alterdata = copy.metaData.entities.find(
          (e: any) => e.name === payload.entity_name
        );
        let fields = alterdata.fields.find(
          (e: any) => e.name == payload.field.name
        );
        for (let i of this.Object.keys(payload.field)) {
          fields[i] = payload.field[i];
        }
        this.parent.pservice?.state.update((user: any) => ({
          ...user,
          appState: { ...copy },
        }));
      });
    }
  }
}
