<div class="px-10 textStyle location" fluidRowWidth [type]="header.name">
<ng-container>
    <div class="editable-cell d-flex p-0 text-start word-break-all min-height-37 align-center" *ngIf="!(editval$|async)" (click)="editing()">
{{
data[header.name]
? data[header.name]
: header.default
? header.default
: "&nbsp;&nbsp;&nbsp;"
}}
</div>
<nz-form-control class="d-flex text-start p-0 min-height-37 align-center flex-column"*ngIf="(editval$|async)">
<nz-input-group>
<input auto-focus-input class="text-start p-0" type="text" nz-input [(ngModel)]="data[header.name]" 
  (focusout)="checkValidation($event)"
/>
</nz-input-group>
<div style="color: red ;font-size: smaller;text-align: left;">
  {{error}}
  </div>
</nz-form-control>
</ng-container>
</div>