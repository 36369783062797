<ng-container *ngIf="!(nzModalData.data.showImage)">
  <div>
    <embed [src]="url" frameborder="0" height="200px" width="100%" style="margin-top: 20px;" />
  </div>
  
  <div style="margin-left: auto; display: flex;align-items: center;margin-top: 10px;justify-content: end;column-gap: 10px;">
    <span style="cursor: pointer;display: flex;align-items: center;margin-top: 10px;justify-content: end;column-gap: 10px;"  (click)="downloadFile()">
   
    <div style="color: #1d66ff;font-size: 12px;font-weight: 400;">download</div>
    <img
    
    src="../../../../../../assets/images/icons/downloadIcon.svg"
    alt=""
  />
    </span>
  </div>
</ng-container>
<ng-container *ngIf="(nzModalData.data.showImage)">
  <embed [src]="url" frameborder="0" height="200px" width="100%" style="margin-top: 20px;" />
</ng-container>