<nz-row [formGroup]="addStatuessForm" class="addNewFieldContainer">
  <div formArrayName="statuesDetails" style="width: 100%">
    <nz-layout
      draggable="true"
      (dragstart)="dragStartColumn(i, $event)"
      (dragover)="allowDrop($event)"
      (drop)="dropColumn(i, $event)"
      *ngFor="let variable of statuesDetails().controls; let i = index"
      [formGroupName]="i"
    >
      <nz-row [nzGutter]="10">
        <div
          style="cursor: grab"
          nz-col
          [nzXs]="{ span: 1 }"
          [nzLg]="{ span: 1 }"
          [nzXXl]="1"
        >
          <img src="../../../../assets/images/icons/arrowDragIcon.svg" />
        </div>
        <div nz-col [nzXs]="{ span: 11 }" [nzLg]="{ span: 11 }" [nzXXl]="11">
          <div class="d-flex" style="width: 100%">
            <nz-form-item style="width: 100%">
              <nz-form-control nzErrorTip="Enter field name">
                <nz-input-group>
                  <input
                  oninput="this.value = this.value.replace(/[^a-z_]/g, '');"
                    #defaultFieldValue
                    nz-input
                    formControlName="name"
                    placeholder="field name"
                    (input)="inputFieldName(i, $event)"
                  />
                </nz-input-group>
              </nz-form-control>
              <div
              style="color: red;font-size: 12px;"
              *ngIf="duplicateNameError && duplicateNameIndex === i"
            >
              Duplicate name entered
            </div>
            </nz-form-item>
          </div>
        </div>
        <div nz-col [nzXs]="{ span: 11 }" [nzLg]="{ span: 11 }" [nzXXl]="11">
          <div class="d-flex" style="width: 100%">
            <nz-form-item style="width: 100%">
              <nz-form-control nzErrorTip="Enter label name">
                <nz-input-group>
                  <input
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
                    #defaultFieldValue
                    nz-input
                    formControlName="label"
                    placeholder="label name"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-row>
    </nz-layout>
  </div>

  <nz-col [nzSpan]="24">
    <div class="addMore" style="cursor: pointer" (click)="addMore()">
      ++Add More
    </div>
  </nz-col>
</nz-row>
<nz-row style="justify-content: end">
  <div class="text-center cPointer">
    <button
      nzType="primary"
      class="f-10 fw-700 save"
      type="submit"
      style="padding: 8px 24px"
      (click)="confirm()"
    >
      SAVE
    </button>
  </div>
  <div class="text-center f-10 fw-700 lh-18 blueColor">
    <button
      nz-button
      nzType="default"
      class="buttonDefault cancel f-10 fw-700"
      style="border-radius: 5px; margin-left: 20px"
      (click)="close()"
    >
      CANCEL
    </button>
  </div>
</nz-row>
