<div
  [style.pointerEvents]="!header.can_edit || header.tab_name==='XX' || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'"
  class="px-10 textStyle attachmentContainer"
  fluidRowWidth
  [type]="header.name"
>
  <ng-container>
    <div
      style="display: flex; column-gap: 10px; align-items: center"
      class="attachmentContainer"
    >
      <ng-container
        *ngIf="
          this.data[this.header?.name]?.length !== 0 &&
          this.data[this.header?.name]
        "
      >
        <div
          style="color: #1d66ff; cursor: pointer; font-size: 12px"
          nz-dropdown
          nzClickHide="false"
          nzTrigger="click"
          [nzDropdownMenu]="attachmentMenu"
          (nzVisibleChange)="event($event)"
        >
          <nz-dropdown-menu #attachmentMenu="nzDropdownMenu">
            <ng-container *ngIf="close">
              <ul nz-menu class="attContainer">
                <ng-container
                  *ngFor="
                    let item of this.data[this.header?.name];
                    let index = index
                  "
                >
                  <li class="liClass">
                    <div style="font-size: 11px" class="hideOverFlowText">
                      {{ getShortenName(item) }}
                    </div>
                    <div>
                      <img
                        class="cPointer"
                        src="../../../../../assets/images/eye.svg"
                        (click)="downloadAttachment(item)"
                      /><img
                        class="cPointer"
                        src="../../../../assets/images/icons/TrashBinIcon.svg"
                        (click)="
                          this.data[this.header?.name].splice(index, 1);
                          removeAttachment()
                        "
                      />
                    </div>
                  </li>
                </ng-container>
                <div class="title-text">Attach File</div>
                <li>
                  <img
                    style="width: 16px; height: 16px"
                    class="cPointer"
                    src="../../../assets/images/attachment.png"
                    (click)="UploadFileInput.click()"
                  />
                </li>
              </ul>
            </ng-container>
          </nz-dropdown-menu>
          <img
            class="cPointer"
            nz-tooltip
            [nzTooltipOverlayClassName]="'attToolTipContainers'"
            [nzTooltipTitle]="getShortenName(this.data[this.header?.name][0])"
            nzTooltipPlacement="top"
            src="../../../../../assets/images/icons/imageIcon.png"
            style="width: 18px; height: 18px"
          />
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          this.data[this.header?.name]?.length === 0 ||
          !this.data[this.header?.name]
        "
      >
        <img
          style="width: 16px; height: 16px"
          class="cPointer"
          src="../../../assets/images/attachment.png"
          (click)="UploadFileInput.click()"
        />

        <input
          [hidden]="true"
          type="file"
          #UploadFileInputs
          id="fileUpload"
          (change)="handleChange($event, this.data[this.header?.name])"
          name="fileUpload"
          multiple="multiple"
        />
      </ng-container>

      <img
        class="hideDelete cPointer"
        *ngIf="this.data[this.header?.name]?.length === 1"
        (click)="this.data[this.header?.name].splice(0, 1); removeAttachment()"
        src="../../../../assets/images/icons/TrashBinIcon.svg"
      />
    </div>
  </ng-container>

  <input
    [hidden]="true"
    type="file"
    #UploadFileInput
    id="fileUpload"
    (change)="handleChange($event, this.data[this.header?.name])"
    name="fileUpload"
    multiple="multiple"
  />
</div>
