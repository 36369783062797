import { computed, inject, Injectable, signal } from '@angular/core';
import { orderBy, sortBy } from 'lodash';
import { apiCache, DATA_LOG_FILTER, layoutCache, modalInstance, ROW_ENTITY_DATA } from '../components/entity-layout/smart-table/cache';
import { findAndSetDefaultOrganization } from './appstate.logic';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { StateService } from './state.service';
import { OverlaySidebarService } from '../components/entity-layout/smart-table/overlay-sidebar.service';

interface InitialState {
  currentOrganization: any;
  currentProcess: any;
  userOrgList: any;
  cacheMetaData: any;
  cacheEntityData: any;
  organizationState: any;
  invite_org_list: any;
}

@Injectable({
  providedIn: 'root',
})
export class AppstateService {
  invitationClosed = false;
  initialState: InitialState = {
    currentOrganization: null,
    currentProcess: null,
    userOrgList: null,
    cacheMetaData: {},
    cacheEntityData: {},
    organizationState: {},
    invite_org_list: [],
  };

  loading = signal(true);
  is_mobile = signal(false);
  show_more_entity_table_action = signal(true);
  private state = signal<InitialState>(this.initialState);
  getInvitations = computed(() => {
    if (!this.invitationClosed) {
      return this.state().invite_org_list || [];
    } else {
      return [];
    }
  });

  update_user_org_list(organization: any) {
    this.state.update((state) => ({
      ...state,
      userOrgList: orderBy(organization, 'org_name', 'asc'),
      currentOrganization: findAndSetDefaultOrganization(organization),
    }));
  }
  update_user_org_invite(data: any) {
    this.state.update((state) => ({
      ...state,
      invite_org_list: data,
    }));
  }
  updateDefaultProcess(process: any) {
    this.state.update((state) => ({
      ...state,
      currentProcess: process,
      public___org_processes: orderBy(process, 'd_process_name', 'asc'),
    }));
  }

  updateDefaultOrganization(org: any) {
    this.state.update((state) => ({
      ...state,
      currentOrganization: org,
    }));
  }

  updateMetaDataAndLayout(
    response: any,
    org_name: string,
    process_name: string
  ) {
    const cacheMetaData: any = {};
    cacheMetaData[`${org_name}___${process_name}`] = response;

    this.state.update((state) => ({
      ...state,
      cacheMetaData: { ...state.cacheMetaData, ...cacheMetaData },
    }));
  }

  getCurrentOrganization = computed(() => this.state().currentOrganization);
  getCurrentProcess = computed(() => this.state().currentProcess);
  getOrgList = computed(() => this.state().userOrgList);
  get_org_state = computed(() => this.state().organizationState);
  get_invite_org_list = computed(() => this.state().invite_org_list);

  getEntityHeaders(org_name: string, process_name: string) {
    return computed(() => {
      const metaData =
        this.state().cacheMetaData[`${org_name}___${process_name}`]?.[0]?.[0]
          ?.Results?.[0]?.meta_data;
      let entities: any[] = [];
      if (metaData && metaData.dashboard) {
        entities = [{ display_name: 'Dashboard', index: -1 }];
      }
      if (metaData) {
        entities = [...(entities || []), ...(metaData?.entities || [])];
        entities = orderBy(
          entities,
          (entity: any) => parseInt(entity.index),
          'asc'
        );
      }
      entities = entities.filter((T: any) => !(T.hide_entity === 'true'));

      return entities;
    });
  }

  getEntityTableHeaders(org_name: string, process_name: string) {
    return computed(() => {
      const metaData =
        this.state().cacheMetaData[`${org_name}___${process_name}`]?.[0]?.[0]
          ?.Results?.[0]?.meta_data;
      let entities: any[] = [];
      if (metaData && metaData.dashboard) {
        entities = [{ display_name: 'Dashboard', index: -1 }];
      }
      if (metaData) {
        entities = [...(entities || []), ...(metaData?.entities || [])];
        entities = orderBy(
          entities,
          (entity: any) => parseInt(entity.index),
          'asc'
        );
      }
      entities = entities.filter((T: any) => !(T.hide_entity === 'true'));
      let new_header_array: any []= [];

      entities.forEach((_entity: any) => {
        let selected_entity:any = new_header_array.find(
          (_e: any) => _e.g_name === _entity?.g_name
        );
        if (!selected_entity && _entity?.g_name && _entity.gd_name) {
          let data: any = {
            g_name: _entity?.g_name,
            gd_name: _entity.gd_name,
            // index:_entity.index,
            child_data: [],
          };

          data['child_data'] = [
            {
              name: _entity?.name,
              display_name: _entity?.display_name,
              index:_entity.index,
            },
          ];
          new_header_array.push(data);
        }

        if(selected_entity){
          let child_data = {
            name: _entity?.name,
            display_name: _entity?.display_name,  
            index:_entity.index,          
          };
          selected_entity?.child_data?.push(child_data);
        }
        if((!_entity?.g_name && !_entity.gd_name ) || _entity?.g_name===undefined || _entity?.gd_name ===undefined){
          let no_group ={
            name: _entity?.name,
            display_name: _entity?.display_name,
            index:_entity.index,
          }
          new_header_array.push(no_group)
        }
      });
      new_header_array = new_header_array.map((_e:any)=>{
        if(_e.child_data){
          let group_index = metaData?.g_entities?.find((_grp:any)=>_grp.g_name === _e.g_name)?.index
          _e.child_data= _e.child_data.sort((a:any, b:any) => parseInt(a.index) - parseInt(b.index));
          if(group_index){
            return {
              ..._e,
              index:group_index
            }
          }
        }else{
          return _e
        }
      })      
      new_header_array = orderBy(new_header_array,(entity: any) => parseInt(entity.index),'asc');     
      
      return new_header_array;
    });
  }
  get_meta_data(org_name: any, process_name: any) {
    const initial_data =
      this.state().cacheMetaData[`${org_name}___${process_name}`];
    const meta_data = initial_data?.[0]?.[0]?.Results?.[0]?.meta_data;
    return meta_data;
  }
  get_layout_meta_data(org_name: any, process_name: any) {
    const initial_data =
      this.state().cacheMetaData[`${org_name}___${process_name}`];
    const layout_data = initial_data?.[1]?.layout_metadata;
    return layout_data;
  }
  get_child_table(org_name: any, process_name: any, entity_field: any) {
    const initial_data =
      this.state().cacheMetaData[`${org_name}___${process_name}`];
    const meta_data = initial_data?.[0]?.[0]?.Results?.[0]?.meta_data;
    // const layout_data = initial_data?.[1]?.layout_metadata;
    let child_details: any[] = [];
    // entity_field?.forEach((_field:any)=>{
    //   let find_entity = meta_data.entities.find((_e:any)=>_e.name === _field.name)
    //   if (find_entity) {
    //     let data ={
    //       name: find_entity.name,
    //       nic:_field.nic,
    //       nop:_field.nop,
    //       index:find_entity.index,
    //       display_name:find_entity.display_name,
    //     }
    //     child_details.push(data)
    //   }
    // })
    const sortedMetaFields = sortBy(
      meta_data.entities.map((e: any) => ({ ...e, index: Number(e.index) })),
      'index'
    );
    sortedMetaFields.forEach((s: any) => {
      entity_field?.forEach((_field: any) => {
        if (s.name == _field.name) {
          let data = {
            name: s.name,
            nic: _field.nic,
            nop: _field.nop,
            index: s.index,
            display_name: s.display_name,
          };
          child_details.push(data);
        }
      });
    });

    if (child_details && child_details.length > 0) {
      return child_details;
    }
    return [];
  }
  getEntittyMetaFields = (
    org_name: string,
    process_name: string,
    entityName: string,
    load: 'metadata' | 'layout' = 'layout'
  ) => {
    return computed(() => {
      const metaData =
        this.state().cacheMetaData[`${org_name}___${process_name}`]?.[0]?.[0]
          ?.Results?.[0]?.meta_data;
      const layoutMetaData =
        this.state().cacheMetaData[`${org_name}___${process_name}`]?.[1]
          ?.layout_metadata?.entities;
      const entity = metaData?.entities.find(
        (E: any) => E.display_name == entityName
      );
      if (load === 'metadata') {
        return entity;
      }
      return layoutMetaData?.find((e: any) => e.name == entity?.name);
    });
  };
  getDashboardId = (org_name: string, process_name: string) => {
    return computed(() => {
      const metaData =
        this.state().cacheMetaData[`${org_name}___${process_name}`]?.[0]?.[0]
          ?.Results?.[0]?.meta_data;
      return metaData?.dashboard || '';
    });
  };

  getEntityRecords = (
    org_name: string,
    process_name: string,
    entity_name: string,
    groupedBy: string
  ) => {
    return computed(() => {
      const data =
        this.state().cacheEntityData[
          `${org_name}___${process_name}___${entity_name}`
        ];
      // need to show values only from fetch entity data rest values can be ignored

      return {
        fetchedValue: data?.records?.length ?? 0,
        rowData:
          data?.records.map((e: any) => ({ ...e, entity_name: entity_name })) ||
          [],
        totalRecords: data?.totalCount ?? 0,
      };
    });
  };
  get_org_details_by_name(org_name: any) {
    let org_list = this.getOrgList();
    let find_org = org_list?.find((_org: any) => _org?.org_name === org_name);
    return find_org || {};
  }
  save_org_api_edit_data(data: any) {
    this.state.update((state) => ({
      ...state,
      organizationState: {
        ...state.organizationState,
        api_value: data,
      },
    }));
  }
  save_org_message_edit_data(data: any) {
    this.state.update((state) => ({
      ...state,
      organizationState: {
        ...state.organizationState,
        message_value: data,
      },
    }));
  }
  save_org_secret_edit_data(data: any) {
    this.state.update((state) => ({
      ...state,
      organizationState: {
        ...state.organizationState,
        secret_value: data,
      },
    }));
  }
  save_org_report_edit_data(data: any) {
    this.state.update((state) => ({
      ...state,
      organizationState: {
        ...state.organizationState,
        report_value: data,
      },
    }));
  }

  stateService = inject(StateService);
  update_meta_layout() {
    const currentOrganization = this.getCurrentOrganization();
    const currentProcess = this.getCurrentProcess();
    const payload = {
      org_id: currentOrganization.org_id,
      process_id: currentProcess.process_id,
    };
    combineLatest([
      this.stateService.get_process_metadataNew(
        payload,
        currentProcess.process_name,
        currentProcess.org_process_id
      ),
      this.stateService.getLayoutMetadata(payload),
    ]).subscribe({
      next: (res: any) => {
        if (res) {
          this.updateMetaDataAndLayout(
            res,
            currentOrganization.router_org_value,
            currentProcess.router_process_value
          );
          this.updateMetaDataAndLayout(
            res,
            currentOrganization.router_org_value,
            currentProcess.router_process_value
          );
          this.stateUpdate.next(true);
        }
      },
    });
  }
  private overlayService = inject(OverlaySidebarService);
  clearAppState() {
    this.state.set(this.initialState);
    apiCache.clear();
    this.overlayService.overlayRefs.forEach((e) => {
      if (e) {
        e?.dispose();
      }
    });
  }
  resetAppState() {
    this.state.set(this.initialState);
    apiCache.clear();
    layoutCache.clear();
    ROW_ENTITY_DATA.clear();
    DATA_LOG_FILTER.clear();
    modalInstance.set(null);
    this.overlayService.overlayRefs.forEach((e) => {
      if (e) {
        e?.dispose();
      }
    });
  }
  updateLayoutMetaData(
    org_name: string,
    process_name: string,
    entityName: string,
    load: 'metadata' | 'layout' = 'layout',
    updatedValue: any
  ) {
    this.state.update((state) => {
      const cacheKey = `${org_name}___${process_name}`;
      const metaData =
        state.cacheMetaData[cacheKey]?.[0]?.[0]?.Results?.[0]?.meta_data;
      const layoutMetaData =
        state.cacheMetaData[cacheKey]?.[1]?.layout_metadata?.entities;
      const entity = metaData?.entities.find(
        (E: any) => E.display_name == entityName
      );

      if (load === 'metadata') {
        if (entity) {
          entity.meta_data = updatedValue;
        }
      } else {
        const layoutEntity = layoutMetaData?.find(
          (e: any) => e.name == entity?.name
        );
        if (layoutEntity) {
          layoutEntity.layout_metadata = updatedValue;
        }
      }

      return {
        ...state,
        cacheMetaData: {
          ...state.cacheMetaData,
          [cacheKey]: [
            [{ Results: [{ meta_data: metaData }] }],
            { layout_metadata: { entities: layoutMetaData } },
          ],
        },
      };
    });
  }
  stateUpdate = new BehaviorSubject<any>(null);
  call_initial_fetch = new BehaviorSubject<any>(null);
  state_url_save = new BehaviorSubject<any>(null);

  get_update_fetch() {
    const currentOrganization = this.getCurrentOrganization();
    const currentProcess = this.getCurrentProcess();
    const payload = {
      org_id: currentOrganization.org_id,
      process_id: currentProcess.process_id,
    };
    combineLatest([
      this.stateService.get_process_metadataNew(
        payload,
        currentProcess.process_name,
        currentProcess.org_process_id
      ),
      this.stateService.getLayoutMetadata(payload),
    ]).subscribe({
      next: (res: any) => {
        if (res) {
          this.updateMetaDataAndLayout(
            res,
            currentOrganization.router_org_value,
            currentProcess.router_process_value
          );
          this.updateMetaDataAndLayout(
            res,
            currentOrganization.router_org_value,
            currentProcess.router_process_value
          );
          this.call_initial_fetch.next('call_initial_api');
        }
      },
    });
  }
}
