import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@processo/services/user.service';
import { NgZorroAntdModule } from '@processo/shared/ng-zorro-antd.module';
import { SharedModule } from '@processo/shared/shared.module';
import { extractEntityName, replaceSpaceWithUnderScore, setDefaultProcessToState } from '@processo/shared/state/appstate.logic';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import { combineLatest, combineLatestAll, Subject } from 'rxjs';
interface payload {
  org_id: string,
  process_id: string,
}
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [NgZorroAntdModule, CommonModule, SharedModule],
})
export class LoadingComponent implements OnInit {
  router = inject(Router);
  route=inject(ActivatedRoute)
  stateService = inject(StateService);
  appStateService = inject(AppstateService);
  userService = inject(UserService);
  isRedirect: string = '';
  constructor() {
    this.route.queryParams.subscribe(params => {
      this.isRedirect = params['redirect'] || '';
    });
  }
  ngOnInit(): void {
    this.initial_load();
    // this.router.navigateByUrl('dashboard')
  }
  initial_load() {
    combineLatest([this.userService.get_invite_user_pending(), this.userService?.getUserOrgProcess()]).subscribe({
      next: async (response) => {
        const inviteUserResponse = response[0];
        const orgProcessResponse = response[1];
        let organizations = orgProcessResponse[0]?.org;
        try {
          if (!organizations || organizations.length === 0) {
            await this.setinvitationListToStore(inviteUserResponse);
            console.error('No organization found for the user');
            this.router.navigateByUrl(`/org/no-org`);
          } else {
            try {
              organizations = organizations.map((_o: any) => ({
                ..._o,
                public___org_processes: _o?.public___org_processes?.map((x: any) => ({
                  ...x,
                  router_process_value: x.d_process_name
                })),
                router_org_value: _o?.org_name
              }));
              await this.setOrganizationListToStore(organizations);
              await this.setinvitationListToStore(inviteUserResponse);
              await setDefaultProcessToState({ appStateService: this.appStateService, stateService: this.stateService });

              if (this.isRedirect) {
                const decodedRedirect = decodeURIComponent(this.isRedirect);
                const orgName = await extractEntityName(decodedRedirect, 'org');
                const process_name = await extractEntityName(decodedRedirect, 'process');
                const org = organizations.find((e: any) => e.router_org_value === orgName);
                if (org) {
                  this.appStateService.updateDefaultOrganization(org);
                  const process = org?.public___org_processes.find((p: any) => p.router_process_value == process_name)
                  this.appStateService.updateDefaultProcess(process);
                  if (process) {
            
                    const viewValue = decodedRedirect.includes('/action/') ? decodedRedirect.split('/').pop() : decodedRedirect?.split('/')[6] || '';
                    this.router.navigateByUrl(`/org/${orgName}/process/${process_name}/view/${viewValue}`);
                  } else {
           
                    this.router.navigateByUrl(this.isRedirect);
                  }
                } else {
                  this.router.navigateByUrl(this.isRedirect);
                }
              } else {
                const currentOrg = this.appStateService.getCurrentOrganization();
                const currentProcess = this.appStateService.getCurrentProcess();
                if (currentOrg && currentProcess) {
                  const orgName = currentOrg.router_org_value;
                  const processName = currentProcess.router_process_value;
                  this.router.navigateByUrl(`/org/${orgName}/process/${processName}/view`);
                } else {
                
                  console.error('Current organization or process is not set');
                }
              }
            } catch (error) {
              console.error('Error during organization and process handling:', error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  private async setOrganizationListToStore(organizations: any) {
    this.appStateService.update_user_org_list(organizations);
  }
  private async setinvitationListToStore(data: any) {
    let value = data[0]?.invites;
    this.appStateService.update_user_org_invite(value);
  }
}
