import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
interface TabData {
  index: number;
  label: string;
  name: string;
}
@Component({
  selector: 'lib-add-new-tab',
  templateUrl: './add-new-tab.component.html',
  styleUrls: ['./add-new-tab.component.scss'],
})
export class AddNewTabComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  popupHeaderName!: string;
  defaultTabData: any;
  constructor(private modal: NzModalRef, private fb: FormBuilder) {}
  addStatuessForm!: FormGroup;
  ngOnInit(): void {
    this.defaultTabData = this.nzModalData.tabValue;
    this.popupHeaderName = this.nzModalData?.data?.popupName;
    this.createForm();
  }

  createForm() {
    this.addStatuessForm = this.fb.group({
      statuesDetails: this.fb.array([]),
    });
    if (this.defaultTabData && this.defaultTabData.length > 0) {
      this.defaultTabData.forEach((tabData: TabData | undefined) => {
        this.statuesDetails().push(this.newStatusAddDetails(tabData));
      });
      this.statuesDetails().push(this.newStatusAddDetails());
    } else {
      this.addMore();
    }
  }

  newStatusAddDetails(tabData: TabData = {} as TabData): FormGroup {
    const fieldname = tabData.name || '';
    const labelname = tabData.label || '';
    return this.fb.group({
      label: [
        { value: labelname, disabled: !!labelname },
        [Validators.required],
      ],
      name: [
        { value: fieldname, disabled: !!fieldname },
        [Validators.required],
      ],
    });
  }

  statuesDetails(): FormArray {
    return this.addStatuessForm.get('statuesDetails') as FormArray;
  }

  close() {
    this.modal.destroy();
  }

  confirm() {
    if (this.addStatuessForm.valid && !this.duplicateNameError) {
      let result = this.statuesDetails().controls.map((e: any, i: any) => {
        return { ...e.value, index: i };
      });
      this.modal.destroy(result);
    } else {
      this.statuesDetails().markAllAsTouched();
      this.statuesDetails().markAsDirty();
    }
  }

  addMore() {
    this.statuesDetails().push(this.newStatusAddDetails());
  }
  removeColor(index: any) {
    const statusDetailsArray = this.addStatuessForm.get(
      'statuesDetails'
    ) as FormArray;
    const selectedStatus = statusDetailsArray.at(index);
    selectedStatus.get('color')?.setValue('');
  }
  draggedColumnIndex: any;
  dragStartColumn(i: any, e: any) {
    this.draggedColumnIndex = i;
  }
  allowDrop(e: any) {
    e.preventDefault();
  }
  dropColumn(i: any, e: any) {
    this.arrayMove(this.statuesDetails().controls, this.draggedColumnIndex, i);
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    this.draggedColumnIndex = null;
  }
  duplicateNameError: boolean = false;
  duplicateNameIndex!: number | null;
  inputFieldName(index: number, event: any) {
    let inputText = event.target.value;
    let result = this.statuesDetails().controls.map((e: any, i: any) => {
      return { ...e.value, index: i };
    });

    let isDuplicate = result?.filter((e: any) => e.name === inputText);
    if (isDuplicate.length > 1) {
      this.duplicateNameError = true;
      this.duplicateNameIndex = index;
    } else {
      this.duplicateNameError = false;
      this.duplicateNameIndex = null;
    }
  }
}
