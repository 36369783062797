<div class="tableHeader"  *ngIf="showHeader">
<div class="headerContainer">
    <div style="width: 30px !important; text-align: center"
    (click)="openAction()"
    ><img src="../../assets/images/add.png" class="w-15 cPointer "></div>
<ng-container *ngFor="let header of headerData;let i = index">
    <div class="headerText px-20 headerCell"
          [ngClass]="header.name"
            [style.width]="parent.dynamicWidth[header?.datatype]"
           
            (dragstart)="parent.dragStartColumn(i)" (dragover)="parent.allowDrop($event)" (drop)="dropColumn(i)"
            >
            <div draggable="true" style="
                          display: flex;
                          /* justify-content: center; */
                          cursor: pointer;
                          width: 100%;
                          align-items: center;
                        ">
            <img src="../../../assets/images/drag.svg" class="w-15 imageHover">
            <div class="textStyle" style="margin-left: auto;">{{header?.label}}</div>
            <div class="" style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: auto;
                          " *ngIf="
                            getSortOrder(header.label) &&
                            parent.getSortingOrderlength() > 1
                          " nz-dropdown nzTrigger="click" [nzDropdownMenu]="sortMenu" class="sort-bubble">
            <div class="" style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                              
                            ">
              <img src="../../assets/images/sort_asc.svg" *ngIf="parent.sortingOrder[header.label] === 'asc'" />
              <img src="../../assets/images/sort_desc.svg" *ngIf="parent.sortingOrder[header.label] === 'desc'" />
            </div>
            <div class="" *ngIf="parent.getSortingOrderNumber(header.label) != ''" style="padding-bottom: 3px;">
              <span style="padding-left: 4px; padding-right: 0px;font-size: 9px;">{{
                parent.getSortingOrderNumber(header.label)
                }}</span>
            </div>
            <nz-dropdown-menu #sortMenu="nzDropdownMenu">
              <div class="sorting-popup" style="overflow: scroll; max-height: 400px">
                <nz-row class="f-10 fw-400" style="opacity: 0.8">
                  SORTING ORDER
                </nz-row>
                <nz-row *ngFor="
                                  let item of parent.getSortingOrderArray();
                                  let i = index
                                " class="mt-10">
                  <div class="" style="
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                  ">
                    <div class="" style="display: flex; align-items: center">

                      <img src="../../assets/images/sort_asc.svg" *ngIf="parent.sortingOrder[item] === 'asc'"
                        (click)="addToSortingOrder(item)" />
                      <img src="../../assets/images/sort_desc.svg" *ngIf="parent.sortingOrder[item] === 'desc'"
                        (click)="addToSortingOrder(item)" />
                      <span style="
                                        padding-left: 8px;
                                        word-break: break-all;
                                      ">
                        {{ item }}
                      </span>
                    </div>
                    <div class="" style="display: flex; align-items: center">
                      <img src="../../assets/images/Trash.svg" (click)="parent.removeFromSort(item)" style="padding-left: 3px"
                        alt="" />
                    </div>
                  </div>
                </nz-row>
                <nz-divider> </nz-divider>
                <nz-row class="mt-10">
                  <span (click)="parent.removeFromSort(header.label)">Clear sort</span>
                </nz-row>
              </div>
            </nz-dropdown-menu>
          </div>
          <div class="" style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: auto;
                          " *ngIf="
                            getSortOrder(header.label) &&
                            parent.getSortingOrderlength() == 1
                          " (click)="addToSortingOrder(header.label)" class="sort-bubble">
            <div class="" style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                            ">
              <img src="../../assets/images/sort_asc.svg"  *ngIf="parent.sortingOrder[header.label] === 'asc'" />
              <img src="../../assets/images/sort_desc.svg" *ngIf="parent.sortingOrder[header.label] === 'desc'" />
            </div>
          </div>
            
            <img src="../../../assets/images/Sort.svg" class="w-15 imageHover" style="margin-right: auto;"
            *ngIf="!getSortOrder(header.label)"
            (click)="addToSortingOrder(header.label)"
            >
            <img src="../../../assets/images/icons/closeIcon.svg" class="w-15 imageHover"
            *ngIf="header.show_grid !== 'always' && header.field_type !== 'SYSTEM'"
            (click)="removeHeader(header)"
            >
            </div>
        <!-- <nz-resize-handle nzDirection="right">
          <div class="resize-trigger"></div>
        </nz-resize-handle> -->
  </div>
</ng-container>
<div class="headerText p-8 w-95">
    <div class="textStyle">Action</div>
    </div> 
</div>
</div>