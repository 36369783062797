import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { Observable, debounceTime, map, shareReplay, tap } from 'rxjs';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, DoCheck {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  tableService = inject(ProcessoCustomTableService);
  @Output() stopedit: any = new EventEmitter<any>();
  @Output() startDropDownEditEmitter: any = new EventEmitter<any>();
  @Input() data: any;
  @Input() header: any;
  @Input() hideSelectOption: any;
  @Input() parent: any;
  @Input() innerTable: any;
  @ViewChild('static', { static: true }) static: any;
  @ViewChild('entity', { static: true }) entity: any;
  options: any = [];
  enableSelect(comp: any) {
    this.hideSelectOption = false;
    comp.host.nativeElement.click();
  }
  public disableSelect() {
    this.hideSelectOption = true;
  }
  public filteredOptions: any = [];
  ngOnInit(): void {}
  ngDoCheck(): void {
    // if (this.header?.callUniqueApi && this.editClicked) {
    //   this.checkIsunique();
    // }
    // this.cdr.markForCheck();
  }
  constructor(private cdr: ChangeDetectorRef) {}
  getEntityptionList$: any = new Observable();
  latestEntityOptionList = [];
  openDropDownEntityChange(f: any, search = '', event?: any) {
    if (event) {
      let body: any = {
        org_id: this.data.org_id,
        process_id: this.data.process_id,
        entity_name: f.entity_name,
        process_name: this.data.process_name.replace(/ /g, '_'),
        field_name: f.field_name,
        limit: 50,
        skip: 0,
        field_str: search,
      };
      let fields = this.parent.erulistData?.entities?.find(
        (e: any) => e?.name === this.parent?.tabName
      )?.fields;
      if (f.def) {
        let filterValue = this.data[f.def];
        let val: any = [];
        if (Array.isArray(filterValue)) {
          val = [...filterValue];
        } else {
          val = [filterValue || ''];
        }
        if (
          fields.find((e: any) => e.name == f.def)?.datatype ==
          'dropdown_single_select'
        ) {
          body['filter'] = {
            [f.dpef]: val[0],
          };
        } else {
          body['filter'] = {
            [`$in`]: val,
          };
        }
      }
      this.getEntityptionList$ = this.parent.pservice
        .getentityfielddata(body)
        .pipe(
          shareReplay(),
          map((r: any) => r[0].entity_data),
          tap((data: any) => {
            this.latestEntityOptionList = data;
            this.cdr.markForCheck();
          })
        );
    }
  }
  p_created_by: any = [];
  emit_entity_data_single(event: any) {    
    let created_by_id: any = [];
    let selected_value: any = this.latestEntityOptionList.find(
      (_data: any) => _data.entity_data === event
    );
    if (selected_value) {
      created_by_id.push(selected_value?.p_created_by[0]);
    }
    this.p_created_by = created_by_id;
    if(this.parent.selected_entity_created_by_id[`${this.data.entity_id}`]){
    this.parent.selected_entity_created_by_id[`${this.data.entity_id}`][`${this.header.name}`] = created_by_id
    }
  }
  stopEdit() {    
    this.handleDropdownClose(false);
    let tableEdit: any;
    this.isEdit = false;
    if (this.header?.option_type == 'ENTITY_DATA') {
      this.openDropDownEntityChange(this.header, '', true);
      this.data[`_${this.header.name}`] = this.latestEntityOptionList
        .filter((item: any) => {
          return item.entity_data == this.data[this.header.name];
        })
        ?.map((v: any) => v.entity_id)[0];
      if (this.innerTable) {
        this.parent.updateDataOnInnerStopEdit(
          {
            target: {
              value: this.data[this.header.name],
            },
          },
          this.header.name,
          this.data,
          { [`_${this.header.name}`]: this.data[`_${this.header.name}`] },this.p_created_by
        );
      } else {
        this.parent.updateDataOnStopEdit(
          {
            target: {
              value: this.data[this.header.name],
            },
          },
          this.header.name,
          this.data,
          this.parent.erulistData,
          { [`_${this.header.name}`]: this.data[`_${this.header.name}`] },this.p_created_by
        );
      }
    } else {
      if (this.innerTable) {
        tableEdit = 'innerTable';

        this.parent.updateDataOnInnerStopEdit(
          {
            target: {
              value: this.data[this.header.name],
            },
          },
          this.header.name,
          this.data
        );
      } else {
        this.parent.updateDataOnStopEdit(
          {
            target: {
              value: this.data[this.header.name],
            },
          },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
    }
    let data: any = {};
    data[`${this.header.name}`] = this.data[this.header.name];
    data[`_${this.header.name}`] = this.data[`_${this.header.name}`];
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
  isEdit = false;

  fields: any = [];
  getAPIFieldList$: any = new Observable();
  openDropDownAPIChange(f: any, search = '', event?: any): void {
    if (event) {
      let body: any = {
        org_id: this.data.org_id,
        api_name: f.api_name,
        process_id: this.data.process_id,
        entity_id: [this.data.entity_id || ''],
        field_str: search,
      };
      if (f.def) {
        let fields = this.parent.erulistData?.entities?.find(
          (e: any) => e?.name === this.parent?.tabName
        )?.fields;

        let filterValue = this.data[f.def];
        let val: any = [];
        if (Array.isArray(filterValue)) {
          val = [...filterValue];
        } else {
          val = [filterValue || ''];
        }

        body['filter'] = {
          [`$in`]: val,
        };
      }
      this.parent.pservice
        .getApiFields(body)
        .pipe(debounceTime(300))
        .subscribe((res: any) => {
          this.fields = res?.org.map((item: any) => item[f.api_field]);
          this.cdr.markForCheck();
        });
    }
  }

  editClicked = false;
  onEditClick() {
    if (!this.header.can_edit || this.data?.is_locked || this.header.tab_name==='XX' || this.data?.lock_fields?.includes(this.header?.name)) {
      return;
    }
    this.editClicked = true;
    this.startDropDownEditEmitter.emit();
    if (this.header?.option_type === 'API') {
      this.openDropDownAPIChange(this.header, '', true);
    }
    if (this.header?.option_type === 'ENTITY_DATA') {
      this.openDropDownEntityChange(this.header, '', true);
    }
    if (this.header?.option_type === 'STATIC') {
      this.filteredOptions = this.header.options;
    }
  }
  handleDropdownClose(event: any) {
    if (!event) {
      this.editClicked = false;
    }
  }
  filter(searchValue: string): void {
    if (!searchValue) {
      this.filteredOptions = this.header.options;
    } else {
      this.filteredOptions = this.header.options.filter((option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    this.cdr.markForCheck();
  }
  error = '';
 check_is_unique(e: any) {
  this.data[this.header?.name] = e
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.stopEdit()
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.stopEdit()
       }
     }, 100);
   }

 }
}
