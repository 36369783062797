import { Component, inject } from '@angular/core';
import { StateService } from '@processo/shared/state/state.service';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-delete-column-popup',
  templateUrl: './delete-column-popup.component.html',
  styleUrls: ['./delete-column-popup.component.scss'],
})
export class DeleteColumnPopupComponent {
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  selectedColumn!: string;
  type: string = 'field';
  text: string = '';
  tabs: any = [];
  childEntities = new Map();
  selectedChildEntities = [];
  selectedNewTab: any = '';
  constructor(private modalRef: NzModalRef,private stateService:StateService) {
    this.selectedColumn = this.nzModalData.data.selectedField;
    this.type = this.nzModalData.data.deleteType;
    this.text = this.nzModalData.data?.text;
    this.tabs = this.nzModalData.data?.tabs;
    this.nzModalData.data.childEntites?.forEach((c: any) => {
      this.childEntities.set(c.name, { en: c.name
        , nic: c.nic });
    });
  }
  handleCancel(): void {
    this.modalRef.destroy({ action: 'cancel' });
  }
  showError:boolean = false
  handleOk() {
    if (this.type === 'tab') {
      if(this.tabs?.length > 0 && this.selectedNewTab === ''){
        this.showError = true
        return
      }
      this.modalRef.destroy({
        action: 'confirm',
        selectedTab: this.selectedNewTab,
      });
    } else if (this.type === 'entity') {
      this.modalRef.destroy({
        action: 'confirm',
        childToDelete: this.selectedChildEntities.map((e) =>
          this.childEntities.get(e)
        ),
      });
    } else {
      this.modalRef.destroy({ action: 'confirm' });
    }
  }
  get_label(name:any){
    let entity_label = this.stateService?.appState()?.metaData?.entities?.find((_entities:any)=> _entities?.name === name)?.display_name;
    return entity_label
  }
}
