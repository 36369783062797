import {
  Component,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  ViewContainerRef,
  OnInit,
  NgZone,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { AttachmentComponent } from '../attachment/attachment.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CurrencyComponent } from '../currency/currency.component';
import { DateComponent } from '../date/date.component';
import { DropdownMultipleComponent } from '../dropdown-multiple/dropdown-multiple.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { EmailComponent } from '../email/email.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import { LocationComponent } from '../location/location.component';
import { NumberComponent } from '../number/number.component';
import { PeopleComponent } from '../people/people.component';
import { PhoneComponent } from '../phone/phone.component';
import { ProgressComponent } from '../progress/progress.component';
import { RatingsComponent } from '../ratings/ratings.component';
import { StatusComponent } from '../status/status.component';
import { TagComponent } from '../tag/tag.component';
import { TextAreaWrappedComponent } from '../text-area-wrapped/text-area-wrapped.component';
import { WebsiteComponent } from '../website/website.component';
import { TimeComponent } from '../time/time.component';
import { PriorityComponent } from '../priority/priority.component';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-custom-cell',
  templateUrl: './custom-cell.component.html',
  styleUrls: ['./custom-cell.component.scss'],
})
export class CustomCellComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() header: any;
  @Input() innerTable?: any;
  @Input() data: any;
  @Input() parent: any;
  componentMap: any = {
    textbox: InputFieldComponent,
    email: EmailComponent,
    textarea: TextAreaWrappedComponent,
    number: NumberComponent,
    currency: CurrencyComponent,
    checkbox: CheckboxComponent,
    rating: RatingsComponent,
    progress: ProgressComponent,
    dropdown_single_select: DropdownComponent,
    dropdown_multi_select: DropdownMultipleComponent,
    status: StatusComponent,
    phone: PhoneComponent,
    attachment: AttachmentComponent,
    website: WebsiteComponent,
    date: DateComponent,
    location: LocationComponent,
    priority: PriorityComponent,
    tag: TagComponent,
    people: PeopleComponent,
    time: TimeComponent,
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}
  @ViewChild('appDynamicComponentLoader', {
    static: true,
    read: ViewContainerRef,
  })
  appDynamicComponentLoader!: ViewContainerRef;
  dataLoaded = false;
  ngAfterViewInit() {
    this.loadComponent();
  }

  loadComponent() {
    this.ngZone.runOutsideAngular(() => {
      if (this.componentMap[this.header.datatype]) {
        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            this.componentMap[this.header.datatype]
          );
        this.appDynamicComponentLoader.clear();

        const componentRef: any =
          this.appDynamicComponentLoader.createComponent(componentFactory);
        componentRef.instance.header = this.header;
        componentRef.instance.data = this.data;
        componentRef.instance.parent = this.parent;
        componentRef.instance.innerTable = this.innerTable;
        this.cdr.detectChanges();
      }
    });
  }
}
