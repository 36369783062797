import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import { environment } from 'projects/processo/src/environments/environment';
@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss'],
})
export class MobileSidebarComponent implements OnInit {
  visible = false;
  stateService = inject(StateService);
  router = inject(Router);
  tokenService = inject(TokenService);
  appStateService = inject(AppstateService);
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  environment=environment
  ngOnInit(): void {
    this.getRouteUrl();
    const userObj = this.stateService.isUserLoggedIn().userDetail.attributes;
    this.firstName = userObj?.first_name.charAt(0);
    this.lastName = userObj?.last_name.charAt(0);
    this.fullName = `${userObj?.first_name} ${userObj?.last_name}`;
  }
  menuTextChange(org: any) {
    this.stateService.updateAppstate({
      event: 'UPDATE_ORG',
      value: org,
    });
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  checkRoutes() {
    const islocationInDashboard = this.router?.url?.indexOf('dashboard') > -1;
    return islocationInDashboard ? true : false;
  }
  getProcess() {
    return this.stateService
      .appState()
      .selectedOrganization?.public___org_processes?.sort(
        (a: any, b: any) => a.rowIndex - b.rowIndex,
      );
  }
 async selectedProcess(process: any) {
    if(await this.stateService.canNavigate()){
    this.stateService.updateAppstate({
      event: 'UPDATE_PROCESS',
      value: process,
    });
  }
  }
  currentProcessSelected(item: any) {
    return (
      this.stateService.appState().selectedProcess.process_id ===
      item.process_id
    );
  }
  async home() {
    if(await this.stateService.canNavigate()){
    const islocationInDashboard = this.router.url.indexOf('dashboard') > -1;
    if (!islocationInDashboard) {
      this.router.navigate(['dashboard']);
    }
  }
  }
  async gotoOrganization() {
    if(await this.stateService.canNavigate()){
    this.router.navigateByUrl('organization/organization-details');
    }
  }
 async gotoProfileSettings() {
  if(await this.stateService.canNavigate()){
    this.router.navigateByUrl('profile');
  }
  }

  signOut() {
    let body ={
      refresh_token:this.tokenService?.getRefreshToken()
    }
    this.stateService.logout(body).subscribe((res)=>{
      if(res){
       
      }
    })
    this.stateService.clearState();
    this.appStateService.clearAppState() 
  }
  profileSection!: boolean;
  organizationSection!: boolean;
  getRouteUrl() {
    this.router.events.subscribe((res: any) => {
      const currentURL = window.location.href;

      if (currentURL.includes('profile')) {
        this.profileSection = false;
      } else if (currentURL.includes('organization')) {
        this.profileSection = false;
        this.organizationSection = true;
      } else {
        this.profileSection = true;
        this.organizationSection = false;
      }
    });
  }
 async setting() {
    if(await this.stateService.canNavigate()){
    this.router.navigate(['organization/details/basic-details']);
    }
  }
}
