<thead>
  <tr class="table-header">
    <th style="width: 30px !important; text-align: center" (click)="openAction()">
      <div>
        <img src="../../assets/images/add.png" width="15px" class="cPointer" />
      </div>
    </th>
    <ng-container>
      <th nz-resizable nzBounds="window" nzPreview [nzMinWidth]="60" (nzResizeEnd)="onResize($event, header.datatype)"
        nz-tooltip nzTooltipPlacement="bottom" [nzTooltipTitle]="header.tool_tip" (click)="dynamic(header.datatype)"
        class="hoverdata" *ngFor="let header of componentState?.columnHeaders; index as i;"
        (dragstart)="dragStartColumn(i)" (dragover)="allowDrop($event)" (drop)="dropColumn(i)"
        [nzTooltipOverlayClassName]="'toolTipContainers'" [nzWidth]="dynamicWidth[header.datatype]">
        <div draggable="true" style="
                          display: flex;
                          justify-content: center;
                          cursor: pointer;
                          align-items: center;
                        ">
          <img src="../../../assets/images/drag.svg" class="showHover" />
          <span style="font-size: 12px;    white-space: break-spaces;">{{ header.label }}</span>
          <div class="" style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          " *ngIf="
                            getSortOrder(header.label) &&
                            getSortingOrderlength() > 1
                          " nz-dropdown nzTrigger="click" [nzDropdownMenu]="sortMenu" class="sort-bubble">
            <div class="" style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                            ">
              <img src="../../assets/images/sort_asc.svg" *ngIf="sortingOrder[header.label] === 'asc'" />
              <img src="../../assets/images/sort_desc.svg" *ngIf="sortingOrder[header.label] === 'desc'" />
            </div>
            <div class="" *ngIf="getSortingOrderNumber(header.label) != ''">
              <span style="padding-left: 4px; padding-right: 0px">{{
                getSortingOrderNumber(header.label)
                }}</span>
            </div>
            <nz-dropdown-menu #sortMenu="nzDropdownMenu">
              <div class="sorting-popup" style="overflow: scroll; max-height: 400px">
                <nz-row class="f-10 fw-400" style="opacity: 0.8">
                  SORTING ORDER
                </nz-row>
                <nz-row *ngFor="
                                  let item of getSortingOrderArray();
                                  let i = index
                                " class="mt-10">
                  <div class="" style="
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                  ">
                    <div class="" style="display: flex; align-items: center">

                      <img src="../../assets/images/sort_asc.svg" *ngIf="sortingOrder[item] === 'asc'"
                        (click)="addToSortingOrder(item)" />
                      <img src="../../assets/images/sort_desc.svg" *ngIf="sortingOrder[item] === 'desc'"
                        (click)="addToSortingOrder(item)" />
                      <span style="
                                        padding-left: 8px;
                                        word-break: break-all;
                                      ">
                        {{ item }}
                      </span>
                    </div>
                    <div class="" style="display: flex; align-items: center">
                      <img src="../../assets/images/Trash.svg" (click)="removeFromSort(item)" style="padding-left: 3px"
                        alt="" />
                    </div>
                  </div>
                </nz-row>
                <nz-divider> </nz-divider>
                <nz-row class="mt-10">
                  <span (click)="removeFromSort(header.label)">Clear sort</span>
                </nz-row>
              </div>
            </nz-dropdown-menu>
          </div>
          <div class="" style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          " *ngIf="
                            getSortOrder(header.label) &&
                            getSortingOrderlength() == 1
                          " (click)="addToSortingOrder(header.label)" class="sort-bubble">
            <div class="" style="
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              align-items: center;
                              width: 5px;
                            ">
              <img src="../../assets/images/sort_asc.svg" *ngIf="sortingOrder[header.label] === 'asc'" />
              <img src="../../assets/images/sort_desc.svg" *ngIf="sortingOrder[header.label] === 'desc'" />
            </div>
          </div>
          <img src="../../../assets/images/Sort.svg" class="showHover" *ngIf="!getSortOrder(header.label)"
            (click)="addToSortingOrder(header.label)" />
          <img *ngIf="
                            header.show_grid !== 'always' &&
                            header.field_type !== 'SYSTEM'
                          " src="../../../assets/images/icons/closeIcon.svg" class="showHover"
            (click)="removeTabelHeader(header, i)" style="margin-top: -2px" />
        </div>
        <nz-resize-handle nzDirection="right">
          <div class="resize-trigger"></div>
        </nz-resize-handle>
      </th>
    </ng-container>
    <th style="width: 100px !important; text-align: center">
      <div style="font-size: 12px">Action</div>
    </th>
  </tr>
</thead>