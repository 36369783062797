import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-custom-table-body',
  templateUrl: './custom-table-body.component.html',
  styleUrls: ['./custom-table-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableBodyComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() dynamicWidth: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() headerData: any;
  bodyText: any = [
    {
      name: 'rating',
      dataType: 'rating',
    },
    {
      name: 'dropdown',
      dataType: 'dropdown',
    },
    {
      name: 'textbox',
      dataType: 'textbox',
    },
    {
      name: 'location',
      dataType: 'location',
    },
    {
      name: 'currency',
      dataType: 'currency',
    },
    {
      name: 'number',
      dataType: 'number',
    },
    {
      name: 'website',
      dataType: 'website',
    },
    {
      name: 'labels',
      dataType: 'labels',
    },
    {
      name: 'textarea',
      dataType: 'textarea',
    },
    {
      name: 'people',
      dataType: 'people',
    },
    {
      name: 'phone',
      dataType: 'phone',
    },
    {
      name: 'email',
      dataType: 'email',
    },
    {
      name: 'progress',
      dataType: 'progress',
    },
    {
      name: 'attachment',
      dataType: 'attachment',
    },
    {
      name: 'text_area_unwrapped',
      dataType: 'text_area_unwrapped',
    },
    {
      name: 'checkbox',
      dataType: 'checkbox',
    },
    {
      name: 'dropdown_multi_select',
      dataType: 'dropdown_multi_select',
    },
    {
      name: 'dropdown_single_select',
      dataType: 'dropdown_single_select',
    },
    {
      name: 'date',
      dataType: 'date',
    },
    {
      name: 'image',
      dataType: 'image',
    },
    {
      name: 'status',
      dataType: 'status',
    },
    {
      name: 'tag',
      dataType: 'tag',
    },
  ];
  @Output() selectedActions = new EventEmitter<any>();
  @Input() editIds: any;
  @Output() startEdits = new EventEmitter<any>();
  @Output() stopEdits = new EventEmitter<any>();
  @Output() checkChanges = new EventEmitter<any>();
  @Output() ratingChanges = new EventEmitter<any>();
  @Input() hideSelectOption: any;
  @Input() api_fields: any;
  ngOnInit(): void {}
  getWidth(width: any) {
    return width;
  }
  selectedAction(selectedOption: string, data: any) {
    this.selectedActions.emit({ selectedOption, data });
  }
  trackrow(index: number, header: any) {
    return header.name;
  }
  ratingChange(e: any, v: any, n: any) {
    this.ratingChanges.emit({ e, v, n });
  }
  checkChange(e: any, v: any, n: any) {
    this.checkChanges.emit({ e, v, n });
  }
  editId() {
    return this.editIds;
  }
  startEdit(name: any, index: any, data: any) {
    this.startEdits.emit({ name, index, data });
  }
  stopEdit(v?: any, type?: any, entityId?: any) {
    this.stopEdits.emit({ v, type, entityId });
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          return `Exact value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  showEmailError(email: string): boolean {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }
  formatNumber(v: any, field: any) {
    const inputValue = v;
    if (field.seperator && inputValue) {
      // const numericValue = Number(inputValue);
      const numericValue = Number(inputValue?.replace(/,/g, ''));
      if (numericValue) {
        let separator = '';
        if (field.seperator.toLowerCase() === 'millions') {
          separator = 'en-US';
        } else if (field.seperator.toLowerCase() === 'thousands') {
          separator = 'en-IN';
        } else {
          separator = '';
        }
        const formattedValue = this.formatNumberWithSeparator(
          numericValue,
          separator,
          field.decimal
        );
        return formattedValue;
      }
    }
    return inputValue;
  }
  formatNumberWithSeparator(
    value: number,
    format: string,
    decimal: number
  ): string {
    if (format) {
      const formattedValue = value.toLocaleString(format, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formattedValue;
    } else {
      return value.toFixed(decimal || 0);
    }
  }
  startDropDownEditEmitter(comp: any) {}
}
