<div class="fieldListBody" [style.pointerEvents]="allowEdit?'none':'auto'">
    <ng-container *ngFor="let field of formInputValue;let i=index">
      <ng-container *ngIf="field?.name">
        <ng-container [ngSwitch]="field.datatype">
          <nz-form-item *ngSwitchCase="'textbox'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span>
  
            </nz-form-label>
  
            <nz-form-control [nzXs]="{ span: 24, offset: 0 }">
              <nz-input-group>
                <input type="text" class="f-12" nz-input [(ngModel)]="field.default" (blur)="checkIsUnique(field)"
                  (ngModelChange)="textboxValidation(field, field.default)" [disabled]="disableFields"
                  [readOnly]="field.dfc " />
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'textarea'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <textarea rows="2" nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" [(ngModel)]="field.default"
                (ngModelChange)="textboxValidation(field, field.default)" [disabled]="disableFields"
                [readOnly]="field.dfc"></textarea>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'email'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control [nzErrorTip]="field.label + ' is required'">
              <nz-input-group>
                <input class="f-12" type="text" nz-input nzPlaceHolder="-" [readOnly]="field.dfc"
                  (input)="emailValidation(field, field.default)" (blur)="checkIsUnique(field)"
                  [(ngModel)]="field.default" [disabled]="disableFields" />
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="dropdown-class" *ngSwitchCase="'dropdown_single_select'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <ng-container *ngIf="field?.option_type === 'STATIC'">
                <nz-select [nzDropdownClassName]="'dropDownContainer'" class="dropdownui" nzPlaceHolder="-"
                  (ngModelChange)="field.error=''" [(ngModel)]="field.default" [disabled]="disableFields ||field.dfc"
                  nzAllowClear>
                  <ng-container *ngFor="let option of field.options">
                    <nz-option *ngIf="field.default" [nzLabel]="field.default" [nzValue]="field.default"
                      nzHide></nz-option>
                    <nz-option class="f-12" *ngIf="field.options?.length" [nzValue]="option.name"
                      nzLabel="{{ option.name | titlecase }}"></nz-option>
                  </ng-container>
                </nz-select>
              </ng-container>
              
              <ng-container *ngIf="field?.option_type === 'API'">
                <nz-select [(ngModel)]="field.default" nzPlaceHolder="Select option" style="width: 100%"
                  [disabled]="disableFields || field.dfc" (ngModelChange)="field.error=''"
                  (nzOpenChange)="openDropDownApiChange(field, '', $event)" [nzDropdownRender]="renderTemplate"
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'" [nzShowArrow]="true"
                  nzAllowClear>
                  <ng-template #renderTemplate>
                    <input #saearhx2 nz-input placeholder="Search..." #inputs (input)="openDropDownApiChanges(field, inputs.value,true)"/>
                  </ng-template>
                  <nz-option *ngIf="field.default" [nzLabel]="field.default" [nzValue]="field.default" nzHide></nz-option>
                  <nz-layout *ngFor="let option of getApiptionList$ | async">
                    <nz-option class="f-12" [nzLabel]="option" [nzValue]="option">
                    </nz-option>
                  </nz-layout>
                </nz-select>
              </ng-container>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'dropdown_multi_select'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <ng-container *ngIf="field?.option_type === 'STATIC'">
                <nz-select class="dropdownui" [(ngModel)]="field.default" [nzMaxTagCount]="2"
                nzMode="multiple" [disabled]="disableFields || field.dfc"
                (ngModelChange)="field.error=''" [nzMaxTagPlaceholder]="tagPlaceHolder"
                nzPlaceHolder="-" class="cPointer" style="cursor: pointer !important"
                [nzDropdownClassName]="'dropDownContainer'">
                <ng-container *ngFor="let options of field.options">
                  <ng-container *ngFor="let option of field.default">
                    <nz-option   *ngIf="option !==''" [nzLabel]="option" [nzValue]="option"
                    nzHide></nz-option>
                  </ng-container>

                  <nz-option class="f-12" *ngIf="field.options" [nzValue]="options.name"
                    nzLabel="{{ options.name | titlecase }}"></nz-option>
                </ng-container>
              </nz-select>
  
              </ng-container>
              <ng-container *ngIf="field?.option_type === 'ENTITY_DATA'">
                <nz-select [nzMode]="'multiple'" (nzOpenChange)="openDropDownEntityChange(field, '', $event)"
                  [disabled]="disableFields || field.dfc"
                  (ngModelChange)="updateFieldEntity($event,field,i);field.error=''" nzPlaceHolder="Select option"
                  style="width: 100%" [nzDropdownRender]="renderTemplate" [nzDropdownClassName]="'userSelect'"
                  [(ngModel)]="field.default" (ngModelChange)="emitSelectValues(field?.name, $event)"
                  [nzShowArrow]="true">
                  <nz-option class="f-12" *ngFor="let option of field.default" [nzLabel]="option" [nzValue]="option"
                    nzHide></nz-option>
                  <ng-template #renderTemplate>
                    <div style="
                      padding: 10px;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                    ">
                      <nz-input-group nzSize="large" [nzSuffix]="suffixIcon" style="top: -100%; margin-top: -10px">
                        <input class="f-12" #search placeholder="search" nz-input (input)="
                          openDropDownEntityChange(
                            field,
                            search.value,
                            true
                          )
                        " />
                      </nz-input-group>
                      <ng-template #suffixIcon>
                        <span nz-icon nzType="search"></span>
                      </ng-template>
                    </div>
                  </ng-template>
                  <nz-layout *ngFor="let option of getEntityptionList$ | async">
                    <ng-container *ngIf="option">
                      <nz-option class="f-12" [nzLabel]="option.entity_data" [nzValue]="option.entity_data">
                      </nz-option>
                    </ng-container>
                  </nz-layout>
                </nz-select>
              </ng-container>
              <ng-container *ngIf="field?.option_type === 'API'">
                <ng-container *ngIf="field?.option_type === 'API'">
                  <nz-select nzPlaceHolder="Select option" style="width: 100%" [disabled]="disableFields || field.dfc"
                    (ngModelChange)="field.error=''" (nzOpenChange)="openDropDownApiChange(field, '', $event)"
                    [nzDropdownRender]="renderTemplate" [nzDropdownClassName]="'userSelect'" [nzMode]="'multiple'"
                    [nzShowArrow]="true" [(ngModel)]="field.default">
                    <nz-option *ngFor="let option of field.default" [nzLabel]="option" [nzValue]="option"
                      nzHide></nz-option>
                    <ng-template #renderTemplate>
                      <div class="selectSearchInputStyle" *ngIf="(getApiptionList$ | async)" style="
                        padding: 10px;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                      ">
                        <nz-input-group nzSize="large" [nzSuffix]="suffixIcon" style="top: -100%; margin-top: -10px">
                          <input class="f-12" placeholder="search" #inputs (input)="openDropDownApiChanges(field, inputs.value,true)" />
                        </nz-input-group>
                        <ng-template #suffixIcon>
                          <span nz-icon nzType="search"></span>
                        </ng-template>
                      </div>
                    </ng-template>
                    <nz-layout *ngFor="let option of getApiptionList$ | async"
                      class="f-12 dropDownContainer">
                      <nz-option class="f-12" [nzLabel]="option" [nzValue]="option">
                      </nz-option>
                    </nz-layout>
                  </nz-select>
                </ng-container>
              </ng-container>
              <ng-template #tagPlaceHolder let-selectedList style="background-color: none !important">+{{
                selectedList.length }} more</ng-template>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'website'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input [readOnly]="field.dfc" class="f-12" type="text" nz-input [(ngModel)]="field.default"
                  nzPlaceHolder="-" (blur)="checkIsUnique(field)" (input)="websiteValidation(field, field.default)"
                  [disabled]="disableFields" />
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'number'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control [nzErrorTip]="field.label + ' is required'">
              <nz-input-group>
                <input
                [readOnly]="field.dfc && !field.editable"
                class="f-12"
                #inputtt
                type="text"
                [disabled]="disableFields"
                [(ngModel)]="field.default"
                oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
                nz-input
                (change)="
                  updateInputValues(field?.name, field.default, field);
                  numberValidation(field, inputtt.value)
                "
                (ngModelChange)="numberFieldValidation(field, field.default)"
              />
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'rating'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control class="ratingContainer">
              <nz-rate [disabled]="disableFields || field.dfc" (ngModelChange)="field.error=''"
                [nzCharacter]="characterIcon" #rate [(ngModel)]="field.default" [nzCount]="field.end_value">
                <ng-template #characterIcon let-index>
                  <img style="width: 15px;" *ngIf="!getHoverValue(rate, index)"
                    [src]="getEmojiImage(field.emoji_value, 'blue')" />
                  <img style="width: 15px;" *ngIf="getHoverValue(rate, index)"
                    [src]="getEmojiImage(field.emoji_value, 'white')" />
                </ng-template>
              </nz-rate>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'location'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input class="f-12" (ngModelChange)="textboxValidation(field, field.default)" type="text" nz-input
                  [(ngModel)]="field.default" [disabled]="disableFields" [readOnly]="field.dfc" />
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'progress'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <div style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;">
              <div>{{field.startValue}}</div>
              <div>{{ field.endValue }}</div>
            </div>
            <nz-slider [ngClass]="{'pointerNone':disableFields || field.dfc}" [ngModel]=" convertToString(field.default)"
              nzPlaceHolder="-" [nzMin]="field.startValue" (ngModelChange)="field.default = convertToString($event);field.error=''"
              [nzMax]="field.endValue || 100" style="width: 100%" [disabled]="disableFields || field.dfc"></nz-slider>
              <div style="color: red;font-size: 10px">
                {{ field?.error }}
              </div>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'checkbox'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }} <span
                style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <div class="">
              <label [disabled]="disableFields || field.dfc" nz-checkbox style="font-size:11px;"
                (ngModelChange)="field.error=''" [(ngModel)]="field.default"></label>
            </div>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'date'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <div style="width: 100%">
              <div>
                <nz-date-picker [disabled]="disableFields || (field.dfc  && !field.editable)" [nzAllowClear]="false"
                  class="w100" style="height: 37px" [nzFormat]="'dd-MM-yyyy'"
                  (nzOnOpenChange)="datePickerOpen($event, field.allow_days)" [nzDisabledDate]="disabledDate"
                  [ngModel]="(field.default==='Current_Date'?today:field.default||'')" (ngModelChange)="dateUpdate($event,field)
                "></nz-date-picker>
              </div>
              <div>
                <div style="color: red;font-size: 10px">
                  {{ field?.error }}
                </div>
              </div>
            </div>
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'currency'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control [nzXs]="{ span: 24, offset: 0 }">
              <nz-input-group>
                <nz-input-group>
                  <input
                  [readOnly]="field.dfc && !field.editable"
                  #inputtt
                  [disabled]="disableFields"
                  class="f-12"
                  type="text"
                  [(ngModel)]="field.default"
                  oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
                  nz-input
                  (change)="
                    updateInputValues(field?.name, field.default, field);
                    numberValidation(field, inputtt.value)
                  "
                />
                </nz-input-group>
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
  
          <!-- Labels -->
          <nz-form-item *ngSwitchCase="'labels'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <nz-select class="dropdownui" nzPlaceHolder="-" [(ngModel)]="field.default"
                [disabled]="disableFields || field.dfc" [nzDropdownClassName]="'dropDownContainer'" nzAllowClear>
                <nz-option class="f-12" *ngFor="let option of field.options" [nzValue]="option.name"
                  [nzLabel]="option.value"></nz-option>
              </nz-select>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <div *ngSwitchCase="'tag'">
          <nz-form-item >
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field?.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control>
              <nz-select [nzRemoveIcon]="null" class="dropdownui" [(ngModel)]="field.default" [nzMaxTagCount]="2"
                [disabled]="disableFields || field.dfc" nzMode="multiple" [nzMaxTagPlaceholder]="tagPlaceHolder"
                nzPlaceHolder="-" [nzDropdownClassName]="'tagStyle'" class="cPointer" style="cursor: pointer !important"
                [nzDropdownRender]="tagTemp" (ngModelChange)="field.error=''">
                <ng-template #tagTemp>
                  <div style="display: flex;align-items: center;column-gap: 16px;padding: 0 5px;">
                    <input style="border: 1px solid var(--dblue);" [(ngModel)]="addTagOption" nz-input
                      placeholder="add tag..." />
                    <div class="primaryBtnStyle optionBtn">
                      <button nz-button nzType="primary" class="save" style="margin-top: 0!important;"
                        [disabled]="disableTagOptionButton" (click)="saveTagOption(field)">
                        SAVE
                      </button>
                    </div>
                  </div>
                </ng-template>
                <nz-option class="f-12" nzCustomContent *ngFor="let option of field.options" [nzValue]="option.name"
                  [nzLabel]="option.name">
                  <ng-container>
                    <div class="f-12">{{ option.name }}</div>
  
                  </ng-container>
                </nz-option>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                  <nz-row nz-menu style="padding: 10px">
                    <nz-col [nzSpan]="24" nz-menu-item class="text-center"
                      style="border-bottom: 1px solid #d2d2d2; color: #7c818c">
                      Select color for the option
                    </nz-col>
                    <nz-col [nzSpan]="6" class="mt-10 mb-10" style="display: flex; justify-content: center"
                      *ngFor="let item of statuesColor">
                      <div [style.background]="item.color" class="cPointer"
                        style="height: 32px; width: 32px; border-radius: 50%"></div>
                    </nz-col>
                    <nz-col [nzSpan]="24" class="text-center cPointer" style="
                      border-top: 1px solid #d2d2d2;
                      color: #1d66ff;
                      font-size: 12px;
                      margin-top: 10px;
                    ">
                      Remove color
                    </nz-col>
                  </nz-row>
                </nz-dropdown-menu>
              </nz-select>
  
              <ng-template #tagPlaceHolder let-selectedList style="background-color: none !important">
                +{{ selectedList.length }} more</ng-template>
            </nz-form-control>
  
          </nz-form-item>
          <div style="color: red;font-size: 10px">
            {{ field?.error }}
          </div>
          </div>
          <nz-form-item *ngSwitchCase="'priority'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field?.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <input nz-dropdown nzTrigger="click" readonly [nzDropdownMenu]="menu" nz-input [(ngModel)]="field.default"
              [placeholder]="''" class="f-12" />
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu (click)="$event.preventDefault();$event.stopPropagation()" nzSelectable class="option-list">
                <ng-container *ngFor="let item of field.options">
                  <li #menuitem nz-menu-item (click)="field.default= item.name;field.error = '';">
                    <span style="padding: 2px;border-radius: 50%;pointer-events: auto;">
                      <div class="avtarcheck">
                        <span nz-icon nzType="flag" nzTheme="fill" [style.color]="getColor(item.name)"></span>
                      </div>
                    </span>
                    <span style="margin-left: 5px;pointer-events: auto;">{{item.name}}</span>
                  </li>
                </ng-container>
              </ul>
            </nz-dropdown-menu>
            <div style="color: red;font-size: 10px">
              {{ field?.error }}
            </div>
          </nz-form-item>
          <div *ngSwitchCase="'status'">
            <nz-form-item class="dropdown-class statusSelectStyle removeBorder" >
              <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
                <span style="color: red">{{
                  field.mandatory ? "*" : ""
                  }}</span></nz-form-label>
              <nz-form-control [nzErrorTip]="field.label + ' is required'">
                <nz-select (ngModelChange)="field.error=''" [disabled]="disableFields || (field.dfc  && !field.editable)"
                  [nzCustomTemplate]="defaultTemplate" class="dropdownui" [nzDropdownClassName]="'dropDownContainer'"
                  nzPlaceHolder="-" [(ngModel)]="field.default" nzAllowClear 
                  [style.color]="getStatusColors(field.default, getOptionStatus(field))"                             [style.border]="
                  '1px solid' +
                  getStatusColors(
                    field.default,
                    getOptionStatus(field)
                  )
                "
                [style.borderRadius]="'5px'"
                  
                  >
                  <nz-option class="f-12" *ngFor="let option of getOptionStatus(field)" [nzValue]="option.name"
                    nzLabel="{{ option.name | titlecase }}"></nz-option>
                </nz-select>
                <ng-template #defaultTemplate let-selected>
                  <div [style.background]="hexToRgba(
                    getStatusColor(selected, getOptionStatus(field)),0.2)
                  " style="height: 100%; padding-left: 10px;border-radius: 5px;">
                    {{ selected.nzLabel }}
                  </div>
                </ng-template>
              </nz-form-control>
  
            </nz-form-item>
            <nz-row>
              <div nz-col  [nzSpan]="24" style="color: red;font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-row>
          </div>
  
  
  
  
  
          <nz-form-item
          *ngSwitchCase="'attachment'"
          style="cursor: auto"
        >
          <nz-form-label
            [nzLabelAlign]="'left'"
            [nzXs]="{ span: 24, offset: 0 }"
            >{{ field.label }}
            <span style="color: red">{{
              field.mandatory ? "*" : ""
            }}</span></nz-form-label
          >
          <ng-container>
            <div
              style="
                display: flex;
                column-gap: 10px;
                align-items: center;
              "
              class="attachmentContainer"
            >
              <ng-container *ngIf="field.default?.length !== 0">
                <div
                  style="
                    color: #1d66ff;
                    cursor: pointer;
                    font-size: 12px;
                  "
                            nz-dropdown
                            nzTrigger="click"
                            [nzDropdownMenu]="attachmentMenu"
                >
                <nz-dropdown-menu
                  #attachmentMenu="nzDropdownMenu"
                >
                  <ul nz-menu class="attContainer">
                    <ng-container
                      *ngFor="
                        let item of field.default;
                        let index = index
                      "
                    >
                      <li class="liClass">
                        <div
                          style="font-size: 11px"
                          class="hideOverFlowText"
                        >
                          {{ getShortenName(item) }}
                        </div>
                        <div>
                          <img
                            class="cPointer"
                            src="../../../../../assets/images/eye.svg"
                            (click)="downloadAttachment(item)"
                          /><img
                            class="cPointer"
                            src="../../../../../assets/images/icons/TrashBinIcon.svg"
                            (click)="
                              field.default.splice(index, 1)
                            "
                          />
                        </div>
                      </li>
                    </ng-container>
                    <div class="title-text">Attach File</div>
                    <li>
                      <img
                        style="width: 16px; height: 16px"
                        class="cPointer"
                        src="../../../../../assets/images/attachment.png"
                        (click)="UploadFileInput.click()"
                      />
                    </li>
                  </ul>
                </nz-dropdown-menu>
                  <img
                    class="cPointer"
                    nz-tooltip
                    [nzTooltipOverlayClassName]="
                      'attToolTipContainers'
                    "
                    [nzTooltipTitle]="
                      getShortenName(field.default[0])
                    "
                    nzTooltipPlacement="top"
                    src="../../../../../assets/images/icons/imageIcon.png"
                    style="width: 18px; height: 18px"
                  />
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  field.default?.length === 0 || !field.default
                "
              >
                <img
                  style="width: 16px; height: 16px"
                  class="cPointer"
                  src="../../../../../assets/images/attachment.png"
                  (click)="UploadFileInputs.click()"
                />
                <input
                  [hidden]="true"
                  type="file"
                  #UploadFileInputs
                  id="fileUpload"
                  (change)="
                    handleChange($event, field); field.error = ''
                  "
                  name="fileUpload"
                  multiple="multiple"
                />
              </ng-container>
              <img
                class="hideDelete cPointer"
                *ngIf="field.default.length === 1"
                (click)="field.default.splice(0, 1)"
                src="../../../../../assets/images/icons/TrashBinIcon.svg"
              />
            </div>
          </ng-container>
  
          <input
            [hidden]="true"
            type="file"
            #UploadFileInput
            id="fileUpload"
            (change)="
              handleChange($event, field); field.error = ''
            "
            name="fileUpload"
            multiple="multiple"
          />
          <nz-col [nzSpan]="24">
            <div
              style="
                color: red;
                font-size: 10px;
                text-align: start;
              "
            >
              {{ field?.error }}
            </div>
          </nz-col>
        </nz-form-item>
  
  
          <nz-form-item *ngSwitchCase="'phone'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <nz-form-control class="phoneInputStyle">
              <nz-input-group [nzPrefix]="suffixTemplate">
                <input [disabled]="disableFields" [readOnly]="field.dfc" class="f-12" type="text" (click)="mobileCode()"
                  #mobileInput nz-input (input)="setMobileNumber(field,selectedCountryCode[i],mobileInput,i)"
                  [value]="getMobileNumber(field,i)[1]||''" required nzPlaceHolder="-"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');" (blur)="checkIsUnique(field)" />
                <ng-template #suffixTemplate>
                  <nz-select [disabled]="disableFields || field.dfc" (click)="mobileCode()"
                    (ngModelChange)="setMobileNumber(field,$event,mobileInput,i)" #selectPhNumber
                    [ngModel]="getMobileNumber(field,i)[0]||'+91'" [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    nzAllowClear [nzDropdownRender]="selectSearchTemplatePhone">
                    <ng-template #selectSearchTemplatePhone>
                      <input #saearhFieldData nz-input placeholder="Search..."
                        (input)="filtercountryCode(saearhFieldData.value)" /></ng-template>
                    <nz-option class="f-12" [nzLabel]="(getMobileNumber(field,i)[0])||'+91'"
                      [nzValue]="getMobileNumber(field,i)[0]||'+91'" nzHide></nz-option>
                    <ng-container *ngFor="let item of getCountryCode(mobileCountryCode) ">
                      <nz-option class="f-12" [nzLabel]="item.PhoneCode" [nzValue]="item.PhoneCode"></nz-option>
                    </ng-container>
                  </nz-select>
                </ng-template>
              </nz-input-group>
              <div style="color: red; position: absolute; font-size: 10px">
                {{ field?.error }}
              </div>
            </nz-form-control>
          </nz-form-item>
  
          <nz-form-item *ngSwitchCase="'people'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
            <div style="width: 100%;">
              <nz-layout class="peopleContainer">
                <nz-form-control>
                  <nz-select [disabled]="disableFields || field.dfc" class="dropdownui" nzMode="multiple"
                    [nzCustomTemplate]="multipleTemplate" class="cPointer" [nzPlaceHolder]="showPeopleIcon"
                    style="cursor: pointer !important;width: 100%!Important;" [nzDropdownClassName]="'tagStyle'"
                    (ngModelChange)="field.default = $event;checkPeopleVald(field);field.error=''"
                    (nzOpenChange)="!$event?stopEdit():null" (click)="startEdit(field)" [(ngModel)]="field.default">
                    <ng-container *ngIf="field.default">
                      <ng-container *ngFor="let item of field.default">
                        <nz-option class="f-12" *ngIf="item !==''"  [nzLabel]="item" [nzValue]="item"
                        nzHide></nz-option>
                      </ng-container>

                    </ng-container>
                    <ng-container *ngFor="let option of getPeopleOption(field)">
                      <nz-option *ngIf="option !== ''" class="f-12" nzCustomContent 
                      [nzValue]="option" [nzLabel]="option">
                      <ng-container>
                        <div style="display: flex; justify-content: space-between">
                          <div class="f-12"><nz-avatar class="optionContainer" [nzText]="shortenName(option)"></nz-avatar>
                            {{ option }}</div>
                        </div>
                      </ng-container>
                    </nz-option>
                    </ng-container>

                  </nz-select>
                </nz-form-control>
                <ng-template #multipleTemplate let-selected>
                  <nz-avatar [nzText]="shortenName(selected.nzLabel)"></nz-avatar>
                </ng-template>
                <ng-template #showPeopleIcon>
                  <img src="../../../../../assets/images/icons/tabelPeople.svg" style="width: 16px;" alt="People Icon">
                </ng-template>
  
              </nz-layout>
              <div style="color: red;font-size: 10px">
                {{ field?.error }}
              </div>
            </div>
  
  
  
          </nz-form-item>
          <nz-form-item *ngSwitchCase="'time'">
            <nz-form-label [nzLabelAlign]="'left'" [nzXs]="{ span: 24, offset: 0 }">{{ field.label }}
              <span style="color: red">{{
                field.mandatory ? "*" : ""
                }}</span></nz-form-label>
  
            <div class="timerInputstyle" nz-dropdown nzTrigger="click" [nzDropdownMenu]="timeMenu"
              (click)="onOpenTime(field)" style="font-size: 11px;">
              {{getTimeValue(field) ||
              ' '}}</div>
            <nz-dropdown-menu #timeMenu="nzDropdownMenu">
              <ul nz-menu class="timerMenuStyle">
                <nz-tabset [(nzSelectedIndex)]="selectedRTimeTabIndex">
                  <nz-tab nzTitle="Manual">
                    <div style="padding: 0 15px;">
                      <input oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="f-12"
                        [(ngModel)]="manualTime" type="text" nz-input nzPlaceHolder="-">
                    </div>
                  </nz-tab>
                  <nz-tab nzTitle="Range">
                    <div style="padding: 0 15px;">
                      <nz-time-picker [nzDefaultOpenValue]="defaultOpenValue" nzFormat="HH:mm" [(ngModel)]="timerRange1"
                        [nzPopupClassName]="'timerStyle'"></nz-time-picker>
                      -
                      <nz-time-picker [nzDefaultOpenValue]="defaultOpenValue" [(ngModel)]="timerRange2"
                        [nzPopupClassName]="'timerStyle'" nzFormat="HH:mm"></nz-time-picker>
                    </div>
  
                  </nz-tab>
                </nz-tabset>
                <div class="btnAlign">
                  <div class="primaryBtnStyle">
                    <button nz-menu-item nz-button nzType="primary" class="save" (click)="saveTime(field)">
                      SAVE
                    </button>
                  </div>
                  <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
                    <button nz-menu-item nz-button nzType="default" class="buttonDefault f-10 fw-700"
                      style="height: 37px!important; border-radius: 5px;">
                      CANCEL
                    </button>
                  </div>
                </div>
              </ul>
            </nz-dropdown-menu>
            <div style="color: red; font-size: 10px">
              {{ field?.error }}
            </div>
          </nz-form-item>
  
          <nz-form-item *ngSwitchDefault>
            <nz-form-control>
              <nz-input-group style="opacity: 0">
                <input class="f-12" [readOnly]="field.dfc" [disabled]="disableFields || field.dfc" type="text" nz-input
                  [formControlName]="field.name" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  
  <div style="padding: 5px 20px;">
    <nz-row [nzJustify]="'space-between'">
      <div style="display: flex;margin-left: auto;">
        <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
          <button nz-button nzType="primary" [style.Opacity]="allowEdit?'0':'1'" class="buttonDefault save f-12" style="height: 35px; width: 90px"
            (click)="saveField()">
            SAVE
          </button>
        </div>
        <div class="text-center f-10 fw-700 lh-18 cPointer" style="margin-left: 10px; height: 35px; width: 90px">
          <button nz-button nzType="default" style="height: 34px" class="cancel f-12" (click)="cancel()">
            CANCEL
          </button>
        </div>
      </div>
    </nz-row>
  </div>
