import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirm-delete-popup',
  templateUrl: './confirm-delete-popup.component.html',
  styleUrls: ['./confirm-delete-popup.component.scss'],
})
export class ConfirmDeletePopupComponent {
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  constructor(private modal: NzModalRef) {}
  close() {
    this.modal.destroy('cancel');
  }
  confirm() {
    this.modal.destroy('confirm');
  }
}
