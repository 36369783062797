<div>
  <ng-container *ngIf="fileSupported">
    <embed
      [src]="url"
      frameborder="0"
      height="200px"
      width="100%"
      style="margin-top: 20px"
    />
  </ng-container>
  <ng-container *ngIf="!fileSupported">
    <div
      style="
        margin: auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
      "
    >
      File preview is not supported
    </div>
  </ng-container>
</div>
<div
  style="
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: end;
  "
>
  <span
    style="
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: end;
      column-gap: 10px;
    "
    (click)="downloadFile()"
  >
    <div style="color: #1d66ff; font-size: 12px; font-weight: 400">
      Download
    </div>
    <img src="../../../../assets/images/icons/downloadIcon.svg" alt="" />
  </span>
</div>
