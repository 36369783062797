// api-const.ts
import { environment } from "../../environments/environment";
export const ORGAINISATION_API_CONST = {
    CREATE_ORG: `${environment.base_eru_routes_url}/processo/func/create_org`,
    CREATE_ORG_PROCESS: `${environment.base_eru_routes_url}/processo/func/create_org_process`,
    INVITE_USERS_TO_ORGANISATION: `${environment.base_eru_routes_url}/processo/func/invite_users`,

}
export const USER_API_CONST = {
  UPDATE_USER: `${environment.base_eru_auth_url}/processo/eru/updateuser`,
  UPDATE__USER: `${environment.base_eru_routes_url}/processo/func/update_user`,
  GET_RECOVERY_CODE: `${environment.base_eru_auth_url}/processo/eru/getrecoverycode`,
  VERIFY_RECOVERY_CODE: `${environment.base_eru_auth_url}/processo/eru/verifyrecoverycode`,
  GET_VERIFY_CODE: `${environment.base_eru_auth_url}/processo/eru/getverifycode${environment.debugMode ? '?silent=true' : ''}`,
  CHECK_VERIFY_CODE: `${environment.base_eru_auth_url}/processo/eru/checkverifycode`,
  GET_PROCESS: `${environment.base_url}/store/processo/myquery/execute/get_process`,
  GET_USER_ORG_PROCESS: `${environment.base_url}/store/processo/myquery/execute/get_user_org_process`,
  UPDATE_ORG: `${environment.base_eru_routes_url}/processo/func/update_org`,
  GET_USER_ORG_PROCESS_BY_ID: `${environment.base_url}/store/processo/myquery/execute/get_user_org_process`,
  GET_PROCESS_METADATA: `${environment.base_url}/store/processo/myquery/execute/get_process_metadata`,
  REMOVE_PROCESS_FROM_ORG: `${environment.base_eru_routes_url}/processo/func/remove_process_from_org`,
  REMOVE_USER_FROM_PROCESS: `${environment.base_eru_routes_url}/processo/func/remove_user_from_process`,
  GET_INVITE_USER_PENDING: `${environment.base_url}/store/processo/myquery/execute/get_invite_user_pending`,
  UPDATE_ACCEPT_INVITATION: `${environment.base_url}/store/processo/myquery/execute/update_accept_invitation`,
  UPDATE_REJECT_INVITATION: `${environment.base_url}/store/processo/myquery/execute/update_reject_invitation`,
  GET_INVITE_USER_EMAILID: `${environment.base_url}/store/processo/myquery/execute/get_invite_user_emailid`,
  SHOW_FIELD: `${environment.base_url}/store/processo/myquery/execute/show_field`,
  HIDE_FIELD: `${environment.base_eru_routes_url}/processo/func/hide_field`,
  SHOW_GRID_VALUE: `${environment.base_eru_routes_url}/processo/func/show_grid_value`,
  SAVE_ENTITY_DATA: `${environment.base_eru_routes_url}/processo/func/save_entity_data`,
  REMOVE_TAB: `${environment.base_url}/store/processo/myquery/execute/remove_tab`,
  REMOVE_TAB_FIELD_NAMES: `${environment.base_url}/store/processo/myquery/execute/remove_tab_field_names`,
  DELETE_ENTITY_DATA: `${environment.base_url}/store/processo/myquery/execute/delete_entity_data`,
  DELETE_ENTITY_DATAS: `${environment.base_eru_routes_url}/processo/func/delete_entity_data`,
  UPDATE_ORG_DEFAULT: `${environment.base_eru_routes_url}/processo/func/update_org_default`,
  UPDATE_ORG_PROCESS_DEFAULT: `${environment.base_eru_routes_url}/processo/func/update_org_process_default`,
  ADD_ORG_ROLE: `${environment.base_eru_routes_url}/processo/func/add_org_role`,
  DELETE_ORG_ROLE: `${environment.base_eru_routes_url}/processo/func/delete_org_role`,
  REMOVE_ROLE_FROM_USER: `${environment.base_url}/store/processo/myquery/execute/remove_role_from_user`,
  UPDATE_USER_ROLE: `${environment.base_url}/store/processo/myquery/execute/update_user_role`,
  UPDATE_USER_ACCESS: `${environment.base_url}/store/processo/myquery/execute/update_user_access`,
  MAP_USER_TO_PROCESS_USER: `${environment.base_url}/store/processo/myquery/execute/map_user_to_process_user`,
  REMOVE_USER_FROM_PROCESS_USER: `${environment.base_url}/store/processo/myquery/execute/remove_user_from_process_user`,
  UPDATE_ENTITY_VISIBILITY: `${environment.base_url}/store/processo/myquery/execute/update_entity_visibility`,
  MAP_USER_TO_ENTITY_DATA: `${environment.base_url}/store/processo/myquery/execute/map_user_to_entity_data`,
  MAP_OTHER_USERS_TO_PROCESS_USER: `${environment.base_url}/store/processo/myquery/execute/map_other_users_to_process_user`,
  REMOVE_USER_FROM_ENTITY_DATA: `${environment.base_url}/store/processo/myquery/execute/remove_user_from_entity_data`,
  MAP_ROLE_TO_ENTITY_DATA: `${environment.base_url}/store/processo/myquery/execute/map_role_to_entity_data`,
  REMOVE_ROLE_FROM_ENTITY_DATA: `${environment.base_url}/store/processo/myquery/execute/remove_role_from_entity_data`,
  GET_ENTITY_DATA_LOGS: `${environment.base_url}/store/processo/myquery/execute/get_entity_data_logs`,
  REMOVE_VISIBILITY_FIELD: `${environment.base_url}/store/processo/myquery/execute/remove_visibility_field`,
  SAVE_VISIBILITY_FIELD: `${environment.base_eru_routes_url}/processo/func/save_visibility_field`,
  REMOVE_VISIBILITY_VALUE: `${environment.base_eru_routes_url}/processo/func/remove_visibility_value`,
  GET_ENTITY_FIELD_DATA: `${environment.base_url}/store/processo/myquery/execute/getentityfielddata`,
  GET_CURRENCY_LIST: `${environment.base_url}/store/processo/myquery/execute/get_countries_value`,
  SAVE_APIS: `${environment.base_eru_routes_url}/processo/func/save_apis`,
  GET_APIS: `${environment.base_url}/store/processo/myquery/execute/get_apis`,
  GET_API_FIELDS: `${environment.base_eru_routes_url}/processo/func/exec_api`,
  REMOVE_API: `${environment.base_url}/store/processo/myquery/execute/remove_apis`,
  SAVE_TABS: `${environment.base_eru_routes_url}/processo/func/save_tabs`,
  UPDATE_PROCESS_ENTITY_VISIBILITY: `${environment.base_url}/store/processo/myquery/execute/update_process_entity_visibility`,
  MAP_USER_TO_PROCESS_ENTITY: `${environment.base_url}/store/processo/myquery/execute/map_user_to_process_entity`,
  REMOVE_USER_FROM_PROCESS_ENTITY: `${environment.base_url}/store/processo/myquery/execute/remove_user_from_process_entity`,
  MAP_ROLE_TO_PROCESS_ENTITY: `${environment.base_url}/store/processo/myquery/execute/map_role_to_process_entity`,
  REMOVE_ROLE_FROM_PROCESS_ENTITY: `${environment.base_url}/store/processo/myquery/execute/remove_role_from_process_entity`,
  GET_MOBILE_COUNTRY_CODE: `${environment.base_eru_routes_url}/processo/func/exec_api`,
  GET_WORKFLOW_EVENT: `${environment.base_url}/store/processo/myquery/execute/get_workflow_event`,
  SAVE_APPROVAL_MATRIX: `${environment.base_url}/store/processo/myquery/execute/save_approval_matrix`,
  REMOVE_WORKFLOW_EVENT: `${environment.base_url}/store/processo/myquery/execute/remove_workflow_event`,
  GET_APPROVAL_MATRIX: `${environment.base_url}/store/processo/myquery/execute/get_approval_matrix`,
  REMOVE_APPROVAL_MATRIX: `${environment.base_url}/store/processo/myquery/execute/remove_approval_matrix`,
  FETCH_ENTITY_DATA_BY_LOGS: `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_by_logs`,
  GET_CURRENCY_LISTS: `${environment.base_url}/store/processo/myquery/execute/get_countries_value`,
  GET_COUNTRY_CODE: `https://erufunc.dev.processo.io/processo/func/exec_api`,
  SAVE_MESSAGE_TEMPLATE: `${environment.base_url}/store/processo/myquery/execute/save_message_template`,
  GET_MESSAGE_TEMPLATE: `${environment.base_url}/store/processo/myquery/execute/get_message_template`,
  REMOVE_MESSAGE_TEMPLATE: `${environment.base_url}/store/processo/myquery/execute/remove_message_template`,
  CHANGE_ENTITY_FIELD_INDEX: `${environment.base_eru_routes_url}/processo/func/change_entity_field_index`,
  UPDATE_SUPERVISOR: `${environment.base_url}/store/processo/myquery/execute/update_supervisor`,
  SAVE_SECRET: `${environment.base_eru_routes_url}/processo/func/save_secret_key`,
  GET_SECRET: `${environment.base_url}/store/processo/myquery/execute/get_secret_key`,
  SAVE_WHATSAPP: `${environment.base_eru_routes_url}/processo/func/save_connectors`,
  REMOVE_SECRET: `${environment.base_eru_routes_url}/processo/func/remove_secret_key`,
  SAVE_CONNECTORS: `${environment.base_eru_routes_url}/processo/func/save_connectors`,
  GET_CONNECTORS: `${environment.base_url}/store/processo/myquery/execute/get_connectors`,
  SAVE_USER_FIELD: `${environment.base_eru_routes_url}/processo/func/save_user_field`,
  SAVE_USER_DATA: `${environment.base_eru_routes_url}/processo/func/save_user_data`,
  DELETE_USER_DATA: `${environment.base_url}/store/processo/myquery/execute/remove_user_data`,
};
