import { Component, HostBinding } from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
}
