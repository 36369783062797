import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/authentication/auth.guard';
import { ViewNewOrganizationComponent } from './modules/view-new-organization/view-new-organization.component';
import { LoadingComponent } from './modules/registeration/components/loading/loading.component';
import { OverviewComponent } from './modules/dashboard/components/overview/overview.component';
import { EmptyOrganisationComponent } from './shared/components/empty-organisation/empty-organisation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'loading',
    component: LoadingComponent,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/registeration/registeration.module').then(
        (m) => m.RegisterationModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'onboarding',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'organization',
    loadChildren: () =>
      import('./modules/organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
  },

  {
    path: 'view-organization',
    loadChildren: () =>
      import(
        './modules/view-new-organization/view-new-organization.module'
      ).then((m) => m.ViewNewOrganizationModule),
  },

  // refactor
  {
    path: 'org',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './shared/organization/organization-layout/organization-layout.component'
          ).then((c) => c.OrganizationLayoutComponent),
        children: [
          {
            path: 'list',
            loadComponent: () =>
              import(
                './shared/organization/organization-list/organization-list.component'
              ).then((c) => c.OrganizationListComponent),
              
          },
          {
            path: 'new-action',
            loadComponent: () => 
              import(
                './shared/components/new-action/new-action.component'
              ).then((c) => c.NewActionComponent),
          },
          {
            path: 'new',
            loadComponent: () =>
              import(
                './shared/organization/create-new-organization/create-new-organization.component'
              ).then((c) => c.CreateNewOrganizationComponent),
          },
          {
            path: 'no-org',
            loadComponent: () => import('./shared/components/empty-organisation/empty-organisation.component').then((c) => c.EmptyOrganisationComponent)
          }
        ],
      },

      {
        path: ':organization_name',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './shared/organization/organization-layout/organization-layout.component'
              ).then((c) => c.OrganizationLayoutComponent),
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-details-layout/organization-details-layout.component'
                  ).then((c) => c.OrganizationDetailsLayoutComponent),
                children: [
                  {
                    path: 'basic',
                    loadComponent: () =>
                      import(
                        './shared/organization/organization-basic-detail-component/organization-basic-detail-component.component'
                      ).then(
                        (m) => m.OrganizationBasicDetailComponentComponent
                      ),
                  },
                  {
                    path: 'roles',
                    loadComponent: () =>
                      import(
                        './shared/organization/organization-role-details/organization-role-details.component'
                      ).then((m) => m.OrganizationRoleDetailsComponent),
                  },
                  {
                    path: 'users',
                    loadComponent: () =>
                      import(
                        './shared/organization/organization-user-details/organization-user-details.component'
                      ).then((m) => m.OrganizationUserDetailsComponent),
                  },
                ],
              },
            ],
          },
          {
            path: 'process/:process_name',

            children: [
              {
                path: 'view',
                loadComponent: () =>
                  import('./shared/components/layout/layout.component').then(
                    (c) => c.LayoutComponent
                  ),
                children: [
                  {
                    path: 'Dashboard',
                    loadComponent: () =>
                      import(
                        './shared/components/superset-dashboard/superset-dashboard.component'
                      ).then((c) => c.SupersetDashboardComponent),
                  },
                 
                  {
                    path: ':entity_display_name',
                    loadComponent: () =>
                      import(
                        './shared/components/entity-layout/entity-layout.component'
                      ).then((c) => c.EntityLayoutComponent),

                     
                  },
                ],
              },
              {
                path: 'new',
                loadComponent: () =>
                  import('./shared/components/layout/layout.component').then(
                    (c) => c.LayoutComponent
                  ),
                children: [
                  {
                    path: ':entity_display_name',
                    loadComponent: () =>
                      import(
                        './shared/components/new-action/new-action.component'
                      ).then((c) => c.NewActionComponent),
                 }
               ]
              },

              {
                path: 'edit',
                loadComponent: () =>
                  import('./shared/components/layout/layout.component').then(
                    (c) => c.LayoutComponent
                  ),
                children: [
                  {
                    path: ':entity_display_name',
                    children: [
                      {
                        path:':id',
                        loadComponent: () =>
                          import(
                            './shared/components/new-action/new-action.component'
                          ).then((c) => c.NewActionComponent),
                      }
                    ]
                  }
                ]
              },
              {
                path: 'action',
                loadChildren: () =>
                  import('./modules/organization/organization.module').then(
                    (m) => m.OrganizationModule
                  ),
              },
              {
                path: 'action',
                loadComponent: () =>
                  import('./shared/components/layout/layout.component').then(
                    (c) => c.LayoutComponent
                  ),
                children: [
                  {
                    path: 'bulk-import/:entity_display_name',
                    loadComponent: () =>
                      import(
                        './shared/components/bulk-import/bulk-import.component'
                      ).then((c) => c.BulkImportComponent),
                 },
                 {
                  path: 'conversation/:entity_display_name',
                  loadComponent: () =>
                    import(
                      './modules/dashboard/components/conversation/conversation.component'
                    ).then((c) => c.ConversationComponent),
               }
               ]
              },
              {
                path: 'my-approval',
                loadComponent: () =>
                  import('./shared/components/layout/layout.component').then(
                    (c) => c.LayoutComponent
                  ),
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './shared/components/my-approval/my-approval.component'
                      ).then((c) => c.MyApprovalComponent),
                 }
               ]
              },
              {
                path: 'edit-new-entity/:entity_id',
                loadComponent: () =>
                  import(
                    './shared/components/new-action/new-action.component'
                  ).then((c) => c.NewActionComponent),
              },
              {
                path: 'api',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-layout/organization-layout.component'
                  ).then((c) => c.OrganizationLayoutComponent),
                children: [
                  {
                    path: 'list',
                    loadComponent: () =>
                      import(
                        './shared/organization/api-list-details/api-list-details.component'
                      ).then((c) => c.ApiListDetailsComponent),
                  },
                  {
                    path: 'new',
                    loadComponent: () =>
                      import(
                        './shared/organization/create-new-api/create-new-api.component'
                      ).then((c) => c.CreateNewApiComponent),
                  },

                  {
                    path: 'edit',
                    loadComponent: () =>
                      import(
                        './shared/organization/create-new-api/create-new-api.component'
                      ).then((c) => c.CreateNewApiComponent),
                  },
                ],
              },
              {
                path: 'message-template',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-layout/organization-layout.component'
                  ).then((c) => c.OrganizationLayoutComponent),
                children: [
                  {
                    path: 'list',
                    loadComponent: () =>
                      import(
                        './shared/organization/message-template-list/message-template-list.component'
                      ).then((c) => c.MessageTemplateListComponent),
                  },
                  {
                    path: 'new',
                    loadComponent: () =>
                      import(
                        './shared/organization/message-template-create-new/message-template-create-new.component'
                      ).then((c) => c.MessageTemplateCreateNewComponent),
                  },
                  {
                    path: 'edit',
                    loadComponent: () =>
                      import(
                        './shared/organization/message-template-create-new/message-template-create-new.component'
                      ).then((c) => c.MessageTemplateCreateNewComponent),
                  },
                ],
              },
              {
                path: 'secret',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-layout/organization-layout.component'
                  ).then((c) => c.OrganizationLayoutComponent),
                children: [
                  {
                    path: 'list',
                    loadComponent: () =>
                      import(
                        './shared/organization/secret-list/secret-list.component'
                      ).then((c) => c.SecretListComponent),
                  },
                  {
                    path: 'new',
                    loadComponent: () =>
                      import(
                        './shared/organization/secret-create-new/secret-create-new.component'
                      ).then((c) => c.SecretCreateNewComponent),
                  },
                  {
                    path: 'edit',
                    loadComponent: () =>
                      import(
                        './shared/organization/secret-create-new/secret-create-new.component'
                      ).then((c) => c.SecretCreateNewComponent),
                  },
                ],
              },
              {
                path: 'connectors',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-layout/organization-layout.component'
                  ).then((c) => c.OrganizationLayoutComponent),
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './shared/organization/organization-connectors/organization-connectors.component'
                      ).then((c) => c.OrganizationConnectorsComponent),
                  },
                ],
              },
              {
                path: 'report',
                loadComponent: () =>
                  import(
                    './shared/organization/organization-layout/organization-layout.component'
                  ).then((c) => c.OrganizationLayoutComponent),
                children: [
                  {
                    path: 'list',
                    loadComponent: () =>
                      import(
                        './shared/organization/report-list/report-list.component'
                      ).then((c) => c.ReportListComponent),
                  },
                  {
                    path: 'new',
                    loadComponent: () =>
                      import(
                        './shared/organization/report-create-new/report-create-new.component'
                      ).then((c) => c.ReportCreateNewComponent),
                  },
                  {
                    path: 'edit',
                    loadComponent: () =>
                      import(
                        './shared/organization/report-create-new/report-create-new.component'
                      ).then((c) => c.ReportCreateNewComponent),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'user',
    loadComponent: () =>
      import('./shared/components/profile/user-layout/user-layout.component').then(
        (c) => c.UserLayoutComponent
      ),
    children: [
      {
        path: 'settings',
        loadComponent: () => import('./shared/components/profile/user-settings/user-settings.component').then((c) => c.UserSettingsComponent)
      },
      {
        path: 'invitations',
        loadComponent: () => import('./shared/components/profile/user-invitation/user-invitation.component').then((m) => m.UserInvitationComponent)
      }
    ]
  },
  {
    path: 'terms-of-service',
    loadComponent: () =>
      import('./shared/components/terms-of-service/terms-of-service.component').then(
        (c) => c.TermsOfServiceComponent
      ),
    },
    {
      path: 'privacy-policy',
      loadComponent: () =>
        import('./shared/components/privacy-policy/privacy-policy.component').then(
          (c) => c.PrivacyPolicyComponent
        ),
      },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
