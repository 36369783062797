<ng-container *ngIf="data?.show === 'entity'">
  <form nz-form [formGroup]="entityForm" autocomplete="off" class="w-100">
    <nz-row [nzGutter]="10">
      <input style="position:absolute;right:-5555555555555555px" nz-input #in1  placeholder="Entity Name" formControlName="dummy" type="text"
      />
      <nz-col [nzSpan]="12" class="mt-10 fieldStyle">
        <nz-form-control>
          <nz-form-label class="dgrey-text InputAlignLeft">Entity Name<span class="redColor">*</span></nz-form-label>
          <input nz-input #in1 (input)="checkExistingName(in1.value.toLowerCase(),'Entity')" placeholder="Entity Name" formControlName="entityname" type="text"
          oninput="this.value = this.value.replace(/[^a-z_]/g, '');" />
        </nz-form-control>
      <div *ngIf="entityNameError" class="errorTextStyle">Entity Name already exists</div>
      <div *ngIf="entityForm.get('entityname')?.invalid && entityForm.get('entityname')?.touched && !entityNameError" class="errorTextStyle">
        entity name required
      </div>
      </nz-col>
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-form-control>
          <nz-form-label class="dgrey-text InputAlignLeft">Display Name<span class="redColor">*</span></nz-form-label>
          <input #in2 (input)="checkExistingName(in2.value,'Display')" nz-input placeholder="Display Name" formControlName="displayname" type="text"
          oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');" />
        </nz-form-control>
      <div *ngIf="displayNameError" class="errorTextStyle">Display Name already exists</div>
      <div *ngIf="entityForm.get('displayname')?.invalid && entityForm.get('displayname')?.touched && !displayNameError" class="errorTextStyle">
        display name required
      </div>
      </nz-col>
      <div nz-col [nzSpan]="10" class="switchStyle mt-24">
        <div class="" style="display: flex; align-items: center">
          <nz-switch formControlName="isPeople" id="switch0"></nz-switch>
          <label for="switch0" class="f-12">&nbsp;&nbsp;Is People ?</label>
        </div>
      </div>
      <div nz-col [nzSpan]="14" class="switchStyle mt-24" [hidden]="!(entityForm.get('isPeople')?.value)">
        <div class="" style="display: flex; align-items: center">
          <nz-switch formControlName="enableConversation" id="switch1"></nz-switch>
          <label for="switch1" class="f-12">&nbsp;&nbsp;Enable Conversation ?</label>
        </div>
      </div>
      <nz-col [nzSpan]="24" style="display: flex;justify-content: end;align-items: center;" class="mt-20">
        <div class="text-center cPointer" style="border: none!important;">
          <button style="border: none!important;padding: 0 24px;" nzType="primary" class="f-10 fw-700" type="button"
            (click)="saveEntityForm()">
            SAVE ENTITY

          </button>
        </div>
        <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
          <button nz-button nzType="default" class="buttonDefault f-10 fw-700"
            style="height: 37px; border-radius: 5px; margin-left: 20px" (click)="cancel()">
            CANCEL
          </button>
        </div>
      </nz-col>
    </nz-row>
    <nz-row [nzGutter]="12">
      <nz-col [nzSpan]="24" class="mt-10 mb-10">
        <div class="textEntity mb-10">Position this Entity</div>
      </nz-col>
    </nz-row>
    <nz-row class="addNewFieldContainer" [nzAlign]="'middle'" >
      <div class="addNewFieldSubContainer">
        <nz-layout draggable="true" (dragstart)="dragStartColumn(i, $event)" (dragover)="allowDrop($event,i)"
          (drop)="dropColumn(i, $event)" *ngFor="let variable of tab; let i = index" style="cursor: grab!important"
          >
          <nz-row [nzGutter]="10" [nzAlign]="'middle'" class="mt-10" [ngClass]="{'backgroundBlue':movingIndex === i}">
            <div style="cursor: grab!important" nz-col [nzSpan]="2">
              <img src="../../../../assets/images/icons/arrowDragIcon.svg" />
            </div>
            <div nz-col nz-col [nzSpan]="12">
              {{variable.display_name}}
            </div>
            <div nz-col nz-col [nzSpan]="10">
              <div class="d-flex" style="width: 100%;align-items: center;">

                <img class="cPointer " src="../../../../assets/images/Pencil.svg" (click)="editEntityName(variable,i)">
                <!-- <ng-container *ngIf="variable.entity_type ==='SYSTEM'"> -->
                <img (click)="hideEntity(variable?.hide_entity,i)" class="cPointer ml-10" [src]="variable.hide_entity==='true' ?'../../../../assets/images/hideEye.svg':'../../../../assets/images/eye.svg'">
                <!-- </ng-container> -->
                <img class="cPointer ml-10" src="../../../../assets/images/icons/TrashBinIcon.svg" *ngIf="variable.entity_type !=='SYSTEM'" (click)="deleteEntity(variable,i)">
              </div>
            </div>
          </nz-row>
        </nz-layout>
      </div>
    </nz-row>
  </form>
</ng-container>