import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterConvertorServiceService {
  entitiesData: any = [];
  Array = Array;
  constructor() {}
  getSortedInputTypes() {
    let val = [
      {
        label: 'attachment',
        datatype: 'attachment',
        image: '../../assets/images/entityIcons/Attachment.svg',
      },
      {
        label: 'checkbox',
        datatype: 'checkbox',
        image: '../../assets/images/entityIcons/Checkbox.svg',
      },
      {
        label: 'currency',
        datatype: 'currency',
        image: '../../assets/images/entityIcons/Currency.svg',
      },
      {
        label: 'date',
        datatype: 'date',
        image: '../../assets/images/entityIcons/Calendar.svg',
      },
      {
        label: 'dropdown',
        datatype: 'dropdown_single_select',
        image: '../../assets/images/entityIcons/singleSelect.svg',
      },
      {
        label: 'dropdown (Multi)',
        datatype: 'dropdown_multi_select',
        image: '../../assets/images/entityIcons/multiSelect.svg',
      },
      {
        label: 'email',
        datatype: 'email',
        image: '../../assets/images/entityIcons/Email.svg',
      },
      {
        label: 'location',
        datatype: 'location',
        image: '../../assets/images/entityIcons/Location.svg',
      },
      {
        label: 'number',
        datatype: 'number',
        image: '../../assets/images/entityIcons/Number.svg',
      },
      {
        label: 'people',
        datatype: 'people',
        image: '../../assets/images/entityIcons/people.svg',
      },
      {
        label: 'phone',
        datatype: 'phone',
        image: '../../assets/images/entityIcons/Phone.svg',
      },
      {
        label: 'priority',
        datatype: 'priority',
        image: '../../assets/images/entityIcons/Priority.svg',
      },
      {
        label: 'progress',
        datatype: 'progress',
        image: '../../assets/images/entityIcons/Progress.svg',
      },
      {
        label: 'rating',
        datatype: 'rating',
        image: '../../assets/images/entityIcons/Rating.svg',
      },
      {
        label: 'tag',
        datatype: 'tag',
        image: '../../assets/images/entityIcons/tag.svg',
      },
      {
        label: 'text area',
        datatype: 'textarea',
        image: '../../assets/images/entityIcons/textArea.svg',
      },
      {
        label: 'textbox',
        datatype: 'textbox',
        image: '../../assets/images/entityIcons/Textbox.svg',
      },
      {
        label: 'time',
        datatype: 'time',
        image: '../../assets/images/entityIcons/Time.svg',
      },
      {
        label: 'website (url)',
        datatype: 'website',
        image: '../../assets/images/entityIcons/Website.svg',
      },
      {
        label: 'Status',
        datatype: 'status',
        image: '../../../assets/images/addNewColumnIcons/messageBox.svg',
      },
    ].sort((a, b) => a.label.localeCompare(b.label));

    return {
      new_fields: val,
    };
  }
  get_entities_data(state: any) {
    let entitiesData = [];
    let defaultEntities = state?.metaData?.entities?.find(
      (_e: any) => _e.name === state?.selectedEntity
    );
    if (defaultEntities) {
      let data = {
        label: defaultEntities?.display_name,
        entity_name: defaultEntities?.name,
        field_name: defaultEntities?.name,
        default: true,
      };
      entitiesData.push(data);
    }

    defaultEntities?.fields?.map((_field: any) => {
      if (
        (_field?.datatype === 'dropdown_single_select' &&
          _field.option_type === 'ENTITY_DATA') ||
        (_field?.datatype === 'dropdown_multi_select' &&
          _field.option_type === 'ENTITY_DATA')
      ) {
        let data = {
          label: _field?.label,
          field_name: _field.name,
          entity_name: _field?.entity_name,
          default: false,
        };
        entitiesData.push(data);
      }
    });
    this.entitiesData = entitiesData;
  }
  getField(entityName: string, state: any): any {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === entityName
    )?.entity_name;
    let fields = state?.metaData?.entities?.find(
      (_e: any) => _e.name === entity_Name
    )?.fields;
    return fields;
  }
  isDefault(fieldName: any, state: any) {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === fieldName
    );
    if (
      entity_Name?.entity_name === state?.selectedEntity &&
      entity_Name?.default
    ) {
      return false;
    } else {
      return true;
    }
  }
  key3Values(field: any, data: any) {
    if (
      ['currency', 'progress', 'number', 'rating', 'date', 'time'].includes(
        field?.datatype
      )
    ) {
      return null;
    } else if (
      ['email', 'location', 'phone', 'textarea', 'textbox', 'website'].includes(
        field?.datatype
      )
    ) {
      return '';
    } else if (
      [
        'dropdown_single_select',
        'dropdown_multi_select',
        'people',
        'priority',
        'tag',
        'status',
      ].includes(field?.datatype)
    ) {
      return [''];
    }

    return data;
  }

  set_key3_value(data: any) {    
    if (data.key2 === '$in' || data.key2 === '$nin') {
      return [data.key3];
    } else {
      return data.key3;
    }
  }
  set_key3_(data:any){
    if(data.key2 === '$in' || data.key2 === '$nin' || data.key2 === '$nin'|| data.key2 === '$jin' || data.key2 === '$jnin'){
      return [data.key3];
    }
    return data.key3;
  }
  convertFilterValueToJson(dataFilter: any, state: any) {
    this.get_entities_data(state);
    let filterList: any = {};
    if (dataFilter.length > 0 && dataFilter[0].key1 == '') {
      filterList = {};
    } else {
      let count = 0;
      for (let r of dataFilter) {
        count++;
        let is_initial = false;
        // For first level group
        if (this.Array.isArray(r)) {
          if (r.length > 1) {
            // For group or
            if (dataFilter?.[0].key4 === true) {
              for (let a of r) {
                // For group item
                if (!this.Array.isArray(a)) {
                  //this.validateFilterKeyValue(a);
                  if (Object.keys(filterList).includes(`$or`)) {
                    count++;
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a.key3 = String(a.key3);
                    }
                    // if (
                    //   selectedField &&
                    //   selectedField.datatype === 'checkbox'
                    // ) {
                    //   a.key3 = a.key2 == 'true' ? true : false;
                    //   a.key2 = '$eq';
                    // }
                    filterList[`$or`] = [
                      ...filterList[`$or`],
                      {
                        [this.isDefault(a.entity_name, state)
                          ? `${count}___${a.entity_name}~${a.key1}`
                          : `${count}___${a.key1}`]:
                          a.key2 === ''
                            ? a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              :this.set_key3_(a) 
                            : {
                                [a.key2]:
                                  a.key3.length === 0
                                    ? this.key3Values(selectedField, a.key3)
                                    :this.set_key3_(a) ,
                              },
                      },
                    ];
                  } else {
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a.key3 = String(a.key3);
                    }
                    // if (
                    //   selectedField &&
                    //   selectedField.datatype === 'checkbox'
                    // ) {
                    //   a.key3 = a.key2 == 'true' ? true : false;
                    //   a.key2 = '$eq';
                    // }
                    filterList[`$or`] = [
                      {
                        [this.isDefault(a.entity_name, state)
                          ? `${count}___${a.entity_name}~${a.key1}`
                          : `${count}___${a.key1}`]:
                          a.key2 === ''
                            ? a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              : this.set_key3_(a)
                            : {
                                [a.key2]:
                                  a.key3.length === 0
                                    ? this.key3Values(selectedField, a.key3)
                                    :this.set_key3_(a) ,
                              },
                      },
                    ];
                  }
                }
                // For group -> child group
                else {
                  if (a.length > 1) {
                    // For and
                    if (dataFilter?.[0].key4 === true) {
                      filterList['$or'].push({});
                      const m = filterList[`$or`]?.length - 1;
                      for (let b of a) {
                        count++;
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        // if (
                        //   selectedField &&
                        //   selectedField.datatype === 'checkbox'
                        // ) {
                        //   b.key3 = b.key2 == 'true' ? true : false;
                        //   b.key2 = '$eq';
                        // }
                        filterList[`$or`][m][
                          this.isDefault(b.entity_name, state)
                            ? `${count}___${b.entity_name}~${b.key1}`
                            : `${count}___${b.key1}`
                        ] =
                          b.key2 === ''
                            ? b.key3.length === 0
                              ? this.key3Values(selectedField, b.key3)
                              :this.set_key3_(b)  
                            : {
                                [b.key2]:
                                  b.key3.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : this.set_key3_(b) ,
                              };
                      }
                    }
                  } else {
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a[0].key3 = String(a[0].key3);
                    }
                    // if (
                    //   selectedField &&
                    //   selectedField.datatype === 'checkbox'
                    // ) {
                    //   a[0].key3 = a[0].key2 == 'true' ? true : false;
                    //   a[0].key2 = '$eq';
                    // }
                    filterList[`${count}___$or`] = [
                      ...filterList[`${count}___$or`],
                      {
                        [this.isDefault(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1]:
                          a[0].key2 === ''
                            ? a[0].key3.length === 0
                              ? this.key3Values(selectedField, a[0].key3)
                              :this.set_key3_(a[0])  
                            : {
                                [a[0].key2]:
                                  a[0].key3.length === 0
                                    ? this.key3Values(selectedField, a[0].key3)
                                    :this.set_key3_(a[0]) ,
                              },
                      },
                    ];
                  }
                }
              }
            }
            // For group and
            else {
              filterList?.['$or'].push({});
              const n = filterList?.[`$or`]?.length - 1;
              for (let a of r) {
                if (!this.Array.isArray(a)) {
                  if (is_initial) {
                    count++;
                  }
                  is_initial = true;
                  //this.validateFilterKeyValue(a);
                  const fields = this.getField(a.entity_name, state);
                  const selectedField = fields.find(
                    (item: any) => item.name === a.key1
                  );
                  if (selectedField && selectedField.datatype === 'rating') {
                      a.key3 = String(a.key3);
                    }
                  // if (selectedField && selectedField.datatype === 'checkbox') {
                  //   a.key3 = a.key2 == 'true' ? true : false;
                  //   a.key2 = '$eq';
                  // }
                  filterList[`$or`][n][
                    this.isDefault(a.entity_name, state)
                      ? `${count}___${a.entity_name}~${a.key1}`
                      : `${count}___${a.key1}`
                  ] =
                    a.key2 === ''
                      ? a.key3.length === 0
                        ? this.key3Values(selectedField, a.key3)
                        : this.set_key3_(a)
                      : {
                          [a.key2]:
                            a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              : this.set_key3_(a),
                        };
                } else {
                  if (a.length > 1) {
                    const m = filterList[`$or`].length - 1;
                    for (let b of a) {
                      count++;
                      if (Object.keys(filterList[`$or`][m]).includes(`$or`)) {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        // if (
                        //   selectedField &&
                        //   selectedField.datatype === 'checkbox'
                        // ) {
                        //   b.key3 = b.key2 == 'true' ? true : false;
                        //   b.key2 = '$eq';
                        // }
                        filterList[`$or`][m][`$or`] = [
                          ...filterList[`$or`][m][`$or`],
                          {
                            [this.isDefault(b.entity_name, state)
                              ? `${count}___${b.entity_name}~${b.key1}`
                              : `${count}___${b.key1}`]:
                              b.key2 === ''
                                ? b.key3.length === 0
                                  ? this.key3Values(selectedField, b.key3)
                                  :this.set_key3_(b) 
                                : {
                                    [b.key2]:
                                      b.key3.length === 0
                                        ? this.key3Values(selectedField, b.key3)
                                        : this.set_key3_(b) ,
                                  },
                          },
                        ];
                      } else {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        // if (
                        //   selectedField &&
                        //   selectedField.datatype === 'checkbox'
                        // ) {
                        //   b.key3 = b.key2 == 'true' ? true : false;
                        //   b.key2 = '$eq';
                        // }
                        filterList[`$or`][m][`$or`] = [
                          {
                            [this.isDefault(b.entity_name, state)
                              ? `${count}___${b.entity_name}~${b.key1}`
                              : `${count}___${b.key1}`]:
                              b.key2 === ''
                                ? b.key3.length === 0
                                  ? this.key3Values(selectedField, b.key3)
                                  : this.set_key3_(b)
                                : {
                                    [b.key2]:
                                      b.key3.length === 0
                                        ? this.key3Values(selectedField, b.key3)
                                        : this.set_key3_(b) ,
                                  },
                          },
                        ];
                      }
                    }
                  } else {
                    const m = filterList[`$or`].length - 1;
                    let sample: any = {};
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a[0].key3 = String(a[0].key3);
                    }
                    // if (
                    //   selectedField &&
                    //   selectedField.datatype === 'checkbox'
                    // ) {
                    //   a[0].key3 = a[0].key2 == 'true' ? true : false;
                    //   a[0].key2 = '$eq';
                    // }
                    sample[
                      this.isDefault(a[0].entity_name, state)
                        ? `${count}___${a[0].entity_name}~${a[0].key1}`
                        : `${count}___${a[0].key1}`
                    ] =
                      a[0].key2 === ''
                        ? a[0].key3.length === 0
                          ? this.key3Values(selectedField, a[0].key3)
                          : this.set_key3_(a[0])
                        : {
                            [a[0].key2]:
                              a[0].key3.length === 0
                                ? this.key3Values(selectedField, a[0].key3)
                                : this.set_key3_(a[0]) ,
                          };
                    filterList[`$or`][m] = {
                      ...filterList[`$or`][m],
                      ...sample,
                    };
                  }
                }
              }
            }
          }
          // If group has only one element
          else {
            if (dataFilter?.[0].key4 === true) {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r[0].key3 = String(r[0].key3);
                }
              // if (selectedField && selectedField.datatype === 'checkbox') {
              //   r[0].key3 = r[0].key2 == 'true' ? true : false;
              //   r[0].key2 = '$eq';
              // }
              filterList[
                this.isDefault(r[0].entity_name, state)
                  ? `${count}___${r[0].entity_name}~${r[0].key1}`
                  : `${count}___${r[0].key1}`
              ] =
                r[0].key2 === ''
                  ? r[0].key3.length === 0
                    ? this.key3Values(selectedField, r[0].key3)
                    : this.set_key3_(r[0])
                  : {
                      [r[0].key2]:
                        r[0].key3.length === 0
                          ? this.key3Values(selectedField, r[0].key3)
                          : this.set_key3_(r[0]),
                    };
            } else {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r[0].key3 = String(r[0].key3);
                }
              // if (selectedField && selectedField.datatype === 'checkbox') {
              //   r[0].key3 = r[0].key2 == 'true' ? true : false;
              //   r[0].key2 = '$eq';
              // }
              filterList[`${count}___$or`] = [
                ...filterList[`${count}___$or`],
                {
                  [this.isDefault(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1]:
                    r[0].key2 === ''
                      ? r[0].key3.length === 0
                        ? this.key3Values(selectedField, r[0].key3)
                        : this.set_key3_(r[0])
                      : {
                          [r[0].key2]:
                            r[0].key3.length === 0
                              ? this.key3Values(selectedField, r[0].key3)
                              : this.set_key3_(r[0]),
                        },
                },
              ];
            }
          }
        }
        // For first level item
        else {
          // For and
          if (dataFilter?.[0].key4 == true) {
            const fields = this.getField(r.entity_name, state);
            //  this.validateFilterKeyValue(r);
            const selectedField = fields.find(
              (item: any) => item.name === r.key1
            );
            if (selectedField && selectedField.datatype === 'rating') {
                r.key3 = String(r.key3);
              }
            // if (selectedField && selectedField.datatype === 'checkbox') {
            //   r.key3 = r.key2 == 'true' ? true : false;
            //   r.key2 = '$eq';
            // }
            filterList[
              this.isDefault(r.entity_name, state)
                ? `${count}___${r.entity_name}~${r.key1}`
                : `${count}___${r.key1}`
            ] =
              r.key2 === ''
                ? r.key3?.length === 0
                  ? this.key3Values(selectedField, r.key3)
                  :this.set_key3_(r) 
                : {
                    [r.key2]:
                      r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : this.set_key3_(r) ,
                  };
          }
          // For or
          else {
            if (Object.keys(filterList).includes(`$or`)) {
              //this.validateFilterKeyValue(r);
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r.key3 = String(r.key3);
                }
              // if (selectedField && selectedField.datatype === 'checkbox') {
              //   r.key3 = r.key2 == 'true' ? true : false;
              //   r.key2 = '$eq';
              // }
              filterList[`$or`] = [
                ...filterList[`$or`],
                {
                  [this.isDefault(r.entity_name, state)
                    ? `${count}___${r.entity_name}~${r.key1}`
                    : `${count}___${r.key1}`]:
                    r.key2 === ''
                      ? r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : this.set_key3_(r)
                      : {
                          [r.key2]:
                            r.key3.length === 0
                              ? this.key3Values(selectedField, r.key3)
                              : this.set_key3_(r) ,
                        },
                },
              ];
            } else {
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r.key3 = String(r.key3);
                }
              // if (selectedField && selectedField.datatype === 'checkbox') {
              //   r.key3 = r.key2 == 'true' ? true : false;
              //   r.key2 = '$eq';
              // }
              filterList[`$or`] = [
                {
                  [this.isDefault(r.entity_name, state)
                    ? `${count}___${r.entity_name}~${r.key1}`
                    : `${count}___${r.key1}`]:
                    r.key2 === ''
                      ? r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : this.set_key3_(r)
                      : {
                          [r.key2]:
                            r.key3.length === 0
                              ? this.key3Values(selectedField, r.key3)
                              : this.set_key3_(r) ,
                        },
                },
              ];
            }
          }
        }
      }
    }
    return filterList;
  }
  reorderJson(filterJson: any): any {
    const keys = Object.keys(filterJson);
    const sortedKeys = keys.sort((a, b) => {
      const aPrefix = parseInt(a.split('___')[0], 10);
      const bPrefix = parseInt(b.split('___')[0], 10);
      if (isNaN(aPrefix)) return 1;
      if (isNaN(bPrefix)) return -1;

      return aPrefix - bPrefix;
    });
    const reorderedJson: any = {};
    sortedKeys.forEach((key) => {
      reorderedJson[key] = filterJson[key];
    });

    return reorderedJson;
  }
  getReverseFilterValues(filterJson: any, state: any, tabName: any) {
    this.get_entities_data(state);
    let reorder = this.reorderJson(filterJson);
    filterJson = reorder;
    let resultArray: any[] = [];
    
    Object.entries(filterJson).forEach(([key, value], index) => {
      let entity_name: string = '';
      if (key === '$or' && index !== 0) {
        // Handle $or conditions
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue)
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                if(entity_name.includes('___')){
                  let entityName = entity_name.split('___')
                  entity_name = entityName[1]
                }
                const fields = this.getField(entity_name,state);
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  // if (selectedField && selectedField.datatype === 'checkbox') {
                  //   nestedOrCondition.push({
                  //     key1: nestedKey,
                  //     key2: val ? 'true' : 'false',
                  //     key3: '',
                  //     key4: false,
                  //     entity_name: entity_name,
                  //   });
                  // } else {
                  // if( val && typeof val === 'array'){

                  // }
                    if (Array.isArray(val)) {
                      val = val[0]
                    }
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: false,
                      entity_name: entity_name,
                    });
                  // }
                });
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                if (Array.isArray(nestedValue)) {
                  nestedValue = nestedValue[0]
                }
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );
          if (nestedOrCondition.length > 1) {
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray.push(orArray);
      } else if (key === '$or' && index == 0) {
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue) &&
                nestedKey !== '$or'
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                if(entity_name.includes('___')){
                  let entityName = entity_name.split('___')
                  entity_name = entityName[1]
                }
                const fields = this.getField(entity_name,state);
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  // if (selectedField && selectedField.datatype === 'checkbox') {
                  //   nestedOrCondition.push({
                  //     key1: nestedKey,
                  //     key2: val ? 'true' : 'false',
                  //     key3: '',
                  //     key4: true,
                  //     entity_name: entity_name,
                  //   });
                  // } else {
                    if (Array.isArray(val)) {
                      val = val[0]
                    }

                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: false,
                      entity_name: entity_name,
                    });
                  // }
                });
              } else if (nestedKey === '$or') {
                let new_or_array:any = [];
                (nestedValue as Array<any>).forEach((orCondition) => {
                  Object.entries(orCondition).forEach(
                    ([nestedKey, nestedValue]: any) => {
                      if (
                        typeof nestedValue === 'object' &&
                        !Array.isArray(nestedValue)
                      ) {
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          entity_name = tabName
                        }
                        // Nested condition with operator
                        Object.entries(nestedValue).forEach(([operator, val]) => {
                          let key_value = nestedKey.split('___');
                          if (key_value?.length > 1) {
                            nestedKey = key_value[1];
                          }
                          if (Array.isArray(val)) {
                            val = val[0]
                          }

                          new_or_array.push({
                            key1: nestedKey,
                            key2: operator,
                            key3: val,
                            key4: false,
                            entity_name: entity_name,
                          });
                        });
                      } else {
                        // Direct condition
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          entity_name = tabName
                        }
                        let key_value = nestedKey.split('___');
                        if (key_value?.length > 1) {
                          nestedKey = key_value[1];
                        }
                        if (Array.isArray(nestedValue)) {
                          nestedValue = nestedValue[0]
                        }

                        new_or_array.push({
                          key1: nestedKey,
                          key2: '',
                          key3: nestedValue,
                          key4: false,
                          entity_name: entity_name,
                        });
                      }                    
                    }
                  );
                });
                if(new_or_array.length>0){                  
                  nestedOrCondition.push(new_or_array)
                }
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                if (Array.isArray(nestedValue)) {
                  nestedValue = nestedValue[0]
                }
                
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );
          if (nestedOrCondition.length > 1) {  
            if(!(orArray[0].key4)){
              nestedOrCondition.forEach((_e:any)=>_e.key4 = true)
            }          
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray = orArray;
        // resultArray.push(orArray);
      } else {
        // Direct condition
        if (typeof value === 'object' && !Array.isArray(value)) {
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            entity_name = tabName
          }
          if(entity_name.includes('___')){
            let entityName = entity_name.split('___')
            entity_name = entityName[1]
          }
          const fields = this.getField(entity_name,state);
          const selectedField = fields.find((item: any) => item.name === key);
          // Condition with operator
          Object.entries(value as any).forEach(([operator, val]) => {
            let key_value = key.split('___');
            if (key_value?.length > 1) {
              key = key_value[1];
            }
            // if (selectedField && selectedField.datatype === 'checkbox') {
            //   resultArray.push({
            //     key1: key,
            //     key2: val ? 'true' : 'false',
            //     key3: '',
            //     key4: true,
            //     entity_name: entity_name,
            //   });
            // } else {
              if (Array.isArray(val)) {
                val = val[0]
              }

              resultArray.push({
                key1: key,
                key2: operator,
                key3: val,
                key4: true,
                entity_name: entity_name,
              });
            // }
          });
        } else {
          // Direct condition without operator
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            entity_name = tabName
          }
          let key_value = key.split('___');
          if (key_value?.length > 1) {
            key = key_value[1];
          }
          if (Array.isArray(value)) {
            value = value[0]
          }
          
          resultArray.push({
            key1: key,
            key2: '',
            key3: value,
            key4: true,
            entity_name: entity_name,
          });
        }
      }
    });
    return resultArray;
  }
  getSelectedField(name: string, fields: any) {
    let selected_field = fields?.find((_e: any) => _e?.name === name);
    return selected_field;
  }
  get_user_op_field_data(filter: any, state: any, user_field: any) {
    this.get_entities_data(state);
    let convert_filter = [];
    for (let value of filter) {
      if (this.Array.isArray(value)) {
        for (let data of value) {
          if (!this.Array.isArray(data)) {
            let user_selectedField = this.getSelectedField(
              data.key3,
              user_field
            );
            let list_value = {
              op: data?.key2?.replace('$', ''),
              uf: data.key3,
              dtype: this.updateUserFieldType(user_selectedField?.datatype),
            };
            convert_filter.push(list_value);
          }
        }
      } else {
        let user_selectedField = this.getSelectedField(value.key3, user_field);
        let list_value = {
          op: value?.key2?.replace('$', ''),
          uf: value.key3,
          dtype: this.updateUserFieldType(user_selectedField?.datatype),
        };
        convert_filter.push(list_value);
      }
    }
    return convert_filter;
  }

  updateUserFieldType(value: any) {
    if (value === 'date') {
      return 'date';
    } else if (
      value === 'number' ||
      value === 'currency' ||
      value === 'rating' ||
      value === 'progress' ||
      value === 'time'
    ) {
      return 'number';
    } else if (
      value === 'dropdown_multi_select' ||
      value === 'people' ||
      value === 'tag'
    ) {
      return 'array';
    } else if (value === 'checkbox') {
      return 'boolean';
    } else {
      return 'string';
    }
  }
  convertimportFilterValueToJson(dataFilter: any, state: any,total_fields:any) {
    let filterList: any = {};
    if (dataFilter.length > 0 && dataFilter[0].key1 == '') {
      filterList = {};
    } else {
      let count = 0;
      for (let r of dataFilter) {
        count++;
        let is_initial = false;
        // For first level group
        if (this.Array.isArray(r)) {
          if (r.length > 1) {
            // For group or
            if (dataFilter?.[0].key4 === true) {
              for (let a of r) {
                // For group item
                if (!this.Array.isArray(a)) {
                  //this.validateFilterKeyValue(a);
                  if (Object.keys(filterList).includes(`$or`)) {
                    count++;
                    const fields = total_fields;
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a.key3 = String(a.key3);
                    }
                    if (
                      selectedField &&
                      selectedField.datatype === 'checkbox'
                    ) {
                      a.key3 = a.key2 == 'true' ? true : false;
                        a.key2 = '$eq';
                    }
                    filterList[`$or`] = [
                      ...filterList[`$or`],
                      {
                        [this.isDefaults(a.entity_name, state)
                          ? `${count}___${a.entity_name}~${a.key1}`
                          : `${count}___${a.key1}`]:
                          a.key2 === ''
                            ? a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              : a.key3
                            : {
                                [a.key2]:
                                  a.key3.length === 0
                                    ? this.key3Values(selectedField, a.key3)
                                    : a.key3,
                              },
                      },
                    ];
                  } else {
                    const fields = total_fields;
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a.key3 = String(a.key3);
                    }
                    if (
                      selectedField &&
                      selectedField.datatype === 'checkbox'
                    ) {
                      a.key3 = a.key2 == 'true' ? true : false;
                        a.key2 = '$eq';
                    }
                    filterList[`$or`] = [
                      {
                        [this.isDefaults(a.entity_name, state)
                          ? `${count}___${a.entity_name}~${a.key1}`
                          : `${count}___${a.key1}`]:
                          a.key2 === ''
                            ? a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              : a.key3
                            : {
                                [a.key2]:
                                  a.key3.length === 0
                                    ? this.key3Values(selectedField, a.key3)
                                    : a.key3,
                              },
                      },
                    ];
                  }
                }
                // For group -> child group
                else {
                  if (a.length > 1) {
                    // For and
                    if (dataFilter?.[0].key4 === true) {
                      filterList['$or'].push({});
                      const m = filterList[`$or`]?.length - 1;
                      for (let b of a) {
                        count++;
                        const fields = total_fields;
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        if (
                          selectedField &&
                          selectedField.datatype === 'checkbox'
                        ) {
                          b.key3 = b.key2 == 'true' ? true : false;
                            b.key2 = '$eq';
                        }
                        filterList[`$or`][m][
                          this.isDefaults(b.entity_name, state)
                            ? `${count}___${b.entity_name}~${b.key1}`
                            : `${count}___${b.key1}`
                        ] =
                          b.key2 === ''
                            ? b.key3.length === 0
                              ? this.key3Values(selectedField, b.key3)
                              : b.key3
                            : {
                                [b.key2]:
                                  b.key3.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3,
                              };
                      }
                    }
                  } else {
                    const fields = total_fields;
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a[0].key3 = String(a[0].key3);
                    }
                    if (
                      selectedField &&
                      selectedField.datatype === 'checkbox'
                    ) {
                      a[0].key3 = a[0].key2 == 'true' ? true : false;
                        a[0].key2 = '$eq';
                    }
                    filterList[`${count}___$or`] = [
                      ...filterList[`${count}___$or`],
                      {
                        [this.isDefaults(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1]:
                          a[0].key2 === ''
                            ? a[0].key3.length === 0
                              ? this.key3Values(selectedField, a[0].key3)
                              : a[0].key3
                            : {
                                [a[0].key2]:
                                  a[0].key3.length === 0
                                    ? this.key3Values(selectedField, a[0].key3)
                                    : a[0].key3,
                              },
                      },
                    ];
                  }
                }
              }
            }
            // For group and
            else {
              filterList?.['$or'].push({});
              const n = filterList?.[`$or`]?.length - 1;
              for (let a of r) {
                if (!this.Array.isArray(a)) {
                  if (is_initial) {
                    count++;
                  }
                  is_initial = true;
                  //this.validateFilterKeyValue(a);
                  const fields = total_fields;
                  const selectedField = fields.find(
                    (item: any) => item.name === a.key1
                  );
                  if (selectedField && selectedField.datatype === 'rating') {
                      a.key3 = String(a.key3);
                  }
                  if (selectedField && selectedField.datatype === 'checkbox') {
                    a.key3 = a.key2 == 'true' ? true : false;
                      a.key2 = '$eq';
                  }
                  filterList[`$or`][n][
                    this.isDefaults(a.entity_name, state)
                      ? `${count}___${a.entity_name}~${a.key1}`
                      : `${count}___${a.key1}`
                  ] =
                    a.key2 === ''
                      ? a.key3.length === 0
                        ? this.key3Values(selectedField, a.key3)
                        : a.key3
                      : {
                          [a.key2]:
                            a.key3.length === 0
                              ? this.key3Values(selectedField, a.key3)
                              : a.key3,
                        };
                } else {
                  if (a.length > 1) {
                    const m = filterList[`$or`].length - 1;
                    for (let b of a) {
                      count++;
                      if (Object.keys(filterList[`$or`][m]).includes(`$or`)) {
                        const fields = total_fields;
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        if (
                          selectedField &&
                          selectedField.datatype === 'checkbox'
                        ) {
                          b.key3 = b.key2 == 'true' ? true : false;
                            b.key2 = '$eq';
                        }
                        filterList[`$or`][m][`$or`] = [
                          ...filterList[`$or`][m][`$or`],
                          {
                            [this.isDefaults(b.entity_name, state)
                              ? `${count}___${b.entity_name}~${b.key1}`
                              : `${count}___${b.key1}`]:
                              b.key2 === ''
                                ? b.key3.length === 0
                                  ? this.key3Values(selectedField, b.key3)
                                  : b.key3
                                : {
                                    [b.key2]:
                                      b.key3.length === 0
                                        ? this.key3Values(selectedField, b.key3)
                                        : b.key3,
                                  },
                          },
                        ];
                      } else {
                        const fields = total_fields;
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          selectedField &&
                          selectedField.datatype === 'rating'
                        ) {
                            b.key3 = String(b.key3);
                        }
                        if (
                          selectedField &&
                          selectedField.datatype === 'checkbox'
                        ) {
                          b.key3 = b.key2 == 'true' ? true : false;
                            b.key2 = '$eq';
                        }
                        filterList[`$or`][m][`$or`] = [
                          {
                            [this.isDefaults(b.entity_name, state)
                              ? `${count}___${b.entity_name}~${b.key1}`
                              : `${count}___${b.key1}`]:
                              b.key2 === ''
                                ? b.key3.length === 0
                                  ? this.key3Values(selectedField, b.key3)
                                  : b.key3
                                : {
                                    [b.key2]:
                                      b.key3.length === 0
                                        ? this.key3Values(selectedField, b.key3)
                                        : b.key3,
                                  },
                          },
                        ];
                      }
                    }
                  } else {
                    const m = filterList[`$or`].length - 1;
                    let sample: any = {};
                    const fields = total_fields;
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (selectedField && selectedField.datatype === 'rating') {
                        a[0].key3 = String(a[0].key3);
                    }
                    if (
                      selectedField &&
                      selectedField.datatype === 'checkbox'
                    ) {
                      a[0].key3 = a[0].key2 == 'true' ? true : false;
                        a[0].key2 = '$eq';
                    }
                    sample[
                      this.isDefaults(a[0].entity_name, state)
                        ? `${count}___${a[0].entity_name}~${a[0].key1}`
                        : `${count}___${a[0].key1}`
                    ] =
                      a[0].key2 === ''
                        ? a[0].key3.length === 0
                          ? this.key3Values(selectedField, a[0].key3)
                          : a[0].key3
                        : {
                            [a[0].key2]:
                              a[0].key3.length === 0
                                ? this.key3Values(selectedField, a[0].key3)
                                : a[0].key3,
                          };
                    filterList[`$or`][m] = {
                      ...filterList[`$or`][m],
                      ...sample,
                    };
                  }
                }
              }
            }
          }
          // If group has only one element
          else {
            if (dataFilter?.[0].key4 === true) {
              const fields = total_fields;
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r[0].key3 = String(r[0].key3);
              }
              if (selectedField && selectedField.datatype === 'checkbox') {
                r[0].key3 = r[0].key2 == 'true' ? true : false;
                  r[0].key2 = '$eq';
              }
              filterList[
                this.isDefaults(r[0].entity_name, state)
                  ? `${count}___${r[0].entity_name}~${r[0].key1}`
                  : `${count}___${r[0].key1}`
              ] =
                r[0].key2 === ''
                  ? r[0].key3.length === 0
                    ? this.key3Values(selectedField, r[0].key3)
                    : r[0].key3
                  : {
                      [r[0].key2]:
                        r[0].key3.length === 0
                          ? this.key3Values(selectedField, r[0].key3)
                          : r[0].key3,
                    };
            } else {
              const fields = total_fields;
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r[0].key3 = String(r[0].key3);
              }
              if (selectedField && selectedField.datatype === 'checkbox') {
                r[0].key3 = r[0].key2 == 'true' ? true : false;
                  r[0].key2 = '$eq';
              }
              filterList[`${count}___$or`] = [
                ...filterList[`${count}___$or`],
                {
                  [this.isDefaults(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1]:
                    r[0].key2 === ''
                      ? r[0].key3.length === 0
                        ? this.key3Values(selectedField, r[0].key3)
                        : r[0].key3
                      : {
                          [r[0].key2]:
                            r[0].key3.length === 0
                              ? this.key3Values(selectedField, r[0].key3)
                              : r[0].key3,
                        },
                },
              ];
            }
          }
        }
        // For first level item
        else {
          // For and
          if (dataFilter?.[0].key4 == true) {
            const fields = total_fields;
            //  this.validateFilterKeyValue(r);
            const selectedField = fields.find(
              (item: any) => item.name === r.key1
            );
            if (selectedField && selectedField.datatype === 'rating') {
                r.key3 = String(r.key3);
            }
            if (selectedField && selectedField.datatype === 'checkbox') {
              r.key3 = r.key2 == 'true' ? true : false;
                r.key2 = '$eq';
            }
            filterList[
              this.isDefaults(r.entity_name, state)
                ? `${count}___${r.entity_name}~${r.key1}`
                : `${count}___${r.key1}`
            ] =
              r.key2 === ''
                ? r.key3?.length === 0
                  ? this.key3Values(selectedField, r.key3)
                  : r.key3
                : {
                    [r.key2]:
                      r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : r.key3,
                  };
          }
          // For or
          else {
            if (Object.keys(filterList).includes(`$or`)) {
              //this.validateFilterKeyValue(r);
              const fields = total_fields;
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r.key3 = String(r.key3);
              }
              if (selectedField && selectedField.datatype === 'checkbox') {
                r.key3 = r.key2 == 'true' ? true : false;
                  r.key2 = '$eq';
              }
              filterList[`$or`] = [
                ...filterList[`$or`],
                {
                  [this.isDefaults(r.entity_name, state)
                    ? `${count}___${r.entity_name}~${r.key1}`
                    : `${count}___${r.key1}`]:
                    r.key2 === ''
                      ? r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : r.key3
                      : {
                          [r.key2]:
                            r.key3.length === 0
                              ? this.key3Values(selectedField, r.key3)
                              : r.key3,
                        },
                },
              ];
            } else {
              const fields = total_fields;
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (selectedField && selectedField.datatype === 'rating') {
                  r.key3 = String(r.key3);
              }
              if (selectedField && selectedField.datatype === 'checkbox') {
                r.key3 = r.key2 == 'true' ? true : false;
                  r.key2 = '$eq';
              }
              filterList[`$or`] = [
                {
                  [this.isDefaults(r.entity_name, state)
                    ? `${count}___${r.entity_name}~${r.key1}`
                    : `${count}___${r.key1}`]:
                    r.key2 === ''
                      ? r.key3.length === 0
                        ? this.key3Values(selectedField, r.key3)
                        : r.key3
                      : {
                          [r.key2]:
                            r.key3.length === 0
                              ? this.key3Values(selectedField, r.key3)
                              : r.key3,
                        },
                },
              ];
            }
          }
        }
      }
    }
    return filterList;
  }
  set_key3_value_import(value:any,field:any){
    
    if(['currency', 'progress', 'number', 'rating', 'date', 'time'].includes(
      field.datatype)){
        return Number(value.key3)
      }else{
        return value.key3
      }
    
  }
  isDefaults(data:any,state:any){
    return false
  }
  
  getReverseimportFilterValue(filterJson: any,total_fields:any) {
    let resultArray: any[] = [];
    let reorder = this.reorderJson(filterJson);
    filterJson = reorder;
    // Handle direct properties
    Object.entries(filterJson).forEach(([key, value], index) => {
      let entity_name: string = '';
      if (key === '$or' && index !== 0) {
        // Handle $or conditions
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue)
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  // entity_name = tabName
                }
                const fields = total_fields;
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  if (selectedField && selectedField.datatype === 'checkbox') {
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: val ? 'true' : 'false',
                      key3: '',
                      key4: false,
                      entity_name: entity_name,
                    });
                  } else {
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: false,
                      entity_name: entity_name,
                    });
                  }
                });
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  // entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );
          if (nestedOrCondition.length > 1) {
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray.push(orArray);
      } else if (key === '$or' && index == 0) {
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue) &&
                nestedKey !== '$or'
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  // entity_name = tabName
                }
                const fields = total_fields;
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  if (selectedField && selectedField.datatype === 'checkbox') {
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: val ? 'true' : 'false',
                      key3: '',
                      key4: true,
                      entity_name: entity_name,
                    });
                  } else {
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: true,
                      entity_name: entity_name,
                    });
                  }
                });
              } else if (nestedKey === '$or') {
                let new_or_array:any = [];
                (nestedValue as Array<any>).forEach((orCondition) => {
                  Object.entries(orCondition).forEach(
                    ([nestedKey, nestedValue]: any) => {
                      if (
                        typeof nestedValue === 'object' &&
                        !Array.isArray(nestedValue)
                      ) {
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          // entity_name = tabName
                        }
                        // Nested condition with operator
                        Object.entries(nestedValue).forEach(([operator, val]) => {
                          let key_value = nestedKey.split('___');
                          if (key_value?.length > 1) {
                            nestedKey = key_value[1];
                          }
                          new_or_array.push({
                            key1: nestedKey,
                            key2: operator,
                            key3: val,
                            key4: false,
                            entity_name: entity_name,
                          });
                        });
                      } else {
                        // Direct condition
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          // entity_name = tabName
                        }
                        let key_value = nestedKey.split('___');
                        if (key_value?.length > 1) {
                          nestedKey = key_value[1];
                        }
                        new_or_array.push({
                          key1: nestedKey,
                          key2: '',
                          key3: nestedValue,
                          key4: false,
                          entity_name: entity_name,
                        });
                      }                    
                    }
                  );
                });
                if(new_or_array.length>0){                  
                  nestedOrCondition.push(new_or_array)
                }
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  // entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );

          if (nestedOrCondition.length > 1) {
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray = orArray;
        // resultArray.push(orArray);
      } else {
        // Direct condition
        if (typeof value === 'object' && !Array.isArray(value)) {
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            // entity_name = tabName
          }
          const fields = total_fields;
          const selectedField = fields.find((item: any) => item.name === key);
          // Condition with operator
          Object.entries(value as any).forEach(([operator, val]) => {
            let key_value = key.split('___');
            if (key_value?.length > 1) {
              key = key_value[1];
            }
            if (selectedField && selectedField.datatype === 'checkbox') {
              resultArray.push({
                key1: key,
                key2: val ? 'true' : 'false',
                key3: '',
                key4: true,
                entity_name: entity_name,
              });
            } else {
              resultArray.push({
                key1: key,
                key2: operator,
                key3: val,
                key4: true,
                entity_name: entity_name,
              });
            }
          });
        } else {
          // Direct condition without operator
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            // entity_name = tabName
          }
          let key_value = key.split('___');
          if (key_value?.length > 1) {
            key = key_value[1];
          }
          resultArray.push({
            key1: key,
            key2: '',
            key3: value,
            key4: true,
            entity_name: entity_name,
          });
        }
      }
    });

    return resultArray;
  }
  get_ca_ReverseFilterValues(filterJson: any, state: any, tabName: any) {
    this.get_entities_data(state);
    let reorder = this.reorderJson(filterJson);
    filterJson = reorder;
    let resultArray: any[] = [];
    
    Object.entries(filterJson).forEach(([key, value], index) => {
      let entity_name: string = '';
      if (key === '$or' && index !== 0) {
        // Handle $or conditions
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue)
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                const fields = this.getField(entity_name,state);
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  // if (selectedField && selectedField.datatype === 'checkbox') {
                  //   nestedOrCondition.push({
                  //     key1: nestedKey,
                  //     key2: val ? 'true' : 'false',
                  //     key3: '',
                  //     key4: false,
                  //     entity_name: entity_name,
                  //   });
                  // } else {
                  // if( val && typeof val === 'array'){

                  // }
                  // if (Array.isArray(val)) {
                  //   val = val[0]
                  // }
                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: false,
                      entity_name: entity_name,
                    });
                  // }
                });
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                // if (Array.isArray(nestedValue)) {
                //   nestedValue = nestedValue[0]
                // }
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );
          if (nestedOrCondition.length > 1) {
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray.push(orArray);
      } else if (key === '$or' && index == 0) {
        let orArray: any[] = [];
        (value as Array<any>).forEach((orCondition) => {
          let nestedOrCondition: any[] = [];
          Object.entries(orCondition).forEach(
            ([nestedKey, nestedValue]: any) => {
              if (
                typeof nestedValue === 'object' &&
                !Array.isArray(nestedValue) &&
                nestedKey !== '$or'
              ) {
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                if(entity_name.includes('___')){
                  let entityName = entity_name.split('___')
                  entity_name = entityName[1]
                }
                const fields = this.getField(entity_name,state);
                const selectedField = fields.find(
                  (item: any) => item.name === nestedKey
                );
                // Nested condition with operator
                Object.entries(nestedValue).forEach(([operator, val]) => {
                  let key_value = nestedKey.split('___');
                  if (key_value?.length > 1) {
                    nestedKey = key_value[1];
                  }
                  // if (selectedField && selectedField.datatype === 'checkbox') {
                  //   nestedOrCondition.push({
                  //     key1: nestedKey,
                  //     key2: val ? 'true' : 'false',
                  //     key3: '',
                  //     key4: true,
                  //     entity_name: entity_name,
                  //   });
                  // } else {
                    // if (Array.isArray(val)) {
                    //   val = val[0]
                    // }

                    nestedOrCondition.push({
                      key1: nestedKey,
                      key2: operator,
                      key3: val,
                      key4: false,
                      entity_name: entity_name,
                    });
                  // }
                });
              } else if (nestedKey === '$or') {
                let new_or_array:any = [];
                (nestedValue as Array<any>).forEach((orCondition) => {
                  Object.entries(orCondition).forEach(
                    ([nestedKey, nestedValue]: any) => {
                      if (
                        typeof nestedValue === 'object' &&
                        !Array.isArray(nestedValue)
                      ) {
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          entity_name = tabName
                        }
                        // Nested condition with operator
                        Object.entries(nestedValue).forEach(([operator, val]) => {
                          let key_value = nestedKey.split('___');
                          if (key_value?.length > 1) {
                            nestedKey = key_value[1];
                          }
                          // if (Array.isArray(val)) {
                          //   val = val[0]
                          // }

                          new_or_array.push({
                            key1: nestedKey,
                            key2: operator,
                            key3: val,
                            key4: false,
                            entity_name: entity_name,
                          });
                        });
                      } else {
                        // Direct condition
                        let splitKeyValue = nestedKey.split('~');
                        if (splitKeyValue?.length === 2) {
                          entity_name = splitKeyValue?.[0];
                          nestedKey = splitKeyValue?.[1];
                        } else {
                          entity_name = tabName
                        }
                        let key_value = nestedKey.split('___');
                        if (key_value?.length > 1) {
                          nestedKey = key_value[1];
                        }
                        // if (Array.isArray(nestedValue)) {
                        //   nestedValue = nestedValue[0]
                        // }

                        new_or_array.push({
                          key1: nestedKey,
                          key2: '',
                          key3: nestedValue,
                          key4: false,
                          entity_name: entity_name,
                        });
                      }                    
                    }
                  );
                });
                if(new_or_array.length>0){                  
                  nestedOrCondition.push(new_or_array)
                }
              } else {
                // Direct condition
                let splitKeyValue = nestedKey.split('~');
                if (splitKeyValue?.length === 2) {
                  entity_name = splitKeyValue?.[0];
                  nestedKey = splitKeyValue?.[1];
                } else {
                  entity_name = tabName
                }
                let key_value = nestedKey.split('___');
                if (key_value?.length > 1) {
                  nestedKey = key_value[1];
                }
                // if (Array.isArray(nestedValue)) {
                //   nestedValue = nestedValue[0]
                // }
                
                nestedOrCondition.push({
                  key1: nestedKey,
                  key2: '',
                  key3: nestedValue,
                  key4: false,
                  entity_name: entity_name,
                });
              }
            }
          );
          if (nestedOrCondition.length > 1) {  
            if(!(orArray[0].key4)){
              nestedOrCondition.forEach((_e:any)=>_e.key4 = true)
            }          
            orArray.push(nestedOrCondition);
          } else {
            orArray = orArray.concat(nestedOrCondition);
          }
        });
        resultArray = orArray;
        // resultArray.push(orArray);
      } else {
        // Direct condition
        if (typeof value === 'object' && !Array.isArray(value)) {
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            entity_name = tabName
          }
          if(entity_name.includes('___')){
            let entityName = entity_name.split('___')
            entity_name = entityName[1]
          }
          const fields = this.getField(entity_name,state);
          const selectedField = fields.find((item: any) => item.name === key);
          // Condition with operator
          Object.entries(value as any).forEach(([operator, val]) => {
            let key_value = key.split('___');
            if (key_value?.length > 1) {
              key = key_value[1];
            }
            // if (selectedField && selectedField.datatype === 'checkbox') {
            //   resultArray.push({
            //     key1: key,
            //     key2: val ? 'true' : 'false',
            //     key3: '',
            //     key4: true,
            //     entity_name: entity_name,
            //   });
            // } else {
              // if (Array.isArray(val)) {
              //   val = val[0]
              // }

              resultArray.push({
                key1: key,
                key2: operator,
                key3: val,
                key4: true,
                entity_name: entity_name,
              });
            // }
          });
        } else {
          // Direct condition without operator
          let splitKeyValue = key.split('~');
          if (splitKeyValue?.length === 2) {
            entity_name = splitKeyValue?.[0];
            key = splitKeyValue?.[1];
          } else {
            entity_name = tabName
          }
          let key_value = key.split('___');
          if (key_value?.length > 1) {
            key = key_value[1];
          }
          // if (Array.isArray(value)) {
          //   value = value[0]
          // }
          
          resultArray.push({
            key1: key,
            key2: '',
            key3: value,
            key4: true,
            entity_name: entity_name,
          });
        }
      }
    });
    return resultArray;
  }
  convert_entity_filter_to_json(filters: any, state:any) {
    this.entitiesData = []
    this.get_entities_data(state);
    let filterList: any = {};
    if (filters?.length > 0 && filters[0]?.key1 == '') {
      filterList = {};
    } else {
      // this.pservice.updateStoreMetadata(filters, 'filter');
      for (let r of filters) {
        const fields = this.getField(r.entity_name, state);
        let findFieldData = fields?.find((_e: any) => _e.name === r.key1);
        if (
          findFieldData?.datatype === 'currency' ||
          findFieldData?.datatype === 'progress' ||
          findFieldData?.datatype === 'number' ||
          findFieldData?.datatype === 'rating'
        ) {
          r.key3 = r.key3 ? Number(r.key3) : r.key3;
        }
        // For first level group
        if (this.Array.isArray(r)) {
          if (r.length > 1) {
            // For group or
            if (filters?.[0].key4 === true) {
              for (let a of r) {
                // For group item
                if (!this.Array.isArray(a)) {
                  if (Object.keys(filterList).includes('$or')) {
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2 === '$nnull'?'$null':a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  } else {
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2=== '$nnull'?'$null':a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  }
                }
                // For group -> child group
                else {
                  if (a.length > 1) {
                    // For and
                    if (filters?.[0].key4 === true) {
                      filterList['$or'].push({});
                      const m = filterList['$or'].length - 1;
                      for (let b of a) {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          filterList['$or'][m][
                            this.isDefault(b.entity_name, state)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] = b.key2;
                        } else {
                          filterList['$or'][m][
                            this.isDefault(b.entity_name, state)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] =
                            b.key2 === ''
                              ? b?.key3?.length === 0
                                ? this.key3Values(selectedField, b.key3)
                                : b.key3
                              : {
                                  [b.key2=== '$nnull'?'$null':b.key2]:
                                    b?.key3?.length === 0
                                      ? this.key3Values(selectedField, b.key3)
                                      : b.key3,
                                };
                        }
                      }
                    }
                  } else {
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name, state)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]: a[0].key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name, state)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]:
                            a[0].key2 === ''
                              ? a[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, a[0].key3)
                                : a[0].key3
                              : {
                                  [a[0].key2=== '$nnull'?'$null':a[0].key2]:
                                    a[0]?.key3?.length === 0
                                      ? this.key3Values(
                                          selectedField,
                                          a[0].key3
                                        )
                                      : a[0].key3,
                                },
                        },
                      ];
                    }
                  }
                }
              }
            }
            // For group and
            else {
              filterList?.['$or'].push({});
              const n = filterList?.['$or'].length - 1;
              for (let a of r) {
                if (!this.Array.isArray(a)) {
                  const fields = this.getField(a.entity_name, state);
                  const selectedField = fields.find(
                    (item: any) => item.name === a.key1
                  );
                  if (
                    [
                      'currency',
                      'progress',
                      'number',
                      'rating',
                      'phone',
                    ].includes(selectedField.datatype)
                  ) {
                    a.key3 = a.key3 ? Number(a.key3) : a.key3;
                  }
                  if (selectedField.datatype === 'checkbox') {
                    filterList['$or'][n][
                      this.isDefault(a.entity_name, state)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] = a.key2;
                  } else {
                    filterList['$or'][n][
                      this.isDefault(a.entity_name, state)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] =
                      a.key2 === ''
                        ? a?.key3?.length === 0
                          ? this.key3Values(selectedField, a.key3)
                          : a.key3
                        : {
                            [a.key2 === '$nnull'?'$null':a.key2]:
                              a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3,
                          };
                  }
                } else {
                  if (a.length > 1) {
                    const m = filterList['$or'].length - 1;
                    for (let b of a) {
                      if (
                        Object.keys(filterList['$or'][m]).includes('$or')
                      ) {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          filterList['$or'][m]['$or'] = [
                            ...filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          filterList['$or'][m]['$or'] = [
                            ...filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2=== '$nnull'?'$null':b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      } else {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2=== '$nnull'?'$null':b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      }
                    }
                  } else {
                    const m = filterList['$or'].length - 1;
                    let sample: any = {};
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      sample[
                        this.isDefault(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] = a[0].key2;
                    } else {
                      sample[
                        this.isDefault(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] =
                        a[0].key2 === ''
                          ? a[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, a[0].key3)
                            : a[0].key3
                          : {
                              [a[0].key2 === '$nnull'?'$null':a[0].key2]:
                                a[0]?.key3?.length === 0
                                  ? this.key3Values(selectedField, a[0].key3)
                                  : a[0].key3,
                            };
                    }

                    filterList['$or'][m] = {
                      ...filterList['$or'][m],
                      ...sample,
                    };
                  }
                }
              }
            }
          }
          // If group has only one element
          else {
            if (filters?.[0].key4 === true) {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField.datatype === 'checkbox') {
                filterList[
                  this.isDefault(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] = r[0].key2;
              } else {
                filterList[
                  this.isDefault(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] =
                  r[0].key2 === ''
                    ? r[0]?.key3?.length === 0
                      ? this.key3Values(selectedField, r[0].key3)
                      : r[0].key3
                    : {
                        [r[0].key2 === '$nnull'?'$null':r[0].key2]:
                          r[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, r[0].key3)
                            : r[0].key3,
                      };
              }
            } else {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField.datatype === 'checkbox') {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name, state)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]: r[0].key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name, state)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]:
                      r[0].key2 === ''
                        ? r[0]?.key3?.length === 0
                          ? this.key3Values(selectedField, r[0].key3)
                          : r[0].key3
                        : {
                            [r[0].key2 === '$nnull'?'$null':r[0].key2]:
                              r[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, r[0].key3)
                                : r[0].key3,
                          },
                  },
                ];
              }
            }
          }
        }
        // For first level item
        else {
          // For and
          if (filters?.[0].key4 == true) {
            const fields = this.getField(r.entity_name, state);
            const selectedField = fields?.find(
              (item: any) => item.name === r.key1
            );
            if (
              ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                selectedField?.datatype
              )
            ) {
              r.key3 = r.key3 ? Number(r.key3) : r.key3;
            }
            if (selectedField.datatype === 'checkbox') {
              filterList[
                this.isDefault(r.entity_name, state)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] = r.key2;
            } else {
              filterList[
                this.isDefault(r.entity_name, state)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] =
                r.key2 === ''
                  ? r?.key3?.length === 0
                    ? this.key3Values(selectedField, r.key3)
                    : r.key3
                  : {
                      [r.key2 === '$nnull'?'$null':r.key2]:
                        r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3,
                    };
            }
          }
          // For or
          else {
            if (Object.keys(filterList).includes('$or')) {
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField.datatype === 'checkbox') {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2 === '$nnull'?'$null':r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            } else {
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField.datatype === 'checkbox') {
                filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2 === '$nnull'?'$null':r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            }
          }
        }
      }
    }
    return filterList;
  }
  
}
