import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[auto-focus-input]'
})
export class AutofocusinputDirective {
@Input()nameid?:any
@Output()focusEmit=new EventEmitter()
  private timeoutId: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.timeoutId = setTimeout(() => {
      this.elementRef.nativeElement.focus();
      this.focusEmit.emit(this.nameid)
    }, 100);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
