<ng-container *ngIf="entities().length > 0">
  <ul nz-menu nzMode="horizontal">
    <ng-container *ngFor="let tab of entities(); let i = index">
      <ng-container *ngIf="tab?.display_name">
        <li
          class="f-12"
          nz-menu-item
          (click)="changeTab(tab)"
          [nzSelected]="
            entity_display_name
              ? entity_display_name === tab.display_name
              : i === 0
          "
        >
          {{ tab.display_name }}
        </li>
      </ng-container>
      <ng-container *ngIf="tab?.gd_name">
        <li class="f-12" nz-submenu [nzTitle]="title" [nzPlacement]="'bottomCenter'">
          <ng-template #title>
 
            <div style="display: flex;align-items: center;column-gap: 7px;">
              {{ tab.gd_name }}
              <img class="arrow_icon" src="../../../../assets/images/icons/down_arrow.svg">
            </div>
          </ng-template>
          <ul>
            <ng-container *ngFor="let item of tab.child_data">
              <li              
                class="f-12"
                nz-menu-item
                (click)="changeTab(item)"
                [nzSelected]="
                  entity_display_name
                    ? entity_display_name === item.display_name
                    : i === 0
                "
              >
                {{ item.display_name }}
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</ng-container>

<ng-template #skeletonText>
  <div class="ghost" style="height: 20px; margin: auto; width: 100px"></div>
</ng-template>
