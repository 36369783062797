import { Component, HostBinding } from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-ui-scroll',
  templateUrl: './ui-scroll.component.html',
  styleUrls: ['./ui-scroll.component.scss'],
})
export class UiScrollComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
}
