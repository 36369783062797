import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IUserDto, ILoginResponse } from '@processo/models/IResponse.model';
import { IMicrosoftSSOResponse } from '@processo/models/ISSO.model';
import { JwtService } from '@processo/services/jwt.service';
import { TokenService } from '@processo/services/token.service';
import { StateService } from '@processo/shared/state/state.service';
import { environment } from 'projects/processo/src/environments/environment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  from,
  map,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private tokenService: TokenService,
    private jwt: JwtService,
    private state: StateService,
  ) {}
  private currentUserSubject = new BehaviorSubject<IUserDto | null>(null);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public isAuthenticated = this.currentUser.pipe(map((user) => !!user));
  private childWindow: Window | null = null;
  private intervalId!: ReturnType<typeof setTimeout>;
  router = inject(Router);
  getSSO_URL(ssoType: string): Observable<IMicrosoftSSOResponse> {
    return this.http.get<IMicrosoftSSOResponse>(
      `${environment.base_eru_auth_url}/processo/${ssoType}/getssourl?state=${window.location.hostname}/login`,
    );
  }

  initiateSSOgl(
    response: IMicrosoftSSOResponse,
    ssoType:string
  ): Observable<string | null | unknown> {
    return from(
      new Promise<string | null>((resolve) => {
        
        const requestId = response.request_id;
        const windowWidth = 500;
        const windowHeight = 500;
        const left = (window.screen.width - windowWidth) / 2;
        const top = (window.screen.height - windowHeight) / 2;
        const childWindowFeatures = `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;
        this.childWindow = window.open(
          response.url,
          '_blank',
          childWindowFeatures,
        );
        this.intervalId = setInterval(() => {
          try {
            if (this.childWindow && this.childWindow.closed === false) {
              const childWindowURL = decodeURIComponent(this.childWindow.location.href);
        
              if(ssoType == 'gl'){
                if (childWindowURL.includes('code=')) {
                  const regex = /code=([^&]+)/;
                  const match = childWindowURL.match(regex);
                  let codeParam = '';
                  if (match && match[1]) {
                    const codeValue = match[1];
                    codeParam = codeValue;
                  } else {
                    codeParam = '';
                  }
                  this.childWindow.close();
                  clearInterval(this.intervalId);
                  resolve(codeParam);
                } else if (childWindowURL.includes('error=')) {
                  console.error('Error:', 'something went wrong.');
                  this.childWindow.close();
  
                  clearInterval(this.intervalId);
                  resolve(null);
                } 
              }
           
            } else if (this.childWindow && this.childWindow.closed === true) {
              clearInterval(this.intervalId);
              resolve(null);
            }
          } catch (e) {}
        }, 500);
      }),
    );
  }

  initiateSSOms(
    response: IMicrosoftSSOResponse
  ): Observable<string | null | unknown> {
    return from(
      new Promise<string | null>((resolve) => {
        const requestId = response.request_id;
        const windowWidth = 500;
        const windowHeight = 500;
        const left = (window.screen.width - windowWidth) / 2;
        const top = (window.screen.height - windowHeight) / 2;
        const childWindowFeatures = `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;
        this.childWindow = window.open(
          response.url,
          '_blank',
          childWindowFeatures,
        );
        this.intervalId = setInterval(() => {
          try {
            if (this.childWindow && this.childWindow.closed === false) {
              const childWindowURL = this.childWindow.location.href;
              if (childWindowURL.includes('#code=')) {
                const regex = /#code=([^&]+)/;
                const match = childWindowURL.match(regex);
                let codeParam = '';
                if (match && match[1]) {
                  const codeValue = match[1];
                  codeParam = codeValue;
                } else {
                  codeParam = '';
                }
                this.childWindow.close();
                clearInterval(this.intervalId);
                resolve(codeParam);
              } else if (childWindowURL.includes('#error=')) {
                console.error('Error:', 'something went wrong.');
                this.childWindow.close();

                clearInterval(this.intervalId);
                resolve(null);
              }
            } else if (this.childWindow && this.childWindow.closed === true) {
              clearInterval(this.intervalId);
              resolve(null);
            }
          } catch (e) {}
        }, 500);
      }),
    );
  }
  loginMicrosoft(code: string, request_id: string) {
    return this.http
      .post<ILoginResponse>(
        `${environment.base_eru_auth_url}/processo/ms/login`,
        {
          request_id,
          code,
        },
      )
      .pipe(
        tap((response) => {
          this.setAuth(response);
        }),
      );
  }
  LoginGL(code:string){
    return this.http.post(
      `${environment.base_eru_auth_url}/processo/gl/login`,{code:code}
    ) .pipe(
      tap((response:any) => {
        this.setAuth(response);
      }),
    );
  }
  getUserInfo(access_token = this.tokenService.getAccessToken()) {
    return this.http
      .post<IUserDto>(`${environment.base_eru_auth_url}/processo/ms/userinfo`, {
        access_token,
      })
      .pipe(
        map((response: IUserDto) => {
          const { id, attributes } = response;
          return { id, ...attributes };
        }),
      );
  }
  setAuth(response: ILoginResponse, navigate = false): void {
    this.tokenService.saveTokens(response);
    const parsedToken = this.jwt.getParsedToken();
    if (
      typeof parsedToken === 'object' &&
      parsedToken !== null &&
      'attributes' in parsedToken
    ) {
      this.state.updateUser(parsedToken);
      if (navigate) {
        this.router.navigate(['register', 'verify-account']);
      }
    } else {
      this.state.updateUser(null);
    }
  }

  registerEru(email: string, password: string) {
    return this.http
      .post<ILoginResponse>(
        `${environment.base_eru_auth_url}/processo/eru/register`,
        { email, password },
      )
      .pipe(
        tap((response) => {
          this.setAuth(response);
        }),
      );
  }

  loginEru(username: string, password: string) {
    return this.http
      .post<ILoginResponse>(
        `${environment.base_eru_auth_url}/processo/eru/login`,
        { username, password },
      )
      .pipe(
        tap((response) => {
          this.setAuth(response);
        }),
      );
  }

  getCurrentUser() {
    return this.currentUserSubject.getValue()?.attributes || {};
  }
  updateToken(refresh_token = this.tokenService.getRefreshToken(),id=this.tokenService.getID()) {
    return this.http
      .post<ILoginResponse>(
        `${environment.base_eru_auth_url}/processo/eru/fetchtokens`,
        { refresh_token,id },
      )
      .pipe(tap((response) => this.setAuth(response)));
  }

  updatePassword(data: any) {
    return this.http.post(
      `${environment.base_eru_auth_url}/processo/eru/changepassword`,
      data,
    );
  }
  //! need to remove below method after refresh token api is fixed
  autoLogin() {
    const parsedToken = this.jwt.getParsedToken();
    if (
      typeof parsedToken === 'object' &&
      parsedToken !== null &&
      'attributes' in parsedToken
    ) {
      this.state.updateUser(parsedToken);
    } else {
      this.state.updateUser(null);
    }
  }
}
