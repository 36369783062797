import { Component, HostBinding, Input } from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-custom-header-template',
  templateUrl: './custom-header-template.component.html',
  styleUrls: ['./custom-header-template.component.scss'],
})
export class CustomHeaderTemplateComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() groupedArray: any;
  @Input() componentState: any;
  @Input() colapsePanel: any;
}
