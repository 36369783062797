<div class="f-14 fw-500 lh-24">
  {{ this.nzModalData?.message }}
</div>
<div class="f-14 fw-500 lh-24 mt-10">
  {{ this.nzModalData?.message2 }}
</div>
<div class="right_end_btn_container">
  <button
  *ngIf="this.nzModalData.btn_left"
    nzType="primary"
    class="save_btn_style"
    type="submit"
    (click)="close('confirm')"
  >
    {{ this.nzModalData.btn_left }}
  </button>
  <button
  *ngIf="this.nzModalData.btn_right"
    nz-button
    nzType="default"
    class="cancel_btn_style"
    (click)="close('cancell')"
  >
    {{ this.nzModalData.btn_right }}
  </button>
</div>
