import { Component } from '@angular/core';
import { environment } from 'projects/processo/src/environments/environment';
@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  environment=environment
}
