import { Component, Input } from '@angular/core';
import { FilterConvertorServiceService } from '@processo/services/filter-convertor-service.service';
import { StateService } from '@processo/shared/state/state.service';
import { cloneDeep } from 'lodash';
import { map, Observable, shareReplay } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-record-visibility-filter',
  templateUrl: './record-visibility-filter.component.html',
  styleUrls: ['./record-visibility-filter.component.scss'],
})
export class RecordVisibilityFilterComponent {
  totalFields: any = [];
  moment = moment;
  Array = Array;
  @Input() from_value!: any;
  savePeopleList: any;
  datatypesOperator: any = {
    dropdown_single_select: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    dropdown_multi_select: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    status: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    location: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
    ],
    textarea: [
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    checkbox: [
      { label: 'is selected', value: 'true' },
      { label: 'is not selected', value: 'false' },
    ],
    rating: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    currency: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    number: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    labels: [
      { label: 'is', value: '$in' },
      { label: 'is not', value: '$nin' },
    ],
    website: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    people: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    phone: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    progress: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    date: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'is after or equal', value: '$gte' },
      { label: 'is before or equal', value: '$lte' },
      { label: 'is after', value: '$gt' },
      { label: 'is before', value: '$lt' },
    ],
    textbox: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    email: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    tag: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    time: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    priority: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
  };
  userFields: any = [];
  @Input() file_data: any;
  getEntityptionList$: any;

  @Input() filterby!: any;
  @Input() entitiesData!: any;
  @Input() tabName!: any;
  constructor(
    private stateService: StateService,
    private filter_service: FilterConvertorServiceService
  ) {}
  ngOnInit(): void {
    this.tabName = this.stateService?.appState()?.selectedEntity;
    this.userFields = this.file_data?.fields;
  }
  addFilterOne() {
    this.filterby.push({
      key1:null,
      key2: null,
      key3: null,
      key4: this.filterby[0].key4,
      entity_name: this.tabName,
    });
  }
  addGroupOne() {
    this.filterby.push([
      {
        key1: null,
        key2: null,
        key3: null,
        key4: !this.filterby[0].key4,
        entity_name: this.tabName,
      },
    ]);
  }
  changeOperatorAdd(e: any, i: any) {
    this.filterby.map((item: any, index: any) => {
      if (!this.Array.isArray(item) && index != 0) {
        item.key4 = e;
      } else {
        if (this.Array.isArray(item)) {
          for (let i of item) {
            if (!this.Array.isArray(i)) {
              i.key4 = !e;
            } else {
              if (this.Array.isArray(i)) {
                for (let j of i) {
                  j.key4 = e;
                }
              }
            }
          }
        }
      }
    });
  }
  deleteGroupFilter(item: any, index: any) {
    if (this.filterby[item].length > 1) {
      this.filterby[item].splice(index, 1);
    } else {
      const index = this.filterby.splice(item, 1);
    }
    this.submit();
  }
  addNewGroupFilter(i: any) {
    this.filterby[i].push({
      key1: null,
      key2: null,
      key3: null,
      key4: this.filterby[i][0].key4,
      entity_name: this.tabName,
    });
  }
  selectedEntities($event: any, filter: any) {
    if ($event) {
      filter.key1 = null;
      filter.key2 = null;
      filter.key3 = null;
      filter.key4 = true;
    }
  }
  getFields(searchField: any, entityName: any): any {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === entityName
    )?.entity_name;
    const fields = this.stateService
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
      ?.fields?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
    this.totalFields = fields;
    let filterFields = fields
      ?.filter(
        (e: any) =>
          e?.datatype !== 'entity' &&
          e?.datatype !== 'textarea' &&
          e?.datatype !== 'attachment'
      )
      ?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
    if (filterFields && searchField !== '') {
      return filterFields?.filter((e: any) =>
        e.label?.toLowerCase()?.includes(searchField?.toLowerCase())
      );
    } else {
      return filterFields;
    }
  }
  filterTypeFieldChange(event: any, i: any, entityName: any) {
    const fields = this.getFields('', entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filterby[i].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filterby[i].key3 = null;
      }
      this.filterby[i].key2 =
        this.getOperators(this.filterby[i]?.key1, entityName)?.[0]?.value || '';
    }
  }
  filterTypeFieldChangeGroups(event: any, i: any, j: any, entityName: any) {
    const fields = this.getFields('', entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filterby[i][j].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filterby[i][j].key3 = null;
      }
    }
    this.filterby[i][j].key2 =
      this.getOperators(this.filterby[i][j].key1, entityName)?.[0]?.value || '';
  }
  getOperators(field: any, entityName: any): any {
    const fields = this.getFields('', entityName);
    const selectedtype = fields?.find(
      (item: any) => item?.name === field
    )?.datatype;
    const operators = this.datatypesOperator[selectedtype];
    return operators;
  }
  submit(selected_field?: any) {
    let filter_new_data: any;
    if (selected_field === 'checkbox') {
      filter_new_data = this.filterby;
    } else {
      filter_new_data = this.filterby.filter((item: any) => {
        if (Array.isArray(item)) {
          return item.some((subItem) =>
            Object.entries(subItem).every(([key, value]) => {
              return key === 'key2' || (value !== null && value !== '');
            })
          );
        }
        return Object.entries(item).every(([key, value]) => {
          return key === 'key2' || (value !== null && value !== '');
        });
      });
    }

    let new_filter_data = cloneDeep(filter_new_data);
    let converJson = this.filter_service.convertFilterValueToJson(
      new_filter_data,
      this.stateService?.appState()
    );
    let body = {
      org_id: this.stateService?.appState()?.selectedOrganization?.org_id,
      process_id: this.stateService?.appState()?.selectedProcess?.process_id,
      entity_name: this.stateService?.appState()?.selectedEntity,
      ca_name: this.file_data?.name,
      ca_filter: converJson,
    };
    this.stateService.add_ca_execution_filter(body).subscribe()
  }
  update_key3($event: any, item: any) {
    if (item.key2 === '$in') {
      item.key3 = [$event];
    }
  }
  call_save(event: any, item: any, set_value: any) {
    if (event) {
      set_value = event;
      if (item.key1 !== '' && item.key3 !== '' && item.key1 && item.key3) {
        this.submit();
      }
    }
  }
  emojiType: any = [
    {
      value: '(smile)',
      checked: false,
      img1: '"../../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
      img2: '"../../../../assets/images/addNewFieldIcons/blueSmiley.svg',
    },
    {
      value: '(tick)',
      checked: false,
      img1: '"../../../../assets/images/addNewFieldIcons/whiteTick.svg',
      img2: '"../../../../assets/images/addNewFieldIcons/blueTick.svg',
    },
    {
      value: '(like)',
      checked: false,
      img1: '"../../../../assets/images/addNewFieldIcons/whiteLike.svg',
      img2: '"../../../../assets/images/addNewFieldIcons/blueLike.svg',
    },
    {
      value: '(star)',
      checked: false,
      img1: '"../../../../assets/images/addNewFieldIcons/whitestar.svg',
      img2: '"../../../../assets/images/addNewFieldIcons/bluestar.svg',
    },
  ];

  findItsDataTypeValue(field: string, entityName: any) {
    let headersField = this.getFields('', entityName);
    let fieldInfo = headersField?.find((e: any) => e?.name == field);

    
    return fieldInfo;
  }
  getHoverValue(c: any, i: any) {
    if (i + 1 <= c.hoverValue) {
      return true;
    } else {
      return false;
    }
  }
  getEmojiImage(value: any, data: any) {
    let result = this.emojiType.find((e: any) => e.value === value);
    if (result) {
      return data === 'blue' ? result.img1 : result.img2;
    } else {
      return data === 'blue' ? this.emojiType[0].img1 : this.emojiType[0].img2;
    }
  }
  convertToString(value: any) {
    return value?.toString();
  }
  getPeopleOptionList$: any = new Observable();
  openDropDownPeopleChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization.org_id,
        api_name: 'People',
        process_id: this.stateService?.appState()?.selectedProcess.process_id,
        entity_id: [],
      };
      this.getPeopleOptionList$ = this.stateService.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org.map((item: any) => item[f.api_field]);
        })
      );
      this.savePeopleList = this.getPeopleOptionList$;
    }
  }
  filterpeopleOptions(value: string) {
    this.getPeopleOptionList$ = this.savePeopleList.pipe(
      map((options: any) =>
        options.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  multiStaticInput: any = '';
  getMultiStaticOption(options: any, search: any) {
    if (search !== '') {
      return options?.filter((e: any) =>
        e?.name?.toLowerCase().includes(search)
      );
    }
    return options;
  }
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color;
    return color || '';
  }
  openDropDownEntityChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization.org_id,
        process_id: this.stateService?.appState()?.selectedProcess.process_id,
        entity_name: f.entity_name,
        process_name: this.stateService
          ?.appState()
          ?.selectedProcess.process_name.replace(/ /g, '_'),
        field_name: f.field_name,
        limit: 50,
        skip: 0,
        field_str: search,
      };
      this.getEntityptionList$ = this.stateService
        .getentityfielddata(body)
        .pipe(
          shareReplay(),
          map((r: any) => {
            return r?.[0]?.entity_data;
          })
        );
    }
  }
  getApiOptionList$: any = new Observable();
  saveApiOption: any;
  openDropDownAPIChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization.org_id,
        api_name: f.api_name,
        process_id: this.stateService?.appState()?.selectedProcess.process_id,
        entity_id: [],
        field_str: search,
      };
      this.getApiOptionList$ = this.stateService.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org
            .map((item: any) => item[f.api_field])
            .sort((a: any, b: any) =>
              a?.toLowerCase().localeCompare(b?.toLowerCase())
            );
        })
      );
      this.saveApiOption = this.getApiOptionList$;
    }
  }
  filterApiOptions(value: string) {
    this.getApiOptionList$ = this.saveApiOption.pipe(
      map((options: any) =>
        options.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  statusInput: any = '';
  getStatusOptions(o: any, c: any, search: any) {
    let combinedStatus = [...new Set([...o, ...c])];
    if (search !== '') {
      return combinedStatus?.filter((e: any) =>
        e.name?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    return combinedStatus;
  }
  timerRange1: any;
  timerRange2: any;
  manualTime: any;
  selectedRTimeTabIndex: any = 0;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  getTimeValue(d: any) {
    let totalMinutes = Number(d.key3);
    let hours = Math.floor(totalMinutes / 60);
    let remainingMinutes = (totalMinutes - hours * 60) % 60;
    if (remainingMinutes > 60) {
      hours += Math.floor(remainingMinutes / 60);
      remainingMinutes %= 60;
    }

    return `${(hours || 0).toString().padStart(1, '0')} hr ${(
      remainingMinutes || 0
    )?.toFixed(0)} min`;
  }

  onOpenTime(d: any, f: any) {
    this.manualTime = (Number(f.key3) || 0).toFixed(0) || 0;
  }
  saveTime(filter: any) {
    filter.key3 = this.manualTime;
    this.manualTime = null;
  }
}
