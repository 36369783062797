
<div class="px-10 textStyle phone" fluidRowWidth [type]="header.name">
  <div class="inputIconContainer">
    <div style="display: flex;justify-content: end;cursor: pointer;align-items: center;min-height: 37px!important; 
    " class="editable-cell f-12 showDefault" (click)="editing()" *ngIf="!(editval$|async)">
  
      {{data[header?.name]?.split('-')[0]?.trim()}} {{data[header?.name]?.split('-')[1]?.trim()}}
    </div>
    <nz-form-control #element class="phoneInputStyle" *ngIf="editval$|async">
      <nz-input-group  [nzPrefix]="suffixTemplate">
        <input auto-focus-input type="tel" maxlength="10" nz-input [nameid]="'number'" [(ngModel)]="formatedMobileNumber"
          (focusEmit)="pushFocus($event)" (focus)="pushFocus('number')" (focusout)="stopEditingFocusout('number')"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
        <ng-template #suffixTemplate>
          <div class="phnPre" nz-dropdown [nzDropdownMenu]="menu" (mousedown)="$event.preventDefault()" style="padding-left:10px;padding-right:10px; cursor: pointer; "
             (click)="focusInput = true; $event.stopPropagation();">{{selectedCountryCode?selectedCountryCode:'+91'}}</div>
        </ng-template>
      </nz-input-group>

    </nz-form-control>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu" nzPlacement="bottomRight">
    <!-- <nz-input-group  (click)="$event.preventDefault()" class="searchInputContainer" style="pointer-events: auto;">
      <input (click)="$event.preventDefault()" [nzBorderless]="false" #search  nz-input placeholder="Search" style="padding-left: 5px;border-left: 1px solid lightgray;"
        type="text" />
    </nz-input-group> -->
    <input #saearhFieldData nz-input placeholder="Search..."
    (input)="filtercountryCode(saearhFieldData.value)" />
    <ul nz-menu (mousedown)="$event.preventDefault()" (click)="close()" nzSelectable class="option-list"
    >
      <li style="pointer-events: none;" #menuitem nz-menu-item *ngFor="let country of getCountryCode(mobileCountryCode)">
        <span style="padding: 2px;border-radius: 50%;pointer-events: auto;">
  
        </span>
        <span style="margin-left: 5px;pointer-events: auto;"
        (click)="selectedCountryCode=country.PhoneCode;numberSelect()"
        >{{country.PhoneCode}}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
<div style="color: red ;font-size: smaller;text-align: left;">
  {{error}}
  </div>