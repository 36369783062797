import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-table-body',
  templateUrl: './table-body.component.html',
  styleUrls: ['./table-body.component.scss'],
})
export class TableBodyComponent {
  @Input() componentState: any;
  @Input() groupedArray: any;
  @Input() api_fields: any;
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Output() selectIDs: EventEmitter<any> = new EventEmitter();
  @Output() dataUpdate: EventEmitter<any> = new EventEmitter();
  @Output() emitSelectorAPI: EventEmitter<any> = new EventEmitter();
  @Output() editrow: EventEmitter<any> = new EventEmitter();
  @Output() deleterow: EventEmitter<any> = new EventEmitter();
  @Output() showVisibility: EventEmitter<any> = new EventEmitter();

  private _editId = new BehaviorSubject<any>(null);

  addIds(e: any) {
    this.selectIDs.emit(e);
  }

  trackrow(index: number, header: any) {
    return header.name;
  }

  editId() {
    return this._editId.getValue();
  }

  previousComp: any = null;
  startEdit(name: any, index: any, data: any) {
    if (this.previousComp) {
      this.previousComp.editClicked = false;
    }
    this._editId.next(`${name},${index},${data.entity_id}`);
  }

  error = false;
  stopEdit(v?: any, type?: any, entityId?: any) {
    if (!v) {
      if (['dropdown', 'status', 'date'].includes(type)) {
        this.dataUpdate.emit(entityId);
      } else {
        let entity_id = this.editId()?.split(',')[2];
        this.dataUpdate.emit(entity_id);
        this._editId.next(null);
        this.error = false;
      }
      this.previousComp = null;
    } else {
      this.error = true;
    }
  }

  textboxValidation(field: any, event: any) {
    let inputText = event;
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          return `Exact value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  showEmailError(email: string): boolean {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }

  formatNumber(v: any, field: any) {
    const inputValue = v;
    if (field.seperator && inputValue) {
      // const numericValue = Number(inputValue);
      const numericValue = Number(inputValue?.replace(/,/g, ''));
      if (numericValue) {
        let separator = '';
        if (field.seperator.toLowerCase() === 'millions') {
          separator = 'en-US';
        } else if (field.seperator.toLowerCase() === 'thousands') {
          separator = 'en-IN';
        } else {
          separator = '';
        }
        const formattedValue = this.formatNumberWithSeparator(
          numericValue,
          separator,
          field.decimal
        );
        return formattedValue;
      }
    }
    return inputValue;
  }

  formatNumberWithSeparator(
    value: number,
    format: string,
    decimal: number
  ): string {
    if (format) {
      const formattedValue = value.toLocaleString(format, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formattedValue;
    } else {
      return value.toFixed(decimal || 0);
    }
  }

  checkChange(e: any, v: any, n: any) {
    v[n] = e;
    this.dataUpdate.emit(v.entity_id);
    this._editId.next(null);
  }

  ratingChange(e: any, v: any, n: any) {
    v[n] = e;
    this.dataUpdate.emit(v.entity_id);
    this._editId.next(null);
  }

  startDropDownEditEmitter(comp: any) {
    // if (this.previousComp) {
    //   this.previousComp.editClicked = false;
    // }
    // this.previousComp = comp
  }
  hideSelectOption: boolean = true;

  peopleDatas(event: any) {
    this.peopleData(event.header, event.data);
  }

  peopleData(v: any, data: any): void {
    this.stopEdit(false, 'dropdown', data.entity_id);
  }

  peopleDropdownEventEmitters(event: any) {
    this.peopleDropdownEventEmitter(event.event, event.data);
  }

  peopleDropdownEventEmitter(e: any, data: any) {
    if (!e) {
      this.isPeopleDropDownOpen = false;
      this.stopEdit(false, 'dropdown', data.entity_id);
    }
  }

  isPeopleDropDownOpen = false;

  selectedPeoples(event: any) {
    this.selectedPeople();
  }

  selectedPeople() {
    this.selectAPIEmitter({ action: true, people: 'people' });
  }

  selectAPIEmitter(e: any) {
    if (e.action === true) {
      this.emitSelectorAPI.emit(e);
    }
  }

  editRow(e: any) {
    this.editrow.emit(e);
  }

  deleteRow(e: any) {
    this.deleterow.emit(e);
  }

  openVisibilityOptions(data: any) {
    this.showVisibility.emit([data, 'entity']);
  }
}
