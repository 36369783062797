import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '../core/authentication/auth.service';
import { Observable, map, switchMap, tap, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { StateService } from '@processo/shared/state/state.service';
import { USER_API_CONST } from '@processo/consts/api.const';
interface UserAttributes {
  attributes: {
    firstName: string;
    lastName: string;
    mobile: string;
    country: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  selected_process!:any
  selectedApiProcessiD:any
  id: String | undefined;
  processUserId: any = null;
  process_id: String | undefined;
  SelectedOrganisationData: any[] = [];
  userFrom!:string;

  http = inject(HttpClient);
  stateService = inject(StateService);
  authService = inject(AuthService);




  getUser() {
    return this.authService.getCurrentUser();
  }
  isAuthenticated(): boolean {
    return Object.keys(this.getUser()).length > 0 ? true : false;
  }

  addUnderscoresToSpaces(input: string): string {
    return input?.replace(/ /g, '_');
  }
// HTTP API CALLS 

  updateUser(body: UserAttributes) {
    return this.http
      .post(USER_API_CONST.UPDATE_USER, body)
      .pipe(switchMap(() => this.authService.updateToken()));
  }
  update_user(body: UserAttributes) {
    return this.http
      .post(
        USER_API_CONST.UPDATE__USER,
        body,
      )
      .pipe(
        tap((response: any) => {
          this.authService.setAuth(response, true);
        }),
      );
  }

  recoverAccount(email: string): Observable<any> {
    return this.http.post(
      USER_API_CONST.GET_RECOVERY_CODE,
      { username: email },
    );
  }
  recoverAccountVerify(body: any): Observable<any> {
    return this.http.post(
      USER_API_CONST. VERIFY_RECOVERY_CODE,
      body,
    );
  }
  getVerifyCode(body: any): Observable<any> {
    return this.http.post(
      USER_API_CONST.GET_VERIFY_CODE,
      body,
    );
  }
  getConfirmCode(body: any): Observable<any> {
    return this.http.post(
      USER_API_CONST.CHECK_VERIFY_CODE,
      body,
    ).pipe(tap((res:any)=>this.authService.setAuth(res)));
  }
  addOrgRole(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/add_org_role`,
      body,
    );
  }
  deleteOrgRole(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/delete_org_role`,
      body,
    );
  }
// organisation apis

  createOrg(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/create_org`,
      body,
    );
  }
  createOrgProcess(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/create_org_process`,
      body,
    );
  }
  inviteUser(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/invite_users`,
      body,
    );
  }
  updateOrg(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/update_org`,
      body,
    );
  }
  getUserOrgProcessByID(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_user_org_process`,
      body,
    );
  }
  get_invite_user_pending() {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_invite_user_pending`,
      {},
    );
  }
  update_accept_invitation(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_accept_invitation`,
      body,
    );
  }
  update_reject_invitation(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_reject_invitation`,
      body,
    );
  }
  get_invite_user_emailid(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_invite_user_emailid`,
      body,
    );
  }
  updateOrgDefault(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/update_org_default`,
      body,
    );
  }
  removeRoleFromUser(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_role_from_user`,
      body,
    );
  }
  updateUserRole(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_user_role`,
      body,
    );
  }
  updateUserAccess(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_user_access`,
      body,
    );
  }
  mapUserToProcessUser(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_to_process_user`,
      body,
    );
  }
  removeUserFromProcessUser(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_from_process_user`,
      body,
    );
  }

  
  //  process apis
  
  getProcess(body?: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_process`,
      body,
    );
  }
  getUserOrgProcess() {
    return this.http
      .post(
        `${environment.base_url}/store/processo/myquery/execute/get_user_org_process`,
        {},
      )
      .pipe(
        tap((res: any) => {
          this.stateService.updateOrgList(res[0].org);
        }),
      );
  }
  get_process_metadata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_process_metadata`,
      body,
    );
  }
  removeProcessFromOrg(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/remove_process_from_org`,
      body,
    );
  }
  removeUserFromProcess(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/remove_user_from_process`,
      body,
    );
  }
  showField(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/show_field`,
      body,
    );
  }
  hideField(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/hide_field`,
      body,
    );
  }
  showGridValue(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/show_grid_value`,
      body,
    );
  }

  save_entity_data(body: any) {
    let data = {...body,process_name:this.addUnderscoresToSpaces(body.process_name)?.replace(/\s/g, '_')};

    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_entity_data`,
      data,
    );
  }

  removeTab(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_tab`,
      body,
    );
  }

  removeTabFieldNames(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_tab_field_names`,
      body,
    );
  }

  delete_entity_data(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/delete_entity_data`,
      body,
    );
  }
  delete_entity_datas(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/delete_entity_data`,
      body,
    );
  }


  updateOrgProcessDefault(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/update_org_process_default`,
      body,
    );
  }



  updateEntityVisibility(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_entity_visibility`,
      body,
    );
  }
  mapUserToEntityData(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_to_entity_data`,
      body,
    );
  }
  map_other_users_to_process_user(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_other_users_to_process_user`,
      body,
    );
  }
  removeUserFromEntityData(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_from_entity_data`,
      body,
    );
  }
  mapRoleToEntityData(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_role_to_entity_data`,
      body,
    );
  }
  removeRoleFromEntityData(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_role_from_entity_data`,
      body,
    );
  }
  get_entity_data_logs(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_entity_data_logs`,
      body,
    );
  }
  removeVisibilityField(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_visibility_field`,
      body,
    );
  }
  saveVisibilityField(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_visibility_field`,
      body,
    );
  }
  removeVisibilityValue(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/remove_visibility_value`,
      body,
    );
  }

  getentityfielddata(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/getentityfielddata`,
      body,
    );
  }
  getCurrencyList() {
    return this.http
      .post(
        `${environment.base_url}/store/processo/myquery/execute/get_countries_value`,
        {},
      )
      .pipe(map((res: any) => [...res[0].org]));
  }
  saveApis(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_apis`,
      body,
    );
  }
  getApis(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_apis`,
      body,
    );
  }
  getApiFields(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,
      body,
    );
  }
  removeApi(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_apis`,
      body,
    );
  }
  save_tabs(body: any) {
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/save_tabs`,
      body,
    );
  }
  updateProcessEntityVisibility(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_process_entity_visibility`,body
    )
  }
  mapUserToProcessEntity(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_to_process_entity`,body
    )
  }
  removeUserFromProcessEntity(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_from_process_entity`,body
    )
  }
  mapRoleToProcessEntity(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_role_to_process_entity`,body
    )
  }
  removeRoleFromProcessEntity(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_role_from_process_entity`,body
    )
  }
  getMobileCountryCode(body:any){
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/exec_api`,body
    )
  }
  getWorkflowEvent(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_workflow_event`,body
    )
  }
  
  save_approval_matrix(body: any) {
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/save_approval_matrix`,
      body
    );
  }

  removeWorkflowEvent(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_workflow_event`,body    )
  }
  
  getApprovalMatrix(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/get_approval_matrix`,body
    )
  }

  removeApprovalMatrix(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_approval_matrix`,body    )
  }

  fetchEntityDataByLogs(body:any){
    return this.http.post(
      `${environment.base_eru_routes_url}/processo/func/fetch_entity_data_by_logs`,body

    )
  }
  getCurrencyLists(){
    return this.http
    .post(
      `${environment.base_url}/store/processo/myquery/execute/get_countries_value`,
      {},
    )
    .pipe(map((res: any) => [...res[0].org]));
  }

  getCountryCode(body:any){
    return this.http.post(
      `https://erufunc.dev.processo.io/processo/func/exec_api`,body
    )
  }

  saveMessageTemaple(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/save_message_template`,body    );
  }
  
  getMessageTemaple(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/get_message_template`,body    );
  }

  removeMessageTemaple(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/remove_message_template`,body   );
  }
  changeEntityFieldIndex(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/change_entity_field_index`,body   );
  }
  updateSupervisor(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/update_supervisor`,body   );
  }
  
  saveSecret(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/save_secret_key`,body);
  }
  
  getSecret(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/get_secret_key`,body    );
  }

  saveWhatsApp(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/save_connectors`,body);
  }

  removeSecret(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/remove_secret_key`,body   );
  }
  saveConnectors(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/save_connectors`,body);
  }
  getConnectors(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/get_connectors`,body);
  }
  saveUserField(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/save_user_field`,body);
  }
  saveUserData(body:any){
    return this.http
    .post(
    `${environment.base_eru_routes_url}/processo/func/save_user_data`,body);
  }
  deleteUserData(body:any){
    return this.http
    .post(
    `${environment.base_url}/store/processo/myquery/execute/remove_user_data`,body);
  }
  getCustomAction(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/get_custom_action`,body)
  }
  saveCustomAction(body:any){
    return this.http.post(`${environment.base_eru_routes_url}/processo/func/save_custom_action`,body)
  }
  updateProcessEntityRecordVisibility(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_process_entity_record_visibility`,body
    )
  }
  mapUserToProcessEntityRecord(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_to_process_entity_record`,body
    )
  }
  removeUserFromProcessEntityRecord(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_from_process_entity_record`,body
    )
  }
  mapRoleToProcessEntityRecord(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_role_to_process_entity_record`,body
    )
  }
  removeRoleFromProcessEntityRecord(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_role_from_process_entity_record`,body
    )
  }
  saveApiUserField(body:any){
    return this.http.post(`${environment.base_eru_routes_url}/processo/func/save_api_field`,body)
  }
  getReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/get_report_details`,body)
  }
  removeReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/remove_report_details`,body)
  }
  saveReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/save_report_details`,body)
  }
  updateReportVisibility(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/update_report_visibility`,body)
  }
  removeRoleFromReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/remove_role_from_report`,body)
  }
  mapRoleToReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/map_role_to_report`,body)
  }
  removeUserFromReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/remove_user_from_report`,body)
  }
  mapUserToReport(body:any){
    return this.http.post(`${environment.base_url}/store/processo/myquery/execute/map_user_to_report`,body)
  }
  updateDownloadVisibility(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/update_download_visibility`,body
    )
  }

  MapUserToDownload(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_user_to_download`,body
    )
  }
  RemoveUserFromDownload(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_user_from_download`,body
    )
  }
  MapRoleToDownload(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/map_role_to_download`,body
    )
  }
  RemoveRoleFromDownload(body:any){
    return this.http.post(
      `${environment.base_url}/store/processo/myquery/execute/remove_role_from_download`,body
    )
  }
}