import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebsiteComponent implements DoCheck {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  showEditIcon = false;
  @Input() innerTable: any;
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  ngOnInit(): void {}

  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  ngDoCheck(): void {
    if(this.header?.callUniqueApi && this.editval.getValue()){
      
      this.checkValidation({target:{value:this.data[this.header.name]}})
    }
    this.cdr.markForCheck();    
  }
  error = '';
  editing() {
    if(!this.header.can_edit || this.header.tab_name==='XX' || this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)){
      return
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    this.header.error = this.textboxValidation(this.header, e.target.value);
    this.check_is_unique(e)
    return
    if (this.header.datatype === 'website' && this.header.is_unique) {
      this.header.callUniqueApi = false
      let filterData: any = {};
      let body: any = {
        org_id: this.parent.pservice.appState().selectedOrganization.org_id,
        process_id: this.parent.pservice.appState().selectedProcess.process_id,
        process_name:
          this.parent.pservice.appState().selectedProcess.process_name?.replace(/\s/g, '_'),
        entity_name: this.parent.pservice.appState()?.selectedEntity,
        // filter: {
        //   [this.header.name]: this.data[this.header.name],
        // },
        entity_id: this.data?.entity_id,
      };
      filterData[this.header.name] = this.data[this.header.name];
      let data = this.header?.unique_fn?.map((fieldName: any) => {
        if (fieldName) {
          filterData[fieldName] = this.data[fieldName];
        }
      });
      body['filter'] = filterData;
      this.parent.pservice.fetchEntityDataUnique(body).subscribe((res: any) => {
        if (res.exists) {
          this.header.error = `${this.header.label} value already exists`;
          this.cdr.detectChanges();
          return;
        } else {
          let field = this.parent.getTableHeaders()
          let data = this.header?.unique_fn?.map((fieldName: any) => {
            if (fieldName) {
              field.map((_e:any)=>{
                if (_e?.name === fieldName) {
                  _e.callUniqueApi=true                    
                  
                  _e.error = '';
                
                  
                }
              })
            }
          });
          this.header.error = '';
          
          
          this.saveData(e);
          this.cdr.detectChanges();
        }
      });
    } else {
      this.saveData(e);
    }
  }
 check_is_unique(e: any) {
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.saveData(e)
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.saveData(e)
       }
     }, 100);
   }

 }
  saveData(e: any) {
   
    let tableEdit: any;
    if (!this.header.error) {
      if (this.innerTable) {
        tableEdit = 'innerTable';

        this.parent.updateDataOnInnerStopEdit(e, this.header.name, this.data);
      } else {
        this.parent.updateDataOnStopEdit(
          e,
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
      this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
      this.editval.next(false);
    }
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    if (field.mandatory && !inputText) {
      return `${field?.label} is mandatory`;
    } else {
      return '';
    }
  }
}
