<nz-tabset [nzAnimated]="false" class="tabContainers">
    <nz-tab nzTitle="Edit/Delete">
      <nz-input-group [nzPrefix]="prefixTemplateUser">
        <input type="text" nz-input placeholder="search" [nzBorderless]="true" #search
          (input)="FilteravailableField(search.value)" />
      </nz-input-group>
      <ng-template #prefixTemplateUser><img src="../../../../assets/images/icons/searchIcon.svg" alt=""></ng-template>
      <div class="title-text f-11 pl-15">MANAGE FIELDS</div>
      <div class="scrollStyle">
        <ng-container *ngFor="let item of saveAvailableFields">
          <div class="fieldCustomStyle" style="cursor: auto!important;">
            
            <div style="font-size: 12px;font-weight: 400;">{{item?.label | titlecase}}</div>
            <img style="margin-left: auto;" (click)="editField(item)"  class="IconShowStyles cPointer" src="../../../../assets/images/icons/Pencil.svg">
            <img style="margin: 0 20px 0 10px;"(click)="deleteField(item)"  class="IconShowStyles cPointer" src="../../../../assets/images/icons/TrashDelete.svg">
          </div>
        </ng-container>
      </div>
    </nz-tab>
    <nz-tab nzTitle="New Column">
      <nz-input-group [nzPrefix]="prefixTemplateUser">
        <input type="text" nz-input placeholder="search" [nzBorderless]="true" #search2
          (input)="FilterField(search2.value)" />
      </nz-input-group>
      <ng-template #prefixTemplateUser><img src="../../../../assets/images/icons/searchIcon.svg" alt=""></ng-template>
      <div class="title-text f-11 pl-15">CREATE NEW FIELD</div>
      <div class="scrollStyle">
        <ng-container *ngFor="let item of listOfFields">
          <div class="fieldCustomStyle" (click)="selectedField(item)">
            <div style="margin-right:15px;width: 24px;height: 24px;"> <img [src]="item.image" ></div>
            <div style="font-size: 12px;font-weight: 400;">{{item.label | titlecase}}</div>
          </div>
        </ng-container>
      </div>
    </nz-tab>
  </nz-tabset>
