import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit{
  url:any = ''
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  constructor(
    public sanitizer: DomSanitizer,
  ){}
  ngOnInit(): void {
    if(this.nzModalData?.data?.file && this.nzModalData?.data?.type){
      this.convertBase64toFile(this.nzModalData.data.file,this.nzModalData.data.type)
    }
    if(this.nzModalData?.data?.showImage){
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.nzModalData?.data?.file)
    }

  }
  convertBase64toFile(base64Data: any, file_type: any){
    let url = `data:${file_type};base64,${base64Data}`
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  async downloadFile(): Promise<void> {
    await this.saveFile(this.nzModalData.data, this.nzModalData.data.fileName);
  }

  async saveFile(res: any, name: any): Promise<void> {
    let link = document.createElement('a');
    link.href = `data:${res.type};base64,${res.file}`;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
