<nz-form-item>
  <nz-form-control>
    <nz-select
      [(ngModel)]="data[header.name]"
      class="dropdownui"
      nzPlaceHolder="-"
    >
      <nz-option
        *ngFor="let option of header.options"
        [nzValue]="option.name"
        [nzLabel]="option.value"
      ></nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
