import { TokenService } from '@processo/services/token.service';
import { AuthService } from '../authentication/auth.service';
import { Router } from '@angular/router';

export function appInitializer(
  tokenService: TokenService,

  authService: AuthService,
  router:Router
) {
  const refresh_token: any = localStorage.getItem('refresh_token');

  return () =>
    new Promise((resolve: any) => {
      if (
        refresh_token &&
        refresh_token != '' &&
        refresh_token != 'undefined'
      ) {
        authService.updateToken().subscribe(()=>{
          resolve(true);
        },()=>{
          resolve('foo');
        });

      } else {
        setTimeout(() => {
          resolve('foo');
        }, 300);
      }
    }).then((resp)=>{
      if (resp == true) {
        const noRedirectPaths = ['/login', '/register', '/forgot-password', '/register/additional-details','/register/verify-account'];
        if (window.location.pathname !== '/loading' && !noRedirectPaths.includes(window.location.pathname)) {
          router.navigate(['/loading'], {
            queryParams: {
              redirect: window.location.pathname
            }
          });
        } else {
          if (noRedirectPaths.includes(window.location.pathname)) {
            router.navigate(['/']);
          } else
          router.navigate(['/loading']);
        }
      }
    });
}
