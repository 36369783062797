import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() innerTable: any;
  ngOnInit(): void {
    this.data[this.header.name] =  this.data[this.header.name] === true?true:false
    if (this.header.mandatory == true) {        
      this.data[this.header.name] = this.header?.default === true?true:false;
    }
  }
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;

  getchec(e: any) {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';
      this.parent.updateDataOnInnerStopEdit(
        { target: { value: `${e}` } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: `${e}` } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
   cdr=inject(ChangeDetectorRef);
   error = '';
  check_is_unique(e: any) {
    this.data[this.header.name] = e
    let filterData: any = {};
    let body: any = {
      org_id: this.parent.pservice.appState().selectedOrganization.org_id,
      process_id: this.parent.pservice.appState().selectedProcess.process_id,
      process_name: this.parent.pservice
        .appState()
        .selectedProcess.process_name?.replace(/\s/g, '_'),
      entity_name: this.parent.pservice.appState()?.selectedEntity,
      entity_id: this.data?.entity_id,
    };
    if (this.header.is_unique && this.header.unq_sa) {
      filterData[this.header?.name] = this.data[this.header?.name];
    } else if (this.header.is_unique && !this.header.unq_sa) {
      let total_field = this.parent.pservice
        .appState()
        ?.metaData?.entities?.find(
          (_e: any) =>
            _e.name === this.parent.pservice.appState()?.selectedEntity
        )?.fields;
      total_field.forEach((_fields: any) => {
        if (_fields.is_unique && !_fields.unq_sa) {
          filterData[_fields?.name] = this.data[_fields?.name];
        }
      });
    }
    body['filter'] = filterData;
    if(this.header.is_unique){
      this.parent.pservice.fetchEntityDataUnique(body).subscribe({
        next: (res: any) => {
          if (res) {
            if (res.exists) {
              this.error = `${this.header.label} value already exists`;
            } else {
              this.error = '';
            }
            setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.getchec(e)
              }
            }, 100);
            this.cdr.detectChanges();
          }
        },
        complete: () => {

        },
      });
    }else{
      setTimeout(() => {
        if (this.error?.length === 0 || this.error === '') {
       this.getchec(e)
        }
      }, 100);
    }
  }
}
