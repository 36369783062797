
<div style="display: flex; justify-content:flex-end">
  <div style="display: flex;align-items: center;column-gap: 16px;margin-right: auto;" [hidden]="!appStateService.is_mobile()">
    <img src="../../../../assets/images/processo-icon.svg">
    <img (click)="open_menu()" class="c_pointer" src="../../../../assets/images/icons/openMenuIcon.svg">
  </div>

  <div>
    <div style="display: flex; align-items: center; column-gap: 15px">
      <a
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu"
      class="textcolor f-12 fw-400 text-center w100"
      style="
        background-color: #f2f2f2;
        display: flex;
        border-radius: 8px;
        justify-content: space-between;
        padding: 0 6px 0 20px;
        align-items: center;
        height: 32px;
        max-width: 232px;
        min-width: 200px;
      "
      [nzPlacement]="'bottomCenter'"
    >
      {{ selectedOrganization()?.org_name || "Select Organization Name" }}
      <span nz-icon><img src="../../../../../assets/images/arrow-up.png" /></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          style="
            padding-right: 0 !important;
            padding-left: 0 !important;
            padding-top: 0 !important;
          "
        >
          <input
            #saearhx2
            nz-input
            placeholder="Search..."
            (input)="filterorg(saearhx2.value)"
          />
        </li>
        <div style="height: 150px; overflow-y: auto; margin-top: 5px">
          <li
            nz-menu-item
            *ngFor="let org of filteredOrgList"
            (click)="onOrganizationSelection(org)"
            style="font-size: 12px"
          >
            {{ org.org_name }}
          </li>
        </div>
      </ul>
    </nz-dropdown-menu>
      <!-- <img
        (click)="setting()"
        class="c_pointer"
        src="../../../../../assets/images/settings.svg"
      /> -->
      <!-- <nz-badge
        [nzStyle]="{ backgroundColor: '#000000' }"
        [nzOffset]="[-2, 2]"
        nzSize="small"
        [nzCount]="this.appStateService.get_invite_org_list()?.length"
      >
        <img class="c_pointer" src="../../../../../assets/images/solar.svg"
      /></nz-badge> -->
      <div [hidden]="!appStateService.is_mobile()">
        <a nz-dropdown [nzDropdownMenu]="user_menu" nzTrigger="click">
          <div class="profile_logo_container fw-400 f-12">
            {{ firstName }}{{ lastName }}
          </div>
          <nz-dropdown-menu #user_menu="nzDropdownMenu">
            <ul nz-menu class="">
              <li class="menu_style" nz-menu-item (click)="go_to('org')">
                Organizations
              </li>
              <li nz-menu-item (click)="go_to('profile')">Profile Settings</li>
              <li nz-menu-item (click)="signOut()">Sign Out</li>
              <nz-divider class="divider_style"> </nz-divider>
              <li class="fw-600 menu_style" style="cursor: default">
                {{ fullName }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </a>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="appStateService.is_mobile()">
  <nz-drawer
  [nzClosable]="false"
  [nzVisible]="open_process_menu"
  nzPlacement="left"
  (nzOnClose)="close_menu()"
  [nzWrapClassName]="'drawer_menu_container'"
>
  <ng-container *nzDrawerContent class="lb-bgcolor">
    <app-sidebar [routerData]="routerData" (side_bar_output)="side_bar_output($event)"></app-sidebar>
  </ng-container>
  </nz-drawer>
</ng-container>