<div class="px-10 textStyle" fluidRowWidth [type]="header.name" [style.pointerEvents]="!header.can_edit || header.tab_name==='XX' || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'">
  <div class="checkBoxStyle min-height-37 align-center d-flex" style="justify-content: center;">
    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
      <label
      [disabled]="!header.can_edit"
        nz-checkbox
        [ngModel]="data[header.name]=='true'|| data[header.name]== true ?true:false"
        (ngModelChange)="check_is_unique($event)"
      ></label>
      <div style="color: red ;font-size: smaller;text-align: left;">
        {{error}}
        </div>
    </div>
  </div>
</div>

