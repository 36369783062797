<div class="" style="min-height: 63px!important;" [ngClass]="app_service.is_mobile()?'header_toolbar_container_mobile':'header_toolbar_container'">
  <div class="org_toolbar" style="padding-left: 10px;">
    <app-header-menu [routerData]="routerData"></app-header-menu>
  </div>
  <div class="managing_toolbar" [hidden]="app_service.is_mobile()">
    <app-manage-org-header-option [isAdmin]="isAdmin()" [hide_selected_tab]="hide_selected_tab"></app-manage-org-header-option>
  </div>
  <div class="tabset_toolbar" >
    <app-header-nav-bar>
    </app-header-nav-bar>  
  </div>
  <div class="managing_toolbar" style="padding: 10px 0" [hidden]="!app_service.is_mobile()">
    <app-manage-org-header-option [isAdmin]="isAdmin()" [hide_selected_tab]="hide_selected_tab"></app-manage-org-header-option>
  </div>
</div>