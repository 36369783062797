import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import * as moment from 'moment';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit, OnDestroy {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  field: any;
  timerRange1: any = null;
  timerRange2: any = null;
  manualTime: any;
  Number = Number;
  @Input() data: any;
  @Input() header: any;
  @Input() parent: any;
  @Input() innerTable: any;
  Math = Math;
  selectedRTimeTabIndex: any = 0;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  private timeoutRef: any;

  ngOnInit(): void {
    this.manualTime =
      (Number(this.data?.[`${this.header.name}`]) || 0).toFixed(0) || 0;
    this.timerRange1 = this.data?.[`${this.header.name}_st`] || null;
    this.timerRange2 = this.data?.[`${this.header.name}_et`] || null;
    if (this.manualTime && this.timerRange1 && this.timerRange2) {
      this.selectedRTimeTabIndex = 1;
    } else if (this.manualTime && !this.timerRange1 && !this.timerRange2) {
      this.selectedRTimeTabIndex = 0;
    } else {
      this.selectedRTimeTabIndex = 0;
    }
    this.updatetime();
  }

  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  updatetime() {}
  saveTime(data: any, header: any) {
    if (this.selectedRTimeTabIndex === 0) {
      this.timerRange1 = '';
      this.timerRange2 = '';
      data[header.name] = this.manualTime !==""? Number(this.manualTime):null;
      data[`${header?.name}_st`] = null;
      data[`${header?.name}_et`] = null;
    }
    if (this.selectedRTimeTabIndex === 1) {
      let startTime = moment.duration(this.timerRange1).asMinutes();
      let endTime = moment.duration(this.timerRange2).asMinutes();
      if (startTime > endTime) {
        this.manualTime = Math.abs(1440 - startTime + endTime);
      } else {
        this.manualTime = Math.abs(endTime - startTime);
      }
      data[header.name] = this.manualTime;
      data[`${header?.name}_st`] = this.timerRange1
        ? moment.duration(this.timerRange1).asMilliseconds()
        : null;
      data[`${header?.name}_et`] = this.timerRange2
        ? moment.duration(this.timerRange2).asMilliseconds()
        : null;
    }
    let datas: any = {};
    datas[`${header?.name}`] = this.manualTime ? this.manualTime : null;
    datas[`${header?.name}_st`] = this.timerRange1
      ? moment.duration(this.timerRange1).asMilliseconds()
      : null;
    datas[`${header?.name}_et`] = this.timerRange1
      ? moment.duration(this.timerRange2).asMilliseconds()
      : null;
    this.parent.updateDataOnStopEdit(
      { target: { value: datas, datatype: header.datatype } },
      this.header.name,
      this.data,
      this.parent.erulistData
    );
    this.parent.saveEntityDropdownData(this.header, '', this.data);
  }

  selectTime(field: any) {
    this.manualTime = '';
    this.timerRange1 = '';
    this.timerRange2 = '';
  }

  getTimeValue(d: any) {
    let totalMinutes = Number(d[this.header.name]);

    let hours = Math.floor(totalMinutes / 60);
    let remainingMinutes = (totalMinutes - hours * 60) % 60;
    if (remainingMinutes > 60) {
      hours += Math.floor(remainingMinutes / 60);
      remainingMinutes %= 60;
    }
    return `${(hours || 0).toString().padStart(1, '0')} hr ${(
      remainingMinutes || 0
    )?.toFixed(0)} min`;
  }

  close = false;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
}
