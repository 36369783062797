import { Directive, DoCheck, ElementRef, Input } from '@angular/core';
import { ProcessoCustomTableService } from '../processo-custom-table.service';

@Directive({
  selector: '[fluidWidth]',
})
export class FluidWidthDirective {
  @Input() type!: string;
  constructor(
    private el: ElementRef,
    private service: ProcessoCustomTableService
  ) {}
  ngDoCheck(): void {
    let headerHeight = document.querySelector('.header-wrapper');
    let tableMenuHeight = document.querySelector('.menuContainers');
    let org_notification = document.querySelector('.notification_org');
    let org_notification_height = null;
    if(org_notification){
      org_notification_height = org_notification.clientHeight      
    }
    if (window?.innerWidth < 769) {
      let mobileHeaderHeight = document.querySelector('.sidebarContainer');
      let totalHeight =
        (headerHeight ? headerHeight.clientHeight : 0) +
        (tableMenuHeight ? tableMenuHeight.clientHeight : 0) +
        68 +
        (mobileHeaderHeight ? mobileHeaderHeight.clientHeight : 0);
      this.el.nativeElement.style.height = `calc(100vh - ${org_notification_height? org_notification_height + totalHeight +20 :totalHeight}px)`;
    } else {
      let totalHeight =
        (headerHeight ? headerHeight.clientHeight : 0) +
        (tableMenuHeight ? tableMenuHeight.clientHeight : 0) +
        ((headerHeight ? headerHeight.clientHeight : 0) > 52 ? 85 : 100);
      this.el.nativeElement.style.height = `calc(100vh - ${org_notification_height? org_notification_height + totalHeight +20 :totalHeight}px)`;
    }

    // let wrapperContainer = document?.querySelector('.wrapper-container');

    // let viewportHeight = wrapperContainer ? wrapperContainer.clientHeight : 0;
    // this.el.nativeElement.style.height = `${viewportHeight-200}px`;

    this.el.nativeElement.style.overflow = 'scroll';
  }
  ngOnChanges(): void {}
}
