import { Component, Input, OnInit } from '@angular/core';
import { FilterConvertorServiceService } from '@processo/services/filter-convertor-service.service';
import { StateService } from '@processo/shared/state/state.service';

@Component({
  selector: 'app-single-filters',
  templateUrl: './single-filter.component.html',
  styleUrls: ['./single-filter.component.scss'],
})
export class SingleFilterComponent implements OnInit {
  @Input()option_multi!:boolean
  @Input()default_data!:any
  totalFields: any = [];
  Array = Array;
  datatypesOperator: any = {
    dropdown_single_select: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    dropdown_multi_select: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    status: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    location: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    textarea: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    checkbox: [
      { label: 'is selected', value: '$in' },
      { label: 'is not selected', value: '$nin' },
    ],

    labels: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    website: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    people: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    phone: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],

    textbox: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    email: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    tag: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],

    priority: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    time: [
      { label: '=', value: '$in' },
      { label: '<>', value: '$nin' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    progress: [
      { label: '=', value: '$in' },
      { label: '<>', value: '$nin' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    rating: [
      { label: '=', value: '$in' },
      { label: '<>', value: '$nin' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    currency: [
      { label: '=', value: '$in' },
      { label: '<>', value: '$nin' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    date: [
      { label: 'is', value: '$in' },
      { label: 'is not', value: '$nin' },
      { label: 'is after or equal', value: '$gte' },
      { label: 'is before or equal', value: '$lte' },
      { label: 'is after', value: '$gt' },
      { label: 'is before', value: '$lt' },
    ],
    number: [
      { label: '=', value: '$in' },
      { label: '<>', value: '$nin' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
  };
  userFields: any = [];
  constructor(
    private stateService: StateService,
    private filter_service: FilterConvertorServiceService
  ) {}
  ngOnInit(): void {
    let Process_data = this.default_data.process
    this.userFields = Process_data?.user_fields_metadata?.fields;
    
    
  }
  @Input() filterby: any = [
    { key1: '', key2: '', key3: '', key4: true, entity_name: '' },
  ];
  @Input() entitiesData!: any;
  @Input() tabName!: any;
  addFilterOne() {
    this.filterby.push({
      key1: null,
      key2: null,
      key3: null,
      key4: this.filterby[0].key4,
      entity_name: this.tabName,
    });
  }
  addGroupOne() {
    this.filterby.push([
      {
        key1: null,
        key2: null,
        key3: null,
        key4: !this.filterby[0].key4,
        entity_name: this.tabName,
      },
    ]);
  }
  changeOperatorAdd(e: any, i: any) {
    this.filterby.map((item: any, index: any) => {
      if (!this.Array.isArray(item) && index != 0) {
        item.key4 = e;
      } else {
        if (this.Array.isArray(item)) {
          for (let i of item) {
            if (!this.Array.isArray(i)) {
              i.key4 = !e;
            } else {
              if (this.Array.isArray(i)) {
                for (let j of i) {
                  j.key4 = e;
                }
              }
            }
          }
        }
      }
    });
    setTimeout(()=>{
      this.submit()
    },100)
    
  }
  deleteGroupFilter(item: any, index: any) {
    if (this.filterby[item].length > 1) {
      this.filterby[item].splice(index, 1);
    } else {
      const index = this.filterby.splice(item, 1);
    }
    this.submit()
  }
  addNewGroupFilter(i: any) {
    this.filterby[i].push({
      key1: null,
      key2: null,
      key3: null,
      key4: this.filterby[i][0].key4,
      entity_name: this.tabName,
    });
  }
  selectedEntities($event: any, filter: any) {
    if ($event) {
      filter.key1 = null;
      filter.key2 = null;
      filter.key3 = null;
      filter.key4 = true;
    }
  }
  getFields(searchField: any, entityName: any): any {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === entityName
    )?.entity_name;
    const fields = this.default_data.meta_data?.entities?.find((_e: any) => _e.name === entity_Name)
      ?.fields?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
    this.totalFields = fields;
    let filterFields = fields
      ?.filter(
        (e: any) =>
          e?.datatype !== 'entity' &&
          e?.datatype !== 'textarea' &&
          e?.datatype !== 'attachment'
      )
      ?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
    if (filterFields && searchField !== '') {
      return filterFields?.filter((e: any) =>
        e.label?.toLowerCase()?.includes(searchField?.toLowerCase())
      );
    } else {
      return filterFields;
    }
  }
  filterTypeFieldChange(event: any, i: any, entityName: any) {
    const fields = this.getFields('', entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filterby[i].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filterby[i].key3 = null;
      }
      this.filterby[i].key2 =
        this.getOperators(this.filterby[i]?.key1, entityName)?.[0]?.value || '';
    }
  }
  filterTypeFieldChangeGroups(event: any, i: any, j: any, entityName: any) {
    const fields = this.getFields('', entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filterby[i][j].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filterby[i][j].key3 = null;
      }
    }
    this.filterby[i][j].key2 =
      this.getOperators(this.filterby[i][j].key1, entityName)?.[0]?.value || '';
  }
  getOperators(field: any, entityName: any): any {
    const fields = this.getFields('', entityName);
    const selectedtype = fields?.find(
      (item: any) => item?.name === field
    )?.datatype;
    const operators = this.datatypesOperator[selectedtype];
    return operators;
  }
  @Input()from_value:any;
  @Input()file_data:any;
  submit() {
    let filter_new_data = this.filterby.filter((item: any) => {
      if (Array.isArray(item)) {
        return item.some((subItem) =>
          Object.values(subItem).every(
            (value) => value !== null && value !== ''
          )
        );
      }
      return Object.values(item).every(
        (value) => value !== null && value !== ''
      );
    });
    let state = {
      metaData:this.default_data?.meta_data,
      selectedEntity:this.default_data?.entity.name
    }
    let converJson = this.filter_service.convertFilterValueToJson(
      filter_new_data,
      state
    );
    let user_op_field = this.filter_service.get_user_op_field_data(
      filter_new_data,
      state,
      this.userFields
    );
    let filterData = this.flattenArray(filter_new_data);
    let pFilter = this.getPfilterdata(filterData);    
    let body:any = {
      org_id: this.default_data?.org?.org_id,
      process_id: this.default_data?.process?.org_id,
      entity_name: this.default_data?.entity?.name,
      user_op_fn: user_op_field,
      user_att_filter: converJson,
    };
    pFilter?.forEach((_e: any, index: number) => {
      body[`p${index + 1}_pen`] = _e?.pen;
      body[`p${index + 1}_ef`] = _e?.ef;
    });
    if(this.from_value === 'custom_action'){
      body['action_name'] = this.file_data?.name;
      this.stateService
      .addUserAttributeFilterToCaVisibility(body)
      .subscribe((res: any) => {});
    }

  }
  update_key3($event:any,item:any){
    if(item.key2 === '$in'){
      item.key3 = [$event]
    }
  }
  getPfilterdata(data: any) {
    let FilterData: any = [];
    data.forEach((_element: any) => {
      if (
        _element.key3 === '' ||
        _element.entity_name === ''
      ) {
        return
      }
      let field = this.entitiesData?.find(
        (_name: any) => _name.field_name === _element?.entity_name
      );
      if (!field?.default) {
        let data = {
          pen: (`${this.default_data?.process?.process_name}_${
            field?.entity_name
          }`).replace(/\s/g, '_'),
          ef: `_${_element.entity_name}`,
        };
        FilterData.push(data);
      }
    });

    return FilterData;
  }
  flattenArray(arr: any[]): any[] {
    let result: any[] = [];
    arr.forEach((element) => {
      if (Array.isArray(element)) {
        result = result.concat(this.flattenArray(element));
      } else {
        result.push(element);
      }
    });
    return result;
  }
  call_save(event: any, item: any,set_value:any) {
    if(event){
      set_value = event;
      
    if (
      item.key1 !== '' &&
      item.key3 !== '' &&
      item.entity_name !== '' &&
      item.key1 &&
      item.key3 &&
      item.entity_name
    ) {
      if(!this.option_multi){
        this.submit();
      }
    }
      
    }
    
  }
}
