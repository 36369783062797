<div [style.pointerEvents]="!header.can_edit || header.tab_name==='XX' || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'">
  <div class="timerInputstyle" nz-dropdown nzTrigger="click" nzPlacement="bottomCenter" (nzVisibleChange)="event($event)" [nzDropdownMenu]="timeMenu" fluidRowWidth style="font-size: 12px;"> 
    {{getTimeValue(data) ||
    ' '}}</div>
  <nz-dropdown-menu #timeMenu="nzDropdownMenu" >
  <ng-container  *ngIf="close">
    <ul nz-menu class="timerMenuStyle">
      <nz-tabset [(nzSelectedIndex)]="selectedRTimeTabIndex">
        <nz-tab nzTitle="Manual">
          <div style="padding: 0 15px;">
            <input oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="f-12" [(ngModel)]="manualTime"
              type="text" nz-input nzPlaceHolder="-">
          </div>
        </nz-tab>
        <nz-tab  nzTitle="Range">
          <div style="padding: 0 15px;">
            <nz-time-picker nzFormat="HH:mm" [(ngModel)]="timerRange1"  [nzDefaultOpenValue]="defaultOpenValue" [nzPopupClassName]="'timerStyle'"></nz-time-picker>
            -
            <nz-time-picker [(ngModel)]="timerRange2" [nzDefaultOpenValue]="defaultOpenValue" [nzPopupClassName]="'timerStyle'" nzFormat="HH:mm"></nz-time-picker>
          </div>
  
        </nz-tab>
      </nz-tabset>
      <div class="btnAlign">
        <div class="primaryBtnStyle">
          <button nz-menu-item nz-button nzType="primary" class="save" (click)="saveTime(data,header)">
            SAVE
          </button>
        </div>
        <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
          <button nz-menu-item nz-button nzType="default" class="buttonDefault f-10 fw-700"
            style="height: 37px!important; border-radius: 5px;">
            CANCEL
          </button>
        </div>
      </div>
    </ul>
  </ng-container>
  </nz-dropdown-menu>
</div>
