<nz-row [formGroup]="addStatuessForm" class="addNewFieldContainer inputIconContainer">
    <div formArrayName="statuesDetails" style="width: 100%;max-height: 150px;overflow-y: auto;overflow-x: hidden;padding-right: 10px;">
      <nz-layout
        draggable="true"
        (dragstart)="dragStartColumn(i, $event)"
        (dragover)="allowDrop($event)"
        (drop)="dropColumn(i, $event)"
        *ngFor="let variable of statuesDetails().controls; let i = index"
        [formGroupName]="i"
      >
        <nz-row [nzGutter]="10" class="mb-10">
          <div
            style="cursor: grab"
            class="m-auto"
            nz-col
            [nzSpan]="2"
          >
            <img  src="../../../../assets/images/icons/arrowDragIcon.svg" />
          </div>
          <div nz-col [nzSpan]="18">
            <div class="d-flex" style="width: 100%">
              <nz-form-item style="width: 100%">
                <nz-form-control nzErrorTip="Enter value">
                  <nz-input-group>
                    <input
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
                      (input)="inputFieldName(i, $event)"
                      #defaultFieldValue
                      nz-input
                      formControlName="name"
                      placeholder="Tag name"
                     
                    />
                  </nz-input-group>
  
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col [nzSpan]="2">
            <div class="menuContainer" [style.background]="statuesDetails().at(i).get('color')?.value">
            <img
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu1"
              [nzOverlayClassName]="'selectDropDown'"
              class="cPointer"
              style="margin-top: 7px;padding: 7px;"
              src="../../../../assets/images/icons/actionThreeDots.svg"
            />
            </div>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
              <nz-row nz-menu style="padding: 10px">
                <nz-col
                  [nzSpan]="24"
                  nz-menu-item
                  class="text-center"
                  style="border-bottom: 1px solid #d2d2d2; color: #7c818c"
                >
                  Select color for the option
                </nz-col>
                <nz-col
                  [nzSpan]="6"
                  class="mt-10 mb-10"
                  style="display: flex; justify-content: center"
                  *ngFor="let item of color"
                >
                  <div
                    [style.background]="item.color"
                    (click)="updateColor(i, item.color)"
                    [ngClass]="
                      variable.value.color === item.color ? 'activeColor' : ''
                    "
                    class="cPointer"
                    style="height: 32px; width: 32px; border-radius: 50%"
                  ></div>
                </nz-col>
                <nz-col
                  [nzSpan]="24"
                  class="text-center cPointer"
                  style="
                    border-top: 1px solid #d2d2d2;
                    color: #1d66ff;
                    font-size: 12px;
                    margin-top: 10px;
                  "
                  (click)="removeColor(i)"
                >
                  Remove color
                </nz-col>
              </nz-row>
            </nz-dropdown-menu>
          </div>
          <div
            style="cursor: grab"
            nz-col
            [nzXs]="{ span: 2 }"
            [nzLg]="{ span: 2 }"
            [nzXXl]="{ span: 2 }"
          >
            <img
              *ngIf="statuesDetails().length > 1 && i > 0"
              class="cPointer"
              src="../../../../assets/images/icons/delete.svg"
              style="margin-left: 10px;margin-top: 7;"
              (click)="removeStatusOption(i);checkDuplicatename()"
            />
          </div>
          <nz-col [nzXs]="{ span: 19,offset:2}" [nzLg]="{ span: 19,offset:2}" [nzXXl]="{ span: 19,offset:2}">
            <div
            style="color: red"
            *ngIf="duplicateNameError && duplicateNameIndex === i"
          >
            Duplicate name entered
          </div>
          </nz-col>
        </nz-row>
      </nz-layout>
    </div>
    <nz-col [nzSpan]="6">
      <div class="addMore" style="cursor: pointer" (click)="addMore()">
        ++Add More
      </div>
    </nz-col>
  </nz-row>
  <nz-row style="justify-content: end">
    <div class="text-center cPointer">
      <button
        nzType="primary"
        class="f-10 fw-700 save"
        type="submit"
        style="padding: 8px 24px"
        (click)="confirm()"
      >
        SAVE
      </button>
    </div>
    <div class="text-center f-10 fw-700 lh-18 blueColor">
      <button
        nz-button
        nzType="default"
        class="buttonDefault cancel f-10 fw-700"
        style="border-radius: 5px; margin-left: 20px"
        (click)="close()"
      >
        CANCEL
      </button>
    </div>
  </nz-row>
  