<div class="px-10 textStyle " fluidRowWidth [type]="header.name">
  <div style="width: 100%;height:100%;" class="">
    <div style="cursor: pointer;text-align: left;" (click)="editing()" *ngIf="!(editval$|async)" class="editable-cell d-flex p-0 text-start word-break align-center min-height-37 inputContainer word-break-all">
    {{
    data[header.name]
    ? data[header.name]
    : header.default
    ? header.default
    : "&nbsp;&nbsp;&nbsp;"
    }}
    </div>
    <div class="d-flex text-start p-0 align-center min-height-37 inputContainer flex-column" *ngIf="editval$|async">
    <nz-input-group>
    <input [nzStatus]="this.header.error?'error':''" auto-focus-input class="text-start p-0" type="text" nz-input [(ngModel)]="data[header.name]" 
      (focusout)="checkValidation($event)" 
    />
    </nz-input-group>
    <div style="color: red ;font-size: smaller;text-align: left;">
    {{error}}
    </div>
    </div>
    </div>
</div>
