import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
})
export class PriorityComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() header: any;
  @Input() innerTable: any;
  @Input() data: any;
  @Input() parent: any;
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  editClicked = false;
  @Output() startDropDownEditEmitter: any = new EventEmitter<any>();
  onEditClick() {
    this.editClicked = true;
    this.startDropDownEditEmitter.emit();
  }
  handleDropdownClose(event: any) {
    if (!event) {
      this.editClicked = false;
    }
  }

  getColor(value: any) {
    let color = this.priority?.find((e: any) => e?.name === value)?.color;
    return color || '';
  }
  filterpriorityDefault(searchValue: any) {
    this.header.options = this.priority?.filter((e: any) =>
      e?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  cdr = inject(ChangeDetectorRef);
  error = '';
  check_is_unique(name: any) {
    let filterData: any = {};
    let body: any = {
      org_id: this.parent.pservice.appState().selectedOrganization.org_id,
      process_id: this.parent.pservice.appState().selectedProcess.process_id,
      process_name: this.parent.pservice
        .appState()
        .selectedProcess.process_name?.replace(/\s/g, '_'),
      entity_name: this.parent.pservice.appState()?.selectedEntity,
      entity_id: this.data?.entity_id,
    };
    if (this.header.is_unique && this.header.unq_sa) {
      filterData[this.header?.name] = this.data[this.header?.name];
    } else if (this.header.is_unique && !this.header.unq_sa) {
      let total_field = this.parent.pservice
        .appState()
        ?.metaData?.entities?.find(
          (_e: any) =>
            _e.name === this.parent.pservice.appState()?.selectedEntity
        )?.fields;
      total_field.forEach((_fields: any) => {
        if (_fields.is_unique && !_fields.unq_sa) {
          filterData[_fields?.name] = this.data[_fields?.name];
        }
      });
    }
    body['filter'] = filterData;
    if (this.header.is_unique) {
      this.parent.pservice.fetchEntityDataUnique(body).subscribe({
        next: (res: any) => {
          if (res) {
            if (res.exists) {
              this.error = `${this.header.label} value already exists`;
            } else {
              this.error = '';
              setTimeout(() => {
                if (this.error?.length === 0 || this.error === '') {
                  this.parent?.updateDataOnStopEdit(
                    { target: { value: name } },
                    this.header?.name,
                    this.data,
                    this.parent.erulistData
                  );
                }
              }, 100);
            }
            this.cdr.detectChanges();
          }
        },
        complete: () => {},
      });
    } else {
      setTimeout(() => {
        if (this.error?.length === 0 || this.error === '') {
          this.parent?.updateDataOnStopEdit(
            { target: { value: name } },
            this.header?.name,
            this.data,
            this.parent.erulistData
          );
        }
      }, 100);
    }

  }
  selectOption(name: string) {
    this.parent?.updateDataOnStopEdit(
      { target: { value: name } },
      this.header?.name,
      this.data,
      this.parent.erulistData
    );
  }
  removePriority() {
    this.parent?.updateDataOnStopEdit(
      { target: { value: '' } },
      this.header?.name,
      this.data,
      this.parent.erulistData
    );
  }
  clearInput: string = 'clear';

  close = false;
  private timeoutRef: any;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
