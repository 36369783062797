import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { cloneDeep } from 'lodash';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-table-head',
  templateUrl: './table-head.component.html',
  styleUrls: ['./table-head.component.scss'],
})
export class TableHeadComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() componentState: any;
  @Input() erulistData: any;
  @Input() tabName: any;
  @Input() selectedTabName: any;
  @Input() selectedSortLabel: any;

  @Output() sortGroups: EventEmitter<any> = new EventEmitter();
  @Output() tabelEventEmiter: EventEmitter<any> = new EventEmitter();

  dynamicWidth: any = {
    rating: '130px',
    dropdown: '150px',
    textbox: '200px',
    location: '150px',
    currency: '200px',
    number: '150px',
    website: '200px',
    labels: '200px',
    textarea: '400px',
    people: '250px',
    phone: '250px',
    email: '150px',
    progress: '200px',
    attachment: '100px',
    text_area_unwrapped: '200px',
    checkbox: '150px',
    dropdown_multi_select: '300px',
    dropdown_single_select: '300px',
    image: '100px',
    date: '150px',
    status: '150px',
    tag: '350px',
  };
  constructor(private ts: ProcessoCustomTableService) {}

  visible: boolean = false;
  openAction(): void {
    this.visible = true;
    this.isVisited = false;
  }
  isVisited = true;

  removeTabelHeader(item: any, index: any) {
    let selectedColumn = item?.label;
    let entities = this.erulistData?.entities;
    let tabNameIndex = entities?.findIndex(
      (e: any) => e?.name === this.tabName
    );
    if (tabNameIndex !== -1) {
      let fields = entities[tabNameIndex]?.fields;
      let columnIndex = fields?.findIndex(
        (field: any) => field.label === selectedColumn
      );
      if (columnIndex !== -1) {
        let data = {
          method: 'deleteColumn',
          headerName: item.name,
          selectedTabName: this.tabName,
          field: fields,
          index: columnIndex,
          selectedField: item.label,
        };
        this.tabelEventEmiter.emit(data);
      }
    }
  }
  sortingOrder: any = {};

  removeFromSort(name: any) {
    delete this.sortingOrder[name];
    this.newSortJson();
  }

  onResize({ width }: NzResizeEvent, col: string): void {
    this.dynamicWidth[col] = `${width}px`;
  }

  dynamic(e: any) {}
  draggedColumnIndex: any = [];

  public dragStartColumn(index: any) {
    this.draggedColumnIndex = index;
  }

  public allowDrop(event: any) {
    event.preventDefault();
  }

  public dropColumn(index: any) {
    this.arrayMove(this.getTableHeaders(), this.draggedColumnIndex, index);
  }

  public arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return arr;
    }

    const elementsWithGridIndexMinusOne = arr?.filter(
      (item) => item.grid_index === -1
    );

    arr = arr?.filter((item) => item.grid_index !== -1);

    const cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);

    arr = arr.map((item, index) => ({ ...item, grid_index: index }));

    arr = arr.concat(elementsWithGridIndexMinusOne);
    let noGridFIelds = cloneDeep(
      this.erulistData.entities[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]['fields']
    ).filter((e: any) => e.show_grid == 'no');
    arr = [...arr, ...noGridFIelds];
    this.erulistData.entities[
      this.erulistData.entities?.findIndex((e: any) => e.name === this.tabName)
    ]['fields'] = arr;
    return arr;
  }

  getTableHeaders() {
    let fields =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields;

    this.selectedTabName =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.display_name;
    let filteredData = fields
      ?.filter((e: any) => {
        return (
          (e?.show_grid == 'always' || e?.show_grid == 'yes') &&
          (this.selectedSortLabel?.[0]
            ? e?.name !== this.selectedSortLabel?.[0]
            : true)
        );
      })
      .sort((a: any, b: any) => a.grid_index - b.grid_index);
    return this.getShowFields().length > 0 ? filteredData || [] : [];
  }

  getShowFields(searchValue: string = '') {
    let fields =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields;

    let filteredData = fields
      ?.filter((e: any) => {
        const lowerCaseLabel = e?.label?.toLowerCase();
        const lowerCaseSearchValue = searchValue?.toLowerCase();
        return (
          (e?.show_grid == 'always' || e?.show_grid == 'yes') &&
          (lowerCaseSearchValue === '' ||
            lowerCaseLabel.includes(lowerCaseSearchValue))
        );
      })
      .sort((a: any, b: any) => a.grid_index - b.grid_index);

    return filteredData || [];
  }

  getSortOrder(name: any) {
    if (Object.keys(this.sortingOrder).includes(name)) {
      return true;
    } else {
      return false;
    }
  }

  getSortingOrderlength() {
    return Object.keys(this.sortingOrder).length;
  }

  getSortingOrderNumber(name: any) {
    const number = Object.keys(this.sortingOrder)?.findIndex(
      (item: any) => item === name
    );
    return Object.keys(this.sortingOrder).length > 1 ? number + 1 : '';
  }

  getSortingOrderArray() {
    return Object.keys(this.sortingOrder);
  }

  addToSortingOrder(name: any) {
    if (Object.keys(this.sortingOrder).length == 0) {
      this.sortingOrder[name] = 'asc';
    } else if (Object.keys(this.sortingOrder).length == 1) {
      if (Object.keys(this.sortingOrder).includes(name)) {
        if (this.sortingOrder[name] == 'asc') {
          this.sortingOrder[name] = 'desc';
        } else {
          delete this.sortingOrder[name];
        }
      } else {
        this.sortingOrder[name] = 'asc';
      }
    } else {
      if (Object.keys(this.sortingOrder).includes(name)) {
        if (this.sortingOrder[name] === 'asc') {
          this.sortingOrder[name] = 'desc';
        } else {
          this.sortingOrder[name] = 'asc';
        }
      } else {
        this.sortingOrder[name] = 'asc';
      }
    }
    this.newSortJson();
  }

  sortList: any = {};
  newSortJson() {
    this.sortList = { entity_data: [] };
    if (this.selectedSortLabel.length > 0) {
      this.sortList['entity_data'].push(
        this.selectedSortLabel[1] == 'desc'
          ? `-${this.selectedSortLabel[0]}`
          : this.selectedSortLabel[0]
      );
    }
    let headers = this.getTableHeaders();
    for (let item of Object.keys(this.sortingOrder)) {
      let name = headers?.find((j: any) => j.label === item)?.name;
      this.sortList['entity_data'].push(
        this.sortingOrder[item] == 'asc' ? name : `-${name}`
      );
    }
    this.sortGroups.emit({ ['sort']: this.sortList });
  }
}
