import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent {
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  ngOnInit(): void {}
  @Input() innerTable: any;
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  error = '';
  editing() {
    if (
      !this.header.can_edit ||
      this.header.tab_name === 'XX' ||
      this.data?.is_locked ||
      this.data?.lock_fields?.includes(this.header?.name)
    ) {
      return;
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    this.error = this.textboxValidation(this.header, e.target.value);
    this.check_is_unique(true);
    return;
    if (!this.error) {
      let tableEdit: any;
      if (this.innerTable) {
        tableEdit = 'innerTable';

        this.parent.updateDataOnInnerStopEdit(e, this.header.name, this.data);
      } else {
        this.parent.updateDataOnStopEdit(
          { target: { value: this.data[this.header?.name] } },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
      this.editval.next(false);
      this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    }
  }
  check_is_unique(e: any) {
    let filterData: any = {};
    let body: any = {
      org_id: this.parent.pservice.appState().selectedOrganization.org_id,
      process_id: this.parent.pservice.appState().selectedProcess.process_id,
      process_name: this.parent.pservice
        .appState()
        .selectedProcess.process_name?.replace(/\s/g, '_'),
      entity_name: this.parent.pservice.appState()?.selectedEntity,
      entity_id: this.data?.entity_id,
    };
    if (this.header.is_unique && this.header.unq_sa) {
      filterData[this.header?.name] = this.data[this.header?.name];
    } else if (this.header.is_unique && !this.header.unq_sa) {
      let total_field = this.parent.pservice
        .appState()
        ?.metaData?.entities?.find(
          (_e: any) =>
            _e.name === this.parent.pservice.appState()?.selectedEntity
        )?.fields;
      total_field.forEach((_fields: any) => {
        if (_fields.is_unique && !_fields.unq_sa) {
          filterData[_fields?.name] = this.data[_fields?.name];
        }
      });
    }
    body['filter'] = filterData;
    if (this.header.is_unique) {
      this.parent.pservice.fetchEntityDataUnique(body).subscribe({
        next: (res: any) => {
          if (res) {
            if (res.exists) {
              this.error = `${this.header.label} value already exists`;
            } else {
              this.error = '';
              setTimeout(() => {
                if (this.error?.length === 0 || this.error === '') {
                  if (!this.error) {
                    let tableEdit: any;
                    if (this.innerTable) {
                      tableEdit = 'innerTable';
                      this.editval.next(false);
                      this.parent.updateDataOnInnerStopEdit(
                        e,
                        this.header.name,
                        this.data
                      );
                    } else {
                      this.editval.next(false);
                      this.parent.updateDataOnStopEdit(
                        { target: { value: this.data[this.header?.name] } },
                        this.header.name,
                        this.data,
                        this.parent.erulistData
                      );
                    }
                    this.editval.next(false);
                    this.parent.saveEntityDropdownData(
                      this.header,
                      tableEdit,
                      this.data
                    );
                  }
                }
              }, 100);
            }
            this.cdr.detectChanges();
          }
        },
        complete: () => {},
      });
    } else {
      setTimeout(() => {
        if (this.error?.length === 0 || this.error === '') {
          if (!this.error) {
            let tableEdit: any;
            if (this.innerTable) {
              tableEdit = 'innerTable';
              this.editval.next(false);
              this.parent.updateDataOnInnerStopEdit(
                e,
                this.header.name,
                this.data
              );
            } else {
              this.editval.next(false);
              this.parent.updateDataOnStopEdit(
                { target: { value: this.data[this.header?.name] } },
                this.header.name,
                this.data,
                this.parent.erulistData
              );
            }
            this.editval.next(false);
            this.parent.saveEntityDropdownData(
              this.header,
              tableEdit,
              this.data
            );
          }
        }
      }, 100);
    }

  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    if (field.mandatory && !inputText) {
      return `${field?.label} is mandatory`;
    } else {
      return '';
    }
  }
}
