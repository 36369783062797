import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-create-field',
  templateUrl: './create-field.component.html',
  styleUrls: ['./create-field.component.scss']
})
export class CreateFieldComponent {
  @Input()fieldData:any;
  @Output()showNewFieldOutput = new EventEmitter<any>()
  @Input()availableFields:any
  listOfFields:any;
storeListOfField:any;
saveAvailableFields:any;
constructor(){}
  ngOnInit(): void {
  this.saveAvailableFields = this.availableFields?.sort((a:any,b:any)=> a.label?.localeCompare(b?.label))
  this.storeListOfField = this.fieldData?.new_fields
  this.listOfFields = this.storeListOfField
  }
  FilterField(search:any){
    this.listOfFields =  this.storeListOfField?.filter((e:any)=> e?.label?.toLowerCase()?.includes(search?.toLowerCase()))
   
  }
  FilteravailableField(search:any){
    this.saveAvailableFields =  this.availableFields?.filter((e:any)=> e?.label?.toLowerCase()?.includes(search?.toLowerCase()))
  }
  selectedField(item:any){
    let data ={
      selectedData:'createNewField',
      data:item
    }
    this.showNewFieldOutput.emit(data)
  }
  editField(item:any){
    let data ={
      selectedData:'editField',
      data:item
    }
    this.showNewFieldOutput.emit(data)
  }
  deleteField(item:any){
    let data ={
      selectedData:'deleteField',
      data:item
    }
    this.showNewFieldOutput.emit(data)
  }

}
