import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@processo/core/authentication/auth.service';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-time-popup-component',
  templateUrl: './time-popup-component.component.html',
  styleUrls: ['./time-popup-component.component.scss']
})
export class TimePopupComponentComponent {
  count:any;
  timer:any;
  constructor(
    private router:Router,
    private modalRef: NzModalRef,
    private auth_Service:AuthService
  ){}
  private app_state = inject(AppstateService);
  ngOnInit() {
    this.count = 30
    
    // this.timer = setTimeout(() => 
    // {
    //   localStorage.clear();
    //   this.router.navigateByUrl('/login');
    //   this.modalRef.destroy()
    // }
    // ,this.count * 1000);
    this.timer = setInterval(() => {
      if (this.count > 0) {
        this.count--;
      } else {
        clearInterval(this.timer);
        localStorage.clear();
        this.app_state.resetAppState()
        this.router.navigateByUrl('/login');
        this.modalRef.destroy();
      }
    }, 1000); // Update the count every second
  }
  
  cancelClickHandle(event : any) {
    clearInterval(this.timer);
    const refresh_token: any = localStorage.getItem('refresh_token');
    if (
      refresh_token &&
      refresh_token != '' &&
      refresh_token != 'undefined'
    ) {
    this.auth_Service.updateToken().subscribe();
    }else{
      localStorage.clear();
      this.app_state.resetAppState()
      this.router.navigateByUrl('/login');
    }
    this.modalRef.destroy('reset')
  }
}
