import { Injectable } from '@angular/core';
import { ILoginResponse } from '../models/IResponse.model';

enum EToken {
  REFRESH_TOKEN_KEY = 'refresh_token',
  ID_TOKEN = 'id_token',
  USER_ID ='id'
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private idToken!: string | null;
  private accessToken!: string | null;

  saveTokens(tokenResponse: ILoginResponse): void {
    if (tokenResponse.access_token) {
      this.accessToken = tokenResponse.access_token;
    }
    if (tokenResponse.id_token) {
      this.idToken = tokenResponse.id_token;
    }
    if (tokenResponse.refresh_token) {
      localStorage.setItem(EToken.REFRESH_TOKEN_KEY, tokenResponse.refresh_token);
    }
    if (tokenResponse.id) {
      localStorage.setItem(EToken.USER_ID, tokenResponse.id);
    }
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getIdToken(): string | null {
    return this.idToken
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(EToken.REFRESH_TOKEN_KEY);
  }
  getID(): string | null {
    return localStorage.getItem(EToken.USER_ID);
  }

  clearTokens(): void {
    this.idToken = null;
    this.accessToken = null;
    localStorage.clear()
  }
}
