<div class="" style="display: flex; align-items: center" [hidden]="showRow?false:true">
  <div style="width: 30px">
    <input type="checkbox" (click)="parent.addIds(data.entity_id)" />
  </div>
  <ng-container *ngFor="let header of headerData;">
   <lib-custom-cell [header]="header" [parent]="parent" [data]="data"></lib-custom-cell>
  </ng-container>

  <div style="background: white">
    <div class="px-10">
      <img nz-menu nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="cPointer"
        src="../../assets/images/dot.png" alt="" />
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li class="f-12" nz-menu-item (click)="parent.editRow(data)">Edit</li>
        <li class="f-12" nz-menu-item (click)="parent.deleteRow(data.entity_id)">
          Delete
        </li>
        <li class="f-12" nz-menu-item (click)="parent.openVisibilityOptions(data)">
          Visibility
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>

