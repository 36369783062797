import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
interface dataValue {
  color: number;
  value: string;
  name: string;
  defaultValue: string;
}
@Component({
  selector: 'lib-add-statuses-popup',
  templateUrl: './add-statuses-popup.component.html',
  styleUrls: ['./add-statuses-popup.component.scss'],
})
export class AddStatusesPopupComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  select_more_color = '#1677ff';
  select_extra_color_change(event: any) {
    
  }
  
  is_random(color: any) {
    if (color) {
      let default_color = this.statuesColor.some(
        (_e: any) => _e.color?.toLowerCase() === color?.toLowerCase()
      );
      if (default_color) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  addStatuessForm!: FormGroup;
  statuesColor: any = [
    {
      color: '#1DF1FF',
    },
    {
      color: '#56C0C6',
    },
    {
      color: '#FF1DE8',
    },
    {
      color: '#F70295',
    },
    {
      color: '#FFC01D',
    },
    {
      color: '#CF6BFF',
    },
    {
      color: '#E9FD00',
    },
    {
      color: '#FF8058',
    },
  ];
  popupHeaderName!: string;
  defaultValue: any;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  constructor(private modal: NzModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.defaultValue = this.nzModalData.value;
    this.popupHeaderName = this.nzModalData.data.popupName;

    this.createForm();
  }

  createForm() {
    this.addStatuessForm = this.fb.group({
      statuesDetails: this.fb.array([]),
    });
    if (this.defaultValue && this.defaultValue.length > 0) {
      this.defaultValue.forEach((tabData: dataValue | undefined) => {
        this.statuesDetails().push(this.newStatusAddDetails(tabData));
      });
      // if (
      //   (this.nzModalData.selectedStatus === 'openStatus' || this.nzModalData.selectedStatus === 'closeStatus') &&
      //   this.nzModalData?.derivedFromChild
      // ) {
      //   if (
      //     this.addStatuessForm.value.statuesDetails.every(
      //       (item: any) => item.df === null
      //     )
      //   ) {
      //     this.addStatuessForm.value.statuesDetails[0].df =true
      //   }
      // }
    } else {
      let tabData = {
        color: '',
        name: '',
        df: true,
      };
      this.statuesDetails().push(this.newStatusAddDetails(tabData));
      // this.addMore();
    }
  }

  newStatusAddDetails(tabData: dataValue | any = {} as dataValue): FormGroup {
    const statusValue = tabData.value || '';
    const color = tabData.color || '';
    const statusName = tabData.name;
    const defaultValue = tabData?.df;

    // if (this.nzModalData.selectedStatus === 'closeStatus' || this.nzModalData.selectedStatus === 'openStatus') {
    //   return this.fb.group({
    //     color: [color],
    //     name: [statusName, [Validators.required]],
    //     df: [defaultValue],
    //   });
    // }
    // return this.fb.group({
    //   color: [color],
    //   name: [statusName, [Validators.required]],
    // });
    return this.fb.group({
      color: [color],
      name: [statusName, [Validators.required]],
      df: [defaultValue],
    });
  }

  statuesDetails(): FormArray {
    return this.addStatuessForm.get('statuesDetails') as FormArray;
  }
  selecterColorValue!: string;
  updateColor(index: number, color: string) {
    this.statuesDetails().at(index).get('color')?.setValue(color);
  }
  updatedefault(index: number, name: string) {
    this.addStatuessForm.value.statuesDetails.map((e: any) => {
      e.df = false;
    });
    this.statuesDetails().at(index).get(`df`)?.setValue(true);
  }
  close() {
    this.modal.destroy();
  }

  confirm() {
    if (this.addStatuessForm.valid && !this.duplicateNameError) {
      this.modal.destroy(this.addStatuessForm.value.statuesDetails);
    }
  }

  addMore() {
    if (!this.duplicateNameError) {
      this.statuesDetails().push(this.newStatusAddDetails());
    }
  }
  removeColor(index: any) {
    const statusDetailsArray = this.addStatuessForm.get(
      'statuesDetails'
    ) as FormArray;
    const selectedStatus = statusDetailsArray.at(index);
    selectedStatus.get('color')?.setValue('');
  }
  draggedColumnIndex: any;
  draggedColumn: any;
  dragStartColumn(i: any, e: any) {
    if (!this.duplicateNameError) {
      // this.draggedColumnIndex = i;
      this.draggedColumn = this.statuesDetails().at(i);
    }
  }
  allowDrop(e: any) {
    if (!this.duplicateNameError) {
      e.preventDefault();
    }
  }
  // dropColumn(i: any, e: any) {
  //   if (!this.duplicateNameError) {
  //     this.arrayMove(
  //       this.statuesDetails().controls,
  //       this.draggedColumnIndex,
  //       i
  //     );
  //   }
  // }
  dropColumn(i: any, e: any) {
    if (!this.duplicateNameError) {
      const targetControl = this.statuesDetails().at(i);

      // Remove dragged control from its current position
      const draggedIndex = this.statuesDetails().controls.indexOf(
        this.draggedColumn
      );
      this.statuesDetails().removeAt(draggedIndex);

      // Find new position and insert the dragged control
      const targetIndex = this.statuesDetails().controls.indexOf(targetControl);
      this.statuesDetails().insert(targetIndex, this.draggedColumn);

      this.draggedColumn = null;
    }
  }

  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    this.draggedColumnIndex = null;
  }
  duplicateNameError: boolean = false;
  duplicateNameIndex!: number | null;
  inputFieldName(index: number, event: any) {
    let inputText = event.target.value;
    let result = this.statuesDetails().controls.map((e: any, i: any) => {
      return { ...e.value, index: i };
    });

    let isDuplicate = result?.filter((e: any) => e.name === inputText);
    if (isDuplicate.length > 1) {
      this.duplicateNameError = true;
      this.duplicateNameIndex = index;
    } else {
      this.duplicateNameError = false;
      this.duplicateNameIndex = null;
    }
  }
  checkDuplicatename() {
    const nameSet = new Set<string>();
    for (const obj of this.addStatuessForm.value.statuesDetails) {
      if (nameSet.has(obj.name)) {
        this.duplicateNameError = true;
        return false;
      } else {
        this.duplicateNameError = true;
        nameSet.add(obj.name);
      }
    }
    if (nameSet) {
      this.duplicateNameError = false;
    }
    return true;
  }
  removeStatusOption(index: number) {
    const statuesDetailsArray = this.addStatuessForm.get(
      'statuesDetails'
    ) as FormArray;
    statuesDetailsArray.removeAt(index);
  }
  getDefault(index: number) {
    let value = this.addStatuessForm?.value?.statuesDetails[index];
    if (value && value[`df`]) {
      return true;
    } else {
      return false;
    }
  }
  hexToRgba(hex: string, alpha: number): string {
    if(hex?.length>0){
      hex = hex.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }else{
      return 'none'
    }  
  }
}
