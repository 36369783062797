import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-text-area-unwrapped',
  templateUrl: './text-area-unwrapped.component.html',
  styleUrls: ['./text-area-unwrapped.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaUnwrappedComponent implements OnInit {
  @Input() data: any;
  @Input() header: any;
  @Input() editId: any;
  @Input() index: any;
  @Output() startedit: any = new EventEmitter<any>();
  @Output() stopedit: any = new EventEmitter<any>();
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  ngOnInit(): void {
    if (this.header.mandatory) {
      this.data[this.header.name] =
        this.data[this.header.name] ?? this.header.default;
    }
  }

  startEdit(name: any, index: any) {
    this.startedit.emit({ name: name, index: index });
  }

  stopEdit() {
    this.stopedit.emit();
  }

  changeinData() {}
}
