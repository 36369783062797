import { ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit, Signal, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { orderBy } from 'lodash';
import { toObservable } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';
import { extractEntityName } from '@processo/shared/state/appstate.logic';
import * as _ from 'lodash';
@Component({
  selector: 'app-header-nav-bar',
  templateUrl: './header-nav-bar.component.html',
  styleUrls: ['./header-nav-bar.component.scss']
})
export class HeaderNavBarComponent implements OnInit  {
  isMenuOpen:boolean = false
  appStateService = inject(AppstateService);
  _router = inject(ActivatedRoute);
  router = inject(Router);
  params:any = signal({});
  entities!:Signal<any>
  index =0
  entity_display_name:any
  constructor(){
    this._router.params.subscribe((params:any)=>{
    this.params.set(params);
    this.entities=this.appStateService.getEntityTableHeaders(this.params().organization_name,
      this.params().process_name);
      this.get_display_name()
    })
    this.appStateService.stateUpdate.pipe().subscribe((response) => {

      this.entities = this.appStateService.getEntityTableHeaders(this.params().organization_name,
        this.params().process_name);

      this.get_display_name()

    })
    this.appStateService.call_initial_fetch.pipe().subscribe({
      next: (_res: any) => {
        if (_res === 'call_initial_api') {
          this.get_display_name();
        }
      },
    });

  }
  get_display_name(){
    let url_from = 'view'
    if(this.router.url.includes('view')){
      url_from = 'view'
    }else if(this.router.url.includes('new')){
      url_from = 'new'
    }else if(this.router.url.includes('edit')){
      url_from = 'edit'
    }else if(this.router.url.includes('bulk-import')){
      url_from = 'bulk-import'
    }
    if(this.router.url.includes('my-approval')){
      this.entity_display_name = 'no_data'
    }
  extractEntityName(decodeURIComponent(this.router.url), url_from).then((res) => {
    if(res){
      this.entity_display_name = res  
    }

  })
 

  
  }
  cdr = inject(ChangeDetectorRef)
  changeTab(tab:any){
    this.router.navigateByUrl(`/org/${this.params().organization_name}/process/${this.params().process_name}/view/${tab.display_name}`)

  }


  findTabIndex(entities:any){
      const split = decodeURIComponent(this.router.url).split('/').reverse();
    const displayName = (split.includes('edit') ? split[1] : split[0])?.split('?')[0];
    if (displayName === 'my-approval') {
        return 9999;
    }
    if (displayName === 'Dashboard') {
      return -1;
    }

      const index = entities.findIndex((tab:any) => tab.display_name === displayName);

      return index;
  }
  ngOnInit(): void {

    



  }


  ngAfterViewInit(){
    // this.router.navigateByUrl(`/org/${this.params().organization_name}/process/${this.params().process_name}/${this.entities()[0]?.display_name}`)
  }

}
