import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-more-popup',
  templateUrl: './add-more-popup.component.html',
  styleUrls: ['./add-more-popup.component.scss'],
})
export class AddMorePopupComponent implements OnInit {
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  data: any;
  addOrgForm!: FormGroup;
  entityForm!: FormGroup;
  addFieldForm!: FormGroup;
  selectedImgKey!: string;
  processIcons: any = [
    {
      key: 'lockIcon',
      image: '../../../../assets/images/createProcessIcon/lockIcon.svg',
    },
    {
      key: 'cartIcon',
      image: '../../../../assets/images/createProcessIcon/cartIcon.svg',
    },
    {
      key: 'bankIcon',
      image: '../../../../assets/images/createProcessIcon/bankIcon.svg',
    },
    {
      key: 'boxOpen',
      image: '../../../../assets/images/createProcessIcon/boxOpen.svg',
    },
    {
      key: 'folder',
      image: '../../../../assets/images/createProcessIcon/folder.svg',
    },
    {
      key: 'code',
      image: '../../../../assets/images/createProcessIcon/code.svg',
    },
    {
      key: 'money-bag',
      image: '../../../../assets/images/createProcessIcon/money-bag.svg',
    },
    {
      key: 'factory',
      image: '../../../../assets/images/createProcessIcon/factory.svg',
    },
    {
      key: 'home',
      image: '../../../../assets/images/createProcessIcon/home.svg',
    },
    {
      key: 'addressBook',
      image: '../../../../assets/images/createProcessIcon/addressBook.svg',
    },
    {
      key: 'Medkit',
      image: '../../../../assets/images/createProcessIcon/Medkit.svg',
    },
    {
      key: 'ball',
      image: '../../../../assets/images/createProcessIcon/ball.svg',
    },
    {
      key: 'graph',
      image: '../../../../assets/images/createProcessIcon/graph.svg',
    },
    {
      key: 'mapMarker',
      image: '../../../../assets/images/createProcessIcon/mapMarker.svg',
    },
    {
      key: 'briefCase',
      image: '../../../../assets/images/createProcessIcon/briefCase.svg',
    },
    {
      key: 'plant',
      image: '../../../../assets/images/createProcessIcon/plant.svg',
    },
    {
      key: 'default_process',
      image: '../../../../assets/images/createProcessIcon/default_process.svg',
    },
    {
      key: 'supply_finance',
      image: '../../../../assets/images/createProcessIcon/supply_finance.svg',
    },
  ];
  tab: any;
  constructor(
    private modalRef: NzModalRef,
    private _createform: UntypedFormBuilder,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.data = this.nzModalData?.data;
    this.initiateForm();
    this.initiateEntityForm();
    this.createForm();
    if (this.data.show === 'entity') {
      this.tab = this.data.tab;
      this.tab.forEach((e: any) => {
        this.statuesDetails().push(this.newStatusAddDetails(e.display_name));
      });
    }
  }
  initiateForm() {
    this.addOrgForm = this._createform.group({
      processname: [null, [Validators.required]],
      displayname: [null, [Validators.required]],
    });
  }
  initiateEntityForm() {
    this.entityForm = this._createform.group({
      entityname: [null, [Validators.required]],
      displayname: [null, [Validators.required]],
      isPeople: [false, [Validators.required]],
      enableConversation: [false, [Validators.required]],
      // entityPlaced: [null, [Validators.required]],
      // entityName: [null, [Validators.required]],
    });
  }
  selectedImgBase64: string | undefined;
  selectedProcessIcon(data: any) {
    this.selectedImgKey = data.key;
    this.loadImageAsBase64(data.image);
  }
  // loadImageAsBase64(imageUrl: string) {    
  //   fetch(imageUrl)
  //     .then((response) => response.arrayBuffer())
  //     .then((buffer) => {
  //       // Convert ArrayBuffer to base64
  //       const base64 = this.arrayBufferToBase64(buffer);
  //       // Assign base64 data to selectedImgBase64
  //       this.selectedImgBase64 = base64;
  //     })
  //     .catch((error) => {
  //     });
  // }
  loadImageAsBase64(imageUrl: string) {    
    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.arrayBuffer();
      })
      .then((buffer) => {
        const base64 = this.arrayBufferToBase64(buffer);
        this.selectedImgBase64 = base64;
      })
      .catch((error) => {
        console.error('Error loading image:', error);
      });
  }
  

  arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  cancel() {
    this.modalRef.destroy();
  }
  save() {
    if (
      this.addOrgForm.valid &&
      !this.processNameError &&
      !this.displayNameError
    ) {
      let data = {
        processname: this.addOrgForm?.value?.processname,
        displayname: this.addOrgForm?.value?.displayname,
        icons: this.selectedImgBase64,
      };
      this.modalRef.destroy(data);
    } else {
      Object.values(this.addOrgForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  saveEntityForm() {
    if (this.entityForm.valid) {
      if (
        this.addFieldForm &&
        this.addFieldForm.value &&
        this.addFieldForm.value.statuesDetails &&
        this.editIndex !== undefined
      ) {
        const statuesDetailsArray = this.statuesDetails();
        if (statuesDetailsArray && statuesDetailsArray.length > 1) {
          const secondObject = statuesDetailsArray.controls[this.editIndex];
          if (secondObject) {
            const nameControl = secondObject.get('name');
            if (nameControl) {
              nameControl.patchValue(this.entityForm?.value.displayname);
              this.editIndex = null;
              this.initiateEntityForm();
            }
          }
        }
        return;
      }

      this.statuesDetails().push(
        this.newStatusAddDetails(this.entityForm.value.displayname)
      );
      this.initiateEntityForm();
    }
  }
  editIndex: any;
  editEntityName(value: any, index: any) {
    const fieldName = value.get('name').value;
    this.editIndex = index;
    let getselectedTab = this.tab.find(
      (e: any) => e.display_name === fieldName
    );
    if (getselectedTab) {
      this.entityForm.get('entityname')?.setValue(getselectedTab.name);
      this.entityForm.get('displayname')?.setValue(getselectedTab.display_name);
    }
  }
  createForm() {
    this.addFieldForm = this.fb.group({
      statuesDetails: this.fb.array([]),
    });
  }
  statuesDetails(): FormArray {
    return this.addFieldForm.get('statuesDetails') as FormArray;
  }
  addMore() {
    this.statuesDetails().push(this.newStatusAddDetails());
  }
  newStatusAddDetails(e?: any): FormGroup {
    const statusName = e || '';
    return this.fb.group({
      // label: [
      //   null,
      //   [Validators.required],
      // ],
      name: [statusName, [Validators.required]],
    });
  }
  draggedColumnIndex: any;
  dragStartColumn(i: any, e: any) {
    this.draggedColumnIndex = i;
  }
  allowDrop(e: any) {
    e.preventDefault();
  }
  dropColumn(i: any, e: any) {
    this.arrayMove(this.statuesDetails().controls, this.draggedColumnIndex, i);
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    this.draggedColumnIndex = null;
  }
  processNameError = false;
  displayNameError = false;
  checkExistingProcessName(e: any, type: any) {
    
    if (type === 'display') {
      const inputValue = e.trim().toLowerCase().replace(/\s+/g, '');
      let isDuplicate = this.data.processList.some((el: any) => {
          const normalizedProcessName = el.process_name.trim().toLowerCase().replace(/\s+/g, '');
          return normalizedProcessName === inputValue;
      });

      this.displayNameError = isDuplicate;
  }

    if (type == 'process') {
      const inputValue = e.trim().toLowerCase().replace(/\s+/g, '');
      let isDuplicate = this.data.processList.some((el: any) => {
          const normalizedProcessName = el.process_name.trim().toLowerCase().replace(/\s+/g, '');
          return normalizedProcessName === inputValue;
      });
      this.processNameError = isDuplicate;
    
    }
  }
}
