import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  standalone:true,
  imports:[NgIf]
})
export class ConfirmationComponent {
   nzModalData: any = inject(NZ_MODAL_DATA);
   modal_ref =inject(NzModalRef)
   constructor(){   
     
   }
   close(value:any){
    this.modal_ref.close(value)
   }
}
