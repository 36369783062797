export const environment = {
  type: 'DEVELOPMENT',
  production: false,
  debugMode: false,
  idleTime: 1800, //60 (1 minutes) 1800(30 minutes) if user dont use the app logout user after 30min
  timeout: 30,
  base_eru_auth_url: 'https://eruauth.dev.processo.io',
  base_eru_routes_url: 'https://erufunc.dev.processo.io',
  base_url: 'https://eruql.dev.processo.io',
  superSeturl: "https://ss.dev.erutech.io/",
  is_artfine:false,
  ping:2400,//60 (1 minutes) 2400(40 minutes) to call refresh token every 40min
  custom_domain:'processo.io'
};
