import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirm-switch-tab-popup',
  templateUrl: './confirm-switch-tab-popup.component.html',
  styleUrls: ['./confirm-switch-tab-popup.component.scss']
})
export class ConfirmSwitchTabPopupComponent {
  constructor(private modalRef: NzModalRef){}
  stay(){
    this.modalRef.destroy('stay')
  }
  leave(){
    this.modalRef.destroy('leave')
  }
}
