<div class="px-10 textStyle" fluidRowWidth [type]="header.name" >
  <div style="width: 100%;height:100%;" class="field_hover" >
    <ng-container *ngIf="!header.rich_text">
      <div class="editable-cell emphasis d-flex text-start p-0 word-break-all min-height-37 align-center"
      (click)="editing()" *ngIf="!(editval$|async)">
      {{
      data[header.name]
      ? data[header.name]
      : header.default
      ? header.default
      : "&nbsp;&nbsp;&nbsp;"
      }}
    </div>
    </ng-container>
    <ng-container *ngIf="header.rich_text">   
      <div [ngClass]="(editval$|async) ?'editorstyle':'editor'" class="editor" (click)="editing()">          

        <ngx-editor [placeholder]="''" (paste)="onPaste()" (focusout)="textareaUpdate(data[header.name],$event)" [editor]="editor" [(ngModel)]="data[header.name]"></ngx-editor>
      </div>      
    </ng-container>
    <div class="d-flex flex-column">
      <ng-container *ngIf="!header.rich_text">
        <textarea style="padding: 2px;" rows="2" nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }"
        [(ngModel)]="data[header.name]" *ngIf="editval$|async" (focusout)="checkValidation($event)"></textarea>
      </ng-container>
      <div style="color: red ;font-size: smaller;text-align: left;">
        {{error}}
      </div>
    </div>
  </div>
</div>
