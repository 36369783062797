
        <div class="group-sec" style="pointer-events: none">
          <div [ngClass]="{ isOpenClass: colapsePanel.nzActive }" style="pointer-events: auto">
            <img src="../../../assets/images/down-arrow.png" />
          </div>
          <div style="pointer-events: auto;font-size: 12px;">
            {{
            groupedArray !== "undefined"
            ? "Grouping" + " -" + groupedArray
            : "No grouping"
            }}
            -
            {{ componentState?.groupedData[groupedArray]?.length || 0 }}
            rows
          </div>
        </div>