import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { StateService } from '@processo/shared/state/state.service';

export const authGuard: CanActivateFn = () => {
  const state = inject(StateService);
  const tokenService = inject(TokenService);
  const router = inject(Router);

  if (state.isUserLoggedIn().loggedIn) {
    return true;
  } else {
    state.clearState();
    tokenService.clearTokens();
    router.navigate(['/']);
    return false;
  }
};
