<!-- <ng-container *ngIf="header.datatype === 'textbox'" #textbox>
    <lib-input-field [header]="header"  [data]="data" [parent]="parent"></lib-input-field>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'email'" #email>
    <lib-email [header]="header"  [data]="data" [parent]="parent"></lib-email>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'textarea'" #textarea>
    <lib-text-area-wrapped [header]="header"  [data]="data" [parent]="parent"></lib-text-area-wrapped>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'number'" #number>
    <lib-number [header]="header"  [data]="data" [parent]="parent"></lib-number>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'currency'" #currency>
    <lib-currency [header]="header"  [data]="data" [parent]="parent"></lib-currency>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'checkbox'" #checkbox>
    <lib-checkbox [header]="header"  [data]="data" [parent]="parent"></lib-checkbox>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'rating'" #rating>
    <lib-ratings [header]="header"  [data]="data" [parent]="parent"></lib-ratings>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'progress'" #progress>
    <lib-progress [header]="header"  [data]="data" [parent]="parent"></lib-progress>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'dropdown_single_select'" #dropdown_single_select>
    <lib-dropdown [header]="header"  [data]="data" [parent]="parent"></lib-dropdown>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'dropdown_multi_select'" #dropdown_multi_select>
    <lib-dropdown-multiple [header]="header"  [data]="data" [parent]="parent"></lib-dropdown-multiple>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'status'" #status>
    <lib-status [header]="header"  [data]="data" [parent]="parent"></lib-status>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'phone'" #phone>
    <lib-phone [header]="header"  [data]="data" [parent]="parent"></lib-phone>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'tag'" #tag>
    <lib-tag [header]="header"  [data]="data" ></lib-tag>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'people'" #people>
    <lib-people [header]="header"  [data]="data" ></lib-people>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'attachment'" #attachment>
    <lib-attachment [header]="header"  [data]="data" ></lib-attachment>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'website'" #website>
    <lib-website [header]="header"  [data]="data" [parent]="parent"></lib-website>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'date'" #date>
    <lib-date [header]="header"  [data]="data" [parent]="parent"></lib-date>
    </ng-container>
    <ng-container *ngIf="header.datatype === 'location'" #location>
    <lib-location [header]="header"  [data]="data" [parent]="parent"></lib-location>
    </ng-container> -->
    <!-- <div [style.width]="parent.dynamicWidth[header.datatype]">
        <ng-container #appDynamicComponentLoader></ng-container>

    </div> -->
    <div [style.width]="'100%'">
        <ng-container #appDynamicComponentLoader></ng-container>

    </div>
