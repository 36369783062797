<div class="f-14 fw-500 lh-24">
  <div class="" *ngIf="type != 'tab'&& type !== 'workFlow'">
    This action will remove the {{ type }} {{ selectedColumn }} data  from the
    table<br />
    Are you sure you want to proceed ?
    <br />
    <br />
    <div *ngIf="(!(this.nzModalData.data.hide_child_record)) &&childEntities && childEntities.size > 0">
      Delete Entity for child records:
   

        <nz-select nzAutoFocus="false" nzAllowClear="true" style="width: 100%;" nzMode="multiple" nzPlaceHolder="" [(ngModel)]="selectedChildEntities">
          <nz-option *ngFor="let option of childEntities|keyvalue" [nzLabel]="get_label(option.value['en'])" [nzValue]="option.key"></nz-option>
        </nz-select>
      
    </div>
  </div>
  <div class="" *ngIf="type === 'tab'">
    <div class="">
      {{ text }}
    </div>
    <div class="mt-20" *ngIf="tabs.length > 0">
      <nz-select style="width: 100%" [(ngModel)]="selectedNewTab" (ngModelChange)="showError=false">
        <nz-option
          *ngFor="let i of tabs"
          [nzValue]="i.name"
          [nzLabel]="i.label"
        ></nz-option>
      </nz-select>
      <div class="errorStyle" *ngIf="showError">required tab</div>
    </div>
  </div>
  <div class="" *ngIf="type === 'workFlow'">
    <div class="">
      {{ text }}
    </div>
  </div>
  <nz-layout class="action-btn mt-30" style="padding-bottom: 10px">
    <nz-row class="justifyCenter">
      <div class="text-center cPointer"  style="border: none!important;">
        <button 
        style="border: none!important;"
          nzType="primary"
          class="f-10 fw-700"
          type="submit"
          (click)="handleOk()"
        >
          YES, DELETE
        </button>
      </div>
      <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
        <button
          nz-button
          nzType="default"
          class="buttonDefault f-10 fw-700"
          style="height: 37px; border-radius: 5px; margin-left: 20px"
          (click)="handleCancel()"
        >
          NO, DON’T DELETE
        </button>
      </div>
    </nz-row>
  </nz-layout>
</div>
