import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as codemirror from "codemirror";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";


@Component({
  selector: 'app-json-text-area',
  templateUrl: './json-text-area.component.html',
  styleUrls: ['./json-text-area.component.scss']
})


export class JsonTextAreaComponent implements OnInit, AfterViewInit {
  @ViewChild("textarea", { static: true }) textarea!: ElementRef;
  @Output() enteredValue = new EventEmitter <any>();
  @Input()dataValue!:any
  private _data: any; 
  set data(value: any) {
    this._data = value;
    if (this.editor) {
      this.editor?.setValue(this._data);  // Update editor value when data changes
    }
  }
  
  get data(): any {
    return this._data;
  }

  editor!: codemirror.EditorFromTextArea;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.editor = codemirror.fromTextArea(this.textarea.nativeElement, {
      mode: "text/x-mysql",
      indentWithTabs: true,
      smartIndent: true,
      lineNumbers: true,
      autofocus: false,
      showHint: true,
      extraKeys: { "Ctrl-Space": "autocomplete" }
    });

    this.editor.setValue(this._data || this.dataValue ||'');

    this.editor.on('change', () => {
      this._data = this.editor?.getValue();
      this.enteredValue.emit({value:this._data})
    });
  }
}

