<div class="px-10 textStyle tag" fluidRowWidth [type]="header.name">
  <ng-container *ngIf="!editClicked">
    <!-- {{data[header.name] || header.default|json}} -->
    <div class="showTagStyle" (click)="onEditClick(tagSelector)">
      <ng-container
        *ngFor="
          let item of data[header?.name] || header?.default | slice : 0 : 2
        "
      >
        <div class="cardTag" [style.background]="getBackgrounfColor(item)">
          {{ item }}
        </div>
      </ng-container>
      <ng-container *ngIf="(data[header.name] || header.default).length > 2">
        <div class="cardTag">
          + {{ (data[header.name] || header.default).length - 2 }} more
        </div>
      </ng-container>
    </div>

    <!-- <div (click)="onEditClick()" class="showDefault word-break-all min-height-37 align-center d-flex" style="
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        justify-content: center;
      ">
      {{ data[header.name] || header.default || "-" }}
    </div> -->
  </ng-container>
  <ng-container *ngIf="editClicked">
    <nz-form-control>
      <nz-select
        [nzOpen]="true"
        #tagSelector
        (nzOpenChange)="handleDropdownClose($event)"
        class="dropdownui"
        nzMode="multiple"
        [nzCustomTemplate]="multipleTemplate"
        class="cPointer tagStyle"
        style="cursor: pointer !important; font-size: 12px"
        [nzDropdownClassName]="'tagStyle'"
        (ngModelChange)="data[header.name] = $event; check_is_unique()"
        [ngModel]="data[header.name] || header.default"
        #tagStyle
        [nzDropdownRender]="tagTemp"
      >
        <!-- <nz-option *ngFor="let option of header?.option" [nzValue]="option.value"  [nzLabel]="option.name" nzHide ></nz-option> -->
        <ng-template #tagTemp>
          <div
            style="
              display: flex;
              align-items: center;
              column-gap: 16px;
              padding: 0 5px;
            "
          >
            <input
              style="border: 1px solid var(--dblue)"
              [(ngModel)]="addTagOption"
              nz-input
              placeholder="add tag..."
            />
            <div class="primaryBtnStyle optionBtn">
              <a
                [style.pointerEvents]="disableTagOptionButton ? 'none' : 'all'"
                (click)="saveTagOption(header)"
                >Add</a
              >
              <!-- <button nz-button nzType="primary" class="save" style="margin-top: 0!important;" [disabled]="disableTagOptionButton" (click)="saveTagOption(header)">
                ADD
              </button> -->
            </div>
          </div>
        </ng-template>
        <nz-option
          nzCustomContent
          *ngFor="let option of header?.options"
          [nzValue]="option.name"
          [nzLabel]="option.name"
        >
          <ng-container>
            <div style="display: flex; justify-content: space-between">
              <div>{{ option.name }}</div>
            </div>
          </ng-container>
        </nz-option>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <nz-row nz-menu style="padding: 10px">
            <nz-col
              [nzSpan]="24"
              nz-menu-item
              class="text-center"
              style="border-bottom: 1px solid #d2d2d2; color: #7c818c"
            >
              Select color for the option
            </nz-col>
            <nz-col
              [nzSpan]="6"
              class="mt-10 mb-10"
              style="display: flex; justify-content: center"
              *ngFor="let item of statuesColor"
            >
              <div
                [style.background]="item.color"
                class="cPointer"
                style="height: 32px; width: 32px; border-radius: 50%"
              ></div>
            </nz-col>
            <nz-col
              [nzSpan]="24"
              class="text-center cPointer"
              style="
                border-top: 1px solid #d2d2d2;
                color: #1d66ff;
                font-size: 12px;
              "
            >
              Remove color
            </nz-col>
          </nz-row>
        </nz-dropdown-menu>
      </nz-select>
    </nz-form-control>
    <ng-template #multipleTemplate let-selected>
      <div class="ant-select-selection-item-content">
        <div>{{ selected.nzLabel }}</div>
        {{
          updateColor(
            multipleTemplate,
            selected.nzLabel,
            selected.nzValue,
            selected
          )
        }}
      </div>
    </ng-template>
  </ng-container>
</div>
<div style="color: red; font-size: smaller; text-align: left">
  {{ error }}
</div>
