import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject, takeLast } from 'rxjs';

@Component({
  selector: 'lib-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  showCalendar = false;
  currentDate: Date = new Date();
  @Input() innerTable: any;

  disabledDate = (current: Date): boolean => {
    let days = {
      Mon: '1',
      Tue: '2',
      Wed: '3',
      Thu: '4',
      Fri: '5',
      Sat: '6',
      Sun: '0',
    };
    let allowedDays = [];
    if (this.allowedDaysInPicker.length == 0) {
      return false;
    } else {
      for (let i of this.allowedDaysInPicker) {
        if (days[i]) {
          allowedDays.push(Number(days[i]));
        }
      }
      return !allowedDays.includes(current.getDay());
    }
  };
  allowedDaysInPicker = [];
  datepickerAllowedDays(e: any, allowdays: any) {
    if (e) {
      this.allowedDaysInPicker = allowdays;
    } else {
      this.allowedDaysInPicker = [];
    }
  }
  @Input() parent: any;
  @Input() header: any;
  @Input() index: any;
  @Input() data: any;
  ngOnInit(): void {}

  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  error = '';
  editing() {
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }

  @ViewChild('calender') calender!: NzDatePickerComponent;
  @HostListener('document:click', ['$event'])
  ngAfterViewInit() {
    this.calender.cdkConnectedOverlay?.backdropClick.pipe().subscribe((e) => {
      this.calender.checkAndClose();
      this.showCalendar = false;
    });
  }
  stopEdit(e: any) {
    let daye = e.toISOString().split('T')[0];
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(
        { target: { value: daye } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: daye } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
 check_is_unique(e: any) {
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = e.toISOString().split('T')[0];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {
         setTimeout(() => {
           if (this.error?.length === 0 || this.error === '') {
          this.stopEdit(e)
           }
         }, 100);
       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.stopEdit(e)
       }
     }, 100);
   }

 }
  isViewOnlyAccess = true;
  checkUserAcessType() {
    let loggedUser = this.parent.pservice?.isUserLoggedIn()?.userDetail?.id;
    let accessType = this.parent.pservice
      ?.appState()
      ?.selectedProcess?.public___vw_org_process_users?.find(
        (e: any) => e.user_id == loggedUser
      );
    return accessType?.user_access == 'VIEW';
  }
  onDateClick(c: any) {
    if (!this.checkUserAcessType()) {
      c.open();
      this.editing();
    }
  }
}
