import { Injectable, inject } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { TokenService } from './token.service';
import { IUserDto } from '../models/IResponse.model';
@Injectable({
  providedIn: 'root',
})
export class JwtService {
  tokenService = inject(TokenService);
  getParsedToken(): IUserDto {
    let result = jwt_decode(this.tokenService.getIdToken() as string) as {
      identity: IUserDto;
    };
    return result.identity;
  }
}
