import { ChangeDetectorRef, Directive, DoCheck, ElementRef, Input } from '@angular/core';
import { ProcessoCustomTableService } from '../processo-custom-table.service';

@Directive({
  selector: '[fluidRowWidth]'
})
export class FluidRowWidthDirective  {
  @Input()type! :string
  constructor(private cdr :ChangeDetectorRef,private el :ElementRef ,private service :ProcessoCustomTableService) { }
  ngOnChanges(): void {
  
  }
  ngAfterViewInit(){

      const elements = document.querySelector(`.${this.type}`);
      const width = elements?.getBoundingClientRect()?.width;
      if (width) {
        // this.el.nativeElement.style.minWidth = width + 'px';
        // this.el.nativeElement.style.width = width + 'px';
        // this.el.nativeElement.style.maxWidth = width + 'px';
        this.el.nativeElement.style.minWidth = '100%';
        this.el.nativeElement.style.width = '100%';
        this.el.nativeElement.style.maxWidth = '100%';
      }

  }

}
