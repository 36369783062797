<ng-container *ngFor="let filter of filters; let i = index">
  <div class="" style="display: flex; align-items: center; overflow-x: scroll">
    <div
      class=""
      *ngIf="!Array.isArray(filter)"
      style="display: flex; align-items: center; overflow-x: scroll"
    >
      <div class="f-14 fw-400" *ngIf="i == 0" style="width: 100px">
        Filter by
      </div>
      <div class="f-14 fw-400" *ngIf="i != 0" style="width: 100px">
        <nz-switch
          [(ngModel)]="filters[0].key4"
          nzCheckedChildren="AND"
          nzUnCheckedChildren="OR"
          (ngModelChange)="changeOperatorAdd($event, i)"
          [disabled]="i > 1"
        ></nz-switch>
      </div>
      <nz-select
        [(ngModel)]="filter.entity_name"
        nzPlaceHolder="Select field"
        style="width: 200px; margin-left: 10px"
        (ngModelChange)="selectedEntities($event,filter)"
        [nzDropdownClassName]="'reverse_ant-select-dropdown'"
        [nzDropdownRender]="selectSearchTemplateField"
      >
        <ng-template #selectSearchTemplateField>
        </ng-template>
        <ng-container *ngFor="let item of entitiesData">
          <ng-container *ngIf="item.label">
            <nz-option
              nzValue="{{ item?.field_name }}"
              nzLabel="{{ item?.label }}"
            ></nz-option>
          </ng-container>
        </ng-container>
      </nz-select>
      <ng-container>
        <nz-select
          [(ngModel)]="filter.key1"
          nzPlaceHolder="Select field"
          style="width: 200px; margin-left: 10px"
          (ngModelChange)="
            filterTypeFieldChange($event, i, filter.entity_name)
          "
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplateField"
        >
          <ng-template #selectSearchTemplateField>
            <input
              #searchF1
              nz-input
              placeholder="Search..."
              [(ngModel)]="searchField"
              (input)="getFields(searchField, filter.entity_name)"
            />
          </ng-template>
          <nz-option
            *ngFor="let item of getFields(searchField, filter.entity_name)"
            nzValue="{{ item?.name }}"
            nzLabel="{{ item?.label }}"
          ></nz-option>
        </nz-select>
        <nz-select
          [(ngModel)]="filter.key2"
          nzPlaceHolder="in"
          style="width: 170px; margin-left: 20px"
          [disabled]="filter.key1 == ''"
        >
          <nz-option
            *ngFor="
              let item of getOperators(filter?.key1, filter.entity_name)
            "
            nzLabel="{{ item.label }}"
            nzValue="{{ item.value }}"
          ></nz-option>
        </nz-select>
        <ng-template
          [ngTemplateOutlet]="valueOutlet"
          [ngTemplateOutletContext]="{ filter: filter }"
        ></ng-template>
        <div class="" style="margin-left: 10px" *ngIf="filters.length >= 2">
          <img
            src="../../assets/images/Trash.png"
            alt=""
            class="cPointer"
            (click)="deleteItem(i)"
          />
        </div>
      </ng-container>
    </div>

    <!-- Filter Group -->
    <ng-container>
      <div
        class=""
        *ngIf="Array.isArray(filter)"
        style="display: flex; align-items: center"
      >
        <div class="f-14 fw-400" *ngIf="i != 0" style="width: 43px">
          <nz-switch
            [(ngModel)]="filters[0].key4"
            nzCheckedChildren="AND"
            nzUnCheckedChildren="OR"
            [disabled]="i > 1"
            (ngModelChange)="changeOperatorAdd($event, i)"
          ></nz-switch>
        </div>
        <div
          class=""
          style="
            background-color: rgb(247, 248, 249);
            margin-left: 20px;
            padding: 10px;
            border-radius: 15px;
          "
        >
          <div class="mt-10" *ngFor="let item of filter; let j = index">
            <div
              class=""
              *ngIf="!Array.isArray(item)"
              style="display: flex; align-items: center; overflow-x: scroll"
            >
              <div class="f-14 fw-400" *ngIf="j == 0" style="width: 50px"></div>
              <div class="f-14 fw-400" *ngIf="j != 0" style="width: 50px">
                <nz-switch
                  [(ngModel)]="filter[1].key4"
                  nzCheckedChildren="AND"
                  nzUnCheckedChildren="OR"
                  [disabled]="true"
                  (ngModelChange)="changegroupOperatorAdd($event, i, j)"
                ></nz-switch>
              </div>
              <nz-select
                [(ngModel)]="item.entity_name"
                nzPlaceHolder="Select field"
                style="width: 200px; margin-left: 10px"
                (ngModelChange)="selectedEntities($event,item)"
                [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                [nzDropdownRender]="selectSearchTemplateField"
              >
                <ng-template #selectSearchTemplateField>
                  <!-- <input
                #searchF1
                nz-input
                placeholder="Search..."
                [(ngModel)]="searchField"
                (input)="getFields(searchField)"
              /> -->
                </ng-template>
                <ng-container *ngFor="let item of entitiesData">
                  <ng-container *ngIf="item.label">
                    <nz-option
                      nzValue="{{ item?.field_name }}"
                      nzLabel="{{ item?.label }}"
                    ></nz-option>
                  </ng-container>
                </ng-container>
              </nz-select>
              <nz-select
                [(ngModel)]="item.key1"
                nzPlaceHolder="Select field"
                (ngModelChange)="
                  filterTypeFieldChangeGroups($event, i, j, item.entity_name)
                "
                style="width: 170px; margin-left: 10px"
              >
                <nz-option
                  *ngFor="let type of getFields('', item.entity_name)"
                  nzValue="{{ type.name }}"
                  nzLabel="{{ type.label }}"
                ></nz-option>
              </nz-select>
              <nz-select
                [(ngModel)]="item.key2"
                nzPlaceHolder="in"
                style="width: 150px; margin-left: 20px"
                [disabled]="item.key1 === ''"
              >
                <nz-option
                  *ngFor="let op of getOperators(item.key1, item.entity_name)"
                  nzValue="{{ op.value }}"
                  nzLabel="{{ op.label }}"
                ></nz-option>
              </nz-select>
              <ng-template
                [ngTemplateOutlet]="valueOutlet"
                [ngTemplateOutletContext]="{ filter: item }"
              ></ng-template>
              <div class="" style="margin-left: 10px">
                <img
                  src="../../assets/images/Trash.png"
                  alt=""
                  class="cPointer"
                  (click)="deleteGroupFilter(i, j)"
                />
              </div>
            </div>
            <div
              class=""
              *ngIf="Array.isArray(item)"
              style="display: flex; align-items: center"
            >
              <div class="f-14 fw-400" *ngIf="j != 0" style="width: 50px">
                <nz-switch
                  [(ngModel)]="filter[1].key4"
                  nzCheckedChildren="AND"
                  nzUnCheckedChildren="OR"
                  [disabled]="true"
                ></nz-switch>
              </div>
              <div
                class=""
                style="
                  background-color: #cccccc;
                  margin-left: 20px;
                  padding: 10px;
                  border-radius: 15px;
                "
              >
                <div
                  class=""
                  *ngFor="let child of item; let k = index"
                  style="display: flex; align-items: center; overflow-x: scroll"
                >
                  <div
                    class="f-14 fw-400"
                    *ngIf="k == 0"
                    style="width: 50px"
                  ></div>
                  <div class="f-14 fw-400" *ngIf="k != 0" style="width: 50px">
                    <nz-switch
                      [(ngModel)]="item[1].key4"
                      nzCheckedChildren="AND"
                      nzUnCheckedChildren="OR"
                      [disabled]="true"
                    ></nz-switch>
                  </div>
                  <nz-select
                    [(ngModel)]="child.entity_name"
                    nzPlaceHolder="Select field"
                    style="width: 200px; margin-left: 10px"
                    (ngModelChange)="selectedEntities($event,child)"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateField"
                  >
                    <ng-template #selectSearchTemplateField>
                      <!-- <input
                    #searchF1
                    nz-input
                    placeholder="Search..."
                    [(ngModel)]="searchField"
                    (input)="getFields(searchField)"
                  /> -->
                    </ng-template>
                    <ng-container *ngFor="let item of entitiesData">
                      <ng-container *ngIf="item.label">
                        <nz-option
                          nzValue="{{ item?.field_name }}"
                          nzLabel="{{ item?.label }}"
                        ></nz-option>
                      </ng-container>
                    </ng-container>
                  </nz-select>
                  <nz-select
                    [(ngModel)]="child.key1"
                    nzPlaceHolder="Select field"
                    (ngModelChange)="
                      filterTypeFieldChangeChildGroups(
                        $event,
                        i,
                        j,
                        k,
                        child.entity_name
                      )
                    "
                    style="width: 200px; margin-left: 10px"
                  >
                    <nz-option
                      *ngFor="let field of getFields('', child.entity_name)"
                      nzValue="{{ field.name }}"
                      nzLabel="{{ field.label }}"
                    ></nz-option>
                  </nz-select>
                  <nz-select
                    [(ngModel)]="child.key2"
                    nzPlaceHolder="in"
                    style="width: 100px; margin-left: 20px"
                    [disabled]="child.key1 === ''"
                  >
                    <nz-option
                      *ngFor="
                        let op of getOperators(child.key1, child.entity_name)
                      "
                      nzValue="{{ op.value }}"
                      nzLabel="{{ op.label }}"
                    ></nz-option>
                  </nz-select>
                  <ng-template
                    [ngTemplateOutlet]="valueOutlet"
                    [ngTemplateOutletContext]="{ filter: child }"
                  ></ng-template>
                  <div class="" style="margin-left: 10px">
                    <img
                      src="../../assets/images/Trash.png"
                      alt=""
                      class="cPointer"
                      (click)="deleteChildGroup(i, j, k)"
                    />
                  </div>
                </div>

                <nz-row
                  class="mt-20"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div
                    class="f-11 fw-400 cPointer"
                    style="color: #1d66ff"
                    (click)="addNewChildFilter(i, j)"
                  >
                    ++Filter
                  </div>

                  <div
                    class="f-11 fw-400 cPointer"
                    style="color: #1d66ff"
                    (click)="clearChildGroup(i, j)"
                  >
                    Clear Group
                  </div>
                </nz-row>
              </div>
            </div>
          </div>
          <nz-row
            class="mt-20"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="" style="display: flex; align-items: center">
              <div
                class="f-11 fw-400 cPointer"
                style="color: #1d66ff"
                (click)="addNewGroupFilter(i)"
              >
                ++Filter
              </div>
              <div
                class="f-11 fw-400 cPointer"
                style="color: #1d66ff; margin-left: 20px"
                (click)="addChildGroup(i)"
              >
                ++Group
              </div>
            </div>
            <div class="">
              <div
                class="f-11 fw-400 cPointer"
                style="color: #1d66ff"
                (click)="clearGroup(i)"
              >
                Clear Group
              </div>
            </div>
          </nz-row>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container>
  <nz-row class="mt-20">
    <div
      class="f-11 fw-400 cPointer"
      style="color: #1d66ff"
      (click)="addNewFilter()"
    >
      ++Filter
    </div>
    <div
      class="f-11 fw-400 cPointer"
      style="color: #1d66ff; margin-left: 20px"
      (click)="addGroup()"
    >
      ++Group
    </div>
  </nz-row>
</ng-container>
<ng-template #valueOutlet let-filter="filter">
  <ng-container
    *ngIf="findItsDataTypeValue(filter.key1, filter) as field; else default"
  >
    <div [ngSwitch]="field.datatype">
      <div *ngSwitchCase="'email'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          style="width: 200px; margin-left: 20px"
        />
      </div>
      <div *ngSwitchCase="'location'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'website'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'textarea'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'textbox'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'phone'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'currency'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'number'">
        <input
          [(ngModel)]="filter.key3"
          nz-input
          oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
          style="width: 200px; margin-left: 20px"
        />
      </div>

      <div *ngSwitchCase="'checkbox'"></div>

      <div *ngSwitchCase="'rating'">
        <div class="">
          <nz-rate
            [nzCharacter]="characterIcon"
            #rate
            [(ngModel)]="filter.key3"
            [nzCount]="field.end_value"
            style="margin-left: 20px"
          >
            <ng-template #characterIcon let-index>
              <img
                *ngIf="!getHoverValue(rate, index)"
                [src]="getEmojiImage(field.emoji_value, 'blue')"
                style="width: 15px"
              />
              <img
                *ngIf="getHoverValue(rate, index)"
                [src]="getEmojiImage(field.emoji_value, 'white')"
                style="width: 15px"
              />
            </ng-template>
          </nz-rate>
        </div>
      </div>

      <div *ngSwitchCase="'progress'">
        <nz-row [nzAlign]="'middle'" style="width: 200px; margin-left: 20px">
          <nz-col [nzSpan]="18" class="slidersContainer">
            <nz-slider
              [ngModel]="convertToString(filter.key3)"
              (ngModelChange)="filter.key3 = convertToString($event)"
            ></nz-slider>
          </nz-col>
          <nz-col [nzSpan]="6" style="font-size: 9px">
            {{ filter.key3 || "0" }} %
          </nz-col>
        </nz-row>
      </div>

      <div *ngSwitchCase="'date'">
        <div style="width: 200px; margin-left: 20px; min-height: 37px">
          <nz-date-picker
            style="min-height: 37px"
            [ngModel]="filter.key3"
            (ngModelChange)="
              $event
                ? (filter.key3 = moment($event).format('YYYY-MM-DD'))
                : (filter.key3 = $event)
            "
          ></nz-date-picker>
        </div>
      </div>

      <div *ngSwitchCase="'people'">
        <nz-select
          class="dropdownui w-100"
          nzPlaceHolder="-"
          style="margin-left: 20px; min-width: 150px"
          [(ngModel)]="filter.key3"
          (nzOpenChange)="openDropDownPeopleChange(field, '', $event)"
          nzMode="multiple"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplatepeople"
        >
          <ng-template #selectSearchTemplatepeople>
            <input
              #searchF7
              nz-input
              placeholder="Search..."
              (input)="filterpeopleOptions(searchF7.value)"
            />
          </ng-template>
          <nz-layout *ngFor="let option of getPeopleOptionList$ | async">
            <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
          </nz-layout>
        </nz-select>
      </div>

      <div *ngSwitchCase="'dropdown_single_select'">
        <ng-container *ngIf="field?.option_type === 'STATIC'">
          <nz-select
            style="width: 200px; margin-left: 20px"
            nzPlaceHolder="-"
            [(ngModel)]="filter.key3"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateSingleStatic"
            (click)="multiStaticInput = ''"
          >
            <ng-template #selectSearchTemplateSingleStatic>
              <input
                #search1
                nz-input
                placeholder="Search..."
                [(ngModel)]="multiStaticInput"
                (input)="getMultiStaticOption(field.options, multiStaticInput)"
              />
            </ng-template>
            <nz-option
              *ngFor="
                let option of getMultiStaticOption(
                  field.options,
                  multiStaticInput
                )
              "
              [nzValue]="option.name"
              nzLabel="{{ option.name }}"
            ></nz-option>
          </nz-select>
        </ng-container>
        <ng-container *ngIf="field?.option_type === 'ENTITY_DATA'">
          <nz-select
            [(ngModel)]="filter.key3"
            (nzOpenChange)="openDropDownEntityChange(field, '', $event)"
            nzPlaceHolder="Select option"
            style="width: 200px; margin-left: 20px"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplatesingleEntity"
            [nzShowArrow]="true"
            nzMode="multiple"
          >
            <ng-template #selectSearchTemplatesingleEntity>
              <input
                #search
                nz-input
                placeholder="Search..."
                (input)="openDropDownEntityChange(field, search.value, true)"
              />
            </ng-template>
            <nz-option  
            *ngIf="!(getEntityptionList$ | async)"          
            nzLabel="{{filter.key3}}"
            nzValue="{{filter.key3}}"
            nzHide
          ></nz-option>
            <nz-layout *ngFor="let option of getEntityptionList$ | async">
              <nz-option
                [nzLabel]="option.entity_data"
                [nzValue]="option.entity_data"
              >
              </nz-option>
            </nz-layout>
          </nz-select>
        </ng-container>
        <ng-container *ngIf="field?.option_type === 'API'" class="w-100">
          <nz-select
            class="dropdownui w-100"
            nzPlaceHolder="-"
            style="margin-left: 20px; min-width: 150px"
            [(ngModel)]="filter.key3"
            nzMode="multiple"
            (nzOpenChange)="openDropDownAPIChange(field, '', $event)"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateFieldSingleApi"
          >
            <ng-template #selectSearchTemplateFieldSingleApi>
              <input
                #searchF2
                nz-input
                placeholder="Search..."
                (input)="openDropDownAPIChange(field, searchF2.value, $event)"
              />
            </ng-template>
            <nz-option  
            *ngIf="!(getApiOptionList$ | async)"          
            nzLabel="{{filter.key3}}"
            nzValue="{{filter.key3}}"
            nzHide
          ></nz-option>
            <nz-layout *ngFor="let option of getApiOptionList$ | async">
              <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
            </nz-layout>
          </nz-select>
        </ng-container>
      </div>

      <div *ngSwitchCase="'dropdown_multi_select'">
        <ng-container *ngIf="field?.option_type === 'STATIC'">
          <nz-select
            style="width: 200px; margin-left: 20px"
            nzPlaceHolder="-"
            [(ngModel)]="filter.key3"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateFieldStatic"
            (click)="multiStaticInput = ''"
          >
            <ng-template #selectSearchTemplateFieldStatic>
              <input
                #search1
                nz-input
                placeholder="Search..."
                [(ngModel)]="multiStaticInput"
                (input)="getMultiStaticOption(field.options, multiStaticInput)"
              />
            </ng-template>
            <ng-container
              *ngFor="
                let option of getMultiStaticOption(
                  field.options,
                  multiStaticInput
                )
              "
            >
              <nz-option
                [nzValue]="option.name"
                nzLabel="{{ option.name }}"
              ></nz-option>
            </ng-container>
          </nz-select>
        </ng-container>
        <ng-container *ngIf="field?.option_type === 'ENTITY_DATA'">
          <nz-select
            [(ngModel)]="filter.key3"
            (nzOpenChange)="openDropDownEntityChange(field, '', $event)"
            nzPlaceHolder="Select option"
            style="width: 200px; margin-left: 10px"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateFieldEntity"
            [nzShowArrow]="true"
            nzMode="multiple"
          >
            <ng-template #selectSearchTemplateFieldEntity>
              <input
                #searchF4
                nz-input
                placeholder="Search..."
                (input)="openDropDownEntityChange(field, searchF4.value, true)"
              />
            </ng-template>
            <nz-option  
            *ngIf="!(getEntityptionList$ | async)"          
            nzLabel="{{filter.key3}}"
            nzValue="{{filter.key3}}"
            nzHide
          ></nz-option>
            <nz-layout *ngFor="let option of getEntityptionList$ | async">
              <nz-option
                [nzLabel]="option?.entity_data"
                [nzValue]="option?.entity_data"
              >
              </nz-option>
            </nz-layout>
          </nz-select>
        </ng-container>
        <ng-container *ngIf="field?.option_type === 'API'" class="w-100">
          <nz-select
            class="dropdownui w-100"
            nzPlaceHolder="-"
            style="margin-left: 10px; min-width: 150px"
            [(ngModel)]="filter.key3"
            (nzOpenChange)="openDropDownAPIChange(field, '', $event)"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateFieldApi"
          >
            <ng-template #selectSearchTemplateFieldApi>
              <input
                #searchF2
                nz-input
                placeholder="Search..."
                (input)="openDropDownAPIChange(field, searchF2.value, $event)"
              />
            </ng-template>
            <nz-option  
            *ngIf="!(getApiOptionList$ | async)"          
            nzLabel="{{filter.key3}}"
            nzValue="{{filter.key3}}"
            nzHide
          ></nz-option>
            <nz-layout *ngFor="let option of getApiOptionList$ | async">
              <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
            </nz-layout>
          </nz-select>
        </ng-container>
      </div>
      <div *ngSwitchCase="'status'">
        <ng-container>
          <nz-select
            style="width: 200px; margin-left: 20px"
            nzPlaceHolder="-"
            [(ngModel)]="filter.key3"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplatestatus"
            (click)="statusInput = ''"
          >
            <ng-template #selectSearchTemplatestatus>
              <input
                #search1
                nz-input
                placeholder="Search..."
                [(ngModel)]="statusInput"
                (input)="
                  getStatusOptions(
                    field.open_status,
                    field.close_status,
                    statusInput
                  )
                "
              />
            </ng-template>
            <nz-option
              *ngFor="
                let option of getStatusOptions(
                  field.open_status,
                  field.close_status,
                  statusInput
                )
              "
              [nzValue]="option.name"
              nzLabel="{{ option.name }}"
            ></nz-option>
          </nz-select>
        </ng-container>
      </div>
      <div *ngSwitchCase="'tag'">
        <ng-container>
          <nz-select
            style="width: 200px; margin-left: 20px"
            nzPlaceHolder="-"
            [(ngModel)]="filter.key3"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplatetag"
            (click)="multiStaticInput = ''"
          >
            <ng-template #selectSearchTemplatetag>
              <input
                #search1
                nz-input
                placeholder="Search..."
                [(ngModel)]="multiStaticInput"
                (input)="getMultiStaticOption(field.options, multiStaticInput)"
              />
            </ng-template>
            <nz-option
              *ngFor="
                let option of getMultiStaticOption(
                  field.options,
                  multiStaticInput
                )
              "
              [nzValue]="option.name"
              nzLabel="{{ option.name }}"
            ></nz-option>
          </nz-select>
        </ng-container>
      </div>
      <div *ngSwitchCase="'priority'">
        <nz-select
          style="width: 200px; margin-left: 20px"
          nzPlaceHolder="-"
          [(ngModel)]="filter.key3"
          nzMode="multiple"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          (click)="multiStaticInput = ''"
        >
          <ng-template #selectSearchTemplateFieldStatic> </ng-template>
          <nz-option
            *ngFor="let option of field?.options"
            [nzValue]="option?.name"
            [nzLabel]="option?.name"
          ></nz-option>
        </nz-select>
      </div>
      <div *ngSwitchCase="'time'">
        <div
          style="width: 200px; margin-left: 20px;background: white;"
          class="timerInputstyle"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="timeMenu"
          (click)="onOpenTime(field, filter)"
          style="font-size: 11px"
        >
          {{ getTimeValue(filter) || " " }}
        </div>

        <nz-dropdown-menu #timeMenu="nzDropdownMenu">
          <ul nz-menu class="timerMenuStyle">
            <nz-tabset [(nzSelectedIndex)]="selectedRTimeTabIndex">
              <nz-tab nzTitle="Manual">
                <div style="padding: 0 15px">
                  <input
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    class="f-12"
                    [(ngModel)]="manualTime"
                    type="text"
                    nz-input
                    nzPlaceHolder="-"
                  />
                </div>
              </nz-tab>
            </nz-tabset>
            <div class="btnAlign">
              <div class="primaryBtnStyle">
                <button
                  nz-menu-item
                  nz-button
                  nzType="primary"
                  class="save"
                  (click)="saveTime(filter)"
                >
                  SAVE
                </button>
              </div>
              <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
                <button
                  nz-menu-item
                  nz-button
                  nzType="default"
                  class="buttonDefault f-10 fw-700"
                  style="height: 37px !important; border-radius: 5px"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div *ngSwitchDefault>
        <input
          [(ngModel)]="filter.key3"
          nz-input
          nzPlaceHolder="in"
          style="width: 200px; margin-left: 20px"
        />
      </div>
    </div>
  </ng-container>

  <ng-template #default>
    <input
      [(ngModel)]="filter.key3"
      nz-input
      nzPlaceHolder="in"
      style="width: 200px; margin-left: 20px"
    />
  </ng-template>
</ng-template>
