<nz-row>
    <nz-col [nzSpan]="24">
        <div class="text-center">
            <img  src="../../../../assets/images/icons/timer.png"/>
        </div>
    </nz-col>
    <nz-col [nzSpan]="24">
        <div class="text-center f-12 fw-500 mt-10">
            There has been no activity for a long time. In <span [innerHtml]="count"></span> seconds, your session will end and you will be logged out
        </div>
    </nz-col>
</nz-row>
<nz-layout class="action-btn mt-30" style="padding-bottom: 10px">
    <nz-row class="justifyCenter">
      <div class="text-center cPointer"  style="border: none!important;">
        <button 
        style="border: none!important;"
          nzType="primary"
          class="f-10 fw-700"
          type="submit"
          (click)="cancelClickHandle($event)"
        >
        Keep me logged in
        </button>
      </div>
    </nz-row>
  </nz-layout>