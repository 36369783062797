import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  HostBinding,
  DoCheck,
} from '@angular/core';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { NzInputGroupComponent } from 'ng-zorro-antd/input';
import { BehaviorSubject, fromEvent, tap } from 'rxjs';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent implements OnInit,DoCheck {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() header: any;
  @Input() data: any;
  @Input() editId: any;
  @Input() index: any;
  @Input() parent: any;
  @Input() innerTable: any;

  // @Output() checkdefault: any = new EventEmitter<any>()
  @Output() startedit: any = new EventEmitter<any>();
  @Output() stopedit: any = new EventEmitter<any>();
  selectedCountryCode: string = '+91';
  mobileCountryCode: any;
  mouseAtmobileDropDown = false;
  formatedMobileNumber: any;
  editval$: any;
  @ViewChild('element') element: any;
  constructor(
    private ProcessoCustomTableService: ProcessoCustomTableService,

    private cdr: ChangeDetectorRef
  ) {
    this.editval$ = this.editval.pipe(tap(() => {}));
  }
  ngDoCheck(): void {
    if(this.header?.callUniqueApi && this.editval.getValue()){
      
      this.stopEditingFocusout(true)
    }
    this.cdr.markForCheck();    
  }
  checkDefault(header: any, data: any) {
    if (isNaN(Number(data))) {
      return Number(header.default).toFixed(header.decimal);
    } else {
      return Number(data).toFixed(header.decimal);
    }
  }

  startEdit(name: any, index: any) {
    // this.editId = `${name},${index}`
    this.startedit.emit({ name: name, index: index });
  }
  getCountryCode(mobileCountryCode: any) {
    return mobileCountryCode;
  }
  stopEdit() {
    // this.editId = null
    this.data[
      this.header.name
    ] = `${this.selectedCountryCode} - ${this.formatedMobileNumber}`;
    this.stopedit.emit();
  }
  check(e: any) {
    let regex = new RegExp('^+[0-9]+-[0-9]+$');
  }
  ngOnInit(): void {
    this.selectedCountryCode = this.data[this.header?.name]
      ?.split('-')[0]
      ?.trim();
    this.formatedMobileNumber = this.data[this.header?.name]
      ?.split('-')[1]
      ?.trim();
  }
  @Output() getMobileCode = new EventEmitter<any>();
  storemobileCountryCode: any;
  mobileCode() {
    let body = {
      org_id: this.data?.org_id,
      api_name: 'Phone Code',
      process_id: this.data.process_id,
      entity_id:[this.data.entity_id||'']
    };
    if (!this.mobileCountryCode) {
      this.getMobileCode.emit(body);
      if (this.ProcessoCustomTableService.countryCode.length > 0) {
        this.mobileCountryCode = this.ProcessoCustomTableService.countryCode;
      } else {
        this.parent.pservice.getCountryCode(body).subscribe((res: any) => {
          if (res) {
            this.mobileCountryCode = res?.org;
            this.mobileCountryCode.sort((a: any, b: any) => {
              const codeA = parseInt(a.PhoneCode.replace(/[^\d]/g, ''), 10);
              const codeB = parseInt(b.PhoneCode.replace(/[^\d]/g, ''), 10);
              return codeA - codeB;
            });
            this.ProcessoCustomTableService.countryCode =
              this.mobileCountryCode;
            this.storemobileCountryCode = this.mobileCountryCode;
          }
        });
      }
    } else {
      this.mobileCountryCode = this.storemobileCountryCode;
    }
  }
  getMobileNumber() {
    return this.data[this.header.name];
  }
  selectedCode(e: any) {
    if (e) {
      this.selectedCountryCode = e;
      this.stopEdit();
    }
  }

  //
  editval = new BehaviorSubject<boolean>(false);

  editing() {
    if(!this.header.can_edit || this.header.tab_name==='XX' || this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)){
      return
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
      this.mobileCode();
    }
  }
  stopEditing() {
    // this.editval.next(false);
  }
  hover: any = false;
  focusInput = false;
  error = '';
  stopEditingFocusout(e: any) {
    if (this.focusInput) {
      return;
    }
    if (this.formatedMobileNumber) {
      this.header.callUniqueApi = false
      const formattedValue =
        this.selectedCountryCode + ' - ' + this.formatedMobileNumber;
      this.data[this.header.name] = formattedValue;
      // if (this.header.datatype === 'phone' && this.header.is_unique) {
      //   let filterData: any = {};

      //   let body: any = {
      //     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
      //     process_id:
      //       this.parent.pservice.appState().selectedProcess.process_id,
      //     process_name:
      //       this.parent.pservice.appState().selectedProcess.process_name?.replace(/\s/g, '_'),
      //     entity_name: this.parent.pservice.appState()?.selectedEntity,
      //     // filter: {
      //     //   [this.header.name]: formattedValue,
      //     // },
      //     entity_id: this.data?.entity_id,
      //   };
      //   filterData[this.header.name] = formattedValue;
      //   let data = this.header?.unique_fn?.map((fieldName: any) => {
      //     if (fieldName) {
      //       filterData[fieldName] = this.data[fieldName];
      //     }
      //   });
      //   body['filter'] = filterData;
      //   this.parent.pservice
      //     .fetchEntityDataUnique(body)
      //     .subscribe((res: any) => {
      //       if (res.exists) {
      //         this.header.error = `${this.header.label} value already exists`;
      //         this.cdr.detectChanges();
      //         return;
      //       } else {
      //         let field = this.parent.getTableHeaders()
      //         let data = this.header?.unique_fn?.map((fieldName: any) => {
      //           if (fieldName) {
      //             field.map((_e:any)=>{
      //               if (_e?.name === fieldName) {
                      
      //                 _e.error = '';
      //             _e.callUniqueApi=true                    
                      
                      
      //               }
      //             })
      //           }
      //         });
      //         this.header.error = '';
          

      //         this.saveData(formattedValue);
      //         this.cdr.detectChanges();
      //       }
      //     });
      // } else {
      //   this.saveData(formattedValue);
      // }
      this.check_is_unique(formattedValue)
    } else {
      this.selectedCountryCode = '+91';
      this.data[this.header.name] = '';
      if (this.innerTable) {
        this.parent.updateDataOnInnerStopEdit(
          { target: { value: '' } },
          this.header.name,
          this.data
        );
      } else {
        this.parent.updateDataOnStopEdit(
          { target: { value: '' } },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
      this.editval.next(false);
    }
    if (!this.hover) {
      // this.editval.next(false);
    }
  }
 check_is_unique(formattedValue: any) {
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
              this.saveData(formattedValue);
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
       this.saveData(formattedValue);
       }
     }, 100);
   }

 }
  saveData(formattedValue: any) {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(
        { target: { value: formattedValue } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: formattedValue } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    this.editval.next(false);
  }
  numberSelect() {
    this.focusInput = false;
  }
  stopEditingFocusoutdrop(e: any) {
    this.stopEditingFocusout(true);
  }
  elements: any = [];
  pushFocus(e: any) {}
  close() {}
  filtercountryCode(value: any) {
    this.mobileCountryCode = this.storemobileCountryCode.filter((e: any) =>
      e.CountryName.toLowerCase().includes(value.toLowerCase()) ||    e.PhoneCode.toLowerCase().includes(value.toLowerCase())
    );
  }
}
