import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  entitiesData: any = [];
  Array = Array;
  constructor() {}
  convertSortSaveLayoutPayload(data: any, selectedGroup: any) {
    let sortorder = data
      ?.map((_e: any, index: number) => {
        if (selectedGroup === _e) {
          return `${0}___${_e}`;
        } else if (_e?.includes('-')) {
          let data = _e?.split('-');
          if (selectedGroup === data[1]) {
            return `${0}___${_e}`;
          } else {
            return `${index + 1}___${_e}`;
          }
        } else {
          return `${index + 1}___${_e}`;
        }
      })
      ?.filter(Boolean);

    return sortorder;
  }
  convertLayoutSort(data: any) {
    let group_default: any;
    let sortvalue = data;
    let sort = sortvalue
      ?.map((_e: any) => {
        let value = _e?.split('___');
        if (value?.[0] === '0') {
          group_default = value?.[1];
        }
        return value?.[1];
      })
      ?.filter(Boolean);
    let sortData = {
      group: group_default,
      sort: sort,
    };
    return sortData;
  }
  convert_filter_api_json_entity(filter: any, state: any,is_show_closed:any) {
    this.get_entities_data(state);
    let filterList: any = {};

    if (filter === undefined) {
      filter = [
        {
          key1: '',
          key2: '',
          key3: '',
          key4: true,
          entity_name: state?.selectedEntity,
        },
      ];
    }
    if (filter?.length > 0 && filter[0]?.key1 == '') {
      filterList = {};
    } else {
      for (let r of filter) {
        const fields = this.getField(r.entity_name, state);
        let findFieldData = fields?.find((_e: any) => _e.name === r.key1);
        if (
          findFieldData?.datatype === 'currency' ||
          findFieldData?.datatype === 'progress' ||
          findFieldData?.datatype === 'number' ||
          findFieldData?.datatype === 'rating'
        ) {
          r.key3 = r.key3 ? Number(r.key3) : r.key3;
        }
        // For first level group
        if (this.Array.isArray(r)) {
          if (r.length > 1) {
            // For group or
            if (filter?.[0].key4 === true) {
              for (let a of r) {
                // For group item
                if (!this.Array.isArray(a)) {
                  if (Object.keys(filterList).includes('$or')) {
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields?.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField?.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField?.datatype === 'checkbox') {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  } else {
                    const fields = this.getField(a.entity_name, state);
                    const selectedField = fields?.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField?.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField?.datatype === 'checkbox') {
                      filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name, state)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  }
                }
                // For group -> child group
                else {
                  if (a.length > 1) {
                    // For and
                    if (filter?.[0].key4 === true) {
                      filterList['$or'].push({})
                      const m = filterList['$or'].length - 1;
                      for (let b of a) {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields?.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField?.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField?.datatype === 'checkbox') {
                          filterList['$or'][m][
                            this.isDefault(b.entity_name, state)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] = b.key2;
                        } else {
                          filterList['$or'][m][
                            this.isDefault(b.entity_name, state)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] =
                            b.key2 === ''
                              ? b?.key3?.length === 0
                                ? this.key3Values(selectedField, b.key3)
                                : b.key3
                              : {
                                  [b.key2]:
                                    b?.key3?.length === 0
                                      ? this.key3Values(selectedField, b.key3)
                                      : b.key3,
                                };
                        }
                      }
                    }
                  } else {
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields?.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField?.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField?.datatype === 'checkbox') {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name, state)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]: a[0].key2,
                        },
                      ];
                    } else {
                      filterList['$or'] = [
                        ...filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name, state)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]:
                            a[0].key2 === ''
                              ? a[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, a[0].key3)
                                : a[0].key3
                              : {
                                  [a[0].key2]:
                                    a[0]?.key3?.length === 0
                                      ? this.key3Values(
                                          selectedField,
                                          a[0].key3
                                        )
                                      : a[0].key3,
                                },
                        },
                      ];
                    }
                  }
                }
              }
            }
            // For group and
            else {
              filterList?.['$or'].push({})
              const n = filterList?.['$or'].length - 1;
              for (let a of r) {
                if (!this.Array.isArray(a)) {
                  const fields = this.getField(a.entity_name, state);
                  const selectedField = fields?.find(
                    (item: any) => item.name === a.key1
                  );
                  if (
                    [
                      'currency',
                      'progress',
                      'number',
                      'rating',
                      'phone',
                    ].includes(selectedField?.datatype)
                  ) {
                    a.key3 = a.key3 ? Number(a.key3) : a.key3;
                  }
                  if (selectedField?.datatype === 'checkbox') {
                    filterList['$or'][n][
                      this.isDefault(a.entity_name, state)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] = a.key2;
                  } else {
                    filterList['$or'][n][
                      this.isDefault(a.entity_name, state)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] =
                      a.key2 === ''
                        ? a?.key3?.length === 0
                          ? this.key3Values(selectedField, a.key3)
                          : a.key3
                        : {
                            [a.key2]:
                              a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3,
                          };
                  }
                } else {
                  if (a.length > 1) {
                    const m = filterList['$or'].length - 1;
                    for (let b of a) {
                      if (Object.keys(filterList['$or'][m]).includes('$or')) {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields?.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField?.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField?.datatype === 'checkbox') {
                          filterList['$or'][m]['$or'] = [
                            ...filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          filterList['$or'][m]['$or'] = [
                            ...filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      } else {
                        const fields = this.getField(b.entity_name, state);
                        const selectedField = fields?.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField?.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField?.datatype === 'checkbox') {
                          filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name, state)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      }
                    }
                  } else {
                    const m = filterList['$or'].length - 1;
                    let sample: any = {};
                    const fields = this.getField(a[0].entity_name, state);
                    const selectedField = fields?.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField?.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField?.datatype === 'checkbox') {
                      sample[
                        this.isDefault(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] = a[0].key2;
                    } else {
                      sample[
                        this.isDefault(a[0].entity_name, state)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] =
                        a[0].key2 === ''
                          ? a[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, a[0].key3)
                            : a[0].key3
                          : {
                              [a[0].key2]:
                                a[0]?.key3?.length === 0
                                  ? this.key3Values(selectedField, a[0].key3)
                                  : a[0].key3,
                            };
                    }

                    filterList['$or'][m] = {
                      ...filterList['$or'][m],
                      ...sample,
                    };
                  }
                }
              }
            }
          }
          // If group has only one element
          else {
            if (filter?.[0].key4 === true) {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields?.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField?.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField?.datatype === 'checkbox') {
                filterList[
                  this.isDefault(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] = r[0].key2;
              } else {
                filterList[
                  this.isDefault(r[0].entity_name, state)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] =
                  r[0].key2 === ''
                    ? r[0]?.key3?.length === 0
                      ? this.key3Values(selectedField, r[0].key3)
                      : r[0].key3
                    : {
                        [r[0].key2]:
                          r[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, r[0].key3)
                            : r[0].key3,
                      };
              }
            } else {
              const fields = this.getField(r[0].entity_name, state);
              const selectedField = fields?.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField?.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField?.datatype === 'checkbox') {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name, state)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]: r[0].key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name, state)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]:
                      r[0].key2 === ''
                        ? r[0]?.key3?.length === 0
                          ? this.key3Values(selectedField, r[0].key3)
                          : r[0].key3
                        : {
                            [r[0].key2]:
                              r[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, r[0].key3)
                                : r[0].key3,
                          },
                  },
                ];
              }
            }
          }
        }
        // For first level item
        else {
          // For and
          if (filter?.[0].key4 == true) {
            const fields = this.getField(r.entity_name, state);
            const selectedField = fields?.find(
              (item: any) => item.name === r.key1
            );
            if (
              ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                selectedField?.datatype
              )
            ) {
              r.key3 = r.key3 ? Number(r.key3) : r.key3;
            }
            if (selectedField?.datatype === 'checkbox') {
              filterList[
                this.isDefault(r.entity_name, state)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] = r.key2;
            } else {
              filterList[
                this.isDefault(r.entity_name, state)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] =
                r.key2 === ''
                  ? r?.key3?.length === 0
                    ? this.key3Values(selectedField, r.key3)
                    : r.key3
                  : {
                      [r.key2]:
                        r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3,
                    };
            }
          }
          // For or
          else {
            if (Object.keys(filterList).includes('$or')) {
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields?.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField?.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField?.datatype === 'checkbox') {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  ...filterList['$or'],
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            } else {
              const fields = this.getField(r.entity_name, state);
              const selectedField = fields?.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField?.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField?.datatype === 'checkbox') {
                filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name, state)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            }
          }
        }
      }
    }
    let findsortFieldbyTab = state.metaData?.entities?.find(
      (e: any) => e.name.toLowerCase() === state.selectedEntity
    )?.fields;
    const isStatus = findsortFieldbyTab?.find(
      (e: any) => e?.datatype === 'status'
    );
    let filterStatus: any = {};
    let hide_status = true
    if(is_show_closed?.length>0){
      is_show_closed?.forEach((_e:any)=>{
        let field_name = _e?.split('___');
        if(field_name[0]==='0' && field_name[1] === isStatus.name){
          hide_status = false;          
        }
      })
    }
    if(hide_status){
      if (isStatus?.name) {
        filterStatus[`_${isStatus.name}`] = { $ne: 'close' };
      }
      filterList = { ...filterList, ...filterStatus };
    }
    return filterList;

    // let entity = this.noOfStatusFields?.find(
    //   (currentEntity: any) =>
    //     currentEntity?.entityName === state?.selectedEntity &&
    //     currentEntity.child === false
    // );
    // this.isStatusClose = this.noOfStatusFields?.some(
    //   (e: any) => e?.showStatue === true
    // );
    // let filterStatus: any = {};
    // if (entity?.statusFieldName && !entity?.showStatue) {
    //   filterStatus[`_${entity?.statusFieldName}`] = {
    //     $ne: !entity?.showStatue ? 'close' : undefined,
    //   };
    // }

    // filterList = { ...filterList, ...filterStatus };
    // this.onfirstClick = false;
    // this.statusFilters(this.statusData || null, scroll);
  }
  statusFilters(event: any, scroll: string) {
    // let filterData = this.flattenArray(filter);
    // let pFilter = this.getPfilterdata(filterData);
    // this.statusData = event;
    // let filter: any = {
    //   filters: filterList,
    //   pfilter: pFilter ? pFilter : [],
    // };
    // if (!this.statusData?.a_status?.null) {
    //   filter['status'] = this.statusData?.a_status;
    // }
    // if (this.isSelectedMore === 'for_my_approval') {
    //   this.morefilterOptions.emit({
    //     filter,
    //     selectedoption: 'for_my_approval',
    //     scroll: scroll,
    //   });
    // } else if (this.isSelectedMore === 'my_drafts') {
    //   this.morefilterOptions.emit({
    //     filter,
    //     selectedoption: 'my_drafts',
    //     scroll: scroll,
    //   });
    // } else if (this.isSelectedMore === 'error_records') {
    //   this.morefilterOptions.emit({
    //     filter,
    //     selectedoption: 'error_records',
    //     scroll: scroll,
    //   });
    // } else if (this.isSelectedMore === 'approved_records') {
    //   this.morefilterOptions.emit({
    //     filter,
    //     selectedoption: 'approved_records',
    //     scroll: scroll,
    //   });
    // }
  }
  get_entities_data(state: any) {
    let entitiesData = [];
    let defaultEntities = state?.metaData?.entities?.find(
      (_e: any) => _e.name === state?.selectedEntity
    );
    if (defaultEntities) {
      let data = {
        label: defaultEntities?.display_name,
        entity_name: defaultEntities?.name,
        field_name: defaultEntities?.name,
        default: true,
      };
      entitiesData.push(data);
    }

    defaultEntities?.fields?.map((_field: any) => {
      if (
        (_field?.datatype === 'dropdown_single_select' &&
          _field.option_type === 'ENTITY_DATA') ||
        (_field?.datatype === 'dropdown_multi_select' &&
          _field.option_type === 'ENTITY_DATA')
      ) {
        let data = {
          label: _field?.label,
          field_name: _field.name,
          entity_name: _field?.entity_name,
          default: false,
        };
        entitiesData.push(data);
      }
    });
    this.entitiesData = entitiesData;
  }
  getField(entityName: string, state: any): any {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === entityName
    )?.entity_name;
    let fields = state?.metaData?.entities?.find(
      (_e: any) => _e.name === entity_Name
    )?.fields;
    return fields;
  }
  isDefault(fieldName: any, state: any) {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === fieldName
    );
    if (
      entity_Name?.entity_name === state?.selectedEntity &&
      entity_Name?.default
    ) {
      return false;
    } else {
      return true;
    }
  }
  key3Values(field: any, data: any) {
    if (
      ['currency', 'progress', 'number', 'rating', 'date', 'time'].includes(
        field?.datatype
      )
    ) {
      return null;
    } else if (
      ['email', 'location', 'phone', 'textarea', 'textbox', 'website'].includes(
        field?.datatype
      )
    ) {
      return '';
    } else if (
      [
        'dropdown_single_select',
        'dropdown_multi_select',
        'people',
        'priority',
        'tag',
        'status',
      ].includes(field?.datatype)
    ) {
      return [''];
    }

    return data;
  }
  convert_status_filter(datas: any) {
    let data: any = {};
    if (datas?.filterValue === '') {
     data['$eq'] = datas?.filter;;
    } else {
      data[datas?.filterValue] = datas?.filter;
    }
    return data;
  }
  convert_payload_status_filter(datas: any) {
    let data: any = {};
    if (datas?.filterValue === '') {
     return datas?.filter
    } else {
      data[datas?.filterValue] = datas?.filter;
    }
    return data;
  }
  get_a_status_value(value:any){
    if(value){
      let a_status = Object.keys(value)[0]
      if(a_status === '$eq'){
        return value[a_status];
      }else{
        return value
      }      
    }else{
      return undefined
    }
  }

}
