import { Component, HostBinding, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
interface TabData {
  name: string;
}
@Component({
  selector: 'lib-add-select-option-popup',
  templateUrl: './add-select-option-popup.component.html',
  styleUrls: ['./add-select-option-popup.component.scss'],
})
export class AddSelectOptionPopupComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  popupHeaderName!: string;
  defaultTabData: any;
  constructor(private modal: NzModalRef, private fb: FormBuilder) {}
  addStatuessForm!: FormGroup;
  ngOnInit(): void {
    this.defaultTabData = this.nzModalData.value;
    this.popupHeaderName = this.nzModalData?.data?.popupName;
    this.createForm();
  }

  createForm() {
    this.addStatuessForm = this.fb.group({
      statuesDetails: this.fb.array([]),
    });
    if (this.defaultTabData && this.defaultTabData.length > 0) {
      this.defaultTabData.forEach((tabData: TabData | undefined) => {
        this.statuesDetails().push(this.newStatusAddDetails(tabData));
      });
    } else {
      // this.addMore();
      let tabData = {
        name: '',
      };
      this.statuesDetails().push(this.newStatusAddDetails(tabData));
    }
    
  }

  newStatusAddDetails(tabData: TabData = {} as TabData): FormGroup {
    const fieldname = tabData.name || '';
    return this.fb.group({
      name: [
        { value: fieldname, disabled: !!fieldname },
        [Validators.required],
      ],
    });
  }

  statuesDetails(): FormArray {
    return this.addStatuessForm.get('statuesDetails') as FormArray;
  }

  close() {
    this.modal.destroy();
  }

  confirm() {
    let statuesDetails = this.addStatuessForm.value.statuesDetails;
    statuesDetails = statuesDetails.filter((item: { name: string }) => item.name !== '');
    if(statuesDetails?.length === 0){
      this.modal.destroy();
    }
    if ((this.addStatuessForm.valid && !this.duplicateNameError) || (statuesDetails?.length>0 && !this.duplicateNameError)) {
      let result = this.statuesDetails().controls.map((e: any, i: any) => {
        return { ...e.value };
      });
      let check_duplicate = result
      
      const namesSet = new Set<string>();
      for (const { name, index } of check_duplicate) {
        if (namesSet.has(name)) {
          this.duplicateNameError = true;
          this.duplicateNameIndex = index;
          return;
        } else {
          this.duplicateNameError = false;
        }
        namesSet.add(name);
      }
      if (!this.duplicateNameError) {
        this.modal.destroy(result);
      }
    } else {
      this.statuesDetails().markAllAsTouched();
      this.statuesDetails().markAsDirty();
    }
  }

  addMore() {
    if (!this.duplicateNameError) {
      this.statuesDetails().push(this.newStatusAddDetails());
    }
  }
  removeColor(index: any) {
    const statusDetailsArray = this.addStatuessForm.get(
      'statuesDetails'
    ) as FormArray;
    const selectedStatus = statusDetailsArray.at(index);
    selectedStatus.get('color')?.setValue('');
  }
  draggedColumnIndex: any;
  dragStartColumn(i: any, e: any) {
    if (!this.duplicateNameError) {
      this.draggedColumnIndex = i;
    }
  }
  allowDrop(e: any) {
    if (!this.duplicateNameError) {
      e.preventDefault();
    }
  }
  dropColumn(i: any, e: any) {
    if (!this.duplicateNameError) {
      this.arrayMove(
        this.statuesDetails().controls,
        this.draggedColumnIndex,
        i
      );
    }
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    this.draggedColumnIndex = null;
  }
  duplicateNameError: boolean = false;
  duplicateNameIndex!: number | null;
  inputFieldName(index: number, event: any) {
    let inputText = event.target.value;
    let result = this.statuesDetails().controls.map((e: any, i: any) => {
      return { ...e.value, index: i };
    });

    let isDuplicate = result?.filter((e: any) => e.name === inputText);
    if (isDuplicate.length > 1) {
      this.duplicateNameError = true;
      this.duplicateNameIndex = index;
    } else {
      this.duplicateNameError = false;
      this.duplicateNameIndex = null;
    }
  }
  removeStatusOption(index: number) {
    const statuesDetailsArray = this.addStatuessForm.get(
      'statuesDetails'
    ) as FormArray;
    statuesDetailsArray.removeAt(index);
  }
  checkDuplicatename() {
    const nameSet = new Set<string>();
    for (const obj of this.addStatuessForm.value.statuesDetails) {
      if (nameSet.has(obj.name)) {
        this.duplicateNameError = true;
        return false;
      } else {
        this.duplicateNameError = true;
        nameSet.add(obj.name);
      }
    }
    if (nameSet) {
      this.duplicateNameError = false;
    }
    return true;
  }
}
