import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { inject, Injectable, Renderer2, RendererFactory2, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverlaySidebarService {
  private overlay = inject(Overlay);
   overlayRefs: any[] = [];

  createSidebar(component: any, fromParent: string, position = 'left', additonalParmsToComponent: any,hasBackdrop:boolean=true, callback = () => { }) {
    const routerContainer = document.querySelector(fromParent);
    if (!routerContainer) {
      console.error(fromParent);
      return;
    }

    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(routerContainer)
        .withPositions([{
          originX: position === 'left' ? 'start' : position === 'right'? 'end':'center',
          originY: 'top',
          overlayX: position === 'left' ? 'start' : position === 'right'? 'end':'center',
          overlayY: 'top',
        }, {
          originX: position === 'left' ? 'start' : position === 'right'? 'end':'center',
          originY: 'bottom',
          overlayX: position === 'left' ? 'start' : position === 'right'? 'end':'center',
          overlayY: 'bottom',
        }]),
      hasBackdrop: hasBackdrop, backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block()
    });

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose();
      this.overlayRefs = this.overlayRefs.filter(ref => ref !== overlayRef);
      callback()
      
    });

    if (component) {
      const componentPortal = new ComponentPortal(component);
      const componentRef = overlayRef.attach(componentPortal);
      const componentInstance: any = componentRef.instance;
      componentInstance.inputParams = additonalParmsToComponent
      componentInstance.popup = overlayRef
       
      
    }
    
    this.overlayRefs.push(overlayRef);
    return overlayRef
  }

  ngOnDestroy(): void {
    this.overlayRefs.forEach(ref => ref.dispose());
  }
}
