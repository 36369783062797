
<div [style.pointerEvents]="isViewOnlyAccess && parent.isSelectedMore !== 'for_my_approval'">
  <div class="px-10 textStyle date" fluidRowWidth [type]="header?.name" [style.pointerEvents]="(header.dfc && !header.editable) || header.tab_name==='XX' || parent.state?.tableDiable === 'ERRORS' || !header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name) ? 'none':'auto'">
    <div class="min-height-37 align-center d-flex">

      <ng-container *ngIf="header.default || data[header.name] ">
        <span
          (click)="onDateClick(calender)"
          class="cPointer  " style="font-size: 12px;"

        >
          {{ (data[header.name]|| (header.default==='Current_Date'?currentDate:'')) | date: header.date_format }}
        </span>
      </ng-container>
      <ng-container *ngIf="!data[header.name] && !header.default">
        <div class="">
          <img
          src="../../../assets/images/Calendar.png"
          class="cPointer"
          style="margin-left: 5px"
          (click)="calender.open(); editing()"
          *ngIf="!showCalendar"
      />
        </div>

    </ng-container>
      <nz-date-picker

        [nzBackdrop]="true"
        [nzDropdownClassName]="'date_picker_table'"
        [(ngModel)]="data[header.name]"
        style="visibility: hidden; width: 0px"
        #calender
        [nzFormat]="header.date_format"
        (ngModelChange)="check_is_unique($event)"
        (nzOnOpenChange)="datepickerAllowedDays($event,header.allow_days)"
        [nzDisabledDate]="disabledDate"
        bordered="{false}"
      ></nz-date-picker>

    </div>
    <div style="color: red ;font-size: smaller;text-align: left;">
      {{error}}
      </div>
    </div>

</div>
