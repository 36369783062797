import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VieFilesTableComponent } from '../../popup/vie-files-table/vie-files-table.component';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentComponent implements OnInit, OnDestroy {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  showFile: boolean = false;
  // fileName?: string = '';
  @Input() innerTable: any;
  @Input() header: any;
  @Input() data: any;
  @Input() parent: any;
  constructor(
    private nzModalService: NzModalService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    if (!Object.keys(this.data).includes(this.header.name)) {
      this.showFile = false;
    } else {
      this.showFile = true;
      // this.fileName = this.data[this.header.name];
    }
  }

  // handleChange(e: any) {
  //   this.fileName = e?.file?.name;
  //   if (this.fileName) {
  //     this.showFile = true;
  //     this.data[this.header.name] = this.showFile;
  //   }
  // }

  async handleChange(e: any, field: any) {
    setTimeout(async () => {
      const files = e.target.files;
      const filesArray = Array.from(files);

      const uploadPromises = filesArray.map((file: any) => {
        const reader = new FileReader();
        const fileName = file?.name?.replace(/_/g, '');

        return new Promise<void>((resolve, reject) => {
          reader.onload = () => {
            const base64String = reader.result as string;
            const removeBase64Text = base64String?.split(',')?.[1];
            this.uploadAttachment(fileName, removeBase64Text, field)
              .then(resolve)
              .catch(reject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      try {
        await Promise.all(uploadPromises);
        this.updateData();
      } catch (error) {
        console.error('File upload failed', error);
      }
    }, 100);
  }

  uploadAttachment(name: any, file: any, field: any): Promise<void> {
    let body = {
      file_name: name,
      org_process_id:
        this.parent.pservice.appState().selectedProcess.org_process_id,
      file: file,
    };

    return new Promise<void>((resolve, reject) => {
      this.parent.pservice.uploadFile(body).subscribe((res: any) => {
        if (res) {
          if (this.data[this.header?.name] === undefined) {
            this.data[this.header?.name] = [];
          }
          this.data[this.header?.name]?.push(res?.file_name);
          resolve();
        } else {
          reject(new Error('Upload failed'));
        }
      }, reject);
    });
  }

  updateData() {
    this.parent.updateDataOnStopEdit(
      { target: { value: this.data[this.header?.name] } },
      this.header.name,
      this.data,
      this.parent.erulistData
    );
    this.data[this.header.name] = this.data[this.header?.name];
    this.parent.saveEntityDropdownData(this.header, '', this.data);
    this.cdr.detectChanges();
  }
  removeAttachment() {
    this.parent.updateDataOnStopEdit(
      { target: { value: this.data[this.header?.name] } },
      this.header.name,
      this.data,
      this.parent.erulistData
    );
  }

  getShortenName(field: any) {
    if (field) {
      let newFilename = field?.split('_')?.pop();
      return newFilename;
    }
  }
  downloadAttachment(file_name: any) {
    let payload = {
      file_name: file_name,
      org_process_id:
        this.parent.pservice.appState().selectedProcess.org_process_id,
    };
    this.parent.pservice.downloadFile(payload).subscribe((res: any) => {
      if (res) {
        let file = res.file;
        let type = res.file_type;
        let fileName = file_name;
        this.openFile(file, type, fileName);
      }
    });
  }
  popup1:any
  openFile(file: any, type: any, fileName: any) {
    const data = {
      file: file,
      type: type,
      fileName: fileName,
    };
    if (!this.isSupportedFileType(type)) {
      this.downloadFile(data, fileName);
      return;
    }

    this.popup1 = this.nzModalService.create({
      nzWidth: '394px',
      nzTitle: '',
      nzContent: VieFilesTableComponent,
      nzMaskClosable: true,
      nzFooter: null,
      nzClosable: true,
      nzData: {
        data: data,
      },
      nzClassName: 'viewFile',
    });
  }
  supportedFileTypes: string[] = ['application/pdf', 'image/jpeg', 'image/png'];
  isSupportedFileType(fileType: string): boolean {
    return this.supportedFileTypes.includes(fileType);
  }
  async downloadFile(data: any, fileName: any): Promise<void> {
    await this.saveFile(data, fileName);
  }
  async saveFile(res: any, name: any): Promise<void> {
    let link = document.createElement('a');
    link.href = `data:${res.type};base64,${res.file}`;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  close = false;
  private timeoutRef: any;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
    this.popup1?.close()

  }
}
