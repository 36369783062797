import { Component, EventEmitter, Input, OnInit, Output, Pipe, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import { environment } from 'projects/processo/src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  logo:string=''

  @Input() routerData?: any;
  router = inject(Router);
  stateService = inject(StateService);
  appStateService = inject(AppstateService);
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  environment = environment
  processList: any = this.appStateService.getCurrentOrganization
  constructor(private tokenService: TokenService,) {
     this.logo= environment.is_artfine?'../../../../assets/images/logo/ArtfineBilzSideLogo.svg':'../../../../../assets/images/processo-icon.svg'
  }
  ngOnInit(): void {
    const userObj = this.stateService.isUserLoggedIn().userDetail.attributes;
    this.firstName = userObj?.first_name.charAt(0);
    this.lastName = userObj?.last_name.charAt(0);
    this.fullName = `${userObj?.first_name} ${userObj?.last_name}`;
  }

  async gotoProfileSettings() {
    // this.router.navigateByUrl('profile');
    this.router.navigateByUrl('user/settings')
  }

  async signOut() {
      let body = {
        refresh_token: this.tokenService?.getRefreshToken()
      }
      this.stateService.logout(body).subscribe((res) => {
        if (res) {
          this.stateService.clearState();
        }
      })
      this.stateService.clearState();
      this.appStateService.clearAppState() 
  }

  async gotoOrganization() {
    this.router.navigateByUrl('org/list');
  }

  async home() {
    this.router.navigateByUrl('/loading')
    // if(await this.stateService.canNavigate()){
    //   this.stateService.resetPagination()
    //   if(!this.stateService.orgList()){
    //     this.router.navigate(['dashboard']);
    //     return
    //   }
    //   const islocationInDashboard = this.router.url.indexOf('dashboard') > -1;
    //   if (!islocationInDashboard) {
    //     this.stateService?.updateAppstate({
    //       event: 'UPDATE_ORG',
    //       value:
    //         this.stateService
    //           .orgList()
    //           ?.find((e: any) => e?.public___user_org_default__is_default) ||
    //         this.stateService?.orgList()[0]?.org?.[0] ||
    //         null,
    //     });

    //     let loginUserId =
    //       this.stateService?.isUserLoggedIn()?.userDetail.attributes?.sub || null;
    //     let result = this.stateService
    //       .appState()
    //       ?.selectedOrganization?.public___org_processes?.find((process: any) => {
    //         let users = process?.public___vw_org_process_users?.find(
    //           (user: any) => user.user_id === loginUserId,
    //         );
    //         return users && users?.public___user_org_process_default__is_default;
    //       });

    //     if (result) {
    //       this.stateService.updateAppstate({
    //         event: 'UPDATE_PROCESS',
    //         value: result || null,
    //       });
    //     } else {
    //       this.stateService.updateAppstate({
    //         event: 'UPDATE_PROCESS',
    //         value:
    //           this.stateService.appState()?.selectedOrganization
    //             ?.public___org_processes[0] || null,
    //       });
    //     }
    //     this.router.navigate(['dashboard']);
    //   }

    // }

  }

  async selectedProcess(process: any) {
    this.appStateService.updateDefaultProcess(process)
    const currentOrg = this.appStateService.getCurrentOrganization().router_org_value;
    this.router.navigateByUrl(`/org/${currentOrg}/process/${process.router_process_value}/view`)
  }

  currentProcessSelected(item: any) {
    if(!this.routerData){
      return false
    }
    return item.router_process_value == this.routerData().process_name
    
  }
  @Output() side_bar_output = new EventEmitter<any>()
  go_to(value:any){
    if(value){
      this.side_bar_output.emit(value)
    }
  }
  setting(){
    let value ={
       route:this.router.url,
       from:'table'
      }
      
      this.appStateService.state_url_save.next(value)
     this.router.navigate(['/org',this.appStateService.getCurrentOrganization()?.org_name,'basic'])
   }
}

