import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TableCollapseComponent } from '../table-collapse/table-collapse.component';
import { CustomTableHeaderComponent } from '../custom-table-header/custom-table-header.component';
import { CustomTableBodyComponent } from '../custom-table-body/custom-table-body.component';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { InputFieldComponent } from '../input-field/input-field.component';
import { EmailComponent } from '../email/email.component';
import { TextAreaWrappedComponent } from '../text-area-wrapped/text-area-wrapped.component';
import { AttachmentComponent } from '../attachment/attachment.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CurrencyComponent } from '../currency/currency.component';
import { DateComponent } from '../date/date.component';
import { DropdownMultipleComponent } from '../dropdown-multiple/dropdown-multiple.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { LocationComponent } from '../location/location.component';
import { NumberComponent } from '../number/number.component';
import { PeopleComponent } from '../people/people.component';
import { PhoneComponent } from '../phone/phone.component';
import { ProgressComponent } from '../progress/progress.component';
import { RatingsComponent } from '../ratings/ratings.component';
import { StatusComponent } from '../status/status.component';
import { TagComponent } from '../tag/tag.component';
import { WebsiteComponent } from '../website/website.component';
import { of } from 'rxjs';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-custom-table-row',
  templateUrl: './custom-table-row.component.html',
  styleUrls: ['./custom-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableRowComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() key: any;
  @Input() dynamicWidth: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() headerData: any;
  @Input() editIds: any;
  @Input() hideSelectOption: any;
  @Input() api_fields: any;
  @Output() selectedActions = new EventEmitter<any>();
  @Output() startEdits = new EventEmitter<any>();
  @Output() stopEdits = new EventEmitter<any>();
  @Output() checkChanges = new EventEmitter<any>();
  @Output() ratingChanges = new EventEmitter<any>();
  showRow = true;

  constructor(
    private ngZone: NgZone,
    private service: ProcessoCustomTableService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.service.getShowHideSubject().subscribe((val: any) => {
      if (val.includes(this.key)) {
        this.showRow = false;
      } else {
        this.showRow = true;
      }

      cdr.markForCheck();
    });
  }

  @ViewChild('textbox', { read: ViewContainerRef })
  textboxContainer!: ViewContainerRef;
  @ViewChild('email', { read: ViewContainerRef })
  emailContainer!: ViewContainerRef;
  @ViewChild('textarea', { read: ViewContainerRef })
  textareaContainer!: ViewContainerRef;
  @ViewChild('number', { read: ViewContainerRef })
  numberContainer!: ViewContainerRef;
  @ViewChild('currency', { read: ViewContainerRef })
  currencyContainer!: ViewContainerRef;
  @ViewChild('checkbox', { read: ViewContainerRef })
  checkboxContainer!: ViewContainerRef;
  @ViewChild('rating', { read: ViewContainerRef })
  ratingContainer!: ViewContainerRef;
  @ViewChild('progress', { read: ViewContainerRef })
  progressContainer!: ViewContainerRef;
  @ViewChild('dropdown_single_select', { read: ViewContainerRef })
  dropdownSingleSelectContainer!: ViewContainerRef;
  @ViewChild('dropdown_multi_select', { read: ViewContainerRef })
  dropdownMultiSelectContainer!: ViewContainerRef;
  @ViewChild('status', { read: ViewContainerRef })
  statusContainer!: ViewContainerRef;
  @ViewChild('phone', { read: ViewContainerRef })
  phoneContainer!: ViewContainerRef;
  @ViewChild('tag', { read: ViewContainerRef }) tagContainer!: ViewContainerRef;
  @ViewChild('people', { read: ViewContainerRef })
  peopleContainer!: ViewContainerRef;
  @ViewChild('attachment', { read: ViewContainerRef })
  attachmentContainer!: ViewContainerRef;
  @ViewChild('website', { read: ViewContainerRef })
  websiteContainer!: ViewContainerRef;
  @ViewChild('date', { read: ViewContainerRef })
  dateContainer!: ViewContainerRef;
  @ViewChild('location', { read: ViewContainerRef })
  locationContainer!: ViewContainerRef;
  headerDataChangesSubscription: any;
  ngOnInit() {}
  ngOnChanges(change: any) {
    // if (this.container) {
    //    // this.clearAndLoadComponent(true); // this.clearAndLoadComponent(true);

    // }

    this.clearAndLoadComponent(true);
  }

  ngAfterViewInit() {
    this.clearAndLoadComponent();
  }
  clearAndLoadComponent(onChanges?: boolean) {
    // this.ngZone.runOutsideAngular(() => {
    //  this.headerData.forEach((hdr: any) =>{
    //   let header= hdr
    //   if (header) {
    //     const containerName = `${header.datatype}Container` as keyof CustomTableRowComponent;
    //     const containerRef = this[containerName] as ViewContainerRef;
    //     if (containerRef) {
    //       containerRef.clear();
    //       const componentClass = componentMapping[header.datatype];
    //       if (componentClass) {
    //         const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    //         const componentRef:any = containerRef.createComponent(componentFactory);
    //         componentRef.instance.header = header;
    //         componentRef.instance.data = this.data;
    //         componentRef.instance.parent = this.parent;
    //         this.cdr.detectChanges();
    //       }
    //     }
    //   }else{
    //   }
    //  });
    // });
  }
}
const componentMapping: any = {
  textbox: InputFieldComponent,
  email: EmailComponent,
  textarea: TextAreaWrappedComponent,
  number: NumberComponent,
  currency: CurrencyComponent,
  checkbox: CheckboxComponent,
  rating: RatingsComponent,
  progress: ProgressComponent,
  dropdown_single_select: DropdownComponent,
  dropdown_multi_select: DropdownMultipleComponent,
  status: StatusComponent,
  phone: PhoneComponent,
  tag: TagComponent,
  people: PeopleComponent,
  attachment: AttachmentComponent,
  website: WebsiteComponent,
  date: DateComponent,
  location: LocationComponent,
};
