import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'lib-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnDestroy {
  @Input() header: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() innerTable: any;
  searchs = '';

  // @Output() stopEdits = new EventEmitter<any>();
  // stopEdit(v?: any, type?: any, entityId?: any) {
  //   this.stopEdits.emit({ v, type, entityId });
  // }
  ngOnInit() {}
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  o$(e: any) {
    return of([...this.header.open_status, ...this.header.close_status]).pipe(
      map((r) => r.filter((v: any) => v.name.includes(e.toLowerCase())))
    );
  }

  d$(d: any) {
    return of([...this.header.open_status, ...this.header.close_status]).pipe(
      map((r) => r.find((v: any) => v.name == d))
    );
  }
  update(status: any) {
    this.data[this.header.name] = status.name;
    let tableEdit: any;
    let selectedOption = this.header.open_status?.some(
      (e: any) => e?.name === status.name
    )
      ? 'open'
      : this.header.close_status?.some((e: any) => e?.name === status.name)
      ? 'close'
      : '';
    let data = {
      value: status.name,
      selectedOption: selectedOption,
      dataType: 'status',
    };
    this.check_is_unique(data)
   
  }
  save(data:any){
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(data, this.header.name, this.data);
    } else {
      this.parent.updateDataOnStopEdit(
        data,
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
  cdr=inject(ChangeDetectorRef);
  error = '';
 check_is_unique(e: any) {
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.save(e)
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.save(e)
       }
     }, 100);
   }

 }
  private timeoutRef: any;
  close = false;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
