import { Injectable, inject } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notification = inject(NzNotificationService);

  show(title: string, status: number | string, message: string) {
    this.notification.create(title.toLowerCase(), `${title}`, message, {
      nzPlacement: 'bottomRight',
    });
  }
}
