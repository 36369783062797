import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingsComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() parent: any;
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() innerTable: any;
  checkDefault(header: any, data: any) {
    if (header.mandatory && data != '') {
      return header.default;
    } else {
      return data;
    }
  }

  ratingChange(rating: any) {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(
        { target: { value: rating } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: rating } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
  cdr=inject(ChangeDetectorRef);
  error = '';
 check_is_unique(e: any) {
  this.data[this.header?.name]= e
   let filterData: any = {};
   let body: any = {
     org_id: this.parent.pservice.appState().selectedOrganization.org_id,
     process_id: this.parent.pservice.appState().selectedProcess.process_id,
     process_name: this.parent.pservice
       .appState()
       .selectedProcess.process_name?.replace(/\s/g, '_'),
     entity_name: this.parent.pservice.appState()?.selectedEntity,
     entity_id: this.data?.entity_id,
   };
   if (this.header.is_unique && this.header.unq_sa) {
     filterData[this.header?.name] = this.data[this.header?.name];
   } else if (this.header.is_unique && !this.header.unq_sa) {
     let total_field = this.parent.pservice
       .appState()
       ?.metaData?.entities?.find(
         (_e: any) =>
           _e.name === this.parent.pservice.appState()?.selectedEntity
       )?.fields;
     total_field.forEach((_fields: any) => {
       if (_fields.is_unique && !_fields.unq_sa) {
         filterData[_fields?.name] = this.data[_fields?.name];
       }
     });
   }
   body['filter'] = filterData;
   if(this.header.is_unique){
     this.parent.pservice.fetchEntityDataUnique(body).subscribe({
       next: (res: any) => {
         if (res) {
           if (res.exists) {
             this.error = `${this.header.label} value already exists`;
           } else {
             this.error = '';
             setTimeout(() => {
              if (this.error?.length === 0 || this.error === '') {
             this.ratingChange(e)
              }
            }, 100);
           }
           this.cdr.detectChanges();
         }
       },
       complete: () => {

       },
     });
   }else{
     setTimeout(() => {
       if (this.error?.length === 0 || this.error === '') {
      this.ratingChange(e)
       }
     }, 100);
   }

 }
  emojiType: any = [
    {
      value: '(smile)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueSmiley.svg',
    },
    {
      value: '(tick)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteTick.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueTick.svg',
    },
    {
      value: '(like)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteLike.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueLike.svg',
    },
    {
      value: '(star)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whitestar.svg',
      img2: '../../../../assets/images/addNewFieldIcons/bluestar.svg',
    },
  ];
  getEmojiImage(value: any, data: any) {
    let result = this.emojiType.find((e: any) => e.value === value);
    if (result) {
      return data === 'blue' ? result.img1 : result.img2;
    } else {
      return data === 'blue' ? this.emojiType[0].img1 : this.emojiType[0].img2;
    }
  }
  getHoverValue(c: any, i: any) {
    if (i + 1 <= c.hoverValue) {
      return true;
    } else {
      return false;
    }
  }
}
