import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from '@processo/services/loading.service';
import { NgZorroAntdModule } from '@processo/shared/ng-zorro-antd.module';

@Component({
  selector: 'loader',
  standalone: true,
  imports: [CommonModule, NgZorroAntdModule],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  loadingService = inject(LoadingService);
}
