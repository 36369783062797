<nz-row
  [formGroup]="addStatuessForm"
  class="addNewFieldContainer inputIconContainer"
>
  <div formArrayName="statuesDetails" style="width: 100%" class="statusRow">
    <nz-layout
      draggable="true"
      (dragstart)="dragStartColumn(i, $event)"
      (dragover)="allowDrop($event)"
      (drop)="dropColumn(i, $event)"
      *ngFor="let variable of statuesDetails().controls; let i = index"
      [formGroupName]="i"
    >
      <nz-row [nzAlign]="'middle'" [nzGutter]="10" class="mb-10">
        <nz-col [nzSpan]="2">
          <img
            class="cPointer"
            src="../../../../assets/images/icons/arrowDragIcon.svg"
            style="margin-right: 8px"
          />
        </nz-col>
        <nz-col [nzSpan]="17">
          <nz-form-item style="width: 100%">
            <nz-form-control nzErrorTip="Enter name">
              <nz-input-group>
                <input
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
                  #defaultFieldValue
                  nz-input
                  formControlName="name"
                  placeholder="status name"
                  (input)="inputFieldName(i, $event)"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col [nzSpan]="2">
          <div
            class="menuContainer"
            [style.background]="
              hexToRgba(statuesDetails().at(i).get('color')?.value, 0.2)
            "
          >
            <img
              style="margin-top: 7px; padding: 7px"
              *ngIf="popupHeaderName !== 'addNew'"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu1"
              [nzOverlayClassName]="'selectDropDown'"
              class="cPointer"
              src="../../../../assets/images/icons/actionThreeDots.svg"
            />
          </div>
        </nz-col>
        <nz-col [nzSpan]="1">
          <img
            *ngIf="statuesDetails().length > 1 && i > 0"
            class="cPointer"
            src="../../../../assets/images/icons/delete.svg"
            (click)="removeStatusOption(i); checkDuplicatename()"
          />
        </nz-col>
        <nz-col [nzSpan]="1">
          <ng-container>
            <img
              [ngClass]="{ defaultIcons: !getDefault(i) }"
              class="cPointer"
              (click)="
                updatedefault(i, statuesDetails().at(i).get('name')?.value)
              "
              [src]="
                getDefault(i)
                  ? '../../../../assets/images/icons/blackDefaultIcon.svg'
                  : '../../../../assets/images/icons/whiteDefaultIcon.svg'
              "
              alt=""
            />
          </ng-container>
        </nz-col>
        <nz-col
          [nzXs]="{ span: 20, offset: 2 }"
          [nzLg]="{ span: 20, offset: 2 }"
          [nzXXl]="{ span: 20, offset: 2 }"
        >
          <div
            style="color: red"
            *ngIf="duplicateNameError && duplicateNameIndex === i"
          >
            Duplicate name entered
          </div>
        </nz-col>
      </nz-row>
      <nz-dropdown-menu #menu1="nzDropdownMenu">
        <nz-row nz-menu style="padding: 10px">
          <nz-col
            [nzSpan]="24"
            nz-menu-item
            class="text-center f-12"
            style="border-bottom: 1px solid #d2d2d2; color: #7c818c"
          >
            Select color for the option
          </nz-col>
          <nz-col
            [nzSpan]="6"
            class="mt-10 mb-10"
            style="display: flex; justify-content: center"
            *ngFor="let item of statuesColor"
          >
            <div
              [style.background]="hexToRgba(item.color, 0.2)"
              [ngClass]="
                variable.value.color.toLowerCase() === item.color.toLowerCase() ? 'activeColor' : '' 
              "
              (click)="updateColor(i, item.color)"
              class="cPointer"
              style="height: 32px; width: 32px; border-radius: 50%"
            ></div>
          </nz-col>
          <nz-col
            [nzSpan]="6"
            class="mt-10 mb-10"
            style="display: flex; justify-content: center"
          >
            <div
            [ngClass]="is_random(statuesDetails().at(i).get('color')?.value)
             ? 'activeColor' : '' 
          "
              class="cPointer" [style.background]="is_random(statuesDetails().at(i).get('color')?.value)
             ?
              hexToRgba(statuesDetails().at(i).get('color')?.value, 0.2):''
            "
              style="height: 32px; width: 32px; border-radius: 50%;text-align: center;display: flex;justify-content: center;align-items: center;"
            >
            <nz-color-picker [nzFormat]="'hex'" [nzFlipFlop]="flipFlop" (nzOnOpenChange)="select_extra_color_change($event)" formControlName="color"></nz-color-picker>
            <ng-template #flipFlop>
              <img
              src="../../../../assets/images/icons/pick_color.png"
            />
            </ng-template>

            </div>
          </nz-col>
          <nz-col
            [nzSpan]="24"
            class="text-center cPointer"
            style="
              border-top: 1px solid #d2d2d2;
              color: #1d66ff;
              font-size: 12px;
              margin-top: 10px;
            "
            (click)="removeColor(i)"
          >
            Remove color
          </nz-col>
        </nz-row>
      </nz-dropdown-menu>
    </nz-layout>
  </div>

  <nz-col [nzSpan]="8">
    <div class="addMore" style="cursor: pointer" (click)="addMore()">
      ++Add More
    </div>
  </nz-col>
</nz-row>
<nz-row style="justify-content: end">
  <div class="text-center cPointer">
    <button
      nzType="primary"
      class="f-10 fw-700 save"
      type="submit"
      style="padding: 8px 24px"
      (click)="confirm()"
    >
      SAVE
    </button>
  </div>
  <div class="text-center f-10 fw-700 lh-18 blueColor">
    <button
      nz-button
      nzType="default"
      class="buttonDefault f-10 cancel fw-700"
      style="border-radius: 5px; margin-left: 20px"
      (click)="close()"
    >
      CANCEL
    </button>
  </div>
</nz-row>
