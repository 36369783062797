import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { UserService } from '@processo/services/user.service';
import { StateService } from '@processo/shared/state/state.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMarks } from 'ng-zorro-antd/slider';
import { of, map } from 'rxjs';
import { StatusPopupComponent } from './popup/status-popup/status-popup.component';
import { TagPopupComponent } from './popup/tag-popup/tag-popup.component';
import { DropdownComponent } from './popup/dropdown/dropdown.component';

@Component({
  selector: 'app-add-new-column',
  templateUrl: './add-new-column.component.html',
  styleUrls: ['./add-new-column.component.scss']
})
export class AddNewColumnComponent implements OnDestroy{
  @Input() selectedMenu: any;
  @Input() selectedMenuData: any;
  // @Input() newColumnValue: any;
  @Input() data: any;
  @Input() isEdit: any;
  @Output() selectAPI: EventEmitter<any> = new EventEmitter();
  savenewColumnValue: any;
  newDropDown: any[] = [];
  parentEntityFields: boolean = false;
  fieldEntity: boolean = false;
  Object = Object;
  saveCurrencyLists: any;
  stateService = inject(StateService);
  userService = inject(UserService);
  private modalService = inject(NzModalService);
  private cdr = inject(ChangeDetectorRef);
  entities: any[] = [];
  savedEntities: any;
  @Output() addSaveColumns: EventEmitter<any> = new EventEmitter();
  newColumnValue:any
  allFields: any = [];
  public form: any = {
    option_type: false,
    parent_entity: '',
    parent_field: '',
  };
    @Input()availableFields:any
  ngOnInit(): void {
    this.newColumnValue = this.data?.new_fields
    this.entities = this.stateService.appState()?.metaData.entities;
    this.savedEntities = this.entities;
    this.savenewColumnValue = this.newColumnValue;
    this.setFormFieldValue(this.selectedMenuData?.datatype);
    this.getCurrencyList(true)
    if (
      this.formData.data_type.value === 'dropdown_single_select' ||
      this.formData.data_type.value === 'dropdown_multi_select'
    ) {
      this.getSelectedTabField();
    }
  }
  saveValue:boolean = false
  fieldNameValidation(){
   let validation= this.availableFields?.some((e: any) => e?.name === this.formData?.fieldName.value);
   if(validation){
    this.formData.fieldName.validators.error= 'Field Name Already Exists.'
    this.saveValue = true
   }else{
    this.formData.fieldName.validators.error=''
    this.saveValue = false

   }
  }
  setFormFieldValue(keyName: any) {
    if (this.isEdit) {
      if (this.selectedMenuData?.option_type === 'ENTITY_DATA') {
        if (
          (this.selectedMenuData?.datatype === 'dropdown_single_select' ||
            this.selectedMenuData?.datatype === 'dropdown_multi_select') &&
          this.selectedMenuData?.def !== '' &&
          this.selectedMenuData?.dpef !== ''
        ) {
          this.showDependentField = true;
        } else {
          this.showDependentField = false;
        }
      }

      if (this.selectedMenuData?.option_type === 'API') {
        if (
          (this.selectedMenuData?.datatype === 'dropdown_single_select' ||
            this.selectedMenuData?.datatype === 'dropdown_multi_select') &&
          this.selectedMenuData?.def !== '' &&
          this.selectedMenuData?.def
        ) {
          this.showDependentField = true;
        } else {
          this.showDependentField = false;
        }
      }
      this.formData.data_type.value = this.selectedMenuData.datatype;
      // this.formData.fieldForm.optionType =
      this.getFieldForm(this.selectedMenuData.datatype);
      Object.keys(this.selectedMenuData).forEach((a: any) => {
        let e = this.getCorrectkey(a);
        if (Object.keys(this.formData).includes(e)) {
          if (e !== 'fieldForm') {
            if (
              ['string', 'boolean', 'number'].includes(typeof this.formData[e])
            ) {
              this.formData[e] = this.selectedMenuData[a];
            } else {
              if (!Array.isArray(this.formData[e])) {
                this.formData[e].value = this.selectedMenuData[a];
              } else {
                this.formData[e] = this.selectedMenuData[a];
              }
            }
          }
        } else {
          if (Object.keys(this.formData.fieldForm).includes(e)) {
            if (
              ['string', 'boolean', 'number'].includes(
                typeof this.formData.fieldForm[e]
              )
            ) {
              this.formData.fieldForm[e] = this.selectedMenuData[a];
              if (this.selectedMenuData[a] == 'API') {
                this.selectAPI.emit({ action: true });
                this.selectAPI.emit({
                  action: true,
                  data: this.formData.fieldForm.api_name,
                });
              }
            } else {
              if (!Array.isArray(this.formData.fieldForm[e])) {
                this.formData.fieldForm[e].value = this.selectedMenuData[a];
              } else {
                if (
                  [
                    'options',
                    'open_status',
                    'close_status',
                    'optionType',
                  ].includes(e)
                ) {
                  this.formData.fieldForm[e] = this.selectedMenuData[a];
                } else {
                  if (e == 'AllowDays') {
                    this.formData.fieldForm[e] = this.formData.fieldForm[e].map(
                      (day: any) => {
                        if (this.selectedMenuData[a].includes(day.value)) {
                          return { ...day, checked: true };
                        }
                        return day;
                      }
                    );
                  }
                }
              }
            }
          } else {
          }
        }
      });
      if(this.selectedMenuData?.datatype === 'date'){
        this.formData.fieldForm.dateFormat.value= this.selectedMenuData.date_format;
      }
      if (this.selectedMenuData.datatype === 'status') {
        this.filterStatusDefault('');
      }
      if (
        (this.selectedMenuData.datatype === 'dropdown_single_select' ||
          this.selectedMenuData.datatype === 'dropdown_multi_select') &&
        this.selectedMenuData?.option_type === 'ENTITY_DATA'
      ) {
        // this.getEntityFields();
      }
    } else {
      this.formData.data_type.value = keyName;
      this.getFieldForm(keyName);
    }
  }
  getCorrectkey(key: any) {
    switch (key) {
      case 'open_status':
        return 'open_status';
      case 'close_status':
        return 'close_status';
      case 'api_name':
        return 'api_name';
      case 'can_group':
        return 'can_group';
      case 'datatype':
        return 'data_type';
      case 'default':
        return 'default_value';
      case 'default_group':
        return 'default_group';
      case 'entity_name':
        return 'entity_name';
      case 'field_name':
        return 'field_name';
      case 'emoji_value':
        return 'emojiValue';
      case 'field_type':
        return 'field_type';
      case 'grid_index':
        return 'grid_index';
      case 'label':
        return 'fieldLabel';
      case 'mandatory':
        return 'isMandatory';
      case 'map_roles':
        return 'map_roles';
      case 'map_users':
        return 'map_roles';
      case 'name':
        return 'fieldName';
      case 'option_type':
        return 'optionType';
      case 'options':
        return 'options';
      // case 'show_grid':
      //   return 'showGrid';
      case 'tab_name':
        return 'tab_name';
      case 'is_ephi':
        return 'is_ephi';
      case 'is_pii':
        return 'is_pii';
      case 'to_encrypt':
        return 'to_encrypt';
      case 'tool_tip':
        return 'tooltip';
      case 'end_value':
        return 'number';
      case 'data_length_check':
        return 'dataLengthCheck';
      case 'data_length':
        return 'dataLength';
      case 'options':
        return 'options';
      case 'symbol':
        return 'Symbol';
      case 'seperator':
        return 'Seperator';
      case 'decimal':
        return 'Decimal';
      case 'endValue':
        return 'endValue';
      case 'startValue':
        return 'startValue';
      case 'allow_days':
        return 'AllowDays';
      case 'default':
        return 'default';
      case 'api_field':
        return 'api_field';
      case 'tf_idx':
        return 'tf_idx';
      case 'dfc':
        return 'dfc';
      case 'ce':
        return 'ce';
      case 'cef':
        return 'cef';
      case 'dpef':
        return 'dpef';
      case 'def':
        return 'def';
      // case 'is_hidden':
      //   return 'is_hidden';
      case 'editable':
        return 'editable';
      case 'cefa':
        return 'cefa';
      case 'is_unique':
        return 'is_unique';
        case 'num_val':
        return 'dataLength';
      case 'num_val_check':
        return 'dataLengthCheck';
      default:
        return '';
    }
  }
  public formData: any = {
    data_type: {
      value: '',
      type: 'dropdown',
      validators: {
        required: true,
        inputValidators: [],
      },
    },

    fieldForm: {},
    isMandatory: false,
    // is_hidden: false,
    // showGrid: false,
    tab_name: {
      value: '',
      validators: {
        required: true,
        inputValidators: [],
      },
      placeholder: 'Tab Name',
    },
    can_group: false,
    default_group: false,

    tooltip: {
      type: 'text',
      value: '',
      validators: {
        required: false,
        inputValidators: [],
      },
      error: {},
    },
    default_value: '',
    is_pii: false,
    to_encrypt: false,
    is_ephi: false,
    fieldName: {
      placeholder: 'Field Name',
      value: '',
      validators: {
        required: true,
        inputValidators: ['^[A-Za-z0-9s]/g'],
        error: '',
      },
      type: 'text',
    },
    fieldLabel: {
      placeholder: 'Field Label',
      value: '',
      validators: {
        required: true,
        inputValidators: [],
        error: '',
      },
      type: 'text',
    },
    cef: '',
    ce: '',
    dfc: false,
    editable: false,
    cefa: '',
  };
  filterDataTYpe(searchValue: any) {
    this.newColumnValue = this.savenewColumnValue.filter((option: any) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  selectDataTYpe(item: any) {
    this.newDropDown = [];
    this.formData.dfc = false;
    this.formData.ce = '';
    this.formData.cef = '';
    this.formData.data_type.value = item;
    this.formData.fieldForm = {};
    this.getFieldForm(item);
    this.getEntityField();
    if (
      this.formData.data_type.value === 'dropdown_single_select' ||
      this.formData.data_type.value === 'dropdown_multi_select'
    ) {
      this.getSelectedTabField();
      // this.showDefaultdependency();
      this.parentEntityFields = false;
      this.fieldEntity = false;
    }
  }
  getFieldForm(keyName: any) {
    if (keyName === 'date') {
      this.formData.fieldForm = {
        dateFormat: {
          placeholder: '',
          value: 'dd-MM-YYYY',
          options: [
            ['dd-MM-YYYY', 'DD-MM-YYYY'],
            ['YYYY-MM-dd', 'YYYY-MM-DD'],
            ['MM-dd-YYYY', 'MM-DD-YYYY'],
          ],
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        defaultValue: {
          placeholder: null,
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        AllowDays: [
          { value: 'Mon', checked: true },
          { value: 'Tue', checked: true },
          { value: 'Wed', checked: true },
          { value: 'Thu', checked: true },
          { value: 'Fri', checked: true },
          { value: 'Sat', checked: true },
          { value: 'Sun', checked: true },
        ],
      };
      this.formData.cefa = 'max';
    } else if (keyName === 'textarea') {
      this.formData.fieldForm = {

        default_value: '',
        dataLengthCheckOptions: [
          { value: 'EXACT' },
          { value: 'MAX' },
          { value: 'MIN' },
        ],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
      };
      this.formData.rich_text = false;
    } else if (keyName === 'textbox') {
      this.formData.fieldForm = {
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: '',
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLengthCheckOptions: [
          { value: 'EXACT' },
          { value: 'MAX' },
          { value: 'MIN' },
        ],
      };
      this.formData.is_unique = false;
      this.formData.unique_fn = [];
    } else if (keyName === 'location') {
      this.formData.fieldForm = {
        default_value: '',
      };
    } else if (
      keyName === 'website' ||
      keyName === 'phone' ||
      keyName === 'rm'
    ) {
      this.formData.fieldForm = {
        default_value: '',
      };
      if(keyName === 'website' ||
      keyName === 'phone'){
        this.formData.is_unique=false
      }
    } else if (keyName === 'currency') {
      this.formData.fieldForm = {
        default_value: null,
        dataLengthCheckOptions: [
          { value: 'MAX' },
          { value: 'MIN' },
        ],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Decimal: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        DecimalOptions: [{ value: '1', checked: 'false' }],
        Seperator: {
          placeholder: 'Enter',
          value: 'none',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        SeparatorOptions: [
          { label: 'None', value: 'none' },
          { label: 'Millions', value: 'millions' },
          { label: 'Thousands', value: 'thousands' },
        ],
        Symbol: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
      };
      this.formData.cefa = 'sum';
    }else if (keyName === 'attachment') {
      this.formData.default_value = [];
    }else if (keyName === 'number') {
      this.formData.fieldForm = {
        default_value: null,
        dataLengthCheckOptions: [
          { value: 'MAX' },
          { value: 'MIN' },
        ],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Decimal: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Seperator: {
          placeholder: 'Enter',
          value: 'none',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        SeparatorOptions: [
          { label: 'None', value: 'none' },
          { label: 'Millions', value: 'millions' },
          { label: 'Thousands', value: 'thousands' },
        ],
      };
      this.formData.cefa = 'sum';
    } else if (keyName === 'status') {
      this.formData.fieldForm = {
        open_status: [],
        close_status: [],
        default_value: '',
      };
    } else if (keyName === 'rating') {
      this.formData.fieldForm = {
        emojiType: [
          {
            value: '(smile)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueSmiley.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
          },
          {
            value: '(tick)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueTick.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteTick.svg',
          },
          {
            value: '(like)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueLike.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteLike.svg',
          },
          {
            value: '(star)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/bluestar.svg',
            img2: '../../../assets/images/addNewFieldIcons/whitestar.svg',
          },
        ],
        emojiValue: '',
        start_value: '1',
        end_value: '',
        number: {
          placeholder: '',
          value: 1,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: '',
      };
    } else if (keyName === 'progress') {
      this.formData.fieldForm = {
        startValue: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        endValue: {
          placeholder: 'Enter',
          value: 100,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: '',
      };
    } else if (keyName === 'tag') {
      this.formData.fieldForm = {
        default_value: [],
        options: [],
      };
      this.formData.default_value = [];
    } else if (keyName === 'dropdown_single_select') {
      this.formData.fieldForm = {
        options: [],
        optionType: 'STATIC',
        api_name: '',
        api_field: '',
        entity_name: '',
        field_name: '',
        default_value: '',
        def: '',
        dpef: '',
      };
    } else if (keyName === 'dropdown_multi_select') {
      this.formData.fieldForm = {
        options: [],
        optionType: 'STATIC',
        api_name: '',
        api_field: '',
        entity_name: '',
        field_name: '',
        default_value: [],
        def: '',
        dpef: '',
      };
      this.formData.default_value = [];
    } else if (keyName === 'checkbox') {
      this.formData.fieldForm = {
        default_value: '',
      };
    } else if (keyName === 'people') {
      this.formData.fieldForm = {
        default_value: [],
        api_field: '',
        api_field_validators: {
          required: true,
          error: '',
        },
      };
      this.formData.default_value = [];
    } else if (keyName === 'priority') {
      this.formData.fieldForm = {
        default_value: '',
        options: [
          {
            name: 'Urgent',
          },
          {
            name: 'High',
          },
          {
            name: 'Normal',
          },
          {
            name: 'Low',
          },
        ],
      };
    }else if(keyName === 'email'){
      this.formData.is_unique=false
    }
  }
  getEntityField() {
    // let fields = this.entities
    //   .find((item: any) => item?.name == this.formData.ce)
    //   ?.fields?.filter(
    //     (e: any) => e?.datatype === this.formData?.data_type?.value
    //   );
    // this.savetabFields = fields?.sort((a: any, b: any) =>
    //   a.name.localeCompare(b.name)
    // );
    // this.tabFields = this.savetabFields;
  }
  priorityDefaultOption: any;
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  filterpriorityDefault(searchValue: any) {
    this.priorityDefaultOption = this.priority.filter((option: any) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  getCurrencyLists: any;
  CurrencyLists: any;
  getCurrencyList(event: any) {
    if (event) {
      this.userService.getCurrencyLists().subscribe((res: any) => {
        if (res) {
          this.getCurrencyLists = res;
          this.CurrencyLists = this.getCurrencyLists
        }
      });
    }
  }
  filterCurrency(searchValue: any) {
    this.CurrencyLists = this.saveCurrencyLists.filter((option: any) =>
      option.CurrencyName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color;
    return color || '';
  }
  getDropdown(a: any) {
    return a;
  }
  popup1:any;
  popup2:any;
  popup3:any;
  popup4:any;
  ngOnDestroy(): void {
    this.popup1?.close();
    this.popup2?.close();
    this.popup3?.close();
    this.popup4?.close();
  }
  openStatuses(form: any) {
    let datas = {
      popupName: 'status',
    };
    this.popup1 = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD OPEN STATUSES',
      nzContent: StatusPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.open_status,
        derivedFromChild: this.formData.dfc,
        selectedStatus: 'openStatus',
      },
    });
    this.popup1.afterClose.subscribe((res:any) => {
      if (res?.length > 0) {
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.open_status = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }
  deleteStatus(index: number): void {
    if (index >= 0 && index < this.formData.fieldForm.open_status.length) {
      this.formData.fieldForm.open_status.splice(index, 1);
    }
  }
  closeStatuses() {
    let datas = {
      popupName: 'status',
    };
    this.popup2 = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD CLOSE STATUSES',
      nzContent: StatusPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.close_status,
        derivedFromChild: this.formData.dfc,
        selectedStatus: 'closeStatus',
      },
    });
    this.popup2.afterClose.subscribe((res: any) => {
      if (res != 'cancel') {
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.close_status = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }
  delteCloseStatus(i: number): void {
    if (i >= 0 && i < this.formData.fieldForm.close_status.length) {
      this.formData.fieldForm.close_status.splice(i, 1);
    }
  }
  filteredStatusOptions: any;
  filterStatusDefault(searchValue: any) {
    this.filteredStatusOptions = this.getDefaultStatusOptions().filter(
      (option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  getDefaultStatusOptions(): any {
    return [
      ...this.formData.fieldForm.open_status,
      ...this.formData.fieldForm.close_status,
    ];
  }
  addtag(value: string) {
    let datas = {
      popupName: value,
    };
    this.popup3 = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD TAG OPTION',
      nzContent: TagPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.options,
      },
    });
    this.popup3.afterClose.subscribe((res:any) => {
      if (res != 'cancel') {
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.options = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }
  deletetag(i: number) {
    if (i >= 0) {
      this.formData.fieldForm.options.splice(i, 1);
    }
  }
  saveTagDefault: any;
  filterTagDefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveTagDefault.filter(
      (option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  getEmojiicon(v: any, type: any) {
    let result = this.formData.fieldForm.emojiType.find(
      (e: any) => e.value == v
    );
    if (type == 'fill') {
      return result.img1;
    } else {
      return result.img2;
    }
  }
  marks: NzMarks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };
  optionTypeChange(e: any) {
    if (e === 'API') {
      this.selectAPI.emit({ action: true });
    }
  }
  saveDefaultValue: any;
  addDropDownoption(value: string) {
    let datas = {
      popupName: value,
    };
    this.popup4 = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD VALUES',
      nzContent: DropdownComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.options,
      },
    });
    this.popup4.afterClose.subscribe((res:any) => {
      if (res != 'cancel') {
        const flattenedArray = []?.concat(...res);
        this.formData.fieldForm.options = flattenedArray;
        this.saveDefaultValue = this.formData.fieldForm.options;
      }
    });
  }
  delteDropDown(i: number): void {
    if (i >= 0 && i < this.formData.fieldForm.options.length) {
      this.formData.fieldForm.options.splice(i, 1);
    }
  }
  filterdefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveDefaultValue.filter(
      (option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  save_api_options: any;
  @Input() select_api_options: any = [];
  selectApiChange(e: any) {
    this.selectAPI.emit({ action: true, data: e });
  }
  filterapi(searchValue: any) {
    this.select_api_options = this.save_api_options.filter((option: any) =>
      option.api_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  Save_api_field_options: any;
  @Input() api_field_options: any = [];
  filterFieldOption(searchValue: any) {
    this.api_field_options = this.Save_api_field_options.filter((option: any) =>
      option.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  showDependentField = false;
  saveoptions: any;
  filterMultiDefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveoptions.filter((option: any) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  onPeopleOpenChange(e: any) {
    this.selectAPI.emit({ action: true, people: 'people' });
  }
  selectPeopleChange(e: any) {
    if (e) {
      this.formData.default_value = [];
    }
  }
  people$() {
    return of(this.api_field_options).pipe(
      map((e) => e[0] || {}),
      map((e) => Object.keys(e).filter((_: any) => _ !== 'rowIndex'))
    );
  }
  peopleDefaultInput: any = '';
  getPeopleOption() {
    document.querySelector('.ant-select-item-option-content');
    const elements = document.querySelectorAll(
      '.ant-select-item-option-content'
    );
    elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.style.fontSize = '12px';
      }
    });
    return of(this.api_field_options).pipe(
      map((e: any) =>
        e?.map((_e: any) => _e[this.formData?.fieldForm?.api_field])
      ),
      map((options: string[]) =>
        options?.filter((option) =>
          option
            ?.toLowerCase()
            ?.includes(this.peopleDefaultInput?.toLowerCase())
        )
      )
    );
  }
  showGridChange(event: any) {
    this.formData.showGrid = event;
  }
  entityField: any = [];
  onCanGroupChange(e: any) {
    if (e === false) {
      this.formData.default_group = false;
      this.entityField = [];
    }
  }
  onDefaultGropChange(e: any) {
    // if (e === true) {
    //   this.entityField = this.entities
    //     .find((item: any) => item.name === this.tabName)
    //     ?.fields.filter((item: any) => item.default_group === true);
    //   if (this.entityField.length > 0) {
    //     this.formData['defaultGroup'] == true;
    //   } else {
    //     this.formData['defaultGroup'] == false;
    //     this.entityField = [];
    //   }
    // } else {
    //   this.entityField = [];
    // }
  }
  getTabNames() {
    // return of(
    //   this.erulistData.entities[
    //     this.erulistData.entities.findIndex((e: any) => e.name === this.tabName)
    //   ].tabs
    // );
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  closeDrawer() {
    this.close.emit('close');
  }
  entityFields: any = [];
  onSave() {
    if(this.saveValue){
      return
    }
    if (this.checkValidation(this.formData)) {
      // let fields =
      //   this.erulistData.entities[
      //     this.erulistData.entities.findIndex(
      //       (e: any) => e.name === this.tabName
      //     )
      //   ].fields;
      let fields = []
      let data: any = {
        // entity_name: this.tabName,
        field: {
          name: this.formData?.fieldName.value,
          label: this.formData?.fieldLabel.value,
          default: this.formData?.default_value,
          datatype: this.selectedMenu,
          // tab_name: this.formData.tab_name.value,
          can_group: this.formData?.can_group,
          mandatory: this.formData?.isMandatory,
          // is_hidden: this.formData?.is_hidden,
          editable: this.formData?.editable,
          is_unique: this.formData?.is_unique,
          // show_grid:
          //   this.formData.showGrid === true ||
          //   this.formData.showGrid === 'yes' ||
          //   this.formData.showGrid === 'always'
          //     ? 'yes'
          //     : 'no',
          grid_index: this.formData.showGrid ? fields.length + 1 : -1,
          default_group: this.formData?.default_group,
          tool_tip: this.formData.tooltip.value,
          is_pii: this.formData.is_pii,
          to_encrypt: this.formData.to_encrypt,
          is_ephi: this.formData.is_ephi,
          dfc: this.formData.dfc,
          ce: this.formData.ce,
          cef: this.formData.cef,
          cefa: this.formData.cefa,
          tf_idx: this.isEdit ? this.formData.tf_idx : fields.length + 1,
        },
      };
      if (
        this.formData.dfc &&
        this.formData.ce === '' &&
        this.formData.cef === ''
      ) {
        return;
      }
      if (this.formData.data_type.value === 'textbox') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData.is_unique?'': this.formData?.default_value,
            data_length: this.formData?.fieldForm.dataLength.value,
            data_length_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'textarea') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            data_length: this.formData?.fieldForm.dataLength.value,
            data_length_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'number') {
        let defaultValue!: Number;
        if (this.formData?.default_value) {
          let defaltData = Number(this.formData?.default_value).toFixed(
            this.formData?.fieldForm?.Decimal?.value
          );
          defaultValue = parseFloat(defaltData);
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: defaultValue || null,
            decimal: this.formData.fieldForm.Decimal.value,
            seperator: this.formData.fieldForm.Seperator.value,
            num_val: this.formData?.fieldForm.dataLength.value,
            num_val_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'currency') {
        let defaultValue!: Number;
        if (this.formData?.default_value) {
          let defaltData = Number(this.formData?.default_value).toFixed(
            this.formData?.fieldForm?.Decimal?.value
          );
          defaultValue = parseFloat(defaltData);
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: defaultValue || null,
            symbol: this.formData.fieldForm.Symbol.value,
            decimal: this.formData.fieldForm.Decimal.value,
            seperator: this.formData.fieldForm.Seperator.value,
            num_val: this.formData?.fieldForm.dataLength.value,
            num_val_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (
        ['rm', 'phone', 'attachment'].includes(
          this.formData.data_type.value
        )
      ) {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
          },
        };
      } else if (this.formData.data_type.value === 'progress') {
        if(!(this.formData?.fieldForm.startValue.value.toString().length>0) || !(this.formData?.fieldForm.endValue.value.toString().length>0)){
          return
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: Number(this.formData?.default_value),
            startValue: this.formData?.fieldForm.startValue.value,
            endValue: this.formData?.fieldForm.endValue.value,
          },
        };
      } else if (this.formData.data_type.value === 'date') {
        data['field'] = {
          ...data['field'],
          ...{
            default:
              this.formData?.default_value === 'Current_Date'
                ? 'Current_Date'
                : '',
            date_format: this.formData?.fieldForm.dateFormat.value,
            allow_days: this.formData?.fieldForm.AllowDays.filter(
              (day: any) => day.checked == true
            )?.map((item: any) => item.value),
          },
        };
      } else if (this.formData.data_type.value === 'rating') {
        data['field'] = {
          ...data['field'],
          ...{
            start_value: 1,
            end_value: this.formData?.fieldForm.number.value,
            emoji_value: this.formData?.fieldForm?.emojiValue? this.formData?.fieldForm?.emojiValue:'(smile)',
          },
        };
      } else if (this.formData.data_type.value === 'dropdown_single_select') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            option_type: this.formData?.fieldForm.optionType,
            api_name: this.formData.fieldForm.api_name,
            api_field: this.formData.fieldForm.api_field,
            entity_name: this.formData.fieldForm.entity_name,
            field_name: this.formData.fieldForm.field_name,
            options: this.formData.fieldForm.options,
            dpef:
              this.formData.fieldForm.optionType === 'ENTITY_DATA'
                ? this.formData.fieldForm.dpef
                : undefined,
            def:
              this.formData.fieldForm.optionType === 'ENTITY_DATA' ||
              this.formData.fieldForm.optionType === 'API'
                ? this.formData.fieldForm.def
                : undefined,
          },
        };

        if (
          (this.formData.fieldForm.optionType === 'ENTITY_DATA' &&
            this.formData.fieldForm.def !== '' &&
            this.formData.fieldForm.dpef === '') ||
          (this.formData.fieldForm.def === '' &&
            this.formData.fieldForm.dpef !== '')
        ) {
          return;
        }
      } else if (this.formData.data_type.value === 'dropdown_multi_select') {
        data['field'] = {
          ...data['field'],
          ...{
            default:
              this.formData?.default_value?.length > 0
                ? [this.formData?.default_value]
                : [],
            option_type: this.formData?.fieldForm.optionType,
            api_name: this.formData.fieldForm.api_name,
            api_field: this.formData.fieldForm.api_field,
            entity_name: this.formData.fieldForm.entity_name,
            field_name: this.formData.fieldForm.field_name,
            options: this.formData.fieldForm.options,
            dpef:
              this.formData.fieldForm.optionType === 'ENTITY_DATA'
                ? this.formData.fieldForm.dpef
                : undefined,
            def:
              this.formData.fieldForm.optionType === 'ENTITY_DATA' ||
              this.formData.fieldForm.optionType === 'API'
                ? this.formData.fieldForm.def
                : undefined,
          },
        };
        if (
          (this.formData.fieldForm.optionType === 'ENTITY_DATA' &&
            this.formData.fieldForm.def !== '' &&
            this.formData.fieldForm.dpef === '') ||
          (this.formData.fieldForm.def === '' &&
            this.formData.fieldForm.dpef !== '')
        ) {
          return;
        }
      } else if (this.formData.data_type.value === 'status') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            open_status: this.formData.fieldForm.open_status,
            close_status: this.formData.fieldForm.close_status,
          },
        };
      } else if (this.formData.data_type.value === 'Checkbox') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
          },
        };
      } else if (this.formData.data_type.value === 'location') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData.fieldForm.options,
          },
        };
      } else if (this.formData.data_type.value === 'tag') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData?.fieldForm.options,
          },
        };
      } else if (this.formData.data_type.value === 'people') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            api_field: this.formData.fieldForm.api_field,
          },
        };
      } else if (this.formData.data_type.value === 'priority') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData?.fieldForm.options,
          },
        };
      }
      else if(this.formData.data_type.value === 'website'){
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData.is_unique?'': this.formData?.default_value,
          },
        };
      }
      if (data.field.default_group) {
        this.entityFields = this.entities
          .find(
            (item: any) => item.name === this.formData.fieldForm.entity_name
          )
          ?.fields.map((e: any) => ({ ...e, default_group: false }));
      }
      // if (this.isEdit) {
      //   data.field.tf_idx = this.selectedDataType?.tf_idx;
      //   this.editSaveColumns.emit(data);
      // } else {
        let datas = {
          data: data,
          form: {
            ...this.form,
            option_type: this.form.option_type.toString(),
          },
        };
        if (!(this.number_default_error === '')) {
          return;
        }
        this.addSaveColumns.emit(datas);
      // }
    }
  }

  checkValidation(formData: any) {
    let errors = [];
    let keysToValidate = ['fieldName', 'fieldLabel'];
    Object.keys(formData).forEach((_: any) => {
      if (keysToValidate.includes(_)) {
        if (formData[_].validators.required) {
          if (!(formData[_].value.length > 0)) {
            formData[
              _
            ].validators.error = `${formData[_].placeholder} is required.`;
          } else {
            formData[_].validators.error = ``;
          }
        }
        if (formData[_].validators.required && formData[_].value.length > 0) {
          if (formData[_]?.validators?.inputValidators?.length > 0) {
            if (formData[_].value.split(' ').length > 1) {
              formData[_].validators.error = `No spaces allowed.`;
            } else if (_ === 'fieldName') {
              if (this.allFields.includes(formData[_].value) && !this.isEdit) {
                formData[_].validators.error = `Field Name already exists.`;
              }
            } else {
              formData[_].validators.error = ``;
            }
          }
        }
      }else{
        if(formData.data_type.value ==='people'){
          if(!formData.fieldForm.api_field){
            formData.fieldForm.api_field_validators.error = `Field Name is required.`
            errors.push(true);
          }else{
            formData.fieldForm.api_field_validators.error = ''            
          }
        }
        if(formData.data_type.value === 'currency' || formData.data_type.value === 'number' || formData.data_type.value === 'textbox' || formData.data_type.value === 'textarea'){
       
          if(!(formData?.fieldForm?.dataLength?.value?.length === 0) && (formData?.fieldForm?.dataLengthCheck.value?.length === 0)){
            errors.push(true);
          }
        }
        
      }
    });
    Object.keys(formData).forEach((_: any) => {
      if (keysToValidate.includes(_)) {
        if (formData[_].validators.error) {
          errors.push(true);
        }
      }
    });
    return errors.length == 0;
  }
  hexToRgba(hex: string, alpha: number): string {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  checkInputValidation(value:string,error:any){
    if(value?.length>0){
      if( error==='fieldName'){
      this.formData.fieldName.validators.error = ''

      }
      if( error==='fieldLabel'){
        this.formData.fieldLabel.validators.error = ''
  
        }
    }
  }
  numberInputValue(value: any) {
    if (this.formData?.fieldForm?.dataLength.value === '') {
      this.formData.fieldForm.dataLengthCheck.value = '';
      this.number_default_error = '';
    }
    if (
      this.formData?.fieldForm?.dataLength?.value?.toString() === '' ||
      !this.formData?.fieldForm?.dataLength?.value?.toString()
    ) {
      this.formData.fieldForm.dataLengthCheck.value = '';
      this.number_default_error = '';
    }
  }
  number_default_error: any = '';
  numberFieldValidation(value: any, event: any, value_check: any) {
    if (value && value_check) {
      let inputText = event?.replace(/[-.]/g, '');
      let length = Number(value);
      if (event?.length > 0) {
        if (value_check === 'MAX') {
          if (inputText?.length > length) {
            this.number_default_error = `Maximum value is ${value}`;
          } else {
            this.number_default_error = '';
          }
        } else if (value_check === 'MIN') {
          if (inputText?.length < length) {
            this.number_default_error = `Minimum value is ${value}`;
          } else {
            this.number_default_error = '';
          }
        } else {
          this.number_default_error = '';
        }
      } else {
        this.number_default_error = '';
      }
    } else {
      this.number_default_error = '';
    }
  }
  textboxValidation(value: any, event: any, value_check: any) {
    let inputText = event;
    let length = Number(value);
    if (event?.length > 0) {
      if (value_check === 'MAX') {
        if (inputText?.length > length) {
          this.number_default_error = `Maximum value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else if (value_check === 'MIN') {
        if (inputText?.length < length) {
          this.number_default_error = `Minimum value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else if (value_check === 'EXACT') {
        if (inputText?.length !== length) {
          this.number_default_error = `Exact value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else {
        this.number_default_error = '';
      }
    } else {
      this.number_default_error = '';
    }
  }
  fieldData: any;
  storeFieldData: any;
  getSelectedTabField() {
    this.fieldData = this.availableFields;
    this.storeFieldData = this.availableFields;
    this.cdr.detectChanges();
  }
  Fieldentity(event: any) {
    if (event) {
      this.parentEntityFields = true;
    } else {
      this.parentEntityFields = false;
      this.formData.fieldForm.def = '';
    }
  }
  filterFieldData(input: any) {
    this.fieldData = this.storeFieldData.filter((e: any) =>
      e.label.toLowerCase().includes(input.toLowerCase())
    );
  }
}
