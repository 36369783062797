import { Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { setDefaultProcessToState } from '@processo/shared/state/appstate.logic';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit{
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  public badgeCount:number = 20;
  @Input()routerData:any=signal(null);
  appStateService = inject(AppstateService);
  tokenService = inject(TokenService);
  router = inject(Router);
  stateService = inject(StateService);
  orgList = this.appStateService.getOrgList
  selectedOrganization = this.appStateService.getCurrentOrganization;
  filteredOrgList=this.orgList()
  ngOnInit(): void {
    const userObj = this.stateService.isUserLoggedIn().userDetail.attributes;
    this.firstName = userObj?.first_name.charAt(0);
    this.lastName = userObj?.last_name.charAt(0);
    this.fullName = `${userObj?.first_name} ${userObj?.last_name}`;
  }
  async signOut() {
    let body = {
      refresh_token: this.tokenService?.getRefreshToken()
    }
    this.stateService.logout(body).subscribe((res) => {
      if (res) {
        this.stateService.clearState();
      }
    })
    this.stateService.clearState();
    this.appStateService.clearAppState() 
}
  setting(){
   let value ={
      route:this.router.url,
      from:'table'
     }
     
     this.appStateService.state_url_save.next(value)
    this.router.navigate(['/org',this.appStateService.getCurrentOrganization()?.org_name,'basic'])
  }
  filterorg(searchValue: any) {

    if (!searchValue) {
      this.filteredOrgList = this.orgList();
    } else {
      this.filteredOrgList = this.orgList()
        .filter((option: any) =>
          option.org_name.toLowerCase().includes(searchValue.toLowerCase())
        );
    }
  }
  async onOrganizationSelection(org:any){
    this.appStateService.updateDefaultOrganization(org)
    await setDefaultProcessToState({appStateService:this.appStateService,stateService:this.stateService});
    const orgName = this.appStateService.getCurrentOrganization().router_org_value
    const processName =this.appStateService.getCurrentProcess().router_process_value
    this.router.navigateByUrl(`/org/${orgName}/process/${processName}/view`)
  }
  go_to(value:any){
    if(value === 'org'){
      
    this.router.navigateByUrl('org/list');
    }else if(value ==='profile'){
      this.router.navigateByUrl('user/settings')
    }
  }
  open_process_menu:boolean = false;
  open_menu(){
    this.open_process_menu = true;
  }
  close_menu(){
    this.open_process_menu = false;

  }
  side_bar_output(event:any){
    if(event === 'close'){
      this.close_menu()
    }
    
  }
}
