import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-table-collapse',
  templateUrl: './table-collapse.component.html',
  styleUrls: ['./table-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCollapseComponent implements OnDestroy {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() entry: any;
  @Output() detect = new EventEmitter<any>();
  rotateImg: boolean = true;
  service = inject(ProcessoCustomTableService);
  private subscription!: Subscription;

  constructor(private cdr: ChangeDetectorRef) {
    this.subscription = this.service
      .getShowHideSubject()
      .subscribe((val: any) => {
        if (val.includes(this.entry?.id)) {
          this.rotateImg = false;
        } else {
          this.rotateImg = true;
        }

        cdr.markForCheck();
      });
  }

  selectedCollapse() {
    this.service.updateShowHide(this.entry?.id);
    this.detect.emit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
