import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcessoCustomTableService {

  getSortedInputTypes(){
    let val = [
      {
        label: 'attachment',
        datatype: 'attachment',
        image: '../../assets/images/entityIcons/Attachment.svg',
      },
      {
        label: 'checkbox',
        datatype: 'checkbox',
        image: '../../assets/images/entityIcons/Checkbox.svg',
      },
      {
        label: 'currency',
        datatype: 'currency',
        image: '../../assets/images/entityIcons/Currency.svg',
      },
      {
        label: 'date',
        datatype: 'date',
        image: '../../assets/images/entityIcons/Calendar.svg',
      },
      {
        label: 'dropdown',
        datatype: 'dropdown_single_select',
        image: '../../assets/images/entityIcons/singleSelect.svg',
      },
      {
        label: 'dropdown (Multi)',
        datatype: 'dropdown_multi_select',
        image: '../../assets/images/entityIcons/multiSelect.svg',
      },
      {
        label: 'email',
        datatype: 'email',
        image: '../../assets/images/entityIcons/Email.svg',
      },
      {
        label: 'location',
        datatype: 'location',
        image: '../../assets/images/entityIcons/Location.svg',
      },
      {
        label: 'number',
        datatype: 'number',
        image: '../../assets/images/entityIcons/Number.svg',
      },
      {
        label: 'people',
        datatype: 'people',
        image: '../../assets/images/entityIcons/people.svg',
      },
      {
        label: 'phone',
        datatype: 'phone',
        image: '../../assets/images/entityIcons/Phone.svg',
      },
      {
        label: 'priority',
        datatype: 'priority',
        image: '../../assets/images/entityIcons/Priority.svg',
      },
      {
        label: 'progress',
        datatype: 'progress',
        image: '../../assets/images/entityIcons/Progress.svg',
      },
      {
        label: 'rating',
        datatype: 'rating',
        image: '../../assets/images/entityIcons/Rating.svg',
      },
      {
        label: 'tag',
        datatype: 'tag',
        image: '../../assets/images/entityIcons/tag.svg',
      },
      {
        label: 'text area',
        datatype: 'textarea',
        image: '../../assets/images/entityIcons/textArea.svg',
      },
      {
        label: 'textbox',
        datatype: 'textbox',
        image: '../../assets/images/entityIcons/Textbox.svg',
      },
      {
        label: 'time',
        datatype: 'time',
        image: '../../assets/images/entityIcons/Time.svg',
      },
      {
        label: 'website (url)',
        datatype: 'website',
        image: '../../assets/images/entityIcons/Website.svg',
      },
      {
        label: 'Status',
        datatype: 'status',
        image: '../../../assets/images/addNewColumnIcons/messageBox.svg',
      },
    ].sort((a, b) => a.label.localeCompare(b.label));
    
    return({
      new_fields: val,
    });
  }
  erulist: any = this.getSortedInputTypes()

  constructor(private http: HttpClient) { }
  // getCurrencyList() {
  //   return this.http
  //     .post(
  //       `https://eruql.dev.processo.io/store/processo/myquery/execute/get_countries_value`,
  //       {},
  //     )
  //     .pipe(map((res: any) => [...res[0].org]));
  // }
  getentityfielddata(body: any) {
    return this.http
      .post(
        `https://eruql.dev.processo.io/store/processo/myquery/execute/getentityfielddata`,
        body,
      )
      .pipe();
  }
  getApiFields(body: any) {
    return this.http.post(
      `https://erufunc.dev.processo.io/processo/func/exec_api`,
      body,
    );
  }
  getCountryCode(body: any) {
    return this.http.post(
      `https://erufunc.dev.processo.io/processo/func/exec_api`, body
    )
  }

//grouping toggle and toggle off functionality

  private showHideSubject = new BehaviorSubject<any>([]);

  public updateShowHide(value: boolean) {
    const currentValue = this.showHideSubject.getValue()
    if (!currentValue.includes(value)) {
      const updatedArray = [...this.showHideSubject.value, value];
      this.showHideSubject.next(updatedArray);
    }else{
      const updatedArray =   currentValue.filter((E:any)=>E!=value);
      this.showHideSubject.next(updatedArray);

    }
  }

  public getShowHideSubject(): Observable<boolean> {
    return this.showHideSubject.asObservable();
  }
//maintaining column headers
private columnHeaderSubject = new BehaviorSubject<any>([]);

public updateColumnHeader(value: any,event:any) {
  const currentValue = this.showHideSubject.getValue()

}

public updateColumnHeaderSubject(): Observable<boolean> {
  return this.columnHeaderSubject.asObservable();
}



//dynamic width controlling
private dynamicWidthSubject = new BehaviorSubject<any>({
  add:50,
  action:100,
  rating: 130,
  dropdown: 150,
  textbox: 200,
  location: 150,
  currency: 200,
  number: 150,
  website: 200,
  labels: 200,
  textarea: 400,
  people: 250,
  phone: 250,
  email: 150,
  progress: 200,
  attachment: 100,
  text_area_unwrapped: 200,
  checkbox: 150,
  dropdown_multi_select: 300,
  dropdown_single_select: 300,
  image: 100,
  date: 150,
  status: 150,
  tag: 350,
});

public setDynamicWidth(value: any, type: any) {
  const currentValue = this.dynamicWidthSubject.getValue();
  currentValue[type] = value;
  this.dynamicWidthSubject.next(currentValue);
}

public getDynamicWidthSubject(): Observable<any> {
  return this.dynamicWidthSubject.asObservable();
}
public countryCode=[]
}
