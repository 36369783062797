import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-custom-table-header',
  templateUrl: './custom-table-header.component.html',
  styleUrls: ['./custom-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableHeaderComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() headerData: any;
  @Input() dynamicWidth: any;
  @Input() parent: any;
  @Input() key: any;
  headerTexts: any = [
    {
      name: 'rating',
      dataType: 'rating',
    },
    {
      name: 'dropdown',
      dataType: 'dropdown',
    },
    {
      name: 'textbox',
      dataType: 'textbox',
    },
    {
      name: 'location',
      dataType: 'location',
    },
    {
      name: 'currency',
      dataType: 'currency',
    },
    {
      name: 'number',
      dataType: 'number',
    },
    {
      name: 'website',
      dataType: 'website',
    },
    {
      name: 'labels',
      dataType: 'labels',
    },
    {
      name: 'textarea',
      dataType: 'textarea',
    },
    {
      name: 'people',
      dataType: 'people',
    },
    {
      name: 'phone',
      dataType: 'phone',
    },
    {
      name: 'email',
      dataType: 'email',
    },
    {
      name: 'progress',
      dataType: 'progress',
    },
    {
      name: 'attachment',
      dataType: 'attachment',
    },
    {
      name: 'text_area_unwrapped',
      dataType: 'text_area_unwrapped',
    },
    {
      name: 'checkbox',
      dataType: 'checkbox',
    },
    {
      name: 'dropdown_multi_select',
      dataType: 'dropdown_multi_select',
    },
    {
      name: 'dropdown_single_select',
      dataType: 'dropdown_single_select',
    },
    {
      name: 'date',
      dataType: 'date',
    },
    {
      name: 'image',
      dataType: 'image',
    },
    {
      name: 'status',
      dataType: 'status',
    },
    {
      name: 'tag',
      dataType: 'tag',
    },
  ];
  showHeader = true;
  constructor(
    private cdr: ChangeDetectorRef,
    private service: ProcessoCustomTableService
  ) {
    this.service.getShowHideSubject().subscribe((val: any) => {
      if (val.includes(this.key)) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }

      cdr.markForCheck();
    });
  }
  ngOnInit(): void {
    this.cdr.markForCheck();
  }

  openAction() {
    this.parent.openAction();
  }

  onResize(e: any, d: any) {
    // this.parent.onResize(e, d);
  }

  getSortOrder(h: any) {
    return this.parent.getSortOrder(h);
  }

  addToSortingOrder(h: any) {
    this.parent.addToSortingOrder(h);
  }

  removeHeader(header: any) {
    this.parent.removeTabelHeader(header);
    this.cdr.markForCheck();
  }

  dropColumn(index: any) {
    this.parent.dropColumn(index);
    this.cdr.markForCheck();
  }
  getWidth(width: any) {
    return width;
  }
}
