import { Component, Input, OnDestroy, inject, signal } from '@angular/core';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import { Subject } from 'rxjs';

interface ProfileTab {
  name: string;
  display_name: string;
}

interface Entity {
  name: string;
  display_name: string;
  index: string;
  hide_entity: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  @Input() routerData: any = signal(null);
  private _destroy$ = new Subject<void>();
  app_service = inject(AppstateService);
  private state = inject(StateService);
  profileTabs: ProfileTab[] = [
    { name: 'settings', display_name: 'Settings' },
    { name: 'invitations', display_name: 'Invitations' },
  ];

  hide_selected_tab: boolean = false;

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isAdmin(): boolean {
    let UserIds =
      this.state.appState()?.selectedProcess?.public___vw_org_process_users;
    let loggedInUser = this.state.isUserLoggedIn()?.userDetail?.id;
    let user = UserIds?.find((u: any) => u.user_id == loggedInUser);
    return user?.user_access == 'ADMIN';
  }
}
